.wx-textarea.x2-1eba9c5 {
    display: block;
    resize: vertical;
    width: var(--wx-input-width);
    max-width: 100%;
    padding: var(--wx-input-padding);
    outline: none;
    min-height: 100px;
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weight);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    background: var(--wx-input-background)
}

.wx-textarea.x2-1eba9c5:focus {
    border: var(--wx-input-border-focus)
}

.wx-textarea.x2-1eba9c5::placeholder {
    color: var(--wx-input-placeholder-color)
}

.wx-textarea[disabled].x2-1eba9c5 {
    cursor: not-allowed;
    border: var(--wx-input-border-disabled);
    color: var(--wx-color-font-disabled);
    background: var(--wx-input-background-disabled);
    resize: none
}

.wx-textarea[disabled].x2-1eba9c5::placeholder {
    color: var(--wx-color-font-disabled)
}

.wx-textarea.wx-error.x2-1eba9c5 {
    border-color: var(--wx-color-danger);
    color: var(--wx-color-danger)
}

.wx-button.x2-ap8ojf.x2-ap8ojf {
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    text-align: center;
    letter-spacing: normal;
    text-transform: var(--wx-button-text-transform);
    font-family: var(--wx-button-font-family);
    font-size: var(--wx-button-font-size);
    line-height: var(--wx-button-line-height);
    font-weight: var(--wx-button-font-weight);
    padding: var(--wx-button-padding);
    border: var(--wx-button-border);
    border-radius: var(--wx-button-border-radius);
    background-color: var(--wx-button-background);
    color: var(--wx-button-font-color);
    cursor: pointer;
    box-shadow: none;
    transition: none;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    min-width: var(--wx-button-width)
}

.wx-button.x2-ap8ojf.x2-ap8ojf:hover {
    background-image: linear-gradient(#0000001a 0% 100%)
}

.wx-button.x2-ap8ojf.x2-ap8ojf,
.wx-button.x2-ap8ojf.x2-ap8ojf:focus,
.wx-button.x2-ap8ojf.x2-ap8ojf:active {
    outline: none
}

.wx-button.x2-ap8ojf.x2-ap8ojf:active:not([disabled]) {
    opacity: .8
}

.wx-button[disabled].x2-ap8ojf.x2-ap8ojf {
    cursor: not-allowed;
    background: var(--wx-color-disabled);
    border-color: transparent;
    color: var(--wx-color-font-disabled)
}

.wx-block.x2-ap8ojf.x2-ap8ojf {
    display: block;
    width: 100%
}

.wx-square.x2-ap8ojf.x2-ap8ojf {
    border-radius: 0
}

i.x2-ap8ojf.x2-ap8ojf {
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-size: var(--wx-button-icon-size);
    line-height: 1;
    height: var(--wx-button-line-height);
    margin-right: 2px;
    opacity: .7
}

i.x2-ap8ojf.x2-ap8ojf:before {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%)
}

.wx-icon.x2-ap8ojf.x2-ap8ojf {
    padding-left: var(--wx-button-icon-indent);
    padding-right: var(--wx-button-icon-indent);
    min-width: auto
}

.wx-icon.x2-ap8ojf i.x2-ap8ojf {
    margin: 0;
    opacity: 1
}

.wx-primary.x2-ap8ojf.x2-ap8ojf {
    background-color: var(--wx-color-primary);
    color: var(--wx-color-primary-font)
}

.wx-secondary.x2-ap8ojf.x2-ap8ojf {
    background: var(--wx-color-secondary);
    color: var(--wx-color-secondary-font);
    border-color: var(--wx-color-secondary-border)
}

.wx-secondary.x2-ap8ojf.x2-ap8ojf:hover:not([disabled]),
.wx-secondary.wx-pressed.x2-ap8ojf.x2-ap8ojf:not([disabled]),
.wx-secondary.wx-pressed.x2-ap8ojf.x2-ap8ojf:hover:not([disabled]),
.wx-secondary.wx-pressed.x2-ap8ojf.x2-ap8ojf:active:not([disabled]) {
    background: var(--wx-color-secondary-hover);
    color: var(--wx-color-secondary-font-hover)
}

.wx-secondary[disabled].x2-ap8ojf.x2-ap8ojf {
    border-color: var(--wx-color-secondary-border-disabled)
}

.wx-danger.x2-ap8ojf.x2-ap8ojf {
    background-color: var(--wx-color-danger);
    color: var(--wx-button-danger-font-color)
}

.wx-link.x2-ap8ojf.x2-ap8ojf {
    color: var(--wx-color-link);
    padding: 0;
    border: none;
    vertical-align: baseline
}

.wx-link.x2-ap8ojf.x2-ap8ojf,
.wx-link.x2-ap8ojf.x2-ap8ojf:hover,
.wx-link[disabled].x2-ap8ojf.x2-ap8ojf {
    background: transparent
}

.wx-link[disabled].x2-ap8ojf.x2-ap8ojf {
    color: var(--wx-color-font-disabled)
}

.wx-pressed.x2-ap8ojf.x2-ap8ojf,
.wx-pressed.x2-ap8ojf.x2-ap8ojf:hover,
.wx-pressed.x2-ap8ojf.x2-ap8ojf:active,
.wx-pressed[disabled].x2-ap8ojf.x2-ap8ojf {
    opacity: .8;
    background-image: linear-gradient(#0000001a 0% 100%);
    box-shadow: inset 0 2px 2px 1px #00000026
}

.wx-checkbox.x2-1va8f8p.x2-1va8f8p {
    position: relative;
    display: inline-block;
    vertical-align: top;
    max-width: var(--wx-input-width)
}

input.x2-1va8f8p.x2-1va8f8p {
    appearance: none;
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0
}

label.x2-1va8f8p.x2-1va8f8p {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    font-family: var(--wx-checkbox-font-family);
    font-size: var(--wx-checkbox-font-size);
    line-height: var(--wx-checkbox-line-height);
    font-weight: var(--wx-checkbox-font-weight);
    color: var(--wx-checkbox-font-color);
    cursor: pointer
}

span.x2-1va8f8p.x2-1va8f8p {
    display: block
}

span.x2-1va8f8p+span.x2-1va8f8p {
    margin-left: 8px;
    padding-top: calc((var(--wx-checkbox-height) - var(--wx-checkbox-line-height)) / 2);
    padding-bottom: calc((var(--wx-checkbox-height) - var(--wx-checkbox-line-height)) / 2)
}

span.x2-1va8f8p.x2-1va8f8p:first-child {
    position: relative;
    flex-shrink: 0;
    padding-top: calc((var(--wx-checkbox-height) - var(--wx-checkbox-size)) / 2);
    padding-bottom: calc((var(--wx-checkbox-height) - var(--wx-checkbox-size)) / 2)
}

span.x2-1va8f8p.x2-1va8f8p:first-child:before {
    content: "";
    display: block;
    width: var(--wx-checkbox-size);
    height: var(--wx-checkbox-size);
    border: var(--wx-checkbox-border-width) solid var(--wx-checkbox-border-color);
    border-radius: var(--wx-checkbox-border-radius);
    background: var(--wx-input-background)
}

span.x2-1va8f8p.x2-1va8f8p:first-child:after {
    content: "";
    position: absolute;
    display: none;
    left: 50%;
    top: 50%;
    width: calc(var(--wx-checkbox-size) * .56);
    height: calc(var(--wx-checkbox-size) * .32);
    border-style: solid;
    border-color: var(--wx-color-primary-font);
    border-width: 0 0 calc(var(--wx-checkbox-size) * .12) calc(var(--wx-checkbox-size) * .12);
    transform: rotate(-45deg);
    margin-left: calc(var(--wx-checkbox-size) * -.26);
    margin-top: calc(var(--wx-checkbox-size) * -.24)
}

input:checked~label.x2-1va8f8p span.x2-1va8f8p:first-child:before {
    background: var(--wx-color-primary);
    border-color: transparent
}

input:checked~label.x2-1va8f8p span.x2-1va8f8p:first-child:after {
    display: block
}

input[disabled].x2-1va8f8p~label.x2-1va8f8p {
    color: var(--wx-checkbox-border-color-disabled);
    cursor: not-allowed
}

input[disabled]:not(:checked)~label.x2-1va8f8p span.x2-1va8f8p:first-child:before {
    border-color: var(--wx-checkbox-border-color-disabled)
}

input[disabled]:checked~label.x2-1va8f8p span.x2-1va8f8p:first-child:before {
    background: var(--wx-checkbox-border-color-disabled)
}

input[disabled]~label.x2-1va8f8p span.x2-1va8f8p:first-child:after {
    border-color: var(--wx-input-background)
}

.wx-checkboxgroup.x2-8ubv9s.x2-8ubv9s {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: calc(var(--wx-field-gutter) * -1)
}

.wx-item.x2-8ubv9s.x2-8ubv9s {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: var(--wx-field-gutter)
}

.wx-checkboxgroup.wx-inline.x2-8ubv9s .wx-item.x2-8ubv9s {
    flex: none;
    padding-right: var(--wx-field-gutter)
}

.wx-checkboxgroup.grid.x2-8ubv9s .wx-item.x2-8ubv9s {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: var(--wx-field-gutter)
}

.wx-dropdown.x2-1jzzq2v {
    position: absolute;
    z-index: 5;
    background: var(--wx-popup-background);
    box-shadow: var(--wx-popup-shadow);
    border: var(--wx-popup-border);
    border-radius: var(--wx-popup-border-radius);
    overflow: hidden
}

.wx-top-center.x2-1jzzq2v {
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%) translateY(-2px)
}

.wx-top-start.x2-1jzzq2v {
    top: 0;
    left: 0;
    transform: translateY(-100%) translateY(-2px)
}

.wx-top-end.x2-1jzzq2v {
    top: 0;
    right: 0;
    transform: translateY(-100%) translateY(-2px)
}

.wx-bottom-center.x2-1jzzq2v {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%) translateY(2px)
}

.wx-bottom-start.x2-1jzzq2v {
    bottom: 0;
    left: 0;
    transform: translateY(100%) translateY(2px)
}

.wx-bottom-end.x2-1jzzq2v {
    bottom: 0;
    right: 0;
    transform: translateY(100%) translateY(2px)
}

.wx-left-center.x2-1jzzq2v {
    bottom: 50%;
    left: 0;
    transform: translate(-100%, 50%) translate(-2px)
}

.wx-left-start.x2-1jzzq2v {
    top: 0;
    left: 0;
    transform: translate(-100%) translate(-2px)
}

.wx-left-end.x2-1jzzq2v {
    bottom: 0;
    left: 0;
    transform: translate(-100%) translate(-2px)
}

.wx-right-center.x2-1jzzq2v {
    bottom: 50%;
    right: 0;
    transform: translate(100%, 50%) translate(2px)
}

.wx-right-start.x2-1jzzq2v {
    top: 0;
    right: 0;
    transform: translate(100%) translate(2px)
}

.wx-right-end.x2-1jzzq2v {
    bottom: 0;
    right: 0;
    transform: translate(100%) translate(2px)
}

.wx-colorselect.x2-1zsa67.x2-1zsa67 {
    position: relative;
    width: var(--wx-input-width)
}

.wx-selected.x2-1zsa67.x2-1zsa67 {
    position: absolute;
    left: var(--wx-input-icon-indent);
    top: 50%;
    transform: translateY(-50%)
}

.wx-colors.x2-1zsa67.x2-1zsa67 {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 8px
}

.wx-color.x2-1zsa67.x2-1zsa67 {
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    border-radius: var(--wx-input-border-radius);
    cursor: pointer
}

input.x2-1zsa67.x2-1zsa67 {
    display: block;
    width: 100%;
    height: var(--wx-input-height);
    outline: none;
    background: var(--wx-input-background);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weight);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    padding: var(--wx-input-padding);
    padding-right: calc(var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2);
    padding-left: calc(var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2);
    overflow: hidden;
    text-overflow: ellipsis
}

input.wx-focus.x2-1zsa67.x2-1zsa67 {
    border: var(--wx-input-border-focus)
}

input.x2-1zsa67.x2-1zsa67::placeholder {
    color: var(--wx-input-placeholder-color)
}

input[disabled].x2-1zsa67.x2-1zsa67 {
    cursor: not-allowed;
    border: var(--wx-input-border-disabled);
    color: var(--wx-color-font-disabled);
    background: var(--wx-input-background-disabled)
}

input[disabled].x2-1zsa67.x2-1zsa67::placeholder {
    color: var(--wx-color-font-disabled)
}

input[disabled].x2-1zsa67~.wx-color.x2-1zsa67,
input[disabled].x2-1zsa67~.wx-empty.x2-1zsa67 {
    cursor: not-allowed
}

input.wx-error.x2-1zsa67.x2-1zsa67 {
    border-color: var(--wx-color-danger);
    color: var(--wx-color-danger)
}

.wx-empty.x2-1zsa67.x2-1zsa67 {
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    background: linear-gradient(to top left, #0000 0% calc(50% - 1px), red 50%, #0000 calc(50% + 1px) 100%);
    cursor: pointer;
    user-select: none
}

.wx-clear.x2-1zsa67.x2-1zsa67 {
    position: absolute;
    right: var(--wx-input-icon-indent);
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--wx-input-icon-size);
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    border-radius: var(--wx-input-border-radius);
    color: var(--wx-input-icon-color);
    cursor: pointer
}

.wx-clear.x2-1zsa67.x2-1zsa67:hover {
    background: var(--wx-background-hover)
}

.wx-colorboard.x2-8qj470 {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 8px;
    width: 100%
}

.wx-color-block.x2-8qj470 {
    height: 140px;
    width: 100%;
    position: relative;
    box-shadow: 0 1px 3px #0003
}

.wx-color-block.x2-8qj470:before,
.wx-color-block.x2-8qj470:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute
}

.wx-color-block.x2-8qj470:before {
    background-image: linear-gradient(0deg, #000, #fff0);
    z-index: 2
}

.wx-color-block.x2-8qj470:after {
    background-image: linear-gradient(90deg, #fff, #cb998000);
    z-index: 1
}

.wx-color-block-slider.x2-8qj470 {
    height: 16px;
    width: 16px;
    margin: -8px 0 0 -8px
}

.wx-slider.x2-8qj470 {
    border: 2px solid white;
    border-radius: 50%;
    position: absolute;
    z-index: 3;
    box-shadow: 0 1px 3px #0003
}

.wx-slider.x2-8qj470:focus,
.wx-slider.x2-8qj470:hover {
    outline: none;
    box-shadow: 0 1px 3px #0003, inset 0 0 4px #fff
}

.wx-color-line.x2-8qj470 {
    width: 100%;
    height: 8px;
    background-image: linear-gradient(90deg, red, #ff0 17%, #0f0 33%, #0ff, #00f 67%, #f0f 83%, red);
    position: relative;
    border-radius: 6px
}

.wx-color-line-slider.x2-8qj470 {
    height: 14px;
    width: 14px;
    margin: 0 0 0 -7px;
    top: -4px
}

.wx-color-controls.x2-8qj470 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.wx-color.x2-8qj470,
.wx-text.x2-8qj470 {
    width: calc(50% - 4px)
}

.wx-color.x2-8qj470 {
    height: 32px
}

.wx-text.x2-8qj470 {
    outline: none;
    background: var(--wx-input-background);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weight);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    padding: var(--wx-input-padding)
}

.wx-text.x2-8qj470:focus {
    border: var(--wx-input-border-focus)
}

.wx-colorpicker.x2-11liszv.x2-11liszv {
    position: relative;
    width: var(--wx-input-width)
}

.wx-color.x2-11liszv.x2-11liszv {
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    border-radius: var(--wx-input-border-radius);
    cursor: pointer;
    position: absolute;
    left: var(--wx-input-icon-indent);
    top: 50%;
    transform: translateY(-50%)
}

input.x2-11liszv.x2-11liszv {
    display: block;
    width: 100%;
    height: var(--wx-input-height);
    outline: none;
    background: var(--wx-input-background);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weight);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    padding: var(--wx-input-padding);
    padding-right: calc(var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2);
    padding-left: calc(var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2);
    overflow: hidden;
    text-overflow: ellipsis
}

input.wx-focus.x2-11liszv.x2-11liszv {
    border: var(--wx-input-border-focus)
}

input.x2-11liszv.x2-11liszv::placeholder {
    color: var(--wx-input-placeholder-color)
}

input[disabled].x2-11liszv.x2-11liszv {
    cursor: not-allowed;
    border: var(--wx-input-border-disabled);
    color: var(--wx-color-font-disabled);
    background: var(--wx-input-background-disabled)
}

input[disabled].x2-11liszv.x2-11liszv::placeholder {
    color: var(--wx-color-font-disabled)
}

input[disabled].x2-11liszv~.wx-color.x2-11liszv {
    cursor: not-allowed
}

input.wx-error.x2-11liszv.x2-11liszv {
    border-color: var(--wx-color-danger);
    color: var(--wx-color-danger)
}

.wx-list.x2-fl05h9 {
    max-height: 250px;
    overflow-y: auto
}

.wx-item.x2-fl05h9 {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weight);
    color: var(--wx-input-font-color);
    padding: var(--wx-input-padding);
    cursor: pointer
}

.wx-item.wx-focus.x2-fl05h9 {
    background: var(--wx-background-hover)
}

.wx-no-data.x2-fl05h9 {
    padding: var(--wx-input-padding)
}

.wx-combo.x2-1oh2bu.x2-1oh2bu {
    position: relative;
    width: var(--wx-input-width)
}

input.x2-1oh2bu.x2-1oh2bu {
    display: block;
    width: 100%;
    height: var(--wx-input-height);
    outline: none;
    background: var(--wx-input-background);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weight);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    padding: var(--wx-input-padding);
    padding-right: calc(var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2);
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer
}

input.x2-1oh2bu.x2-1oh2bu:focus {
    border: var(--wx-input-border-focus)
}

input.x2-1oh2bu.x2-1oh2bu::placeholder {
    color: var(--wx-input-placeholder-color)
}

input[disabled].x2-1oh2bu.x2-1oh2bu {
    cursor: not-allowed;
    border: var(--wx-input-border-disabled);
    color: var(--wx-color-font-disabled);
    background: var(--wx-input-background-disabled)
}

input[disabled].x2-1oh2bu.x2-1oh2bu::placeholder {
    color: var(--wx-color-font-disabled)
}

input[disabled].x2-1oh2bu~.wx-icon.x2-1oh2bu {
    color: var(--wx-color-font-disabled)
}

input.wx-error.x2-1oh2bu.x2-1oh2bu {
    border-color: var(--wx-color-danger);
    color: var(--wx-color-danger)
}

input.wx-error.x2-1oh2bu~.wx-icon.x2-1oh2bu {
    color: var(--wx-color-danger)
}

.wx-icon.x2-1oh2bu.x2-1oh2bu {
    position: absolute;
    right: var(--wx-input-icon-indent);
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--wx-input-icon-size);
    line-height: 1;
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    user-select: none;
    color: var(--wx-input-icon-color)
}

.wx-icon.x2-1oh2bu.x2-1oh2bu:before {
    display: block
}

.wx-icon.wxi-close.x2-1oh2bu.x2-1oh2bu {
    pointer-events: all
}

.wx-icon.wxi-close.x2-1oh2bu.x2-1oh2bu:hover {
    color: var(--wx-color-danger)
}

.wx-text.x2-1axmg32.x2-1axmg32 {
    position: relative;
    width: var(--wx-input-width)
}

.wx-text.wx-disabled.x2-1axmg32 .wx-icon.x2-1axmg32 {
    color: var(--wx-color-font-disabled)
}

.wx-text.wx-error.x2-1axmg32 .wx-icon.x2-1axmg32 {
    color: var(--wx-color-danger)
}

.wx-icon.x2-1axmg32.x2-1axmg32 {
    position: absolute;
    right: var(--wx-input-icon-indent);
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--wx-input-icon-size);
    line-height: 1;
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    user-select: none;
    color: var(--wx-input-icon-color)
}

.wx-icon.x2-1axmg32.x2-1axmg32:before {
    display: block
}

.wx-icon-left.x2-1axmg32 .wx-icon.x2-1axmg32 {
    right: auto;
    left: var(--wx-input-icon-indent)
}

input.x2-1axmg32.x2-1axmg32 {
    display: block;
    width: var(--wx-input-width);
    height: var(--wx-input-height);
    max-width: 100%;
    padding: var(--wx-input-padding);
    outline: none;
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weight);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    background: var(--wx-input-background);
    overflow: hidden;
    text-overflow: ellipsis
}

input.x2-1axmg32.x2-1axmg32:focus {
    border: var(--wx-input-border-focus)
}

input.x2-1axmg32.x2-1axmg32::placeholder {
    color: var(--wx-input-placeholder-color)
}

.wx-icon-left.x2-1axmg32 input.x2-1axmg32 {
    padding-left: calc(var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2)
}

.wx-icon-right.x2-1axmg32 input.x2-1axmg32 {
    padding-right: calc(var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2)
}

input[disabled].x2-1axmg32.x2-1axmg32 {
    cursor: not-allowed !important;
    border: var(--wx-input-border-disabled);
    color: var(--wx-color-font-disabled);
    background: var(--wx-input-background-disabled);
    pointer-events: none
}

input[disabled].x2-1axmg32.x2-1axmg32::placeholder {
    color: var(--wx-color-font-disabled)
}

.wx-error.x2-1axmg32 input.x2-1axmg32 {
    border-color: var(--wx-color-danger);
    color: var(--wx-color-danger)
}

.wx-title.x2-1axmg32 input.x2-1axmg32 {
    border: 1px solid transparent;
    font-weight: var(--wx-font-weight-md);
    font-size: var(--wx-font-size-md);
    line-height: var(--wx-line-height-md);
    color: var(--wx-color-secondary-font);
    margin-left: -8px;
    width: calc(100% + 8px)
}

.wx-title.x2-1axmg32:focus:not([disabled]) input.x2-1axmg32 {
    border: var(--wx-input-border-focus)
}

.wx-title.x2-1axmg32:hover:not([disabled]) input.x2-1axmg32 {
    border: var(--wx-input-border-focus)
}

.wx-header.x2-wurt7c {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: calc(var(--wx-calendar-gap) * 2);
    font-size: var(--wx-calendar-header-font-size);
    line-height: var(--wx-calendar-header-line-height);
    font-weight: var(--wx-calendar-header-font-weight)
}

.wx-spacer.x2-wurt7c,
.wx-pager.x2-wurt7c {
    width: var(--wx-calendar-cell-size);
    height: var(--wx-calendar-cell-size);
    flex-shrink: 0
}

.wx-pager.x2-wurt7c {
    cursor: pointer;
    border-radius: 50%;
    line-height: 1;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    user-select: none;
    color: var(--wx-calendar-icon-color);
    font-size: var(--wx-calendar-icon-size)
}

.wx-pager.x2-wurt7c:before {
    display: block
}

.wx-pager.x2-wurt7c:hover {
    background-color: var(--wx-background-hover)
}

.wx-label.x2-wurt7c {
    flex: 0 0 calc(100% - var(--wx-calendar-cell-size) * 2);
    max-width: calc(100% - var(--wx-calendar-cell-size) * 2);
    text-align: center;
    color: var(--wx-color-link);
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

button.x2-1f88uh6 {
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    text-align: center;
    letter-spacing: normal;
    font-family: var(--wx-calendar-controls-font-family);
    font-size: var(--wx-calendar-controls-font-size);
    line-height: var(--wx-calendar-controls-line-height);
    font-weight: var(--wx-calendar-controls-font-weight);
    text-transform: none;
    padding: 0;
    border: none;
    border-radius: 0;
    background: transparent;
    color: var(--wx-calendar-controls-font-color);
    cursor: pointer;
    box-shadow: none;
    transition: none;
    max-width: 100%;
    user-select: none
}

button.x2-1f88uh6,
button.x2-1f88uh6:focus,
button.x2-1f88uh6:active {
    outline: none
}

button.x2-1f88uh6:active {
    opacity: .8
}

.wx-weekdays.x2-ee96p9 {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: calc(var(--wx-calendar-gap) * 1.5)
}

.wx-weekday.x2-ee96p9 {
    flex: 0 0 calc(100% / 7);
    max-width: calc(100% / 7);
    font-size: var(--wx-font-size-sm);
    line-height: var(--wx-line-height-sm);
    color: var(--wx-color-font-alt);
    text-align: center
}

.wx-days.x2-ee96p9 {
    display: flex;
    flex-wrap: wrap
}

.wx-day.x2-ee96p9 {
    border-radius: var(--wx-calendar-border-radius);
    flex: 0 0 calc(100% / 7);
    max-width: calc(100% / 7);
    height: calc(var(--wx-calendar-cell-size) - var(--wx-calendar-line-gap) * 2);
    margin: calc(var(--wx-calendar-line-gap) / 2) 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    text-align: center
}

.wx-day.x2-ee96p9:not(.wx-out):not(.wx-selected) {
    cursor: pointer
}

.wx-day.x2-ee96p9:not(.wx-out):not(.wx-selected):hover {
    background: var(--wx-background-hover)
}

.wx-day.wx-out.x2-ee96p9 {
    color: var(--wx-color-font-disabled)
}

.wx-day.wx-selected.x2-ee96p9:not(.wx-out) {
    background: var(--wx-color-primary);
    color: var(--wx-color-primary-font)
}

.wx-day.wx-selected.wx-left.x2-ee96p9:not(.wx-out) {
    border-radius: calc(var(--wx-calendar-border-radius)) 0 0 calc(var(--wx-calendar-border-radius))
}

.wx-day.wx-selected.wx-right.x2-ee96p9:not(.wx-out) {
    border-radius: 0 calc(var(--wx-calendar-border-radius)) calc(var(--wx-calendar-border-radius)) 0
}

.wx-day.wx-inrange.x2-ee96p9:not(.wx-out) {
    border-radius: 0;
    background: var(--wx-color-primary-selected)
}

.wx-day.wx-weekend.x2-ee96p9:not(.wx-selected):not(.wx-out) {
    color: var(--wx-color-primary)
}

.wx-day.wx-inactive.x2-ee96p9 {
    pointer-events: none
}

.wx-months.x2-pmn9ti {
    display: flex;
    flex-wrap: wrap;
    margin: var(--wx-calendar-gap)
}

.wx-month.x2-pmn9ti {
    flex: 0 0 calc(100% / 4 - var(--wx-calendar-gap) * 2);
    max-width: calc(100% / 4 - var(--wx-calendar-gap) * 2);
    margin: calc(var(--wx-calendar-gap) * 2) var(--wx-calendar-gap);
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    height: var(--wx-calendar-cell-size);
    border-radius: var(--wx-calendar-border-radius)
}

.wx-month.wx-current.x2-pmn9ti {
    background: var(--wx-color-primary);
    color: var(--wx-color-primary-font)
}

.wx-month.x2-pmn9ti:not(.wx-current):hover {
    background-color: var(--wx-background-hover)
}

.wx-buttons.x2-pmn9ti {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-top: var(--wx-calendar-gap)
}

.wx-years.x2-is1ghx {
    display: flex;
    flex-wrap: wrap;
    margin: var(--wx-calendar-gap)
}

.wx-year.x2-is1ghx {
    flex: 0 0 calc(100% / 4 - var(--wx-calendar-gap) * 2);
    max-width: calc(100% / 4 - var(--wx-calendar-gap) * 2);
    margin: calc(var(--wx-calendar-gap) * 2) var(--wx-calendar-gap);
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    height: var(--wx-calendar-cell-size);
    border-radius: var(--wx-calendar-border-radius)
}

.wx-year.wx-current.x2-is1ghx {
    background: var(--wx-color-primary);
    color: var(--wx-color-primary-font)
}

.wx-year.x2-is1ghx:not(.wx-current):hover {
    background-color: var(--wx-background-hover)
}

.wx-prev-decade.x2-is1ghx,
.wx-next-decade.x2-is1ghx {
    color: var(--wx-color-font-disabled)
}

.wx-buttons.x2-is1ghx {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-top: var(--wx-calendar-gap)
}

.wx-calendar.x2-9ihaic.x2-9ihaic {
    height: auto;
    width: 100%;
    padding: var(--wx-calendar-padding);
    cursor: default;
    font-family: var(--wx-calendar-font-family);
    font-size: var(--wx-calendar-font-size);
    line-height: var(--wx-calendar-line-height);
    font-weight: var(--wx-calendar-font-weight);
    color: var(--wx-calendar-font-color)
}

.wx-calendar.wx-part.x2-9ihaic.x2-9ihaic {
    padding-bottom: 0
}

.wx-wrap.x2-9ihaic.x2-9ihaic {
    width: calc(var(--wx-calendar-cell-size) * 7);
    margin: 0 auto
}

.wx-buttons.x2-9ihaic.x2-9ihaic {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    margin-top: calc(var(--wx-calendar-gap) * 2)
}

.wx-button-item.x2-9ihaic+.wx-button-item.x2-9ihaic {
    margin-left: calc(var(--wx-calendar-gap) * 3)
}

.wx-datepicker.x2-1k3rk87 {
    position: relative;
    width: var(--wx-input-width)
}

.wx-rangecalendar.x2-wlbsu6 {
    display: flex;
    padding-bottom: var(--wx-calendar-padding)
}

.wx-half.x2-wlbsu6 {
    flex: 1
}

.wx-daterangepicker.x2-qgs2z8 {
    position: relative;
    width: var(--wx-input-width)
}

.wx-icon.x2-12ezr0r {
    color: var(--wx-color-font-alt);
    cursor: pointer;
    font-size: var(--wx-button-icon-size);
    padding: var(--wx-button-icon-indent);
    line-height: var(--wx-button-line-height);
    display: inline-block
}

.wx-icon.x2-12ezr0r:hover {
    background-color: var(--wx-background-hover)
}

.wx-multicombo.x2-ijj3jn.x2-ijj3jn {
    position: relative;
    width: var(--wx-input-width)
}

.wx-multicombo.wx-focus.x2-ijj3jn .wx-wrapper.x2-ijj3jn {
    border: var(--wx-input-border-focus)
}

.wx-multicombo.wx-disabled.x2-ijj3jn .wx-wrapper.x2-ijj3jn {
    border: var(--wx-input-border-disabled);
    background: var(--wx-input-background-disabled)
}

.wx-multicombo.wx-disabled.x2-ijj3jn .wx-tag.x2-ijj3jn {
    background: var(--wx-color-disabled);
    color: var(--wx-color-font-disabled)
}

.wx-multicombo.x2-ijj3jn:not(.wx-disabled) .wx-tag.x2-ijj3jn {
    padding-right: calc(var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2)
}

.wx-multicombo.wx-disabled.x2-ijj3jn input.x2-ijj3jn {
    cursor: not-allowed;
    color: var(--wx-color-font-disabled)
}

.wx-multicombo.wx-disabled.x2-ijj3jn .wx-icon.x2-ijj3jn {
    color: var(--wx-color-font-disabled)
}

.wx-multicombo.wx-error.x2-ijj3jn .wx-wrapper.x2-ijj3jn {
    border-color: var(--wx-color-danger)
}

.wx-multicombo.wx-error.x2-ijj3jn input.x2-ijj3jn,
.wx-multicombo.wx-error.x2-ijj3jn .wx-icon.x2-ijj3jn {
    color: var(--wx-color-danger)
}

.wx-multicombo.wx-not-empty.x2-ijj3jn .wx-tags.x2-ijj3jn {
    gap: var(--wx-multicombo-tag-gap);
    padding: var(--wx-multicombo-tag-gap)
}

.wx-multicombo.wx-not-empty.x2-ijj3jn input.x2-ijj3jn {
    border-top: var(--wx-input-border)
}

.wx-multicombo.wx-not-empty.wx-focus.x2-ijj3jn input.x2-ijj3jn {
    border-top: var(--wx-input-border-focus)
}

.wx-multicombo.wx-not-empty.wx-disabled.x2-ijj3jn input.x2-ijj3jn {
    border-top: var(--wx-input-border-disabled)
}

.wx-multicombo.wx-not-empty.wx-error.x2-ijj3jn input.x2-ijj3jn {
    border-top-color: var(--wx-color-danger)
}

.wx-wrapper.x2-ijj3jn.x2-ijj3jn {
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    background: var(--wx-input-background)
}

.wx-tags.x2-ijj3jn.x2-ijj3jn {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    overflow: hidden
}

.wx-tag.x2-ijj3jn.x2-ijj3jn {
    position: relative;
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weight);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    border: var(--wx-multicombo-tag-border);
    border-radius: var(--wx-multicombo-tag-border-radius);
    background: var(--wx-multicombo-tag-background);
    padding: var(--wx-multicombo-tag-pading)
}

.wxi-close.x2-ijj3jn.x2-ijj3jn {
    position: absolute;
    right: var(--wx-input-icon-indent);
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--wx-input-icon-size);
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    border-radius: var(--wx-input-border-radius);
    color: var(--wx-input-icon-color);
    cursor: pointer
}

.wxi-close.x2-ijj3jn.x2-ijj3jn:hover {
    color: var(--wx-color-primary)
}

.wx-select.x2-ijj3jn.x2-ijj3jn {
    position: relative
}

input.x2-ijj3jn.x2-ijj3jn {
    display: block;
    width: 100%;
    height: var(--wx-input-height);
    outline: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weight);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    padding: var(--wx-input-padding);
    padding-right: calc(var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2);
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer
}

.wx-icon.x2-ijj3jn.x2-ijj3jn {
    position: absolute;
    right: var(--wx-input-icon-indent);
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--wx-input-icon-size);
    line-height: 1;
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    user-select: none;
    color: var(--wx-input-icon-color)
}

.wx-icon.x2-ijj3jn.x2-ijj3jn:before {
    display: block
}

.wx-popup.x2-rd365y {
    position: fixed;
    z-index: var(--wx-popup-z-index);
    background: var(--wx-popup-background);
    box-shadow: var(--wx-popup-shadow);
    border: var(--wx-popup-border);
    border-radius: var(--wx-popup-border-radius);
    overflow: hidden
}

.wx-pager.x2-oo1ivx {
    display: flex;
    gap: var(--wx-padding);
    align-items: center;
    padding: var(--wx-padding)
}

input.x2-oo1ivx {
    display: block;
    width: 50px;
    height: var(--wx-input-height);
    padding: var(--wx-input-padding);
    outline: none;
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weight);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    background: var(--wx-input-background)
}

input.x2-oo1ivx:focus {
    border: var(--wx-input-border-focus)
}

input.x2-oo1ivx::placeholder {
    color: var(--wx-input-placeholder-color)
}

.wx-icon.x2-oo1ivx {
    cursor: pointer;
    padding: var(--wx-input-padding);
    border-radius: var(--wx-input-border-radius);
    font-size: var(--wx-icon-size);
    line-height: 1;
    color: var(--wx-color-link)
}

.wx-icon.x2-oo1ivx:before {
    display: block
}

.wx-icon.x2-oo1ivx:hover {
    background-color: var(--wx-background-hover)
}

.wx-left.x2-oo1ivx,
.wx-center.x2-oo1ivx,
.wx-right.x2-oo1ivx {
    display: flex;
    align-items: center;
    gap: var(--wx-padding)
}

.wx-radio.x2-9x1x11.x2-9x1x11 {
    position: relative;
    display: inline-block;
    vertical-align: top;
    max-width: var(--wx-input-width)
}

input.x2-9x1x11.x2-9x1x11 {
    appearance: none;
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0
}

label.x2-9x1x11.x2-9x1x11 {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    font-family: var(--wx-checkbox-font-family);
    font-size: var(--wx-checkbox-font-size);
    line-height: var(--wx-checkbox-line-height);
    font-weight: var(--wx-checkbox-font-weight);
    color: var(--wx-checkbox-font-color);
    cursor: pointer
}

span.x2-9x1x11.x2-9x1x11 {
    display: block
}

span.x2-9x1x11+span.x2-9x1x11 {
    margin-left: 8px;
    padding-top: calc((var(--wx-checkbox-height) - var(--wx-checkbox-line-height)) / 2);
    padding-bottom: calc((var(--wx-checkbox-height) - var(--wx-checkbox-line-height)) / 2)
}

span.x2-9x1x11.x2-9x1x11:first-child {
    position: relative;
    flex-shrink: 0;
    padding-top: calc((var(--wx-checkbox-height) - var(--wx-checkbox-size)) / 2);
    padding-bottom: calc((var(--wx-checkbox-height) - var(--wx-checkbox-size)) / 2)
}

span.x2-9x1x11.x2-9x1x11:first-child:before {
    content: "";
    display: block;
    width: var(--wx-checkbox-size);
    height: var(--wx-checkbox-size);
    border: var(--wx-checkbox-border-width) solid var(--wx-checkbox-border-color);
    border-radius: 50%;
    background: var(--wx-input-background)
}

span.x2-9x1x11.x2-9x1x11:first-child:after {
    content: "";
    position: absolute;
    display: none;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(var(--wx-checkbox-size) - var(--wx-checkbox-border-width) * 2);
    height: calc(var(--wx-checkbox-size) - var(--wx-checkbox-border-width) * 2);
    border-style: solid;
    border-color: var(--wx-input-background);
    border-width: calc(var(--wx-checkbox-size) * .2);
    border-radius: 50%
}

input:checked~label.x2-9x1x11 span.x2-9x1x11:first-child:before {
    background: var(--wx-color-primary);
    border-color: transparent
}

input:checked~label.x2-9x1x11 span.x2-9x1x11:first-child:after {
    display: block
}

input[disabled].x2-9x1x11~label.x2-9x1x11 {
    color: var(--wx-checkbox-border-color-disabled);
    cursor: not-allowed
}

input[disabled]:not(:checked)~label.x2-9x1x11 span.x2-9x1x11:first-child:before {
    border-color: var(--wx-checkbox-border-color-disabled)
}

input[disabled]:checked~label.x2-9x1x11 span.x2-9x1x11:first-child:before {
    background: var(--wx-checkbox-border-color-disabled)
}

.wx-radiogroup.x2-107sbzk.x2-107sbzk {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: calc(var(--wx-field-gutter) * -1)
}

.wx-item.x2-107sbzk.x2-107sbzk {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: var(--wx-field-gutter)
}

.wx-radiogroup.wx-inline.x2-107sbzk .wx-item.x2-107sbzk {
    flex: none;
    padding-right: var(--wx-field-gutter)
}

.wx-radiogroup.wx-grid.x2-107sbzk .wx-item.x2-107sbzk {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: var(--wx-field-gutter)
}

.wx-richselect.x2-zhb77a.x2-zhb77a {
    position: relative;
    outline: none;
    width: var(--wx-input-width);
    min-height: var(--wx-input-height);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    background: var(--wx-input-background);
    cursor: pointer
}

.wx-richselect.x2-zhb77a.x2-zhb77a:focus {
    border: var(--wx-input-border-focus)
}

.wx-richselect.wx-disabled.x2-zhb77a.x2-zhb77a {
    cursor: not-allowed;
    border: var(--wx-input-border-disabled);
    background: var(--wx-input-background-disabled)
}

.wx-richselect.wx-disabled.x2-zhb77a .wx-label.x2-zhb77a,
.wx-richselect.wx-disabled.x2-zhb77a .wx-icon.x2-zhb77a {
    color: var(--wx-color-font-disabled)
}

.wx-richselect.wx-error.x2-zhb77a.x2-zhb77a {
    border-color: var(--wx-color-danger)
}

.wx-richselect.wx-error.x2-zhb77a .wx-label.x2-zhb77a,
.wx-richselect.wx-error.x2-zhb77a .wx-icon.x2-zhb77a {
    color: var(--wx-color-danger)
}

.wx-label.x2-zhb77a.x2-zhb77a {
    display: block;
    width: 100%;
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weight);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    padding: var(--wx-input-padding);
    padding-right: calc(var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2);
    overflow: hidden
}

.wx-placeholder.x2-zhb77a.x2-zhb77a {
    color: var(--wx-input-placeholder-color)
}

.wx-icon.x2-zhb77a.x2-zhb77a {
    position: absolute;
    right: var(--wx-input-icon-indent);
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--wx-input-icon-size);
    line-height: 1;
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    user-select: none;
    color: var(--wx-input-icon-color)
}

.wx-icon.x2-zhb77a.x2-zhb77a:before {
    display: block
}

.wx-nowrap.x2-zhb77a .wx-label.x2-zhb77a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.wx-segmented.x2-1swfxt4.x2-1swfxt4 {
    display: inline-flex;
    flex-wrap: nowrap;
    background: var(--wx-segmented-background);
    border: var(--wx-segmented-border);
    border-radius: var(--wx-segmented-border-radius);
    padding: var(--wx-segmented-padding);
    max-width: 100%
}

button.x2-1swfxt4.x2-1swfxt4 {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    text-decoration: none;
    text-align: center;
    letter-spacing: normal;
    text-transform: var(--wx-button-text-transform);
    font-family: var(--wx-button-font-family);
    font-size: var(--wx-button-font-size);
    line-height: var(--wx-button-line-height);
    font-weight: var(--wx-button-font-weight);
    padding: var(--wx-button-padding);
    border: var(--wx-button-border);
    border-radius: var(--wx-segmented-border-radius);
    background: transparent;
    color: var(--wx-button-font-color);
    cursor: pointer;
    box-shadow: none;
    transition: none;
    max-width: 100%;
    user-select: none
}

button.x2-1swfxt4.x2-1swfxt4,
button.x2-1swfxt4.x2-1swfxt4:focus,
button.x2-1swfxt4.x2-1swfxt4:active {
    outline: none
}

button.x2-1swfxt4+button.x2-1swfxt4 {
    margin-left: var(--wx-segmented-padding)
}

button.x2-1swfxt4.x2-1swfxt4:hover {
    background: var(--wx-segmented-background-hover)
}

button.wx-selected.x2-1swfxt4.x2-1swfxt4,
button.wx-selected.x2-1swfxt4.x2-1swfxt4:hover,
button.wx-selected.x2-1swfxt4.x2-1swfxt4:focus {
    background: var(--wx-color-primary);
    color: var(--wx-color-primary-font);
    cursor: default
}

.wx-icon.x2-1swfxt4.x2-1swfxt4 {
    position: relative;
    font-size: var(--wx-button-icon-size);
    line-height: 1;
    height: var(--wx-button-line-height);
    opacity: .7
}

.wx-icon.x2-1swfxt4.x2-1swfxt4:before {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%)
}

.wx-icon.wx-only.x2-1swfxt4.x2-1swfxt4 {
    opacity: 1
}

.wx-icon.x2-1swfxt4+.wx-label.x2-1swfxt4 {
    margin-left: 4px
}

.wx-select.x2-f9enzu.x2-f9enzu {
    position: relative;
    width: var(--wx-input-width)
}

select.x2-f9enzu.x2-f9enzu {
    appearance: none;
    display: block;
    width: 100%;
    height: var(--wx-input-height);
    outline: none;
    background: var(--wx-input-background);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weight);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    padding: var(--wx-input-padding);
    padding-right: calc(var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2);
    overflow: hidden !important;
    text-overflow: ellipsis;
    cursor: pointer
}

select.x2-f9enzu.x2-f9enzu:focus {
    border: var(--wx-input-border-focus)
}

select[disabled].x2-f9enzu.x2-f9enzu {
    cursor: not-allowed;
    border: var(--wx-input-border-disabled);
    color: var(--wx-color-font-disabled);
    background: var(--wx-input-background-disabled)
}

select[disabled].x2-f9enzu~.wx-placeholder.x2-f9enzu {
    color: var(--wx-color-font-disabled)
}

select[disabled].x2-f9enzu~.wx-icon.x2-f9enzu {
    color: var(--wx-color-font-disabled)
}

select.wx-error.x2-f9enzu.x2-f9enzu {
    border-color: var(--wx-color-danger);
    color: var(--wx-color-danger)
}

select.wx-error.x2-f9enzu option.x2-f9enzu {
    color: var(--wx-input-font-color)
}

select.wx-error.x2-f9enzu~.wx-icon.x2-f9enzu {
    color: var(--wx-color-danger)
}

.wx-placeholder.x2-f9enzu.x2-f9enzu {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border: var(--wx-input-border);
    border-color: transparent;
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weight);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-placeholder-color);
    padding: var(--wx-input-padding);
    padding-right: calc(var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2);
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    cursor: pointer;
    pointer-events: none
}

.wx-icon.x2-f9enzu.x2-f9enzu {
    position: absolute;
    right: var(--wx-input-icon-indent);
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--wx-input-icon-size);
    line-height: 1;
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    user-select: none;
    color: var(--wx-input-icon-color)
}

.wx-icon.x2-f9enzu.x2-f9enzu:before {
    display: block
}

.wx-slider.x2-vxce8u.x2-vxce8u {
    width: var(--wx-input-width)
}

label.x2-vxce8u.x2-vxce8u {
    display: block;
    margin: var(--wx-slider-label-margin);
    font-family: var(--wx-slider-label-font-family);
    font-size: var(--wx-slider-label-font-size);
    line-height: var(--wx-slider-label-line-height);
    font-weight: var(--wx-slider-label-font-weight);
    color: var(--wx-slider-label-font-color)
}

.wx-slider.x2-vxce8u div.x2-vxce8u {
    padding: calc((var(--wx-slider-height) - var(--wx-slider-track-height)) / 2) 0
}

input.x2-vxce8u.x2-vxce8u {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    height: var(--wx-slider-track-height);
    background: var(--wx-slider-background);
    border: none;
    border-radius: var(--wx-slider-track-border-radius);
    margin: 0
}

input.x2-vxce8u.x2-vxce8u:focus {
    outline: none
}

input.x2-vxce8u.x2-vxce8u::-webkit-slider-runnable-track {
    margin: 0;
    width: 100%;
    height: var(--wx-slider-track-height);
    border: none;
    border-radius: var(--wx-slider-track-border-radius);
    cursor: pointer;
    background: transparent
}

input.x2-vxce8u.x2-vxce8u::-webkit-slider-thumb {
    margin-top: calc((var(--wx-slider-track-height) - var(--wx-slider-thumb-size)) / 2);
    width: var(--wx-slider-thumb-size);
    height: var(--wx-slider-thumb-size);
    background: var(--wx-slider-primary);
    border: var(--wx-slider-thumb-border);
    box-shadow: var(--wx-slider-thumb-shadow);
    border-radius: 50%;
    cursor: pointer;
    appearance: none
}

input.x2-vxce8u.x2-vxce8u::-moz-range-track {
    margin: 0;
    width: 100%;
    height: var(--wx-slider-track-height);
    border: none;
    border-radius: var(--wx-slider-track-border-radius);
    cursor: pointer;
    background: transparent
}

input.x2-vxce8u.x2-vxce8u::-moz-range-thumb {
    margin-top: calc((var(--wx-slider-track-height) - var(--wx-slider-thumb-size)) / 2);
    width: var(--wx-slider-thumb-size);
    height: var(--wx-slider-thumb-size);
    background: var(--wx-slider-primary);
    border: var(--wx-slider-thumb-border);
    border-radius: 50%;
    cursor: pointer;
    appearance: none
}

input.x2-vxce8u.x2-vxce8u::-moz-range-progress {
    background-color: var(--wx-slider-primary);
    height: var(--wx-slider-track-height);
    border-top-left-radius: var(--wx-slider-track-border-radius);
    border-bottom-left-radius: var(--wx-slider-track-border-radius)
}

input.x2-vxce8u.x2-vxce8u::-ms-track {
    color: transparent;
    margin: 0;
    width: 100%;
    height: var(--wx-slider-track-height);
    border: none;
    border-radius: var(--wx-slider-track-border-radius);
    cursor: pointer;
    background: transparent
}

input.x2-vxce8u.x2-vxce8u::-ms-fill-lower {
    background: var(--wx-slider-primary);
    border: .2px solid var(--wx-slider-primary);
    border-radius: var(--wx-slider-track-border-radius)
}

input.x2-vxce8u.x2-vxce8u::-ms-fill-upper {
    background: var(--wx-slider-background);
    border: .2px solid var(--wx-slider-background);
    border-radius: var(--wx-slider-track-border-radius)
}

input.x2-vxce8u.x2-vxce8u::-ms-thumb {
    margin-top: calc((var(--wx-slider-track-height) - var(--wx-slider-thumb-size)) / 2);
    width: var(--wx-slider-thumb-size);
    height: var(--wx-slider-thumb-size);
    background: var(--wx-slider-primary);
    border: var(--wx-slider-thumb-border);
    border-radius: 50%;
    cursor: pointer;
    appearance: none
}

input.x2-vxce8u.x2-vxce8u:focus::-ms-fill-lower {
    background: var(--wx-slider-primary)
}

input.x2-vxce8u.x2-vxce8u:focus::-ms-fill-upper {
    background: var(--wx-slider-background)
}

input[disabled].x2-vxce8u.x2-vxce8u {
    cursor: not-allowed;
    background: var(--wx-color-disabled)
}

input[disabled].x2-vxce8u.x2-vxce8u::-webkit-slider-runnable-track {
    cursor: not-allowed
}

input[disabled].x2-vxce8u.x2-vxce8u::-webkit-slider-thumb {
    cursor: not-allowed;
    background: var(--wx-background);
    border: var(--wx-slider-thumb-border-disabled)
}

input[disabled].x2-vxce8u.x2-vxce8u::-moz-range-track {
    cursor: not-allowed
}

input[disabled].x2-vxce8u.x2-vxce8u::-moz-range-thumb {
    cursor: not-allowed;
    background: var(--wx-background);
    border: var(--wx-slider-thumb-border-disabled)
}

input[disabled].x2-vxce8u.x2-vxce8u::-moz-range-progress {
    cursor: not-allowed;
    background: var(--wx-color-disabled)
}

input[disabled].x2-vxce8u.x2-vxce8u::-ms-track {
    cursor: not-allowed
}

input[disabled].x2-vxce8u.x2-vxce8u::-ms-fill-lower {
    background: var(--wx-color-disabled);
    border-color: var(--wx-color-disabled)
}

input[disabled].x2-vxce8u.x2-vxce8u::-ms-fill-upper {
    background: var(--wx-color-disabled);
    border-color: var(--wx-color-disabled)
}

input[disabled].x2-vxce8u.x2-vxce8u::-ms-thumb {
    cursor: not-allowed;
    background: var(--wx-background);
    border: var(--wx-slider-thumb-border-disabled)
}

input[disabled].x2-vxce8u.x2-vxce8u:focus::-ms-fill-lower {
    background: var(--wx-color-disabled)
}

input[disabled].x2-vxce8u.x2-vxce8u:focus::-ms-fill-upper {
    background: var(--wx-color-disabled)
}

.wx-switch.x2-ryjtre.x2-ryjtre {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    cursor: default
}

input.x2-ryjtre.x2-ryjtre {
    appearance: none;
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0
}

span.x2-ryjtre.x2-ryjtre {
    display: block;
    position: relative;
    width: var(--wx-switch-width);
    height: var(--wx-switch-height);
    border: var(--wx-switch-border-width) solid var(--wx-switch-border-color);
    border-radius: calc(var(--wx-switch-height) / 2);
    background-color: var(--wx-switch-background);
    transition: background-color .4s ease;
    cursor: pointer;
    overflow: hidden
}

span.x2-ryjtre.x2-ryjtre:before {
    content: "";
    position: absolute;
    left: var(--wx-switch-thumb-offset);
    top: var(--wx-switch-thumb-offset);
    height: calc(var(--wx-switch-height) - var(--wx-switch-thumb-offset) * 2 - var(--wx-switch-border-width) * 2);
    width: calc(var(--wx-switch-height) - var(--wx-switch-thumb-offset) * 2 - var(--wx-switch-border-width) * 2);
    border: var(--wx-switch-thumb-border);
    border-radius: 50%;
    background: var(--wx-switch-thumb-background);
    box-shadow: var(--wx-switch-thumb-shadow);
    transition: transform .4s ease
}

input.x2-ryjtre:checked~span.x2-ryjtre {
    background-color: var(--wx-switch-primary)
}

input.x2-ryjtre:checked~span.x2-ryjtre:before {
    transform: translate(calc(var(--wx-switch-width) - var(--wx-switch-height)))
}

input[disabled].x2-ryjtre~span.x2-ryjtre {
    background-color: var(--wx-color-disabled);
    border-color: var(--wx-switch-border-color-disabled);
    cursor: not-allowed
}

input[disabled].x2-ryjtre~span.x2-ryjtre:before {
    border: var(--wx-switch-thumb-border-disabled);
    background: var(--wx-switch-thumb-background-disabled)
}

.wx-tabs.x2-1lveawp.x2-1lveawp {
    display: inline-flex;
    flex-wrap: nowrap
}

button.x2-1lveawp.x2-1lveawp {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-align: center;
    letter-spacing: normal;
    text-transform: var(--wx-button-text-transform);
    font-family: var(--wx-button-font-family);
    font-size: var(--wx-button-font-size);
    line-height: var(--wx-button-line-height);
    font-weight: var(--wx-button-font-weight);
    padding: var(--wx-button-padding);
    border: var(--wx-button-border);
    border-width: var(--wx-tabs-border-width);
    border-radius: 0;
    background: var(--wx-tabs-background);
    color: var(--wx-button-font-color);
    cursor: pointer;
    box-shadow: none;
    transition: none;
    max-width: 100%;
    min-width: var(--wx-tabs-cell-min-width);
    user-select: none
}

button.x2-1lveawp+button.x2-1lveawp:before {
    content: "";
    display: block;
    position: absolute;
    left: calc(var(--wx-tabs-border-width) * -1 - var(--wx-tabs-divider-width) / 2);
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: calc(var(--wx-tabs-border-width) * 2 + var(--wx-tabs-divider-height));
    border-left: var(--wx-tabs-divider-width) solid var(--wx-tabs-divider-color)
}

button.x2-1lveawp.x2-1lveawp:first-child {
    border-top-left-radius: var(--wx-tabs-border-radius);
    border-bottom-left-radius: var(--wx-tabs-border-radius)
}

button.x2-1lveawp.x2-1lveawp:last-child {
    border-top-right-radius: var(--wx-tabs-border-radius);
    border-bottom-right-radius: var(--wx-tabs-border-radius)
}

button.x2-1lveawp.x2-1lveawp,
button.x2-1lveawp.x2-1lveawp:focus,
button.x2-1lveawp.x2-1lveawp:active {
    outline: none
}

button.x2-1lveawp.x2-1lveawp:hover {
    background: var(--wx-tabs-background-hover)
}

button.wx-active.x2-1lveawp.x2-1lveawp,
button.wx-active.x2-1lveawp.x2-1lveawp:hover,
button.wx-active.x2-1lveawp.x2-1lveawp:focus {
    background: var(--wx-tabs-active-background);
    color: var(--wx-tabs-active-color);
    cursor: default
}

button.wx-active.x2-1lveawp.x2-1lveawp:before {
    display: none
}

button.wx-active.x2-1lveawp+button.x2-1lveawp:before {
    display: none
}

button.wx-active.x2-1lveawp.x2-1lveawp:after,
button.x2-1lveawp.x2-1lveawp:hover:after {
    content: "";
    display: block;
    position: absolute;
    left: 13px;
    height: 0;
    width: calc(100% - 26px)
}

button.wx-active.x2-1lveawp.x2-1lveawp:after {
    border-bottom: 2px solid var(--wx-tabs-active-border)
}

button.x2-1lveawp.x2-1lveawp:not(.wx-active):hover:after {
    border-bottom: 2px solid var(--wx-tabs-hover-border)
}

.wx-top.x2-1lveawp button.wx-active.x2-1lveawp:after,
.wx-top.x2-1lveawp button.x2-1lveawp:hover:after {
    top: 100%
}

.wx-bottom.x2-1lveawp button.wx-active.x2-1lveawp:after,
.wx-bottom.x2-1lveawp button.x2-1lveawp:hover:after {
    top: 0
}

.wx-icon.x2-1lveawp.x2-1lveawp {
    position: relative;
    font-size: var(--wx-button-icon-size);
    line-height: 1;
    height: var(--wx-button-line-height);
    opacity: .7
}

.wx-icon.x2-1lveawp.x2-1lveawp:before {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%)
}

.wx-icon.wx-only.x2-1lveawp.x2-1lveawp {
    opacity: 1
}

.wx-icon.x2-1lveawp+.wx-label.x2-1lveawp {
    margin-left: 4px
}

.wx-counter.x2-5si2c3.x2-5si2c3 {
    display: flex;
    border: var(--wx-input-border);
    width: max-content;
    border-radius: var(--wx-input-border-radius)
}

.wx-counter.x2-5si2c3.x2-5si2c3:not(.wx-readonly):has(.wx-input:focus) {
    border: var(--wx-input-border-focus)
}

.wx-input.x2-5si2c3.x2-5si2c3 {
    box-sizing: border-box;
    background: var(--wx-background);
    width: 40px;
    height: 30px;
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    color: var(--wx-input-font-color);
    padding-left: 8px;
    padding-right: 8px;
    outline: none;
    border: none;
    border-left: var(--wx-input-border);
    border-right: var(--wx-input-border)
}

.wx-btn.x2-5si2c3.x2-5si2c3 {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 30px;
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    color: var(--wx-input-font-color);
    background-color: var(--wx-background-alt);
    outline: none;
    border: none;
    cursor: pointer
}

.wx-counter.x2-5si2c3:not(.wx-readonly):not(.wx-disabled) .wx-btn.x2-5si2c3:active {
    background-color: var(--wx-background-hover)
}

.wx-dec.x2-5si2c3.x2-5si2c3,
.wx-inc.x2-5si2c3.x2-5si2c3 {
    fill: var(--wx-color-primary)
}

.wx-btn-dec.x2-5si2c3.x2-5si2c3 {
    border-radius: 2px 0 0 2px
}

.wx-btn-inc.x2-5si2c3.x2-5si2c3 {
    border-radius: 0 2px 2px 0
}

.wx-error.x2-5si2c3.x2-5si2c3,
.wx-counter.wx-error.x2-5si2c3.x2-5si2c3:has(.wx-input:focus) {
    border: 1px solid var(--wx-color-danger)
}

.wx-error.x2-5si2c3 .wx-input.x2-5si2c3 {
    color: var(--wx-color-danger)
}

.wx-disabled.x2-5si2c3 .wx-input.x2-5si2c3,
.wx-disabled.x2-5si2c3 .wx-btn.x2-5si2c3 {
    cursor: not-allowed;
    color: var(--wx-color-font-disabled)
}

.wx-disabled.x2-5si2c3 .wx-btn.x2-5si2c3 {
    background: var(--wx-color-disabled-alt)
}

.wx-disabled.x2-5si2c3 .wx-btn .wx-dec.x2-5si2c3,
.wx-disabled.x2-5si2c3 .wx-btn .wx-inc.x2-5si2c3,
.wx-readonly.x2-5si2c3 .wx-btn .wx-dec.x2-5si2c3,
.wx-readonly.x2-5si2c3 .wx-btn .wx-inc.x2-5si2c3 {
    fill: var(--wx-color-font-disabled)
}

.wx-readonly.x2-5si2c3 .wx-btn.x2-5si2c3 {
    cursor: default
}

.wx-notice.x2-5yx8ba.x2-5yx8ba {
    position: relative;
    background: var(--wx-notice-background);
    box-shadow: var(--wx-notice-shadow);
    border: var(--wx-notice-border);
    border-radius: var(--wx-notice-border-radius);
    margin: var(--wx-notice-margin);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    font-family: var(--wx-notice-font-family);
    font-size: var(--wx-notice-font-size);
    line-height: var(--wx-notice-line-height);
    font-weight: var(--wx-notice-font-weight);
    color: var(--wx-notice-font-color);
    width: var(--wx-notice-width)
}

.wx-notice.x2-5yx8ba.x2-5yx8ba:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: var(--wx-notice-type-border-width);
    height: 100%;
    border-top-right-radius: var(--wx-notice-type-border-width);
    border-bottom-right-radius: var(--wx-notice-type-border-width);
    background: var(--wx-notice-type-border-color)
}

.wx-notice.x2-5yx8ba.x2-5yx8ba:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: var(--wx-notice-type-background-opacity);
    background: transparent
}

.wx-text.x2-5yx8ba.x2-5yx8ba,
.wx-button.x2-5yx8ba.x2-5yx8ba {
    position: relative;
    z-index: 2;
    padding: var(--wx-notice-padding)
}

.wx-text.x2-5yx8ba.x2-5yx8ba {
    flex-shrink: 0;
    word-wrap: break-word;
    max-width: calc(var(--wx-notice-width) - var(--wx-notice-padding) * 2 - var(--wx-notice-icon-size))
}

.wx-close.x2-5yx8ba.x2-5yx8ba {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--wx-notice-icon-size);
    line-height: 1;
    width: var(--wx-notice-icon-size);
    height: var(--wx-notice-icon-size);
    color: var(--wx-notice-type-icon-color);
    cursor: pointer
}

.wx-close.x2-5yx8ba.x2-5yx8ba:before {
    display: block;
    z-index: 1
}

.wx-close.x2-5yx8ba.x2-5yx8ba:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-radius: 50%
}

.wx-close.x2-5yx8ba.x2-5yx8ba:hover:after {
    background: var(--wx-background-hover)
}

.wx-notice.wx-info.x2-5yx8ba.x2-5yx8ba,
.wx-notice.wx-warning.x2-5yx8ba.x2-5yx8ba,
.wx-notice.wx-success.x2-5yx8ba.x2-5yx8ba,
.wx-notice.wx-danger.x2-5yx8ba.x2-5yx8ba {
    color: var(--wx-notice-type-font-color)
}

.wx-notice.wx-info.x2-5yx8ba.x2-5yx8ba:before,
.wx-notice.wx-info.x2-5yx8ba.x2-5yx8ba:after {
    background: var(--wx-color-info)
}

.wx-notice.wx-warning.x2-5yx8ba.x2-5yx8ba:before,
.wx-notice.wx-warning.x2-5yx8ba.x2-5yx8ba:after {
    background: var(--wx-color-warning)
}

.wx-notice.wx-success.x2-5yx8ba.x2-5yx8ba:before,
.wx-notice.wx-success.x2-5yx8ba.x2-5yx8ba:after {
    background: var(--wx-color-success)
}

.wx-notice.wx-danger.x2-5yx8ba.x2-5yx8ba:before,
.wx-notice.wx-danger.x2-5yx8ba.x2-5yx8ba:after {
    background: var(--wx-color-danger)
}

.wx-notice.wx-info.x2-5yx8ba .wx-close.x2-5yx8ba:after,
.wx-notice.wx-warning.x2-5yx8ba .wx-close.x2-5yx8ba:after,
.wx-notice.wx-success.x2-5yx8ba .wx-close.x2-5yx8ba:after,
.wx-notice.wx-danger.x2-5yx8ba .wx-close.x2-5yx8ba:after {
    opacity: var(--wx-notice-type-close-hover-opacity)
}

.wx-notices.x2-ervf1h {
    position: fixed;
    z-index: var(--wx-notice-z-index);
    top: 0;
    right: 0
}

.wx-modal.x2-at32q2 {
    position: fixed;
    z-index: var(--wx-modal-z-index);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: var(--wx-modal-backdrop);
    text-align: center
}

.wx-window.x2-at32q2 {
    background: var(--wx-modal-background);
    box-shadow: var(--wx-modal-shadow);
    border: var(--wx-modal-border);
    border-radius: var(--wx-modal-border-radius);
    padding: var(--wx-modal-padding);
    min-width: var(--wx-modal-width)
}

.wx-header.x2-at32q2 {
    font-family: var(--wx-modal-header-font-family);
    font-size: var(--wx-modal-header-font-size);
    line-height: var(--wx-modal-header-line-height);
    font-weight: var(--wx-modal-header-font-weight);
    color: var(--wx-modal-header-font-color);
    margin-bottom: var(--wx-modal-gutter)
}

.wx-buttons.x2-at32q2 {
    margin-top: var(--wx-modal-gutter);
    display: flex;
    justify-content: center;
    margin-left: calc(var(--wx-modal-gutter) / -2);
    margin-right: calc(var(--wx-modal-gutter) / -2)
}

.wx-button.x2-at32q2 {
    flex: 1;
    max-width: 50%;
    padding: 0 calc(var(--wx-modal-gutter) / 2)
}

.wx-field.x2-16h42zq.x2-16h42zq {
    width: var(--wx-field-width);
    max-width: 100%;
    margin-bottom: var(--wx-field-gutter)
}

.wx-field.wx-left.x2-16h42zq.x2-16h42zq {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start
}

.wx-field.wx-left.x2-16h42zq>label.x2-16h42zq {
    width: var(--wx-label-width);
    flex-shrink: 0;
    margin: 0 var(--wx-field-gutter) 0 0;
    padding-top: calc((var(--wx-input-height) - var(--wx-label-line-height)) / 2);
    text-align: right
}

.wx-field.wx-left.x2-16h42zq>.wx-field-control.x2-16h42zq {
    max-width: calc(100% - var(--wx-label-width) - var(--wx-field-gutter))
}

.wx-field.wx-error.x2-16h42zq label.x2-16h42zq {
    color: var(--wx-color-danger)
}

.wx-field.wx-left.x2-16h42zq .wx-field-control.wx-checkbox.x2-16h42zq {
    padding-top: calc((var(--wx-input-height) - var(--wx-checkbox-height)) / 2);
    padding-bottom: calc((var(--wx-input-height) - var(--wx-checkbox-height)) / 2)
}

.wx-field.wx-left.x2-16h42zq .wx-field-control.wx-slider.x2-16h42zq {
    padding-top: calc((var(--wx-input-height) - var(--wx-slider-height)) / 2);
    padding-bottom: calc((var(--wx-input-height) - var(--wx-slider-height)) / 2)
}

.wx-field.wx-left.x2-16h42zq .wx-field-control.wx-switch.x2-16h42zq {
    padding-top: calc((var(--wx-input-height) - var(--wx-switch-height)) / 2);
    padding-bottom: calc((var(--wx-input-height) - var(--wx-switch-height)) / 2)
}

label.x2-16h42zq.x2-16h42zq {
    display: block;
    margin: var(--wx-label-margin);
    padding: var(--wx-label-padding);
    font-family: var(--wx-label-font-family);
    font-size: var(--wx-label-font-size);
    line-height: var(--wx-label-line-height);
    font-weight: var(--wx-label-font-weight);
    color: var(--wx-label-font-color)
}

.wx-field-control.x2-16h42zq.x2-16h42zq {
    position: relative;
    width: 100%
}

.wx-timepicker.x2-18gvjip {
    position: relative;
    width: var(--wx-input-width)
}

.wx-wrapper.x2-18gvjip {
    padding: 10px 20px
}

.wx-timer.x2-18gvjip {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-bottom: 10px
}

.wx-digit.x2-18gvjip {
    display: block;
    width: 50px;
    height: 38px;
    border: none;
    border-bottom: var(--wx-input-border);
    font-family: var(--wx-input-font-family);
    font-size: 24px;
    line-height: 28px;
    padding: 5px;
    text-align: center;
    color: var(--wx-input-font-color);
    background-color: transparent;
    outline: none
}

.wx-digit.x2-18gvjip:focus {
    border-bottom: var(--wx-input-border-focus)
}

.wx-separator.x2-18gvjip {
    font-size: 24px;
    line-height: 38px
}

.wx-modal.x2-1ki3q24 {
    position: absolute;
    z-index: var(--wx-modal-z-index);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--wx-modal-backdrop);
    display: flex;
    align-items: center;
    justify-content: center
}

.wx-window.x2-1ki3q24 {
    background: var(--wx-modal-background);
    box-shadow: var(--wx-modal-shadow);
    border: var(--wx-modal-border);
    border-radius: var(--wx-modal-border-radius);
    min-width: var(--wx-modal-width)
}

.wx-sidearea.x2-126ofuj {
    position: absolute;
    z-index: var(--wx-popup-z-index);
    background: var(--wx-modal-background);
    box-shadow: var(--wx-modal-shadow);
    border: var(--wx-modal-border);
    border-radius: var(--wx-modal-border-radius);
    min-width: var(--wx-modal-width);
    height: 100vh
}

.wx-sidearea.wx-pos-right.x2-126ofuj {
    right: 0;
    top: 0
}

.wx-portal.x2-1dixdmq {
    display: none
}

.wx-material-theme {
    --wx-color-primary: #2f77e3;
    --wx-color-primary-selected: #d5e6ff;
    --wx-color-primary-font: #fff;
    --wx-color-secondary: rgba(0, 0, 0, .04);
    --wx-color-secondary-hover: rgba(0, 0, 0, .1);
    --wx-color-secondary-font: rgba(0, 0, 0, .7);
    --wx-color-secondary-font-hover: rgba(0, 0, 0, .7);
    --wx-color-secondary-border: transparent;
    --wx-color-secondary-border-disabled: transparent;
    --wx-color-success: #00d19a;
    --wx-color-warning: #ffc975;
    --wx-color-info: #37a9ef;
    --wx-color-danger: #ff5252;
    --wx-color-disabled: #ededed;
    --wx-color-disabled-alt: #e9e9e9;
    --wx-color-font: rgba(0, 0, 0, .7);
    --wx-color-font-alt: rgba(0, 0, 0, .5);
    --wx-color-font-disabled: rgba(0, 0, 0, .3);
    --wx-color-link: var(--wx-color-primary);
    --wx-background: #fff;
    --wx-background-alt: #f7f7f7;
    --wx-background-hover: linear-gradient(rgba(0, 0, 0, .15) 0%, rgba(0, 0, 0, .15) 100%);
    --wx-font-family: Roboto, Arial, Helvetica, sans-serif;
    --wx-font-size: 14px;
    --wx-line-height: 20px;
    --wx-font-size-md: 16px;
    --wx-line-height-md: 24px;
    --wx-font-size-hd: 16px;
    --wx-line-height-hd: 30px;
    --wx-font-size-sm: 12px;
    --wx-line-height-sm: 16px;
    --wx-font-weight: 400;
    --wx-font-weight-md: 500;
    --wx-font-weight-b: 700;
    --wx-icon-color: var(--wx-color-font-alt);
    --wx-icon-size: var(--wx-line-height);
    --wx-border: 1px solid #dfdfdf;
    --wx-border-radius: 2px;
    --wx-radius-medium: var(--wx-border-radius);
    --wx-border-light: none;
    --wx-border-medium: 1px solid #eaedf5;
    --wx-shadow-light: 0px 4px 20px rgba(44, 47, 60, .12);
    --wx-shadow-medium: 0px 2px 4px rgba(0, 0, 0, .15);
    --wx-box-shadow: 0px 2px 4px rgba(0, 0, 0, .15);
    --wx-box-shadow-strong: 0px 2px 5px rgba(0, 0, 0, .3);
    --wx-padding: 8px;
    --wx-field-gutter: 16px;
    --wx-field-width: 400px;
    --wx-input-font-family: var(--wx-font-family);
    --wx-input-font-size: var(--wx-font-size);
    --wx-input-line-height: var(--wx-line-height);
    --wx-input-font-weight: var(--wx-font-weight);
    --wx-input-text-align: left;
    --wx-input-font-color: var(--wx-color-font);
    --wx-input-background: var(--wx-background);
    --wx-input-background-disabled: var(--wx-background);
    --wx-input-placeholder-color: var(--wx-color-font-alt);
    --wx-input-border: var(--wx-border);
    --wx-input-border-focus: 1px solid rgba(0, 0, 0, .5);
    --wx-input-border-disabled: 1px solid var(--wx-color-disabled);
    --wx-input-border-radius: var(--wx-border-radius);
    --wx-input-height: 32px;
    --wx-input-width: 100%;
    --wx-input-padding: 5px 8px;
    --wx-input-icon-indent: 6px;
    --wx-input-icon-color: var(--wx-icon-color);
    --wx-input-icon-size: var(--wx-icon-size);
    --wx-multicombo-tag-gap: 4px;
    --wx-multicombo-tag-border: none;
    --wx-multicombo-tag-border-radius: var(--wx-input-border-radius);
    --wx-multicombo-tag-pading: 2px 8px;
    --wx-multicombo-tag-background: var(--wx-background-alt);
    --wx-checkbox-height: var(--wx-line-height);
    --wx-checkbox-size: 18px;
    --wx-checkbox-border-width: 2px;
    --wx-checkbox-border-color: var(--wx-color-font-alt);
    --wx-checkbox-border-color-disabled: var(--wx-color-font-disabled);
    --wx-checkbox-border-radius: var(--wx-input-border-radius);
    --wx-checkbox-font-family: var(--wx-font-family);
    --wx-checkbox-font-size: var(--wx-font-size);
    --wx-checkbox-line-height: var(--wx-line-height);
    --wx-checkbox-font-weight: var(--wx-font-weight);
    --wx-checkbox-font-color: var(--wx-color-font);
    --wx-label-width: 80px;
    --wx-label-margin: 0 0 8px;
    --wx-label-padding: 0;
    --wx-label-font-family: var(--wx-font-family);
    --wx-label-font-size: var(--wx-font-size);
    --wx-label-line-height: var(--wx-line-height);
    --wx-label-font-weight: var(--wx-font-weight-md);
    --wx-label-font-color: var(--wx-color-font);
    --wx-button-font-family: var(--wx-font-family);
    --wx-button-font-size: var(--wx-font-size);
    --wx-button-line-height: var(--wx-line-height);
    --wx-button-font-weight: var(--wx-font-weight-md);
    --wx-button-text-transform: none;
    --wx-button-font-color: var(--wx-color-font);
    --wx-button-danger-font-color: #fff;
    --wx-button-background: var(--wx-background-alt);
    --wx-button-border: 1px solid transparent;
    --wx-button-border-radius: var(--wx-border-radius);
    --wx-button-height: 32px;
    --wx-button-padding: 5px 20px;
    --wx-button-icon-indent: 7px;
    --wx-button-icon-size: 16px;
    --wx-segmented-background: var(--wx-background-alt);
    --wx-segmented-background-hover: var(--wx-background-hover);
    --wx-segmented-border: var(--wx-border);
    --wx-segmented-border-radius: var(--wx-border-radius);
    --wx-segmented-padding: 3px;
    --wx-tabs-background: var(--wx-background-alt);
    --wx-tabs-background-hover: var(--wx-background-hover);
    --wx-tabs-hover-border: transparent;
    --wx-tabs-border-width: 1px;
    --wx-tabs-border-radius: var(--wx-border-radius);
    --wx-tabs-divider-width: 1px;
    --wx-tabs-divider-height: 100%;
    --wx-tabs-divider-color: #dfdfdf;
    --wx-tabs-cell-min-width: 100px;
    --wx-tabs-active-background: var(--wx-color-primary);
    --wx-tabs-active-color: var(--wx-color-primary-font);
    --wx-tabs-active-border: transparent;
    --wx-slider-height: 16px;
    --wx-slider-primary: var(--wx-color-primary);
    --wx-slider-background: #dfdfdf;
    --wx-slider-track-height: 4px;
    --wx-slider-track-border-radius: 2px;
    --wx-slider-thumb-size: var(--wx-slider-height);
    --wx-slider-thumb-border: 2px solid var(--wx-background);
    --wx-slider-thumb-border-disabled: none;
    --wx-slider-thumb-shadow: var(--wx-box-shadow-strong);
    --wx-slider-label-margin: 0 0 5px;
    --wx-slider-label-font-family: var(--wx-font-family);
    --wx-slider-label-font-size: var(--wx-font-size);
    --wx-slider-label-line-height: var(--wx-line-height);
    --wx-slider-label-font-weight: var(--wx-font-weight-md);
    --wx-slider-label-font-color: var(--wx-color-font);
    --wx-switch-primary: var(--wx-color-primary);
    --wx-switch-background: rgba(0, 0, 0, .3);
    --wx-switch-width: 44px;
    --wx-switch-height: 22px;
    --wx-switch-border-width: 1px;
    --wx-switch-border-color: transparent;
    --wx-switch-border-color-disabled: #dfdfdf;
    --wx-switch-thumb-offset: 1px;
    --wx-switch-thumb-border: none;
    --wx-switch-thumb-border-disabled: none;
    --wx-switch-thumb-background: var(--wx-background);
    --wx-switch-thumb-background-disabled: var(--wx-background);
    --wx-switch-thumb-shadow: var(--wx-box-shadow-strong);
    --wx-popup-z-index: 100;
    --wx-popup-background: var(--wx-background);
    --wx-popup-shadow: var(--wx-shadow-light);
    --wx-popup-border: none;
    --wx-popup-border-radius: var(--wx-border-radius);
    --wx-modal-z-index: 1000;
    --wx-modal-background: var(--wx-background);
    --wx-modal-shadow: var(--wx-shadow-medium);
    --wx-modal-border: none;
    --wx-modal-border-radius: var(--wx-border-radius);
    --wx-modal-width: 280px;
    --wx-modal-padding: 16px 20px;
    --wx-modal-gutter: 14px;
    --wx-modal-backdrop: rgba(0, 0, 0, .5);
    --wx-modal-header-font-family: var(--wx-font-family);
    --wx-modal-header-font-size: var(--wx-font-size-hd);
    --wx-modal-header-line-height: var(--wx-line-height-hd);
    --wx-modal-header-font-weight: var(--wx-font-weight);
    --wx-modal-header-font-color: #000;
    --wx-notice-z-index: 1010;
    --wx-notice-background: var(--wx-background);
    --wx-notice-shadow: var(--wx-shadow-medium);
    --wx-notice-border: none;
    --wx-notice-border-radius: var(--wx-border-radius);
    --wx-notice-margin: 6px 12px;
    --wx-notice-font-family: var(--wx-font-family);
    --wx-notice-font-size: var(--wx-font-size);
    --wx-notice-line-height: var(--wx-line-height);
    --wx-notice-font-weight: var(--wx-font-weight);
    --wx-notice-font-color: var(--wx-color-font);
    --wx-notice-padding: var(--wx-padding);
    --wx-notice-width: 240px;
    --wx-notice-icon-size: var(--wx-icon-size);
    --wx-notice-icon-color: var(--wx-icon-color);
    --wx-notice-type-border-width: 0px;
    --wx-notice-type-border-color: transparent;
    --wx-notice-type-font-color: #fff;
    --wx-notice-type-icon-color: rgba(255, 255, 255, .8);
    --wx-notice-type-background-opacity: 1;
    --wx-notice-type-close-hover-opacity: .3;
    --wx-calendar-padding: 16px;
    --wx-calendar-cell-size: 32px;
    --wx-calendar-gap: 4px;
    --wx-calendar-line-gap: 0px;
    --wx-calendar-border-radius: var(--wx-calendar-cell-size);
    --wx-calendar-font-family: var(--wx-font-family);
    --wx-calendar-font-size: var(--wx-font-size);
    --wx-calendar-line-height: var(--wx-line-height);
    --wx-calendar-font-weight: var(--wx-font-weight);
    --wx-calendar-font-color: var(--wx-color-font);
    --wx-calendar-icon-color: var(--wx-icon-color);
    --wx-calendar-icon-size: var(--wx-icon-size);
    --wx-calendar-header-font-size: var(--wx-font-size);
    --wx-calendar-header-line-height: var(--wx-line-height);
    --wx-calendar-header-font-weight: var(--wx-font-weight-md);
    --wx-calendar-controls-font-family: var(--wx-button-font-family);
    --wx-calendar-controls-font-size: var(--wx-button-font-size);
    --wx-calendar-controls-line-height: var(--wx-button-line-height);
    --wx-calendar-controls-font-weight: var(--wx-button-font-weight);
    --wx-calendar-controls-font-color: var(--wx-color-link)
}

.wx-material-theme {
    font-family: var(--wx-font-family);
    font-size: var(--wx-font-size);
    line-height: var(--wx-line-height);
    font-weight: var(--wx-font-weight);
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--wx-color-font);
    background: var(--wx-background)
}

.wx-material-theme *,
.wx-material-theme *:before,
.wx-material-theme *:after {
    box-sizing: border-box
}

.wx-willow-theme {
    --wx-color-primary: #37a9ef;
    --wx-color-primary-selected: #d5eaf7;
    --wx-color-primary-font: #fff;
    --wx-color-secondary: transparent;
    --wx-color-secondary-hover: rgba(55, 169, 239, .12);
    --wx-color-secondary-font: var(--wx-color-primary);
    --wx-color-secondary-font-hover: var(--wx-color-primary);
    --wx-color-secondary-border: var(--wx-color-primary);
    --wx-color-secondary-border-disabled: #c0c3ce;
    --wx-color-success: #77d257;
    --wx-color-warning: #fcba2e;
    --wx-color-info: #37a9ef;
    --wx-color-danger: #fe6158;
    --wx-color-disabled: #f2f3f7;
    --wx-color-disabled-alt: #e9e9e9;
    --wx-color-font: #2c2f3c;
    --wx-color-font-alt: #9fa1ae;
    --wx-color-font-disabled: #c0c3ce;
    --wx-color-link: var(--wx-color-primary);
    --wx-background: #ffffff;
    --wx-background-alt: #f2f3f7;
    --wx-background-hover: #eaedf5;
    --wx-font-family: "Open Sans", Arial, Helvetica, sans-serif;
    --wx-font-size: 14px;
    --wx-line-height: 20px;
    --wx-font-size-md: 14px;
    --wx-line-height-md: 24px;
    --wx-font-size-hd: 16px;
    --wx-line-height-hd: 30px;
    --wx-font-size-sm: 12px;
    --wx-line-height-sm: 16px;
    --wx-font-weight: 400;
    --wx-font-weight-md: 600;
    --wx-font-weight-b: 700;
    --wx-icon-color: #9fa1ae;
    --wx-icon-size: var(--wx-line-height);
    --wx-border: 1px solid #e6e6e6;
    --wx-border-radius: 3px;
    --wx-radius-major: 6px;
    --wx-border-light: none;
    --wx-border-medium: 1px solid #eaedf5;
    --wx-shadow-light: 0px 3px 10px 0px rgba(44, 47, 60, .12), 0px 1px 2px 0px rgba(44, 47, 60, .06);
    --wx-shadow-medium: 0px 4px 20px 0px rgba(44, 47, 60, .12);
    --wx-box-shadow: 0px 1px 2px rgba(44, 47, 60, .06), 0px 3px 10px rgba(44, 47, 60, .12);
    --wx-box-shadow-strong: 0px 4px 20px rgba(44, 47, 60, .16);
    --wx-padding: 8px;
    --wx-field-gutter: 16px;
    --wx-field-width: 400px;
    --wx-input-font-family: var(--wx-font-family);
    --wx-input-font-size: var(--wx-font-size);
    --wx-input-line-height: var(--wx-line-height);
    --wx-input-font-weight: var(--wx-font-weight);
    --wx-input-text-align: left;
    --wx-input-font-color: var(--wx-color-font);
    --wx-input-background: var(--wx-background);
    --wx-input-background-disabled: var(--wx-color-disabled);
    --wx-input-placeholder-color: var(--wx-color-font-alt);
    --wx-input-border: var(--wx-border);
    --wx-input-border-focus: 1px solid var(--wx-color-primary);
    --wx-input-border-disabled: var(--wx-border);
    --wx-input-border-radius: 3px;
    --wx-input-height: 32px;
    --wx-input-width: 100%;
    --wx-input-padding: 5px 8px;
    --wx-input-icon-indent: 6px;
    --wx-input-icon-color: var(--wx-icon-color);
    --wx-input-icon-size: var(--wx-icon-size);
    --wx-multicombo-tag-gap: 3px;
    --wx-multicombo-tag-border: none;
    --wx-multicombo-tag-border-radius: var(--wx-input-border-radius);
    --wx-multicombo-tag-pading: 2px 8px;
    --wx-multicombo-tag-background: var(--wx-background-alt);
    --wx-checkbox-height: var(--wx-line-height);
    --wx-checkbox-size: var(--wx-checkbox-height);
    --wx-checkbox-border-width: 2px;
    --wx-checkbox-border-color: var(--wx-color-font-alt);
    --wx-checkbox-border-color-disabled: var(--wx-color-disabled);
    --wx-checkbox-border-radius: var(--wx-input-border-radius);
    --wx-checkbox-font-family: var(--wx-font-family);
    --wx-checkbox-font-size: var(--wx-font-size);
    --wx-checkbox-line-height: var(--wx-line-height);
    --wx-checkbox-font-weight: var(--wx-font-weight);
    --wx-checkbox-font-color: var(--wx-color-font);
    --wx-label-width: 100px;
    --wx-label-margin: 0 0 4px;
    --wx-label-padding: 0;
    --wx-label-font-family: var(--wx-font-family);
    --wx-label-font-size: var(--wx-font-size);
    --wx-label-line-height: var(--wx-line-height);
    --wx-label-font-weight: var(--wx-font-weight-md);
    --wx-label-font-color: var(--wx-color-font);
    --wx-button-font-family: var(--wx-font-family);
    --wx-button-font-size: var(--wx-font-size-md);
    --wx-button-line-height: var(--wx-line-height);
    --wx-button-font-weight: var(--wx-font-weight-md);
    --wx-button-text-transform: none;
    --wx-button-font-color: var(--wx-color-font);
    --wx-button-danger-font-color: #fff;
    --wx-button-background: var(--wx-background-alt);
    --wx-button-border: 1px solid transparent;
    --wx-button-border-radius: var(--wx-border-radius);
    --wx-button-height: 32px;
    --wx-button-padding: 5px 16px;
    --wx-button-icon-indent: 5px;
    --wx-button-icon-size: 20px;
    --wx-segmented-background: var(--wx-background-alt);
    --wx-segmented-background-hover: var(--wx-background-hover);
    --wx-segmented-border: none;
    --wx-segmented-border-radius: 6px;
    --wx-segmented-padding: 0px;
    --wx-tabs-background: var(--wx-background);
    --wx-tabs-background-hover: transparent;
    --wx-tabs-hover-border: #9fa1ae;
    --wx-tabs-border-width: 1px;
    --wx-tabs-border-radius: var(--wx-border-radius);
    --wx-tabs-divider-width: 1px;
    --wx-tabs-divider-height: 60%;
    --wx-tabs-divider-color: transparent;
    --wx-tabs-cell-min-width: 100px;
    --wx-tabs-active-background: transparent;
    --wx-tabs-active-color: var(--wx-color-primary);
    --wx-tabs-active-border: var(--wx-tabs-active-color);
    --wx-slider-height: 14px;
    --wx-slider-primary: var(--wx-color-primary);
    --wx-slider-background: var(--wx-background-alt);
    --wx-slider-track-height: 4px;
    --wx-slider-track-border-radius: 2px;
    --wx-slider-thumb-size: var(--wx-slider-height);
    --wx-slider-thumb-border: none;
    --wx-slider-thumb-border-disabled: none;
    --wx-slider-thumb-shadow: var(--wx-box-shadow-strong);
    --wx-slider-label-margin: 0 0 5px;
    --wx-slider-label-font-family: var(--wx-font-family);
    --wx-slider-label-font-size: var(--wx-font-size);
    --wx-slider-label-line-height: var(--wx-line-height);
    --wx-slider-label-font-weight: var(--wx-font-weight-md);
    --wx-slider-label-font-color: var(--wx-color-font);
    --wx-switch-primary: var(--wx-color-primary);
    --wx-switch-background: #9fa1ae;
    --wx-switch-width: 48px;
    --wx-switch-height: 24px;
    --wx-switch-border-width: 0px;
    --wx-switch-border-color: transparent;
    --wx-switch-border-color-disabled: transparent;
    --wx-switch-thumb-offset: 1px;
    --wx-switch-thumb-border: none;
    --wx-switch-thumb-border-disabled: 1px solid #c0c3ce;
    --wx-switch-thumb-background: var(--wx-background);
    --wx-switch-thumb-background-disabled: var(--wx-color-disabled);
    --wx-switch-thumb-shadow: none;
    --wx-popup-z-index: 100;
    --wx-popup-background: var(--wx-background);
    --wx-popup-shadow: var(--wx-shadow-light);
    --wx-popup-border: 1px solid transparent;
    --wx-popup-border-radius: var(--wx-border-radius);
    --wx-modal-z-index: 1000;
    --wx-modal-background: var(--wx-background);
    --wx-modal-shadow: var(--wx-shadow-medium);
    --wx-modal-border: none;
    --wx-modal-border-radius: var(--wx-border-radius);
    --wx-modal-width: 280px;
    --wx-modal-padding: 16px 20px;
    --wx-modal-gutter: 14px;
    --wx-modal-backdrop: rgba(108, 114, 131, .2);
    --wx-modal-header-font-family: var(--wx-font-family);
    --wx-modal-header-font-size: var(--wx-font-size-hd);
    --wx-modal-header-line-height: var(--wx-line-height-hd);
    --wx-modal-header-font-weight: var(--wx-font-weight-b);
    --wx-modal-header-font-color: #000;
    --wx-notice-z-index: 1010;
    --wx-notice-background: var(--wx-background);
    --wx-notice-shadow: var(--wx-shadow-medium);
    --wx-notice-border: none;
    --wx-notice-border-radius: 0 var(--wx-border-radius) var(--wx-border-radius) 0;
    --wx-notice-margin: 6px 12px;
    --wx-notice-font-family: var(--wx-font-family);
    --wx-notice-font-size: var(--wx-font-size);
    --wx-notice-line-height: var(--wx-line-height);
    --wx-notice-font-weight: var(--wx-font-weight);
    --wx-notice-font-color: var(--wx-color-font);
    --wx-notice-padding: 14px;
    --wx-notice-width: 240px;
    --wx-notice-icon-size: var(--wx-icon-size);
    --wx-notice-icon-color: var(--wx-icon-color);
    --wx-notice-type-border-width: 4px;
    --wx-notice-type-border-color: var(--wx-icon-color);
    --wx-notice-type-font-color: var(--wx-color-font);
    --wx-notice-type-icon-color: var(--wx-icon-color);
    --wx-notice-type-background-opacity: 0;
    --wx-notice-type-close-hover-opacity: 1;
    --wx-calendar-padding: 16px;
    --wx-calendar-cell-size: 32px;
    --wx-calendar-gap: 4px;
    --wx-calendar-line-gap: 4px;
    --wx-calendar-border-radius: var(--wx-border-radius);
    --wx-calendar-font-family: var(--wx-font-family);
    --wx-calendar-font-size: var(--wx-font-size);
    --wx-calendar-line-height: var(--wx-line-height);
    --wx-calendar-font-weight: var(--wx-font-weight);
    --wx-calendar-font-color: var(--wx-color-font);
    --wx-calendar-icon-color: var(--wx-icon-color);
    --wx-calendar-icon-size: var(--wx-icon-size);
    --wx-calendar-header-font-size: var(--wx-font-size);
    --wx-calendar-header-line-height: var(--wx-line-height);
    --wx-calendar-header-font-weight: var(--wx-font-weight-md);
    --wx-calendar-controls-font-family: var(--wx-button-font-family);
    --wx-calendar-controls-font-size: var(--wx-font-size);
    --wx-calendar-controls-line-height: var(--wx-line-height);
    --wx-calendar-controls-font-weight: var(--wx-font-weight);
    --wx-calendar-controls-font-color: var(--wx-color-font)
}

.wx-willow-theme {
    font-family: var(--wx-font-family);
    font-size: var(--wx-font-size);
    line-height: var(--wx-line-height);
    font-weight: var(--wx-font-weight);
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--wx-color-font);
    background: var(--wx-background)
}

.wx-willow-theme *,
.wx-willow-theme *:before,
.wx-willow-theme *:after {
    box-sizing: border-box
}

.wx-willow-dark-theme {
    --wx-color-primary: #7a67eb;
    --wx-color-primary-selected: rgba(122, 103, 235, .3);
    --wx-color-primary-font: #fff;
    --wx-color-secondary: transparent;
    --wx-color-secondary-hover: rgba(122, 103, 235, .12);
    --wx-color-secondary-font: var(--wx-color-primary);
    --wx-color-secondary-font-hover: var(--wx-color-primary);
    --wx-color-secondary-border: var(--wx-color-primary);
    --wx-color-secondary-border-disabled: #9fa1ae;
    --wx-color-success: #77d257;
    --wx-color-warning: #fcba2e;
    --wx-color-info: var(--wx-color-primary);
    --wx-color-danger: #fe6158;
    --wx-color-disabled: #384047;
    --wx-color-disabled-alt: #44474a;
    --wx-color-font: rgba(255, 255, 255, .9);
    --wx-color-font-alt: #9fa1ae;
    --wx-color-font-disabled: #9fa1ae;
    --wx-color-link: var(--wx-color-primary);
    --wx-background: #2a2b2d;
    --wx-background-alt: #384047;
    --wx-background-hover: #20262b;
    --wx-font-family: "Open Sans", Arial, Helvetica, sans-serif;
    --wx-font-size: 14px;
    --wx-line-height: 20px;
    --wx-font-size-md: 14px;
    --wx-line-height-md: 24px;
    --wx-font-size-hd: 16px;
    --wx-line-height-hd: 30px;
    --wx-font-size-sm: 12px;
    --wx-line-height-sm: 16px;
    --wx-font-weight: 400;
    --wx-font-weight-md: 600;
    --wx-font-weight-b: 700;
    --wx-icon-color: #c0c3ce;
    --wx-icon-size: var(--wx-line-height);
    --wx-border: 1px solid #384047;
    --wx-border-radius: 3px;
    --wx-radius-major: 6px;
    --wx-border-light: 1px solid #384047;
    --wx-border-medium: 1px solid #384047;
    --wx-shadow-light: 0px 4px 20px 0px rgba(44, 47, 60, .12);
    --wx-shadow-medium: none;
    --wx-box-shadow: 0px 4px 48px rgba(44, 47, 60, .18);
    --wx-box-shadow-strong: 0px 4px 48px rgba(44, 47, 60, .18), 0px 4px 8px rgba(14, 14, 15, .3);
    --wx-padding: 8px;
    --wx-field-gutter: 16px;
    --wx-field-width: 400px;
    --wx-input-font-family: var(--wx-font-family);
    --wx-input-font-size: var(--wx-font-size);
    --wx-input-line-height: var(--wx-line-height);
    --wx-input-font-weight: var(--wx-font-weight);
    --wx-input-text-align: left;
    --wx-input-font-color: var(--wx-color-font);
    --wx-input-background: var(--wx-background);
    --wx-input-background-disabled: var(--wx-color-disabled);
    --wx-input-placeholder-color: var(--wx-color-font-alt);
    --wx-input-border: var(--wx-border);
    --wx-input-border-focus: 1px solid var(--wx-color-primary);
    --wx-input-border-disabled: var(--wx-border);
    --wx-input-border-radius: 3px;
    --wx-input-height: 32px;
    --wx-input-width: 100%;
    --wx-input-padding: 5px 8px;
    --wx-input-icon-indent: 6px;
    --wx-input-icon-color: var(--wx-icon-color);
    --wx-input-icon-size: var(--wx-icon-size);
    --wx-multicombo-tag-gap: 3px;
    --wx-multicombo-tag-border: none;
    --wx-multicombo-tag-border-radius: var(--wx-input-border-radius);
    --wx-multicombo-tag-pading: 2px 8px;
    --wx-multicombo-tag-background: var(--wx-background-alt);
    --wx-checkbox-height: var(--wx-line-height);
    --wx-checkbox-size: var(--wx-checkbox-height);
    --wx-checkbox-border-width: 2px;
    --wx-checkbox-border-color: var(--wx-color-font-alt);
    --wx-checkbox-border-color-disabled: var(--wx-color-disabled);
    --wx-checkbox-border-radius: var(--wx-input-border-radius);
    --wx-checkbox-font-family: var(--wx-font-family);
    --wx-checkbox-font-size: var(--wx-font-size);
    --wx-checkbox-line-height: var(--wx-line-height);
    --wx-checkbox-font-weight: var(--wx-font-weight);
    --wx-checkbox-font-color: var(--wx-color-font);
    --wx-label-width: 100px;
    --wx-label-margin: 0 0 4px;
    --wx-label-padding: 0;
    --wx-label-font-family: var(--wx-font-family);
    --wx-label-font-size: var(--wx-font-size);
    --wx-label-line-height: var(--wx-line-height);
    --wx-label-font-weight: var(--wx-font-weight-md);
    --wx-label-font-color: var(--wx-color-font);
    --wx-button-font-family: var(--wx-font-family);
    --wx-button-font-size: var(--wx-font-size-md);
    --wx-button-line-height: var(--wx-line-height);
    --wx-button-font-weight: var(--wx-font-weight-md);
    --wx-button-text-transform: none;
    --wx-button-background: var(--wx-background-alt);
    --wx-button-font-color: var(--wx-color-font);
    --wx-button-danger-font-color: #fff;
    --wx-button-border: 1px solid transparent;
    --wx-button-border-radius: var(--wx-border-radius);
    --wx-button-height: 32px;
    --wx-button-padding: 5px 16px;
    --wx-button-icon-indent: 5px;
    --wx-button-icon-size: 20px;
    --wx-segmented-background: var(--wx-background-alt);
    --wx-segmented-background-hover: var(--wx-background-hover);
    --wx-segmented-border: none;
    --wx-segmented-border-radius: 6px;
    --wx-segmented-padding: 0px;
    --wx-tabs-background: var(--wx-background);
    --wx-tabs-background-hover: transparent;
    --wx-tabs-hover-border: #9fa1ae;
    --wx-tabs-border-width: 1px;
    --wx-tabs-border-radius: var(--wx-border-radius);
    --wx-tabs-divider-width: 1px;
    --wx-tabs-divider-height: 60%;
    --wx-tabs-divider-color: transparent;
    --wx-tabs-cell-min-width: 100px;
    --wx-tabs-active-background: transparent;
    --wx-tabs-active-color: var(--wx-color-primary);
    --wx-tabs-active-border: var(--wx-tabs-active-color);
    --wx-slider-height: 14px;
    --wx-slider-primary: var(--wx-color-primary);
    --wx-slider-background: var(--wx-background-alt);
    --wx-slider-track-height: 4px;
    --wx-slider-track-border-radius: 2px;
    --wx-slider-thumb-size: var(--wx-slider-height);
    --wx-slider-thumb-border: none;
    --wx-slider-thumb-border-disabled: none;
    --wx-slider-thumb-shadow: var(--wx-box-shadow-strong);
    --wx-slider-label-margin: 0 0 5px;
    --wx-slider-label-font-family: var(--wx-font-family);
    --wx-slider-label-font-size: var(--wx-font-size);
    --wx-slider-label-line-height: var(--wx-line-height);
    --wx-slider-label-font-weight: var(--wx-font-weight-md);
    --wx-slider-label-font-color: var(--wx-color-font);
    --wx-switch-primary: var(--wx-color-primary);
    --wx-switch-background: #384047;
    --wx-switch-width: 48px;
    --wx-switch-height: 24px;
    --wx-switch-border-width: 0px;
    --wx-switch-border-color: transparent;
    --wx-switch-border-color-disabled: transparent;
    --wx-switch-thumb-offset: 1px;
    --wx-switch-thumb-border: none;
    --wx-switch-thumb-border-disabled: 1px solid rgba(255, 255, 255, .7);
    --wx-switch-thumb-background: rgba(255, 255, 255, .9);
    --wx-switch-thumb-background-disabled: var(--wx-color-disabled);
    --wx-switch-thumb-shadow: none;
    --wx-popup-z-index: 100;
    --wx-popup-background: var(--wx-background);
    --wx-popup-shadow: var(--wx-shadow-light);
    --wx-popup-border: 1px solid rgba(255, 255, 255, .26);
    --wx-popup-border-radius: var(--wx-border-radius);
    --wx-modal-z-index: 1000;
    --wx-modal-background: var(--wx-background);
    --wx-modal-shadow: var(--wx-shadow-medium);
    --wx-modal-border: 1px solid #ffffff42;
    --wx-modal-border-radius: var(--wx-border-radius);
    --wx-modal-width: 280px;
    --wx-modal-padding: 16px 20px;
    --wx-modal-gutter: 14px;
    --wx-modal-backdrop: rgba(108, 114, 131, .2);
    --wx-modal-header-font-family: var(--wx-font-family);
    --wx-modal-header-font-size: var(--wx-font-size-hd);
    --wx-modal-header-line-height: var(--wx-line-height-hd);
    --wx-modal-header-font-weight: var(--wx-font-weight-b);
    --wx-modal-header-font-color: #000;
    --wx-notice-z-index: 1010;
    --wx-notice-background: var(--wx-background-alt);
    --wx-notice-shadow: var(--wx-shadow-medium);
    --wx-notice-border: none;
    --wx-notice-border-radius: 0 var(--wx-border-radius) var(--wx-border-radius) 0;
    --wx-notice-margin: 6px 12px;
    --wx-notice-font-family: var(--wx-font-family);
    --wx-notice-font-size: var(--wx-font-size);
    --wx-notice-line-height: var(--wx-line-height);
    --wx-notice-font-weight: var(--wx-font-weight);
    --wx-notice-font-color: var(--wx-color-font);
    --wx-notice-padding: 14px;
    --wx-notice-width: 240px;
    --wx-notice-icon-size: var(--wx-icon-size);
    --wx-notice-icon-color: var(--wx-icon-color);
    --wx-notice-type-border-width: 4px;
    --wx-notice-type-border-color: var(--wx-icon-color);
    --wx-notice-type-font-color: var(--wx-color-font);
    --wx-notice-type-icon-color: #9fa1ae;
    --wx-notice-type-background-opacity: 0;
    --wx-notice-type-close-hover-opacity: 1;
    --wx-calendar-padding: 16px;
    --wx-calendar-cell-size: 32px;
    --wx-calendar-gap: 4px;
    --wx-calendar-line-gap: 4px;
    --wx-calendar-border-radius: var(--wx-border-radius);
    --wx-calendar-font-family: var(--wx-font-family);
    --wx-calendar-font-size: var(--wx-font-size);
    --wx-calendar-line-height: var(--wx-line-height);
    --wx-calendar-font-weight: var(--wx-font-weight);
    --wx-calendar-font-color: var(--wx-color-font);
    --wx-calendar-icon-color: var(--wx-icon-color);
    --wx-calendar-icon-size: var(--wx-icon-size);
    --wx-calendar-header-font-size: var(--wx-font-size);
    --wx-calendar-header-line-height: var(--wx-line-height);
    --wx-calendar-header-font-weight: var(--wx-font-weight-md);
    --wx-calendar-controls-font-family: var(--wx-button-font-family);
    --wx-calendar-controls-font-size: var(--wx-font-size);
    --wx-calendar-controls-line-height: var(--wx-line-height);
    --wx-calendar-controls-font-weight: var(--wx-font-weight);
    --wx-calendar-controls-font-color: var(--wx-color-font)
}

.wx-willow-dark-theme {
    font-family: var(--wx-font-family);
    font-size: var(--wx-font-size);
    line-height: var(--wx-line-height);
    font-weight: var(--wx-font-weight);
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--wx-color-font);
    background: var(--wx-background)
}

.wx-willow-dark-theme *,
.wx-willow-dark-theme *:before,
.wx-willow-dark-theme *:after {
    box-sizing: border-box
}

.wx-scale.x2-nufjbd {
    position: relative;
    box-shadow: var(--wx-timescale-shadow);
    z-index: 1
}

.wx-row.x2-nufjbd,
.wx-cell.x2-nufjbd {
    display: flex;
    box-sizing: border-box
}

.wx-row.x2-nufjbd {
    border-bottom: var(--wx-gantt-border)
}

.wx-cell.x2-nufjbd {
    justify-content: center;
    align-items: center;
    border-right: var(--wx-timescale-border);
    font: var(--wx-timescale-font);
    text-transform: var(--wx-timescale-text-transform);
    color: var(--wx-timescale-font-color)
}

.wx-cell.wx-weekend.x2-nufjbd {
    background: #F5F8FA;
    color: var(--wx-gantt-holiday-color)
}

.wx-measure-cell-body,
.wx-cell.x2-1wbpy33 {
    background: inherit;
    box-sizing: border-box;
    padding: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.wx-tree-cell.x2-1wbpy33 {
    display: flex
}

.wx-measure-cell-body,
.wx-cell.x2-1wbpy33:not(:last-child) {
    border-right: var(--wx-table-cell-border)
}

.wx-shadow.wx-cell.x2-1wbpy33 {
    border-right: var(--wx-table-fixed-column-right-border);
    clip-path: inset(0px -15px 0px 0px);
    z-index: 1
}

.wx-table-tree-toggle.x2-1wbpy33 {
    font-size: 20px;
    cursor: pointer;
    margin: 0 8px 0 0;
    display: inline-block
}

.wx-measure-cell-header,
.wx-cell.x2-hek2za.x2-hek2za {
    padding: 8px;
    position: relative;
    display: flex;
    align-items: center;
    font-weight: var(--wx-header-font-weight);
    background: var(--wx-table-header-background);
    overflow: hidden;
    gap: 10px;
    line-height: 20px
}

.wx-cell.wx-vertical.x2-hek2za.x2-hek2za {
    align-items: flex-end
}

.wx-measure-cell-header.wx-measure-vertical {
    padding: 8px
}

.wx-measure-cell-header,
.wx-cell.x2-hek2za.x2-hek2za:not(:last-child) {
    border-right: var(--wx-table-header-cell-border)
}

.wx-cell.x2-hek2za.x2-hek2za:last-child {
    overflow: hidden
}

.wx-text.x2-hek2za.x2-hek2za {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.wx-vertical.x2-hek2za .wx-text.x2-hek2za {
    height: 100%;
    transform: rotate(-180deg);
    writing-mode: vertical-lr;
    text-overflow: ellipsis;
    overflow: hidden
}

.wx-cell.wx-shadow.x2-hek2za.x2-hek2za {
    box-shadow: var(--wx-table-fixed-column-shadow);
    clip-path: inset(0px -15px 0px 0px);
    border-right: var(--wx-table-fixed-column-right-border)
}

.wx-shadow.x2-hek2za.x2-hek2za,
.wx-fixed.x2-hek2za.x2-hek2za {
    z-index: 1
}

.wx-grip.x2-hek2za.x2-hek2za {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -4px;
    width: 9px;
    border-left: 5px solid var(--wx-table-header-background);
    border-right: 3px solid var(--wx-table-header-background);
    background-color: var(--wx-color-primary);
    opacity: 0;
    cursor: ew-resize;
    z-index: 5
}

.wx-grip.x2-hek2za.x2-hek2za:before,
.wx-grip.x2-hek2za.x2-hek2za:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0;
    height: 0;
    margin: auto
}

.wx-grip.x2-hek2za.x2-hek2za:before {
    border: 3px dashed transparent;
    border-right: 3px solid var(--wx-color-primary);
    right: 5px
}

.wx-grip.x2-hek2za.x2-hek2za:after {
    border: 3px dashed transparent;
    border-left: 3px solid var(--wx-color-primary);
    left: 5px
}

.wx-grip.x2-hek2za.x2-hek2za:hover {
    opacity: 1
}

.wx-sort.x2-hek2za.x2-hek2za {
    // position: absolute;
    inset: 0 5px 0 0;
    // display: flex;
    // align-items: center;
    // justify-content: flex-end
}

.wx-order.x2-hek2za.x2-hek2za {
    width: 16px;
    height: 16px;
    line-height: 16px;
    border-radius: 50%;
    font-size: 12px;
    text-align: center;
    color: #fff;
    background-color: #3498ff
}

.wx-icon.x2-hek2za.x2-hek2za {
    padding: 5px;
    color: #3498ff;
    cursor: pointer
}

.wx-rowspan.x2-hek2za.x2-hek2za {
    z-index: 6
}

.wx-rowspan.wx-shadow.x2-hek2za.x2-hek2za,
.wx-colspan.wx-shadow.x2-hek2za.x2-hek2za {
    z-index: 7
}

.wx-collapse.x2-hek2za.x2-hek2za,
.wx-collapsed.x2-hek2za.x2-hek2za {
    cursor: pointer;
    z-index: 1
}

.wx-measure-cell-footer,
.wx-cell.x2-1pgtgrd.x2-1pgtgrd {
    padding: 8px;
    display: flex;
    align-items: center;
    font-weight: var(--wx-header-font-weight);
    background: var(--wx-table-header-background);
    overflow: hidden
}

.wx-cell.x2-1pgtgrd.x2-1pgtgrd:not(:last-child) {
    border-right: var(--wx-table-footer-cell-border)
}

.wx-cell.wx-vertical.x2-1pgtgrd.x2-1pgtgrd {
    align-items: flex-end
}

.wx-measure-cell-footer.wx-measure-vertical {
    padding: 8px
}

.wx-text.x2-1pgtgrd.x2-1pgtgrd {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.wx-vertical.x2-1pgtgrd .wx-text.x2-1pgtgrd {
    transform: rotate(-90deg) translateY(100%);
    transform-origin: left bottom;
    text-overflow: clip;
    overflow: unset
}

.wx-cell.wx-shadow.x2-1pgtgrd.x2-1pgtgrd {
    clip-path: inset(0px -15px 0px 0px);
    border-right: var(--wx-table-fixed-column-right-border)
}

.wx-shadow.x2-1pgtgrd.x2-1pgtgrd,
.wx-fixed.x2-1pgtgrd.x2-1pgtgrd {
    z-index: 1
}

.wx-rowspan.x2-1pgtgrd.x2-1pgtgrd {
    z-index: 2
}

.wx-rowspan.wx-shadow.x2-1pgtgrd.x2-1pgtgrd,
.wx-colspan.wx-shadow.x2-1pgtgrd.x2-1pgtgrd {
    z-index: 3
}

.wx-header.x2-1byhgan,
.wx-footer.x2-1byhgan {
    font-weight: 500
}

.wx-header.x2-1byhgan {
    top: 0;
    border-bottom: var(--wx-table-header-border)
}

.wx-footer.x2-1byhgan {
    position: sticky;
    z-index: 2;
    bottom: 0;
    border-top: var(--wx-table-header-border)
}

.wx-h-row.x2-1byhgan:not(:last-child) {
    border-bottom: var(--wx-table-header-cell-border)
}

.wx-f-row.x2-1byhgan:not(:last-child) {
    border-bottom: var(--wx-table-footer-cell-border)
}

.wx-overlay.x2-zjaxrx {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
    background-color: var(--wx-background);
    padding: 14px 8px;
    text-align: center
}

.wx-text.x2-1a713m7 {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding-left: 8px;
    font: inherit;
    background: var(--wx-background);
    color: var(--wx-color-font);
    border: 1px solid var(--wx-color-primary)
}

.wx-input.x2-1s4pc76 {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    padding-left: 8px;
    font: inherit;
    background: var(--wx-background);
    color: var(--wx-color-font);
    border: 1px solid var(--wx-color-primary)
}

.wx-value.x2-1eq9nh5 {
    width: 100%;
    height: 100%;
    padding: 8px;
    overflow: hidden;
    outline: none;
    border: 1px solid var(--wx-color-primary)
}

.wx-text.x2-1eq9nh5 {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block
}

.wx-value.x2-z4gexz {
    width: 100%;
    height: 100%;
    padding: 8px;
    overflow: hidden;
    outline: none;
    border: 1px solid var(--wx-color-primary)
}

.wx-text.x2-z4gexz {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.wx-value.x2-z4gexz:focus {
    outline: none;
    display: block
}

.wx-cell.x2-1hzozeb {
    box-sizing: border-box;
    padding: 0;
    background-color: var(--wx-background);
    color: var(--wx-color-font);
    position: relative;
    z-index: 2
}

.wx-shadow.x2-1hzozeb {
    box-shadow: var(--wx-table-fixed-column-shadow)
}

.wx-cell.x2-1hzozeb .wx-dropdown {
    border: var(--wx-table-editor-dropdown-border);
    box-shadow: var(--wx-table-editor-dropdown-shadow)
}

.wx-grid.x2-1rhm7gj {
    height: 100%;
    width: 100%;
}

.wx-grid.x2-1rhm7gj * {
    scroll-margin-top: var(--header-height);
    scroll-margin-bottom: var(--footer-height);
    scroll-margin-left: var(--split-left-width);
    overflow: hidden;
    text-overflow: ellipsis;
}

.wx-table-box.x2-1rhm7gj {
    outline: none;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    border: var(--wx-table-cell-border);
    overflow: hidden;
    box-sizing: content-box
}

.wx-table-box.wx-active.x2-1rhm7gj .wx-row.wx-selected {
    background-color: var(--wx-table-select-focus-background)
}

.wx-header-wrapper.x2-1rhm7gj {
    position: sticky;
    z-index: 2;
    top: 0
}

.wx-body.x2-1rhm7gj,
.wx-data.x2-1rhm7gj {
    position: relative
}

.wx-scroll.x2-1rhm7gj {
    position: relative;
    flex: 1;
    overflow: auto
}

.wx-row.x2-1rhm7gj {
    display: flex;
    box-sizing: border-box;
    background-color: var(--wx-background)
}

.wx-row.x2-1rhm7gj:not(:last-child) {
    border-bottom: var(--wx-table-cell-border)
}

.wx-row.wx-autoheight.x2-1rhm7gj .wx-cell {
    overflow: visible;
    text-overflow: initial;
    white-space: normal;
    word-break: break-all
}

.wx-row.wx-autoheight.x2-1rhm7gj {
    height: max-content
}

.wx-selected.x2-1rhm7gj {
    color: var(--wx-table-select-color);
    background-color: var(--wx-table-select-background)
}

.wx-selected.x2-1rhm7gj .wx-cell:first-child {
    box-shadow: var(--wx-table-select-border)
}

.wx-cell.wx-collapsed.x2-1rhm7gj {
    width: 36px;
    border-right: var(--wx-table-cell-border)
}

.wx-item.x2-xfznf6 {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 36px;
    line-height: 36px;
    padding: 2px 12px;
    font-family: var(--wx-font-family);
    font-weight: var(--wx-font-weight);
    font-size: var(--wx-font-size);
    background-color: var(--wx-background);
    cursor: pointer
}

.wx-item.x2-xfznf6:hover {
    background: var(--wx-background-alt)
}

.wx-item.x2-xfznf6:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.wx-item.x2-xfznf6:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit
}

.wx-value.x2-xfznf6 {
    flex-grow: 1;
    white-space: nowrap;
    color: var(--wx-color-font)
}

.wx-icon.x2-xfznf6,
.wx-sub-icon.x2-xfznf6 {
    vertical-align: middle;
    height: inherit;
    line-height: inherit;
    font-size: var(--wx-icon-size);
    color: var(--wx-icon-color)
}

.wx-icon.x2-xfznf6 {
    margin-right: 8px
}

.wx-subtext.x2-xfznf6 {
    color: var(--wx-color-font-disabled);
    margin-left: 20px;
    white-space: nowrap
}

.wx-menu.x2-1tqohog {
    position: absolute;
    box-shadow: var(--wx-shadow-light);
    min-width: 125px;
    display: flex;
    flex-direction: column;
    z-index: 20;
    border-radius: var(--wx-border-radius);
    background-color: var(--wx-background);
    padding: 4px 0
}

.wx-separator.x2-1tqohog {
    width: 100%;
    border-top: var(--wx-border-medium)
}

.wx-menubar.x2-1siwlw5 {
    display: flex;
    position: relative;
    width: fit-content
}

.wx-item.x2-1siwlw5 {
    background-color: transparent;
    border: none;
    color: var(--wx-color-font);
    box-sizing: border-box;
    height: 36px;
    line-height: 30px;
    padding: 2px 12px;
    font-family: var(--wx-font-family);
    font-weight: var(--wx-font-weight);
    font-size: var(--wx-font-size);
    cursor: pointer;
    outline: none;
    white-space: nowrap
}

.wx-active.x2-1siwlw5,
.wx-item.x2-1siwlw5:hover {
    background-color: var(--wx-background-alt);
    border-radius: var(--wx-button-border-radius)
}

.wx-icon.x2-fogbc2 {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px
}

.wx-hidden.x2-fogbc2 {
    color: var(--wx-color-font-disabled)
}

.wx-area.x2-g241au {
    position: relative;
    height: 100%;
    width: 100%
}

.tooltip {
    padding: 2px 10px;
    border-radius: 2px;
    box-shadow: var(--wx-box-shadow);
    pointer-events: none;
    position: absolute;
    z-index: 10;
    font-size: var(--wx-font-size-sm);
    font-family: var(--wx-font-family);
    color: var(--wx-color-primary-font);
    background-color: #1a1e21
}

.wx-material-theme {
    --wx-table-select-background: #e2e2e2;
    --wx-table-select-focus-background: #dceaff;
    --wx-table-select-color: var(--wx-color-font);
    --wx-table-border: 1px solid #dfdfdf;
    --wx-table-select-border: none;
    --wx-table-header-border: var(--wx-table-border);
    --wx-table-header-cell-border: var(--wx-table-border);
    --wx-table-footer-cell-border: var(--wx-table-border);
    --wx-table-cell-border: var(--wx-table-border);
    --wx-header-font-weight: 500;
    --wx-table-header-background: #fafafb;
    --wx-table-fixed-column-right-border: 3px solid #dfdfdf;
    --wx-table-editor-dropdown-border: none;
    --wx-table-editor-dropdown-shadow: 0px 2px 5px 0px rgba(0, 0, 0, .3)
}

.wx-material-theme .wx-table-menu {
    box-shadow: 0 2px 5px #0000004d;
    outline: none
}

.wx-willow-theme {
    --wx-table-select-background: #eaedf5;
    --wx-table-select-focus-background: #ebedf3;
    --wx-table-select-color: var(--wx-color-font);
    --wx-table-border: 1px solid #e6e6e6;
    --wx-table-select-border: inset 3px 0 var(--wx-color-primary);
    --wx-table-header-border: var(--wx-table-border);
    --wx-table-header-cell-border: var(--wx-table-border);
    --wx-table-footer-cell-border: var(--wx-table-border);
    --wx-table-cell-border: var(--wx-table-border);
    --wx-header-font-weight: 600;
    --wx-table-header-background: #f2f3f7;
    --wx-table-fixed-column-right-border: 3px solid #e6e6e6;
    --wx-table-editor-dropdown-border: var(--wx-table-border);
    --wx-table-editor-dropdown-shadow: 0px 4px 20px 0px rgba(44, 47, 60, .12)
}

.wx-willow-theme .wx-table-menu {
    box-shadow: 0 4px 20px #2c2f3c1f;
    outline: 1px solid #e6e6e6
}

.wx-willow-dark-theme {
    color-scheme: dark;
    --wx-table-select-background: #384047;
    --wx-table-select-focus-background: #465059;
    --wx-table-select-color: var(--wx-color-font);
    --wx-table-border: var(--wx-border);
    --wx-table-select-border: inset 3px 0 var(--wx-color-primary);
    --wx-table-header-border: var(--wx-table-border);
    --wx-table-header-cell-border: var(--wx-table-border);
    --wx-table-footer-cell-border: var(--wx-table-border);
    --wx-table-cell-border: var(--wx-table-border);
    --wx-header-font-weight: 600;
    --wx-table-header-background: #20262b;
    --wx-table-fixed-column-right-border: 3px solid var(--wx-background-alt);
    --wx-table-editor-dropdown-border: var(--wx-border);
    --wx-table-editor-dropdown-shadow: 0px 4px 16px 0px rgba(0, 0, 0, .6)
}

.wx-willow-dark-theme .wx-table-menu {
    box-shadow: 0 4px 16px #0009;
    outline: var(--wx-border)
}

.wx-content.x2-r092m {
    width: 100%;
    white-space: nowrap;
    display: flex;
    align-items: center
}

.wx-toggle-icon.x2-r092m {
    width: var(--wx-icon-size);
    min-width: 12px;
    height: 16px;
    line-height: 16px;
    margin: 0 5px;
    font-size: var(--wx-icon-size);
    color: var(--wx-gantt-icon-color);
    cursor: pointer
}

.wx-toggle-placeholder.x2-r092m {
    width: var(--wx-icon-size);
    height: 16px;
    line-height: 16px;
    margin: 0 5px;
    flex: 0 0 var(--wx-icon-size)
}

.wx-text.x2-r092m {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
}

.wx-add-icon.x2-153hphv {
    cursor: pointer;
    font-size: var(--wx-icon-size);
    height: 16px;
    line-height: 16px;
    display: block;
    color: var(--wx-gantt-icon-color)
}

.wx-add-icon.x2-153hphv:hover {
    color: var(--wx-color-link)
}

.wx-table-wrapper.x2-dnsr36 {
    display: flex;
    flex-direction: column;
    border-right: var(--wx-gantt-border);
    overflow: hidden;
    position: sticky
}

.wx-wrapper-scroll.x2-dnsr36 {
    overflow-x: scroll
}

.wx-wrapper-scroll-y.x2-dnsr36 {
    overflow-y: scroll;
    display: block
}

.wx-scroll-y.x2-dnsr36 {
    overflow-y: auto;
    scrollbar-width: none
}

.wx-scroll-y.x2-dnsr36::-webkit-scrollbar {
    display: none
}

.wx-table.x2-dnsr36 {
    --wx-table-select-background: var(--wx-gantt-select-color);
    --wx-table-select-focus-background: var(--wx-gantt-select-color);
    --wx-table-select-border: none;
    --wx-table-cell-border: var(--wx-grid-body-row-border);
    --wx-table-header-background: var(--wx-background);
    --wx-table-header-border: var(--wx-gantt-border);
    --wx-table-header-cell-border: var(--wx-gantt-border);
    position: sticky;
    top: 0
}

.wx-table.x2-dnsr36>.wx-grid .wx-table-box {
    border: none
}

.wx-table.x2-dnsr36>.wx-grid .wx-scroll {
    overflow: visible
}

.wx-table.x2-dnsr36>.wx-grid {
    font: var(--wx-grid-body-font);
    color: var(--wx-grid-body-font-color)
}

.wx-table.x2-dnsr36>.wx-grid .wx-cell {
    padding: 0 5px
}

.wx-table.x2-dnsr36>.wx-grid .wx-row {
    display: flex;
    align-items: center
}

.wx-table.x2-dnsr36>.wx-grid .wx-cell.wx-text-center {
    text-align: center
}

.wx-table.x2-dnsr36>.wx-grid .wx-cell.wx-text-right {
    text-align: right
}

.wx-table.x2-dnsr36>.wx-grid .wx-body .wx-cell {
    border-right: var(--wx-grid-body-cell-border)
}

.wx-table.x2-dnsr36>.wx-grid .wx-header {
    box-shadow: var(--wx-grid-header-shadow);
    z-index: 1
}

.wx-table.x2-dnsr36>.wx-grid .wx-header .wx-cell {
    font: var(--wx-grid-header-font);
    text-transform: var(--wx-grid-header-text-transform);
    color: var(--wx-grid-header-font-color);
    padding: 0 5px
}

.wx-table.x2-dnsr36>.wx-grid .wx-header .wx-cell:first-child {
    padding-left: 14px
}

.wx-table.x2-dnsr36>.wx-grid .wx-header .wx-cell.wx-text-right {
    justify-content: right
}

.wx-table.x2-dnsr36>.wx-grid .wx-header .wx-cell.wx-text-center {
    justify-content: center;
    padding-left: 5px
}

.wx-table.x2-dnsr36>.wx-grid .wx-header .wx-action i {
    font-size: var(--wx-icon-size);
    color: var(--wx-gantt-icon-color)
}

.wx-table.x2-dnsr36>.wx-grid .wx-header .wx-action .wx-text {
    display: none
}

.wx-table.x2-dnsr36>.wx-grid .wx-header .wx-action i:hover {
    color: var(--wx-color-link)
}

.wx-table.x2-dnsr36>.wx-grid .wx-header .wx-add-task i:before {
    content: ""
}

.wx-table.x2-dnsr36>.wx-grid .wx-header .wx-expand i:before {
    content: ""
}

.wx-table.x2-dnsr36>.wx-grid .wx-reorder-task.wx-row {
    width: 100%;
    background: var(--wx-background-alt);
    border-top: var(--wx-grid-body-row-border)
}

.wx-table.x2-dnsr36>.wx-grid .wx-reorder-task.wx-selected {
    background: var(--wx-gantt-select-color);
    border-top: transparent;
    border-bottom: transparent
}

.wx-baseline.x2-1qryx5p.x2-1qryx5p {
    position: absolute;
    background-color: #a883e4;
    border-radius: var(--wx-gantt-baseline-border-radius);
    z-index: 1
}

.wx-baseline.wx-milestone.x2-1qryx5p.x2-1qryx5p {
    transform: rotate(45deg) scale(.75);
    border-radius: var(--wx-gantt-milestone-border-radius)
}

.wx-bars.x2-1qryx5p.x2-1qryx5p {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden
}

.wx-bar.x2-1qryx5p.x2-1qryx5p {
    box-sizing: border-box;
    position: absolute;
    border-radius: var(--wx-gantt-bar-border-radius);
    font: var(--wx-gantt-bar-font);
    white-space: nowrap;
    line-height: inherit;
    text-align: center;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.wx-bar.wx-touch.x2-1qryx5p.x2-1qryx5p {
    opacity: .5
}

.wx-bar.wx-reorder-task.x2-1qryx5p.x2-1qryx5p {
    z-index: 3
}

.wx-content.x2-1qryx5p.x2-1qryx5p {
    overflow: hidden;
    text-overflow: ellipsis
}

.wx-task.x2-1qryx5p.x2-1qryx5p {
    color: var(--wx-gantt-task-font-color);
    background-color: var(--wx-gantt-task-color);
    border: var(--wx-gantt-task-border)
}

.wx-task.wx-selected.x2-1qryx5p.x2-1qryx5p {
    border: 1px solid var(--wx-gantt-task-border-color);
    box-shadow: var(--wx-gantt-bar-shadow)
}

.wx-task.x2-1qryx5p.x2-1qryx5p:hover {
    box-shadow: var(--wx-gantt-bar-shadow)
}

.wx-summary.x2-1qryx5p.x2-1qryx5p {
    color: var(--wx-gantt-summary-font-color);
    background-color: var(--wx-gantt-summary-color);
    border: var(--wx-gantt-summary-border)
}

.wx-summary.wx-selected.x2-1qryx5p.x2-1qryx5p {
    border: 1px solid var(--wx-gantt-summary-border-color);
    box-shadow: var(--wx-gantt-bar-shadow)
}

.wx-summary.x2-1qryx5p.x2-1qryx5p:hover {
    box-shadow: var(--wx-gantt-bar-shadow)
}

.wx-milestone.x2-1qryx5p .wx-content.x2-1qryx5p {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2
}

.wx-bar.x2-1qryx5p:not(.wx-milestone) .wx-content.x2-1qryx5p {
    position: relative;
    z-index: 2
}

.wx-bars.x2-1qryx5p .wx-text-out {
    position: absolute;
    line-height: normal;
    display: block;
    color: var(--wx-color-font);
    pointer-events: none
}

.wx-milestone.x2-1qryx5p.x2-1qryx5p {
    border-color: var(--wx-gantt-milestone-color)
}

.wx-milestone.x2-1qryx5p .wx-text-out.x2-1qryx5p {
    padding: 0 2px;
    left: 100%
}

.wx-milestone.x2-1qryx5p .wx-content.x2-1qryx5p {
    height: 100%;
    background-color: var(--wx-gantt-milestone-color);
    transform: rotate(45deg) scale(.75);
    border-radius: var(--wx-gantt-milestone-border-radius)
}

.wx-progress-wrapper.x2-1qryx5p.x2-1qryx5p {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: var(--wx-gantt-bar-border-radius);
    overflow: hidden
}

.wx-progress-percent.x2-1qryx5p.x2-1qryx5p {
    height: 100%
}

.wx-progress-marker.x2-1qryx5p.x2-1qryx5p {
    opacity: 0;
    position: absolute;
    top: 80%;
    width: var(--wx-icon-size);
    height: var(--wx-gantt-progress-marker-height);
    background: var(--wx-gantt-progress-border-color);
    clip-path: polygon(50% 0, 100% 30%, 100% 100%, 0 100%, 0 30%);
    color: var(--wx-color-font);
    z-index: 3;
    font-size: calc(var(--wx-font-size-sm) - 2px);
    border-radius: 4px;
    cursor: ew-resize;
    text-align: center;
    line-height: 3
}

.wx-progress-marker.x2-1qryx5p.x2-1qryx5p:before {
    content: "";
    display: block;
    position: absolute;
    width: calc(var(--wx-icon-size) - 2px);
    height: calc(var(--wx-gantt-progress-marker-height) - 2px);
    clip-path: polygon(50% 0, 100% 30%, 100% 100%, 0 100%, 0 30%);
    top: 1px;
    left: 1px;
    background: var(--wx-gantt-link-marker-background);
    z-index: -1;
    border-radius: 4px
}

.wx-bar.x2-1qryx5p:hover .wx-progress-marker.x2-1qryx5p,
.wx-progress-marker.wx-progress-in-drag.x2-1qryx5p.x2-1qryx5p {
    opacity: 1
}

.wx-task.x2-1qryx5p .wx-progress-percent.x2-1qryx5p {
    background-color: var(--wx-gantt-task-fill-color)
}

.wx-summary.x2-1qryx5p .wx-progress-percent.x2-1qryx5p {
    background-color: var(--wx-gantt-summary-fill-color)
}

.wx-link.x2-1qryx5p.x2-1qryx5p {
    position: absolute;
    z-index: 4;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid var(--wx-gantt-link-marker-color);
    background-color: var(--wx-gantt-link-marker-background);
    opacity: 0;
    cursor: default
}

.wx-link.x2-1qryx5p .wx-inner.x2-1qryx5p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 4px solid var(--wx-gantt-link-marker-color);
    pointer-events: none
}

.wx-link.wx-left.x2-1qryx5p.x2-1qryx5p {
    left: -16px
}

.wx-link.wx-right.x2-1qryx5p.x2-1qryx5p {
    right: -16px
}

.wx-link.wx-target.x2-1qryx5p.x2-1qryx5p:hover,
.wx-link.wx-selected.x2-1qryx5p.x2-1qryx5p,
.wx-bar.x2-1qryx5p:hover .wx-link.wx-target.x2-1qryx5p,
.wx-link.wx-visible.wx-target.x2-1qryx5p.x2-1qryx5p {
    opacity: 1;
    cursor: pointer
}

.wx-link.wx-selected.x2-1qryx5p.x2-1qryx5p,
.wx-link.wx-selected.x2-1qryx5p .wx-inner.x2-1qryx5p {
    border-color: inherit
}

.wx-milestone.x2-1qryx5p .wx-link.wx-left.x2-1qryx5p {
    left: -16px
}

.wx-milestone.x2-1qryx5p .wx-link.wx-right.x2-1qryx5p {
    right: -16px
}

.wx-cut.x2-1qryx5p.x2-1qryx5p {
    opacity: 50%
}

.wx-links.x2-wfzywr {
    position: absolute;
    top: 0;
    left: 0
}

.wx-line.x2-wfzywr {
    user-select: auto;
    pointer-events: stroke;
    position: relative;
    cursor: pointer;
    stroke: var(--wx-gantt-link-color);
    stroke-width: 2;
    z-index: 0;
    fill: transparent
}

.wx-chart.x2-1ff484e {
    flex: 1 1 auto;
    overflow: auto
}

.wx-markers.x2-1ff484e {
    position: absolute
}

.wx-marker.x2-1ff484e {
    position: absolute;
    z-index: 4;
    width: 2px;
    height: 100%;
    text-align: center;
    user-select: none;
    transform: scaleX(-1)
}

.wx-default.x2-1ff484e {
    background: var(--wx-gantt-marker-color)
}

.wx-content.x2-1ff484e {
    position: absolute;
    min-width: 50px;
    padding: 4px 8px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font: var(--wx-gantt-marker-font);
    color: var(--wx-gantt-marker-font-color);
    background-color: inherit;
    white-space: nowrap;
    transform: scaleX(-1)
}

.wx-area.x2-1ff484e {
    position: relative;
    overflow-y: hidden
}

.wx-selected.x2-1ff484e {
    position: absolute;
    box-sizing: border-box;
    left: 0;
    width: 100%;
    background: var(--wx-gantt-select-color)
}

.wx-cut.x2-1ff484e {
    opacity: 50%
}

.wx-gantt-holidays.x2-1ff484e {
    height: 100%;
    width: 100%;
    position: absolute
}

.wx-weekend.x2-1ff484e {
    height: 100%;
    background: var(--wx-gantt-holiday-background);
    color: var(--wx-gantt-holiday-color);
    position: absolute
}

.wx-counter.x2-1nc55he {
    margin-bottom: 20px
}

.wx-label.x2-1nc55he {
    display: block;
    margin-bottom: 10px;
    font-family: var(--wx-label-font-family);
    font-size: var(--wx-label-font-size);
    color: var(--wx-label-font-color)
}

.wx-controls.x2-1nc55he {
    display: flex
}

.wx-input.x2-1nc55he {
    box-sizing: border-box;
    background: var(--wx-background);
    width: 40px;
    height: 32px;
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    color: var(--wx-input-font-color);
    text-align: center;
    outline: none
}

.wx-input.x2-1nc55he:focus {
    border: 1px solid var(--wx-input-focus-color)
}

.wx-input.wx-error.x2-1nc55he {
    border: 1px solid var(--wx-color-danger)
}

.wx-btn.x2-1nc55he {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 32px;
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    color: var(--wx-input-font-color);
    background-color: inherit;
    outline: none;
    cursor: pointer
}

.wx-btn.x2-1nc55he:active {
    border: var(--wx-input-border-focus)
}

.wx-btn-dec.x2-1nc55he {
    border-right: 1px solid transparent;
    border-radius: 2px 0 0 2px
}

.wx-btn-inc.x2-1nc55he {
    border-left: 1px solid transparent;
    border-radius: 0 2px 2px 0
}

.wx-dec.x2-1nc55he,
.wx-inc.x2-1nc55he {
    fill: var(--wx-color-primary)
}

.wx-links.x2-8406i3 {
    margin-bottom: 10px
}

.wx-cell.x2-8406i3 {
    text-align: center
}

.wx-task-name.x2-8406i3 {
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    font-weight: var(--wx-input-font-weigth);
    color: var(--wx-input-font-color);
    width: 170px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.wx-wrapper.x2-8406i3 {
    position: relative;
    display: flex
}

.wx-delete-icon.x2-8406i3 {
    margin-left: 12px;
    position: relative;
    top: 2px;
    font-size: var(--wx-icon-size);
    cursor: pointer;
    color: var(--wx-gantt-icon-color)
}

.wx-delete-icon.x2-8406i3:hover {
    color: var(--wx-color-primary)
}

.wx-sidebar.x2-j8yl6f {
    flex: 0 0 400px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background: var(--wx-background);
    box-shadow: 0 1px 30px #00000040;
    overflow: hidden;
    z-index: 1
}

.wx-sidebar.wx-compact.x2-j8yl6f {
    position: absolute;
    width: 100%;
    z-index: 4;
    height: 100%
}

.wx-header.x2-j8yl6f {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 20px;
    border-bottom: var(--wx-gantt-form-header-border)
}

.wx-form.x2-j8yl6f {
    flex: 1 1 auto;
    padding: 20px;
    overflow: auto
}

.wxi-close.x2-j8yl6f {
    width: 24px;
    height: 18px;
    cursor: pointer;
    font-size: 24px;
    color: var(--wx-icon-color)
}

.wx-button.x2-npj3j5 {
    width: 50px;
    height: 50px;
    border: none;
    outline: none;
    border-radius: 50%;
    cursor: pointer
}

.wx-primary.x2-npj3j5 {
    color: var(--wx-color-primary-font);
    background-color: var(--wx-color-primary)
}

.wx-primary.x2-npj3j5:hover {
    background-color: #0b9db1
}

.wx-transparent.x2-npj3j5 {
    width: 40px;
    height: 40px;
    color: var(--wx-color-primary-font);
    background-color: #63636373
}

.wx-transparent.x2-npj3j5:hover {
    background-color: #45454573
}

.wx-button-icon.x2-npj3j5 {
    font-size: 20px
}

.wx-resizer.x2-1myw878.x2-1myw878 {
    position: absolute;
    z-index: 2
}

.wx-resizer-x.x2-1myw878 .wx-resizer-line.x2-1myw878 {
    width: 2px;
    height: 100%
}

.wx-resizer-y.x2-1myw878 .wx-resizer-line.x2-1myw878 {
    height: 2px;
    width: 100%
}

.wx-resizer-active.x2-1myw878 .wx-resizer-line.x2-1myw878 {
    background: #0000000d
}

.wx-gantt.x2-1dzadpy {
    height: 100%;
    width: 100%
}

.wx-layout.x2-1dzadpy {
    position: relative;
    display: flex;
    max-height: 100%;
    max-width: 100%;
    background-color: var(--wx-background);
    overflow: hidden;
    outline: none;
    height: 100%
}

.wx-content.x2-1dzadpy {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden
}

.wx-icon.x2-1dzadpy {
    position: absolute;
    right: 25px;
    bottom: 35px;
    z-index: 4
}

.wx-fullscreen.x2-lr2z1n {
    position: relative;
    height: 100%;
    width: 100%;
    outline: none
}

.wx-fullscreen-icon.x2-lr2z1n {
    position: absolute;
    z-index: 4;
    right: 3px;
    bottom: 16px
}

.wx-separator.x2-1eu7qav {
    width: 1px;
    min-height: 100%;
    border-left: 1px solid rgba(0, 0, 0, .07);
    border-right: var(--wx-border);
    margin: 6px 4px;
    box-sizing: border-box
}

.wx-column>.wx-tb-body>.wx-separator.x2-1eu7qav,
.wx-separator-menu.x2-1eu7qav {
    height: 1px;
    width: 100%;
    border-top: var(--wx-border-medium);
    align-self: center
}

.wx-spacer.x2-1mbb7ow {
    flex-grow: 1
}

.wx-spacer.x2-ptl7r2 {
    flex: 1
}

.wx-tb-element.x2-ptl7r2 {
    padding: 4px;
    flex-shrink: 0;
    align-self: center;
    display: flex
}

.wx-menu.wx-tb-element.x2-ptl7r2 {
    height: 36px;
    line-height: 36px;
    padding: 2px 8px;
    background-color: var(--wx-background)
}

.wx-menu.x2-ptl7r2 button {
    width: 100%;
    background: transparent;
    text-align: left;
    padding-left: 0;
    font-weight: var(--wx-font-weight);
    font-size: var(--wx-font-size)
}

.wx-menu.x2-ptl7r2 .wx-tb-element {
    align-self: stretch
}

.wx-menu.wx-tb-element.x2-ptl7r2 button:hover {
    background: var(--wx-background-alt)
}

.wx-menu.x2-ptl7r2 button i {
    margin-right: 4px;
    font-size: var(--wx-icon-size)
}

.wx-menu.wx-tb-element.x2-ptl7r2:hover {
    background: var(--wx-background-alt)
}

.wx-tb-group.x2-155fw4u.x2-155fw4u {
    position: relative;
    display: flex;
    flex-flow: column
}

.wx-group-collapsed.x2-155fw4u.x2-155fw4u {
    flex-flow: row
}

.wx-collapsed.x2-155fw4u.x2-155fw4u {
    display: flex;
    padding: 10px;
    flex-flow: row;
    cursor: pointer
}

.wx-group-collapsed.x2-155fw4u .wx-collapsed.x2-155fw4u {
    align-items: center
}

.wx-label.x2-155fw4u.x2-155fw4u {
    font-weight: var(--wx-label-font-weight);
    font-size: var(--wx-font-size-sm);
    color: var(--wx-color-font);
    text-align: center;
    display: flex;
    padding: 4px 2px 4px 12px;
    align-items: center;
    justify-content: center;
    width: 100%
}

.wx-tb-group.x2-155fw4u .wx-dropdown {
    overflow: inherit
}

.wx-column.x2-155fw4u.x2-155fw4u {
    align-items: stretch
}

.wx-column.x2-155fw4u .wx-tb-element {
    align-self: stretch
}

.wx-tb-body.x2-155fw4u.x2-155fw4u {
    height: 100%;
    display: flex
}

.wx-column.x2-155fw4u>.wx-tb-body.x2-155fw4u {
    flex-flow: column
}

.wx-menu.x2-7mtmlh {
    position: absolute;
    overflow: visible;
    padding: 4px;
    right: 4px
}

.wx-menu.x2-7mtmlh .wx-dropdown {
    overflow: visible
}

.wx-drop-menu.x2-7mtmlh {
    padding: 4px
}

.wx-toolbar.x2-b19ms9 {
    width: 100%;
    flex-wrap: nowrap;
    display: flex;
    align-items: stretch;
    padding: 4px;
    position: relative
}

.wx-toolbar.wx-wrap.x2-b19ms9 {
    flex-wrap: wrap
}

i.x2-b4dkf1 {
    margin-right: 8px;
    font-size: var(--wx-button-icon-size);
    color: var(--wx-icon-color)
}

.wx-item.x2-b4dkf1 {
    cursor: pointer;
    white-space: nowrap;
    line-height: 26px
}

.wx-label.x2-agyr5c {
    width: auto;
    font-weight: var(--wx-label-font-weight);
    color: var(--wx-input-font-color);
    padding: var(--wx-input-padding);
    overflow: hidden
}

i.x2-ng2v87 {
    margin-right: 8px;
    font-size: var(--wx-icon-size);
    color: var(--wx-color-font)
}

.wx-item.x2-ng2v87 {
    cursor: pointer;
    white-space: nowrap;
    line-height: 36px;
    height: 36px;
    box-sizing: border-box;
    display: flex;
    align-items: center
}

.wx-label.x2-g7c8cw {
    width: 100%;
    white-space: nowrap;
    color: var(--wx-input-font-color);
    padding: var(--wx-input-padding);
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center
}

i.x2-g7c8cw {
    margin-right: 8px;
    font-size: var(--wx-icon-size)
}

.wx-label.x2-g7c8cw:hover {
    background-image: linear-gradient(#0000001a 0% 100%)
}

.menu .item.disabled {
    pointer-events: none
}

.menu .item.disabled .value,
.menu .item.disabled .icon {
    color: var(--wx-color-font-disabled)
}

.wx-tooltip-area.x2-6x497h {
    position: relative;
    height: 100%;
    width: 100%
}

.wx-gantt-tooltip {
    pointer-events: none;
    position: absolute;
    z-index: 10;
    box-shadow: var(--wx-box-shadow);
    border-radius: 2px;
    overflow: hidden
}

.wx-gantt-tooltip-text.x2-6x497h {
    padding: 6px 10px;
    background-color: var(--wx-tooltip-background);
    font: var(--wx-tooltip-font);
    color: var(--wx-tooltip-font-color)
}

.wx-material-theme {
    --wx-gantt-border: var(--wx-border);
    --wx-gantt-form-header-border: var(--wx-border);
    --wx-gantt-icon-color: var(--wx-icon-color);
    --wx-gantt-bar-font: var(--wx-font-weight) var(--wx-font-size) var(--wx-font-family);
    --wx-gantt-bar-border-radius: 50px;
    --wx-gantt-milestone-border-radius: 3px;
    --wx-gantt-task-color: #448aff;
    --wx-gantt-task-font-color: #fff;
    --wx-gantt-task-fill-color: #246cd9;
    --wx-gantt-task-border-color: #448aff;
    --wx-gantt-task-border: 1px solid #246cd9;
    --wx-gantt-summary-color: #1de9b6;
    --wx-gantt-summary-font-color: #5f5f5f;
    --wx-gantt-summary-fill-color: #00d19a;
    --wx-gantt-summary-border-color: #1de9b6;
    --wx-gantt-summary-border: 1px solid #00d19a;
    --wx-gantt-milestone-color: #d33daf;
    --wx-gantt-select-color: rgb(201, 244, 240);
    --wx-gantt-link-color: #87a4bc;
    --wx-gantt-link-marker-background: #f0f0f0;
    --wx-gantt-link-marker-color: #87a4bc;
    --wx-gantt-bar-shadow: 0px 1px 2px rgba(44, 47, 60, .06), 0px 3px 10px rgba(44, 47, 60, .12);
    --wx-gantt-progress-marker-height: 22px;
    --wx-gantt-progress-border-color: #dfdfdf;
    --wx-gantt-baseline-border-radius: 4px;
    --wx-grid-header-font: 500 14px Roboto;
    --wx-grid-header-font-color: #a6a6a6;
    --wx-grid-header-text-transform: uppercase;
    --wx-grid-header-shadow: 0px 3px 5px rgba(0, 0, 0, .1), 0px 1px 2px rgba(0, 0, 0, .1);
    --wx-grid-body-font: 400 14px Roboto;
    --wx-grid-body-font-color: #5f5f5f;
    --wx-grid-body-row-border: 1px solid transparent;
    --wx-grid-body-cell-border: 1px solid transparent;
    --wx-timescale-font: 500 12px Roboto;
    --wx-timescale-font-color: #a6a6a6;
    --wx-timescale-text-transform: uppercase;
    --wx-timescale-shadow: 0px 3px 5px rgba(0, 0, 0, .1), 0px 1px 2px rgba(0, 0, 0, .1);
    --wx-timescale-border: 1px solid transparent;
    --wx-gantt-holiday-background: #f3f7fc;
    --wx-gantt-holiday-color: #9fa1ae;
    --wx-gantt-marker-font: 500 12px Roboto;
    --wx-gantt-marker-font-color: #fff;
    --wx-gantt-marker-color: rgba(6, 189, 248, .77);
    --wx-tooltip-font: var(--wx-font-weight) var(--wx-font-size) var(--wx-font-family);
    --wx-tooltip-font-color: #e6e6e6;
    --wx-tooltip-background: rgba(0, 0, 0, .7);
    --wx-sidebar-close-icon: var(--wx-color-secondary-font)
}

.wx-willow-theme {
    --wx-gantt-border: 1px solid #1d1e261a;
    --wx-gantt-form-header-border: none;
    --wx-gantt-icon-color: #9fa1ae;
    --wx-gantt-bar-font: var(--wx-font-weight) var(--wx-font-size) var(--wx-font-family);
    --wx-gantt-bar-border-radius: 3px;
    --wx-gantt-milestone-border-radius: 3px;
    --wx-gantt-task-color: #3983eb;
    --wx-gantt-task-font-color: #fff;
    --wx-gantt-task-fill-color: #1f6bd9;
    --wx-gantt-task-border-color: #1f6bd9;
    --wx-gantt-task-border: 1px solid transparent;
    --wx-gantt-summary-color: #00ba94;
    --wx-gantt-summary-font-color: #ffffff;
    --wx-gantt-summary-fill-color: #099f81;
    --wx-gantt-summary-border-color: #099f81;
    --wx-gantt-summary-border: 1px solid transparent;
    --wx-gantt-milestone-color: #ad44ab;
    --wx-gantt-select-color: #eaedf5;
    --wx-gantt-link-color: #9fa1ae;
    --wx-gantt-link-marker-background: #eaedf5;
    --wx-gantt-link-marker-color: #9fa1ae;
    --wx-gantt-bar-shadow: 0px 1px 2px rgba(44, 47, 60, .06), 0px 3px 10px rgba(44, 47, 60, .12);
    --wx-gantt-progress-marker-height: 26px;
    --wx-gantt-progress-border-color: #c0c3ce;
    --wx-gantt-baseline-border-radius: 2px;
    --wx-grid-header-font: var(--wx-font-weight-md) var(--wx-font-size) var(--wx-font-family);
    --wx-grid-header-font-color: var(--wx-color-font);
    --wx-grid-header-text-transform: capitalize;
    --wx-grid-header-shadow: none;
    --wx-grid-body-font: var(--wx-font-weight) var(--wx-font-size) var(--wx-font-family);
    --wx-grid-body-font-color: var(--wx-color-font);
    --wx-grid-body-row-border: var(--wx-gantt-border);
    --wx-grid-body-cell-border: 1px solid transparent;
    --wx-timescale-font: var(--wx-font-weight-md) var(--wx-font-size-sm) var(--wx-font-family);
    --wx-timescale-font-color: var(--wx-color-font);
    --wx-timescale-text-transform: uppercase;
    --wx-timescale-shadow: none;
    --wx-timescale-border: var(--wx-gantt-border);
    --wx-gantt-holiday-background: #f0f6fa;
    --wx-gantt-holiday-color: #9fa1ae;
    --wx-gantt-marker-font: var(--wx-font-weight-md) var(--wx-font-size-sm) var(--wx-font-family);
    --wx-gantt-marker-font-color: #fff;
    --wx-gantt-marker-color: rgba(6, 189, 248, .77);
    --wx-tooltip-font: var(--wx-font-weight) var(--wx-font-size) var(--wx-font-family);
    --wx-tooltip-font-color: #e6e6e6;
    --wx-tooltip-background: #4f525a;
    --wx-sidebar-close-icon: #c0c3ce
}

.wx-willow-dark-theme {
    color-scheme: dark;
    --wx-gantt-border: var(--wx-border);
    --wx-gantt-form-header-border: none;
    --wx-gantt-icon-color: #9fa1ae;
    --wx-gantt-bar-font: var(--wx-font-weight) var(--wx-font-size) var(--wx-font-family);
    --wx-gantt-bar-border-radius: 3px;
    --wx-gantt-milestone-border-radius: 3px;
    --wx-gantt-task-color: #37a9ef;
    --wx-gantt-task-font-color: #ffffffe5;
    --wx-gantt-task-fill-color: #098cdc;
    --wx-gantt-task-border-color: #098cdc;
    --wx-gantt-task-border: 1px solid transparent;
    --wx-gantt-summary-color: #00ba94;
    --wx-gantt-summary-font-color: #ffffffe5;
    --wx-gantt-summary-fill-color: #099f81;
    --wx-gantt-summary-border-color: #099f81;
    --wx-gantt-summary-border: 1px solid transparent;
    --wx-gantt-progress-marker-height: 26px;
    --wx-gantt-progress-border-color: #4b5359;
    --wx-gantt-baseline-border-radius: 2px;
    --wx-gantt-bar-shadow: 0px 1px 2px rgba(44, 47, 60, .06), 0px 3px 10px rgba(44, 47, 60, .12);
    --wx-gantt-milestone-color: #ad44ab;
    --wx-gantt-select-color: #384047;
    --wx-gantt-link-color: #9fa1ae;
    --wx-gantt-link-marker-background: #384047;
    --wx-gantt-link-marker-color: #9fa1ae;
    --wx-grid-header-font: var(--wx-font-weight-md) var(--wx-font-size) var(--wx-font-family);
    --wx-grid-header-font-color: var(--wx-color-font);
    --wx-grid-header-text-transform: capitalize;
    --wx-grid-header-shadow: none;
    --wx-grid-body-font: var(--wx-font-weight) var(--wx-font-size) var(--wx-font-family);
    --wx-grid-body-font-color: var(--wx-color-font);
    --wx-grid-body-row-border: var(--wx-border);
    --wx-grid-body-cell-border: 1px solid transparent;
    --wx-timescale-font: var(--wx-font-weight-md) var(--wx-font-size-sm) var(--wx-font-family);
    --wx-timescale-font-color: var(--wx-color-font);
    --wx-timescale-text-transform: uppercase;
    --wx-timescale-shadow: none;
    --wx-timescale-border: var(--wx-border);
    --wx-gantt-holiday-background: #303539;
    --wx-gantt-holiday-color: #878994;
    --wx-gantt-marker-font: var(--wx-font-weight-md) var(--wx-font-size-sm) var(--wx-font-family);
    --wx-gantt-marker-font-color: #fff;
    --wx-gantt-marker-color: rgba(6, 189, 248, .77);
    --wx-tooltip-font: var(--wx-font-weight) var(--wx-font-size) var(--wx-font-family);
    --wx-tooltip-font-color: #e6e6e6;
    --wx-tooltip-background: #4f525a;
    --wx-sidebar-close-icon: #384047
}

/* Add styles for drag handle */
.wx-drag-handle {
  cursor: move;
  color: #999;
  padding: 0 8px;
  display: flex;
  align-items: center;
  height: 100%;
}

.wx-drag-handle:hover {
  color: #666;
}

/* Hide drag handle when row is being dragged */
.wx-reorder-task .wx-drag-handle {
  opacity: 0.5;
}

.wx-drag-handle {
  &:hover {
    opacity: 1 !important;
  }

  &:active {
    cursor: grabbing !important;
  }
}

.wx-row {
  &:hover {
    .wx-drag-handle {
      opacity: 0.8;
    }
  }
}

// Add these styles to maintain proper alignment
.wx-cell-content {
  display: flex;
  align-items: center;

  .wx-drag-handle {
    flex-shrink: 0;
    user-select: none;
    display: inline-flex;
    align-items: center;
  }

  .wx-toggle-icon,
  .wx-toggle-placeholder {
    flex-shrink: 0;
  }

  .wx-text {
    flex: 1;
    min-width: 0;
  }
}

.wx-row {
  .wx-cell-content {
    display: flex;
    align-items: center;
    width: 100%;

    .wx-drag-handle {
      opacity: 0.6;
      transition: opacity 0.2s;

      &:hover {
        opacity: 1;
      }
    }

    .wx-toggle-icon,
    .wx-toggle-placeholder {
      margin-right: 4px;
    }

    .wx-text {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.wx-draggable-row {
  .wx-drag-handle {
    display: inline-flex;
    align-items: center;
    padding: 0 4px;
    color: #999;
    cursor: grab;
    user-select: none;

    &:hover {
      color: #666;
    }
  }

  // Ensure proper alignment with cell content
  .wx-cell:first-child {
    display: flex;
    align-items: center;

    .wx-toggle-icon,
    .wx-toggle-placeholder {
      margin-left: 4px;
    }
  }
}

.wx-toggle-container {
  display: flex;
  align-items: center;
  padding-left: 4px;
}

.wx-toggle-icon,
.wx-toggle-placeholder {
  margin-left: 4px;
}

.wx-drag-handle {
  opacity: 0.6;
  transition: opacity 0.2s;
}

.wx-row:hover .wx-drag-handle {
  opacity: 1;
}