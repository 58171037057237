// sidebar
.aside {
    background: #F6F8FA;
    left: 32px;
    top: 32px;
    bottom: 32px;
    overflow: visible;
    padding-bottom: 0 !important;
}

.ad-aside-menu {
    >.menu-item {
        padding: 7px 0;
    }
}

.aside-menu {
    .menu-item {
        .menu-sub {
            .menu-link {
                padding-left: 0;
            }
        }
    }

    .svg-icon {
        svg {
            width: 20px !important;
            height: 20px !important;
        }
    }
}

.aside {
    .aside-menu {
        .menu {
            .menu-item {
                .menu-link {
                    .menu-title {
                        color: var(--ad-menu-link-color);
                    }
                }

                .menu-sub {
                    .menu-title {
                        color: var(--ad-sub-menu-link-color);
                        font-weight: 500;
                    }

                    .menu-link {
                        padding-top: 3px;
                        padding-bottom: 3px;
                    }
                }
            }
        }
    }
}

.menu-item{
    .menu-sub{
        .menu-sub{
            margin-left: 15px !important;
        }
    }
}

.aside-footer{
    padding: 32px 0;
}



.aside-toggle-logo {
    display: none;
  }
  
  
  // sidebar toggle button
  .sidebar-toggle-button {
    border: 2px solid var(--kt-primary);
    outline: none;
    background: #fff;
    width: 34px;
    height: 34px;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 20px;
  
    i {
      display: inline-block;
      vertical-align: baseline;
      width: 20px;
      height: 20px;
      line-height: 20px;
      font-size: 20px;
      color: var(--kt-primary);
      border-radius: 50%;
      background: #fff;
    }
  
    &:hover {
      background: lighten(#466AEC, 25%);
    }
  }
  
  #kt_toggle_navigation {
    &[data-kt-toggle-name="on"] {

      .app-workspace-switch{
        display: none !important;
      }
  
      .sidebar-toggle-button {
        right: -17px;
        transform: rotate(180deg);
      }
  
      .aside {
        width: 80px;
  
        .aside-logo {
          padding: 0 10px !important;
          text-align: center;
          justify-content: center;
        }
  
        .menu-sub {
          &.menu-sub-accordion {
            display: none !important;
          }
        }
  
        .menu-item {
          .menu-link {
            .menu-title {
              display: none;
            }
          }
        }
      }
  
      .wrapper {
        padding-left: 112px !important;
      }
  
      .aside-default-logo {
        display: none;
      }
  
      .aside-toggle-logo {
        display: block;
      }
  
      .hide-sidebar-tggle {
        display: none !important;
      }
  
      .aside-footer {
        padding: 0 16px !important;
      }
  
      .aside-footer-inner {
        justify-content: center;
      }
    }
  }
  