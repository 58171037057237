// width class
.w-5 {
    width: 5% !important;
}

.w-10 {
    width: 10% !important;
}

.w-15 {
    width: 15% !important;
}

.w-20 {
    width: 20% !important;
}

// modal
.page-builder-modal {
    >.modal-content {
        display: block;
    }
}

.modal-dialog-scrollable {
    height: calc(100% - var(--bs-modal-margin) * 2);
}

// date picker
body {
    .date-picker {
        padding: 11px 20px;
    }
}

// page builder
.page-builder-thumb {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
    }
}

.component-variant-selection {
    .widget-icon {
        margin-bottom: 10px;

        svg,
        img {
            max-width: 100%;
        }

        &.active-state {
            display: none;
        }
    }

    .form-check-label {
        width: 192px;
        border: 2px solid #CCCCCC;
        border-radius: 6px;
        padding: 16px;
        height: 100%;
    }

    .form-check-input {
        display: none;

        &:checked {
            +.form-check-label {
                border: 2px solid #466AEC;

                .widget-icon {
                    &.active-state {
                        display: block;
                    }

                    &.normal-state {
                        display: none;
                    }
                }

                .variant-style-name {
                    color: var(--kt-primary);
                }
            }
        }
    }
}

// breadcrumb

.breadcrumb-dot {
    .breadcrumb-item {
        &:after {
            margin-left: 5px;
        }
    }
}

.breadcrumb {
    .breadcrumb-item {
        &:last-child {
            width: 600px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block !important;
        }
    }
}

// custom style

#customer-detail {
    .label {
        display: none !important;
    }

    .text-muted {
        width: auto;
    }

    .info-col {
        // width: 50%;
        padding-left: 0;
    }
}

.hide-navigation-links {
    .menu-column {
        .menu-item {
            display: none;

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                display: block;
            }
        }
    }
}

.order-quantity-input {
    .form-input {
        max-width: 100%;
    }
}

.onfocus-edit-input {
    width: 100px;
    margin-bottom: 0;
}