.stat-card-wrapper {
  border-radius: 16px;
  display: grid;
  place-content: center;
  padding: 30px 40px;
}

.stat-card-title .stat-card-description {
  margin: auto;
}
