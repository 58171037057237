.theme-custom-loading{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(156, 156, 156, 0.275);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  .table-loading-inner, .section-loading-inner{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 40px;
    background: #fff;
    border-radius: 6px;
    margin-top: 100px;
  }

  .table-overlay, .section-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-color: rgba(156, 156, 156, 0.275);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
}
  
  .loading-spinner {
    display: inline-block;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  // table loader min height
  .table-loader-wrapper{
    min-height: 300px;
  }