.table-modes {

  .table-expan-link {
    text-decoration: underline;
  }

  .table-close-link {
    width: 30px;
    display: none;
    height: 30px;

    .svg-icon.svg-icon-1 svg {
      width: 30px !important;
      height: 30px !important;
    }
  }

  &.enable-expand-view {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    height: 100vh;
    z-index: 100;
    padding: 60px 30px 30px 30px;

    .table-mode-buttons {
      position: absolute;
      top: 15px;
      right: 30px;
    }

    .table-fullscreen-link {
      .table-expan-link {
        display: none;
      }
    }


    .table-close-link {
      display: inline-block;
    }

    .table-responsive-wrapper {
      height: calc(100vh - 120px);
      width: 100%;
      overflow: auto;
    }

    .table-responsive {
      overflow: visible;

      thead {
        position: sticky;
        top: 0;
        background: #ffffff;
        z-index: 10;
      }

      .remove-overflow {
        overflow: visible;
      }
    }



    .replenishment-dashboard-sticky-table{
      >thead {
        tr {
          th {
            &.head-col-sticky {
              position: sticky;
              background: #ffffff;
            }

            &:nth-child(1) {
              left: 0;
            }

            &:nth-child(2) {
              left: 150px;
            }
          }
        }
      }

      >tbody {
        tr {
          td {
            &.body-col-sticky {
              position: sticky;
              background: #ffffff;
            }

            &:nth-child(1) {
              left: 0;
            }

            &:nth-child(2) {
              left: 150px;
            }
          }
        }
      }
    }

    .replenishment-sticky-table {
      >thead {
        tr {
          th {
            &.head-col-sticky {
              position: sticky;
              background: #ffffff;
            }

            &:nth-child(1) {
              left: 0;
            }

            &:nth-child(2) {
              left: 40px;
            }

            &:nth-child(3) {
              left: 90px;
            }

            &:nth-child(4) {
              left: 240px;
            }

            &:nth-child(5) {
              left: 640px;
            }

            &:nth-child(6) {
              left: 890px;
            }
            &:nth-child(7) {
              left: 1040px;
            }
            &:nth-child(8) {
              left: 1140px;
            }
            &:nth-child(9) {
              left: 1290px;
            }
            &:nth-child(10) {
              left: 1390px;
            }
          }
        }
      }

      >tbody {
        tr {
          td {
            &.body-col-sticky {
              position: sticky;
              background: #ffffff;
            }

            &:nth-child(1) {
              left: 0;
            }

            &:nth-child(2) {
              left: 40px;
            }

            &:nth-child(3) {
              left: 90px;
            }

            &:nth-child(4) {
              left: 240px;
            }

            &:nth-child(5) {
              left: 640px;
            }

            &:nth-child(6) {
              left: 890px;
            }
            &:nth-child(7) {
              left: 1040px;
            }
            &:nth-child(8) {
              left: 1140px;
            }
            &:nth-child(9) {
              left: 1290px;
            }
            &:nth-child(10) {
              left: 1390px;
            }
          }
        }
      }

      .remove-overflow {
        thead {
          tr {
            th {
              &.head-col-sticky {
                position: sticky;
                background: #F5F8FA;
              }

              &:nth-child(1) {
                left: 0;
              }
  
              &:nth-child(2) {
                left: 40px;
              }
  
              &:nth-child(3) {
                left: 90px;
              }
  
              &:nth-child(4) {
                left: 240px;
              }
  
              &:nth-child(5) {
                left: 640px;
              }
  
              &:nth-child(6) {
                left: 790px;
              }
              &:nth-child(7) {
                left: 890px;
              }
              &:nth-child(8) {
                left: 1040px;
              }
              &:nth-child(9) {
                left: 1140px;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              &.body-col-sticky {
                position: sticky;
                background: #ffffff;
              }

              &:nth-child(1) {
                left: 0;
              }
  
              &:nth-child(2) {
                left: 40px;
              }
  
              &:nth-child(3) {
                left: 90px;
              }
  
              &:nth-child(4) {
                left: 240px;
              }
  
              &:nth-child(5) {
                left: 640px;
              }
  
              &:nth-child(6) {
                left: 790px;
              }
              &:nth-child(7) {
                left: 890px;
              }
              &:nth-child(8) {
                left: 1040px;
              }
              &:nth-child(9) {
                left: 1140px;
              }
            }
          }
        }
      }
    }

    .manage-distrubution-sticky-table {
      >thead {
        tr {
          th {
            &.head-col-sticky {
              position: sticky;
              background: #ffffff;
            }

            &:nth-child(1) {
              left: 0;
            }

            &:nth-child(2) {
              left: 150px;
            }

            &:nth-child(3) {
              left: 450px;
            }
            &:nth-child(4) {
              left: 600px;
            }
          }
        }
      }

      >tbody {
        tr {
          td {
            &.body-col-sticky {
              position: sticky;
              background: #ffffff;
            }

            &:nth-child(1) {
              left: 0;
            }

            &:nth-child(2) {
              left: 150px;
            }

            &:nth-child(3) {
              left: 450px;
            }
            &:nth-child(4) {
              left: 600px;
            }
          }
        }
      }

    }
  }
}