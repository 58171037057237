//
// Root
//

// Light mode
@include theme-light() {
    // Page bg
    --kt-page-bg: #{$page-bg};

    // Header
    --kt-header-desktop-fixed-bg-color: #{get($header-config, desktop, fixed, bg-color)};
    --kt-header-desktop-fixed-box-shadow: #{get($header-config, desktop, fixed, box-shadow)};

    --kt-header-desktop-fixed-bg-color: #{get($header-config, tablet-and-mobile, fixed, bg-color)};
    --kt-header-desktop-fixed-box-shadow: #{get($header-config, tablet-and-mobile, fixed, box-shadow)};

    // Aside
    --kt-aside-bg-color: #{get($aside-config, bg-color)};

    // Sidebar
    --kt-sidebar-bg-color: #{get($sidebar-config, bg-color)};
    --kt-sidebar-tabs-link-bg-color-default: #{get($sidebar-config, tabs, link-bg-color, default)};
    --kt-sidebar-tabs-link-bg-color-active: #{get($sidebar-config, tabs, link-bg-color, active)};
    --kt-sidebar-tabs-link-icon-color-default: #{get($sidebar-config, tabs, link-icon-color, default)};
    --kt-sidebar-tabs-link-icon-color-active: #{get($sidebar-config, tabs, link-icon-color, active)};
    --kt-sidebar-dashed-border-color: #{get($sidebar-config, dashed-border-color)};
    --kt-sidebar-muted-color: #{get($sidebar-config, muted-color)};
    --kt-sidebar-muted-bg-color: #{get($sidebar-config, muted-bg-color)};
}

// Dark mode
@include theme-dark() {
    // Page bg
    --kt-page-bg: #{$page-bg-dark};

    // Header
    --kt-header-desktop-fixed-bg-color: #{get($header-config, desktop, fixed, bg-color-dark)};
    --kt-header-desktop-fixed-box-shadow: #{get($header-config, desktop, fixed, box-shadow-dark)};

    --kt-header-desktop-fixed-bg-color: #{get($header-config, tablet-and-mobile, fixed, bg-color-dark)};
    --kt-header-desktop-fixed-box-shadow: #{get($header-config, tablet-and-mobile, fixed, box-shadow-dark)};

    // Aside
    --kt-aside-bg-color: #{get($aside-config, bg-color-dark)};

    // Sidebar
    --kt-sidebar-bg-color: #{get($sidebar-config, bg-color-dark)};
    --kt-sidebar-tabs-link-bg-color-default: #{get($sidebar-config, tabs, link-bg-color, default-dark)};
    --kt-sidebar-tabs-link-bg-color-active: #{get($sidebar-config, tabs, link-bg-color, active-dark)};
    --kt-sidebar-tabs-link-icon-color-default: #{get($sidebar-config, tabs, link-icon-color, default-dark)};
    --kt-sidebar-tabs-link-icon-color-active: #{get($sidebar-config, tabs, link-icon-color, active-dark)};
    --kt-sidebar-dashed-border-color: #{get($sidebar-config, dashed-border-color-dark)};
    --kt-sidebar-muted-color: #{get($sidebar-config, muted-color-dark)};
    --kt-sidebar-muted-bg-color: #{get($sidebar-config, muted-bg-color-dark)};
}
