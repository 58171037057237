.mentions-menu {
  background: white;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
}

.mentions-menu::-webkit-scrollbar {
  width: 8px;
}

.mentions-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.mentions-menu::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.mentions-menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mentions-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mentions-menu .item {
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.mentions-menu .item:hover,
.mentions-menu .item.selected {
  background: #f0f0f0;
}

.mentions-menu .item .icon {
  color: #666;
}

.mentions-menu .item .text {
  flex: 1;
}

/* Add these new styles for mentions */
.mention {
  background-color: rgba(24, 119, 232, 0.2);
  padding: 1px 4px;
  border-radius: 2px;
  font-weight: 500;
  display: inline;
  white-space: nowrap;
  color: rgb(5, 76, 160);
}

.mention:hover {
  background-color: rgba(24, 119, 232, 0.3);
}

[data-lexical-mention] {
  background-color: rgba(24, 119, 232, 0.2);
  border-radius: 4px;
  padding: 1px 4px;
  margin: 0 1px;
  vertical-align: baseline;
  display: inline-block;
}

[data-lexical-mention]:hover {
  background-color: rgba(24, 119, 232, 0.3);
  cursor: pointer;
}
