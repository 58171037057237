.selectMenu {
  z-index: 1050 !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.65rem !important;
}

.selectPlaceholder {
  color: #6c757d !important;
}

.selectSingleValue {
  color: #5e6278 !important;
}

.react-select__indicators {
  svg {
    color: #ccc !important;
    stroke-width: 0 !important;
  }
}

.react-select__clear-indicator {
  padding: 0 !important;
  cursor: pointer !important;
}

.react-select__indicator-separator {
  display: none !important;
}

/* Additional styles for select */
.react-select__control {
  border: 1px solid #e4e6ef !important;
  border-radius: 0.65rem !important;
  display: flex !important;
  box-shadow: none !important;
}

.react-select__control--is-focused {
  border-color: #bec0c8 !important;
}

.react-select__control--is-disabled {
  background-color: #EFF2F5 !important;
  border-color: --kt-form-select-disabled-border-color !important;
  font-weight: 500;
}

.react-select__control--is-invalid {
  border-color: #dc3545 !important;
}

.react-select__menu {
  z-index: 1050 !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.65rem !important;
  font-weight: 400 !important;
}


.react-select__option--is-selected {
  background-color: #0065d1 !important;
  color: #fff !important;
}

.react-select__placeholder {
  color: #6c757d !important;
}

.react-select__single-value {
  color: #181C32 !important;
}

.react-select__indicators {
  color: #ccc !important;
}

.react-select__dropdown-indicator {
  padding: 0 8px !important;
}

.react-select__option[aria-disabled="true"] {

  &[data-value="loading-more"],
  &[data-value="no-options"] {
    text-align: center;
    background-color: transparent !important;
    cursor: default;

    &:hover {
      background-color: transparent !important;
    }
  }
}

.react-select {
  &__menu {
    .react-select__option {
      cursor: pointer;
      color: #181C32;

      // Hover state for unselected options
      &:hover:not(.react-select__option--is-selected):not(.react-select__option--is-disabled) {
        background-color: #DEEBFF !important;
        color: #181C32 !important;
      }

      // Focused state (keyboard navigation)
      &--is-focused {
        background-color: transparent !important;

        &:hover:not(.react-select__option--is-selected):not(.react-select__option--is-disabled) {
          background-color: #DEEBFF !important;
        }
      }

      // Selected option
      &--is-selected {
        background-color: #0065d1 !important;
        color: #ffffff !important;
      }

      // Add specific hover state for selected option
      &--is-selected:hover {
        background-color: #0065d1 !important;
        color: #ffffff !important;
      }

      // Disabled option
      &--is-disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }
  }
}