.modal-header{
    padding: 10px 25px;
    .btn.btn-icon{
        height: 30px;
        width: 30px;
    }
}


.modal-dialog{
    &.modal-large{
        @media (min-width: 576px) {
            min-width: 700px;
        }
    }
}