.navigation-left-bar{
    .accordion-item{
        border-radius: 0;
        background: none;
        border: none;

        .accordion-body{
            padding: 20px 10px;
        }

        + .accordion-item{
            border-top: 1px solid #E4E6E6;
        }
    }
    .accordion-button{
        background: none;
        padding: 12px;
        box-shadow: none !important;
    }
}

.navigation-check-item{
    .form-check-label{
        font-weight: 500;
    }
}

.navigation-right-bar{
    .accordion-item{
        background: none;
    }
    .accordion-button{
        background: none;
        padding: 15px;
        box-shadow: none !important;
    }
}