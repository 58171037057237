body {
  font-size: var(--ad-body-font) !important;
  background: #ffffff;
}

a {
  cursor: pointer;

  label {
    cursor: pointer;
  }
}

.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
  @media only screen and (min-width: 767px) {
    max-width: 100%;
  }
}

.radius-10 {
  border-radius: 10px;
}

.min-w-120px {
  min-width: 120px !important;
}

.min-w-180px {
  min-width: 180px !important;
}

.app-custom-wrapper {
  // background: #ffffff;
  // border-radius: 16px;
  // box-shadow: 0px 0px 15px 0px #00000005;
  // padding: 0 32px !important;

  &.layout-consignments,
  &.layout-products {
    padding: 0 !important;
    background: none;
    box-shadow: none;
  }
}

.container,
.container-xxl,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  @media only screen and (min-width: 767px) {
    padding: 0 32px !important;
  }
}

.header {
  height: auto;
}

.wrapper {
  padding-top: 32px;
  padding-bottom: 32px;

  @media only screen and (min-width: 992px) {
    padding-left: 332px;
  }
}

.card {
  .card-header {
    &.auto-height {
      height: auto;
      min-height: auto;
    }
  }
}

thead {
  &.table-row-bordered {
    td,
    th {
      border-style: solid;
    }
  }
}

.sort-both-arrow {
  > div {
    line-height: 5px !important;
  }
}

.popover-body {
  label {
    cursor: pointer;
  }
}

.icon-24 {
  .svg-icon {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.sm-text-start {
  @media only screen and (max-width: 767px) {
    text-align: left !important;
  }
}

.object-fit-cover {
  object-fit: contain;
}

.page-title {
  h1 {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box !important;
    overflow: hidden;
  }
}