.mentions {
    margin: 1em 0;
    min-height: 80px;
    border-radius: 10px;
    border: 1px solid #e4e6ef;
    z-index: 2;
  }

  .mentions--multiLine .mentions__control {
    font-family: inherit;
    font-size: 1.1rem;
    line-height: 16px;
  }

  .mentions--multiLine .mentions__highlighter {
    border: 1px solid transparent;
    padding: 9px;
    min-height: 63px;
  }

  .mentions--multiLine .mentions__input {
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 9px;
    outline: 0;
    color: #5e6278;
  }
  .mentions--multiLine .mentions__input::placeholder {
    color: #A1A5B7;
  }
  .mentions__suggestions__list {
    background-color: white;
    font-size: 10pt;
    max-height: 300px;
    overflow-y: scroll;
    border: 1px solid #e9e9e9;
    color: #181c32;
    border-radius: 5px;
    transform: translateY(10px);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .mentions__suggestions__item {
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    height: 37px;
    line-height: 21px;
    font-size: 14px;
    font-family: sans-serif;
    padding: 8px 12px;
  }

  .mentions__suggestions__item--focused {
    background-color: #DEEBFF;
    color: #181c32;
  }

  .mentions__mention {
    position: relative;
    z-index: 1;
    color: #466aec;
    text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
      -1px -1px 1px white;
    pointer-events: none;
    font-weight: 600;
  }