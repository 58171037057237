.texteditor_ltr {
  text-align: left;
}
.texteditor_rtl {
  text-align: right;
}
.texteditor_paragraph {
  position: relative;
}
.texteditor_quote {
  margin-left: 20px;
  margin-bottom: 10px;
  font-size: 15px;
  color: rgb(101, 103, 107);
  border-left-color: rgb(206, 208, 212);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}

.texteditor_indent {
  --lexical-indent-base-value: 40px;
}
.texteditor_textBold {
  font-weight: bold;
}
.texteditor_textItalic {
  font-style: italic;
}
.texteditor_textUnderline {
  text-decoration: underline;
}
.texteditor_textStrikethrough {
  text-decoration: line-through;
}
.texteditor_textUnderlineStrikethrough {
  text-decoration: underline line-through;
}
.texteditor_textSubscript {
  font-size: 0.8em;
  vertical-align: sub !important;
}
.texteditor_textSuperscript {
  font-size: 0.8em;
  vertical-align: super;
}
.texteditor_textCode {
  background-color: rgb(240, 242, 245);
  padding: 1px 0.25rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
}
.texteditor_hashtag {
  background-color: rgba(88, 144, 255, 0.15);
  border-bottom: 1px solid rgba(88, 144, 255, 0.3);
}
.texteditor_link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}
.texteditor_link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.texteditor_code {
  background-color: rgb(240, 242, 245);
  font-family: Menlo, Consolas, Monaco, monospace;
  display: block;
  padding: 8px 8px 8px 40px !important;
  line-height: 1.53;
  font-size: 13px;
  margin-top: 8px;
  margin-bottom: 8px;
  overflow-x: auto;
  position: relative;
  tab-size: 2;
}
.texteditor_code:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  color: #777;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}
.texteditor_table {
  border-collapse: collapse;
  border-spacing: 0;
  overflow-y: scroll;
  overflow-x: scroll;
  table-layout: fixed;
  width: max-content;
  margin: 30px 0;
}
.texteditor_tableSelection *::selection {
  background-color: transparent;
}
.texteditor_tableSelected {
  outline: 2px solid rgb(60, 132, 244);
}
.texteditor_tableCell {
  border: 1px solid #bbb;
  width: 75px;
  min-width: 75px;
  vertical-align: top;
  text-align: start;
  padding: 6px 8px;
  position: relative;
  outline: none;
}
.texteditor_tableCellSortedIndicator {
  display: block;
  opacity: 0.5;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #999;
}
.texteditor_tableCellResizer {
  position: absolute;
  right: -4px;
  height: 100%;
  width: 8px;
  cursor: ew-resize;
  z-index: 10;
  top: 0;
}
.texteditor_tableCellHeader {
  background-color: #f2f3f5;
  text-align: start;
}
.texteditor_tableCellSelected {
  background-color: #c9dbf0;
}
.texteditor_tableCellPrimarySelected {
  border: 2px solid rgb(60, 132, 244);
  display: block;
  height: calc(100% - 2px);
  position: absolute;
  width: calc(100% - 2px);
  left: -1px;
  top: -1px;
  z-index: 2;
}
.texteditor_tableCellEditing {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}
.texteditor_tableAddColumns {
  position: absolute;
  top: 0;
  width: 20px;
  background-color: #eee;
  height: 100%;
  right: -25px;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}
.texteditor_tableAddColumns:after {
  background-image: url(./icons/plus.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.texteditor_tableAddColumns:hover {
  background-color: #c9dbf0;
}
.texteditor_tableAddRows {
  position: absolute;
  bottom: -25px;
  width: calc(100% - 25px);
  background-color: #eee;
  height: 20px;
  left: 0;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}
.texteditor_tableAddRows:after {
  background-image: url(./icons/plus.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.texteditor_tableAddRows:hover {
  background-color: #c9dbf0;
}
@keyframes table-controls {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.texteditor_tableCellResizeRuler {
  display: block;
  position: absolute;
  width: 1px;
  background-color: rgb(60, 132, 244);
  height: 100%;
  top: 0;
}
.texteditor_tableCellActionButtonContainer {
  display: block;
  right: 5px;
  top: 6px;
  position: absolute;
  z-index: 4;
  width: 20px;
  height: 20px;
}
.texteditor_tableCellActionButton {
  background-color: #eee;
  display: block;
  border: 0;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  color: #222;
  cursor: pointer;
}
.texteditor_tableCellActionButton:hover {
  background-color: #ddd;
}
.texteditor_characterLimit {
  display: inline;
  background-color: #ffbbbb !important;
}
.texteditor_ol1 {
  list-style-position: inside;
}
.texteditor_ol2 {
  list-style-type: upper-alpha;
  list-style-position: inside;
}
.texteditor_ol3 {
  list-style-type: lower-alpha;
  list-style-position: inside;
}
.texteditor_ol4 {
  list-style-type: upper-roman;
  list-style-position: inside;
}
.texteditor_ol5 {
  list-style-type: lower-roman;
  list-style-position: inside;
}
.texteditor_ul {
  list-style-position: inside;
}
.texteditor_listItem {
  margin: 0 32px;
}
.texteditor_listItemChecked,
.texteditor_listItemUnchecked {
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 24px;
  padding-right: 24px;
  list-style-type: none;
  outline: none;
}
.texteditor_listItemChecked {
  text-decoration: line-through;
}
.texteditor_listItemUnchecked:before,
.texteditor_listItemChecked:before {
  content: '';
  width: 16px;
  height: 16px;
  top: 2px;
  left: 0;
  cursor: pointer;
  display: block;
  background-size: cover;
  position: absolute;
}
.texteditor_listItemUnchecked[dir='rtl']:before,
.texteditor_listItemChecked[dir='rtl']:before {
  left: auto;
  right: 0;
}
.texteditor_listItemUnchecked:focus:before,
.texteditor_listItemChecked:focus:before {
  box-shadow: 0 0 0 2px #a6cdfe;
  border-radius: 2px;
}
.texteditor_listItemUnchecked:before {
  border: 1px solid #999;
  border-radius: 2px;
}
.texteditor_listItemChecked:before {
  border: 1px solid rgb(61, 135, 245);
  border-radius: 2px;
  background-color: #3d87f5;
  background-repeat: no-repeat;
}
.texteditor_listItemChecked:after {
  content: '';
  cursor: pointer;
  border-color: #fff;
  border-style: solid;
  position: absolute;
  display: block;
  top: 6px;
  width: 3px;
  left: 7px;
  right: 7px;
  height: 6px;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
}
.texteditor_nestedListItem {
  list-style-type: none;
}
.texteditor_nestedListItem:before,
.texteditor_nestedListItem:after {
  display: none;
}
.texteditor_tokenComment {
  color: slategray;
}
.texteditor_tokenPunctuation {
  color: #999;
}
.texteditor_tokenProperty {
  color: #905;
}
.texteditor_tokenSelector {
  color: #690;
}
.texteditor_tokenOperator {
  color: #9a6e3a;
}
.texteditor_tokenAttr {
  color: #07a;
}
.texteditor_tokenVariable {
  color: #e90;
}
.texteditor_tokenFunction {
  color: #dd4a68;
}
.texteditor_mark {
  background: rgba(255, 212, 0, 0.14);
  border-bottom: 2px solid rgba(255, 212, 0, 0.3);
  padding-bottom: 2px;
}
.texteditor_markOverlap {
  background: rgba(255, 212, 0, 0.3);
  border-bottom: 2px solid rgba(255, 212, 0, 0.7);
}
.texteditor_mark.selected {
  background: rgba(255, 212, 0, 0.5);
  border-bottom: 2px solid rgba(255, 212, 0, 1);
}
.texteditor_markOverlap.selected {
  background: rgba(255, 212, 0, 0.7);
  border-bottom: 2px solid rgba(255, 212, 0, 0.7);
}
.texteditor_embedBlock {
  user-select: none;
}
.texteditor_embedBlockFocus {
  outline: 2px solid rgb(60, 132, 244);
}
.texteditor_layoutContaner {
  display: grid;
  gap: 10px;
  margin: 10px 0;
}
.texteditor_layoutItem {
  border: 1px dashed #ddd;
  padding: 8px 16px;
}
