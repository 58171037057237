.form-select{
    padding: 9px 40px 9px 12px;
    line-height: 16px;
}

.btn{
    font-family: var(--ad-font-family);
    font-size: 14px;
    line-height: 16px;
}

.btn,
.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon){
    padding: 10px 12px;
}

.form-control{
    padding: 9px 12px;
    line-height: 16px;
}

.form-label{
    font-weight: 500;
    font-size: 14px;
    color: var(--ad-label-color);
}

.form-check-input:checked[type=checkbox], .multiselect-container .optionListContainer > ul li input:checked[type=checkbox]{
    background-size: 12px auto;
}