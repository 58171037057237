.pagination{
    border: 1px solid var(--kt-border-color);
    border-radius: 4px;

    .page-item{
        margin: 0;

        + .page-item{
            border-left: 1px solid var(--kt-border-color);
        }

        .page-link{
            padding: 10px;
            min-width: 36px;
            height: 36px;
            width: auto;
            margin: 0;
            border-radius: 0;
            background: none;
            border: none;
        }

        &.active{
            .page-link{
                background-color: var(--kt-pagination-active-bg);
            }
        }

        &.previous, &.next, &.first{
            .page-link{
                width: auto;
            }
        }
    }
}