*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.sign{
    margin-bottom: 20px;
}

.login-root{
    width: 100%!important;
    height: 100vh!important;
    padding : 0 2rem;
    
}

.form-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
}

.form-main{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;    
}

.image-main{
    flex: 1;
    overflow: hidden;
   
}

.login-image{
    width: 1000px!important
}


.input-wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.input-root{
    margin-top: 20px;
}

.form-input{
    padding: 12px 15px;
    width: 350px;
    border-radius: 10px;
    border: none;
    margin-bottom: 20px;
    background-color: #f5f8fa;

    &:focus{
        outline: none;
    }
    
}

.lgn-btn{
    padding: 10px 15px;
    width: 350px;
    margin-top: 20px;
    border-radius: 10px;
    border: none;
    font-size: 1.2rem;

    &:hover{
        background-color: antiquewhite;
    }
}

.line{
    margin : 10px 0
}