:root {
    --ad-font-family: 'Inter', sans-serif;
    --ad-body-bg: #fafafa;
    --ad-body-font: 14px;
    --ad-aside-bg: #F2F3F5;
    --ad-label-color: #181C32;

    // aside menu
    --ad-menu-link-color: #181C32;
    --ad-sub-menu-link-color: #7E8299;
}