.agile-accordion-section {
  .accordion-loop-item {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--bs-gray-300);
  }

  .accordion-button {
    box-shadow: none !important;
    background: none;
    padding: 0 !important;
  }

  .accordion-body {
    padding: 0;
    padding-top: 20px;
  }
}

.board-heading-section {
  flex-wrap: nowrap;
}

.board-heading-item-count {
  display: inline-block;
}

.board-heading-section-sticky {
  position: sticky;
  top: 0;
}

.board-column-wrapper {
  flex-wrap: nowrap;
}

.board-column-item {
  min-height: 200px;
}

.board-card-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.board-card-item{
  &:hover{
    border-color: var(--kt-text-primary) !important;

    .ticket-card-id{
      color: var(--kt-text-primary) !important;;
    }
  }
}

.board-task-create-link {
  &:hover {
    background: #f5f8fa;
  }
}

.dropdown-content {
  z-index: 99999 !important;
}

// column container

// .dummy-background-column-container {
//     background-color: rgba(0, 0, 0, 0.101);
//     width: 320px;
//     height: 300px;
//     max-height: 300px;
//     border-radius: 10px;
//     display: flex;
//     flex-direction: column;
//     opacity: .30;
//     border: 2px solid red;
// }

// task card

// .task-main-container {
//     background-color: #fff;
//     min-height: 100px;
//     gap: 12px;
//     border-radius: 5px;
//     cursor: grab !important;
//     width: 100%;
//     border: 2px solid #efefef;
//     position: relative;
//     transition: all 0.3s ease-in-out;
//     outline: none;

//     &:hover {
//         border: 2px solid #e5e5e5;
//     }

// }

// .dummy-background-task-container {
//     background-color: #fff;
//     height: 100px;
//     gap: 12px;
//     border-radius: 5px;
//     cursor: grab !important;
//     width: 100%;
//     padding: 12px;
//     border: 2px solid #efefef;
//     min-height: 100px;
//     position: relative;
//     transition: all 0.3s ease-in-out;
// }

// project board modal

.project-log-tileline {
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 60px;
  background-color: #E4E6EF;
  position: absolute;
}

.ticket-detail-maincontent{
  max-width: calc(100% - 350px);
}
.editor-scroller{
  overflow: auto;
}