.wx-willow-theme {
    /* Border and general styles */
    --wx-gantt-border: 1px solid var(--kt-border-color);
    --wx-gantt-form-header-border: none;
    --wx-gantt-icon-color: var(--kt-text-muted);

    /* Bars */
    --wx-gantt-bar-font: 500 12px 'Inter', sans-serif !important;
    --wx-gantt-bar-border-radius: 40px;
    --wx-gantt-milestone-border-radius: 0.475rem;


    /* Task styles */
    --wx-gantt-task-color: #C3DDFD;
    --wx-gantt-task-font-color: #181C32 !important;
    --wx-gantt-task-fill-color: #5d89ef;
    --wx-gantt-task-border-color: #9fdeff;
    --wx-gantt-task-border: 1px solid #F1FAFF;

    /* Summary styles */
    --wx-gantt-summary-color: #ccf9e2;
    --wx-gantt-summary-font-color: var(--kt-success-inverse);
    --wx-gantt-summary-fill-color: #6dd29b;
    --wx-gantt-summary-border-color: #E8FFF3;
    --wx-gantt-summary-border: 1px solid #a8ffcf;

    /* Milestone styles */
    --wx-gantt-milestone-color: var(--kt-info);

    /* Selection and link styles */
    --wx-gantt-select-color: var(--kt-gray-200);
    --wx-gantt-link-color: var(--kt-gray-500);
    --wx-gantt-link-marker-background: var(--kt-gray-200);
    --wx-gantt-link-marker-color: var(--kt-gray-500);

    /* Bar shadow */
    --wx-gantt-bar-shadow: var(--kt-box-shadow);

    /* Progress styles */
    --wx-gantt-progress-marker-height: 26px;
    --wx-gantt-progress-border-color: var(--kt-border-color);
    --wx-gantt-baseline-border-radius: 0.475rem;

    /* Grid styles */
    --wx-grid-header-font: 500 var(--kt-font-size) 'Inter', sans-serif !important;
    --wx-grid-header-font-color: var(--kt-text-muted);
    --wx-grid-header-text-transform: uppercase;
    --wx-grid-header-shadow: none;
    --wx-grid-header-background: var(--kt-body-bg);
    --wx-grid-header-border: 1px solid var(--kt-border-color);
    --wx-grid-header-padding: 0.75rem 0.75rem;

    /* Grid body styles */
    --wx-grid-body-font: 400 var(--kt-font-size) 'Inter', sans-serif !important;
    --wx-grid-body-font-color: #181C32;
    --wx-grid-body-row-border: 1px dashed var(--kt-gray-300);
    --wx-grid-body-cell-border: none;
    --wx-grid-body-cell-padding: 0.75rem 0.75rem;
    --wx-grid-body-row-hover-bg: var(--kt-gray-100);
    --wx-grid-body-vertical-align: middle;

    /* Row alternating colors */
    --wx-grid-body-row-even-bg: transparent;
    --wx-grid-body-row-odd-bg: transparent;

    /* Add styles for selected state */
    --wx-grid-body-row-selected-bg: var(--kt-gray-100);
    --wx-grid-body-row-selected-border: 1px dashed var(--kt-gray-400);

    /* Timescale styles */
    --wx-timescale-font: 500 var(--kt-font-size) 'Inter', sans-serif !important;
    --wx-timescale-font-color: var(--kt-text-muted);
    --wx-timescale-text-transform: uppercase;
    --wx-timescale-shadow: none;
    --wx-timescale-border: 1px solid var(--kt-border-color);

    /* Holiday styles */
    --wx-gantt-holiday-background: var(--kt-gray-100);
    --wx-gantt-holiday-color: var(--kt-text-muted);

    /* Marker styles */
    --wx-gantt-marker-font: 500 var(--kt-font-sm) var(--kt-font-family);
    --wx-gantt-marker-font-color: var(--kt-white);
    --wx-gantt-marker-color: var(--kt-primary-light);

    /* Tooltip styles */
    --wx-tooltip-font: 400 var(--kt-font-size) var(--kt-font-family);
    --wx-tooltip-font-color: var(--kt-white);
    --wx-tooltip-background: var(--kt-gray-900);

    /* Sidebar styles */
    --wx-sidebar-close-icon: var(--kt-text-muted);

    /* Add task bar padding */
    --wx-gantt-bar-padding: 0.5rem 0.75rem;
    --wx-gantt-bar-height: 32px;
    /* Adjust the height to accommodate padding */

    position: relative;
    height: 100%
}

/* Add this new style to override any conflicting styles */
.wx-table.x2-dnsr36>.wx-grid .wx-header .wx-cell {
    font-family: 'Inter', sans-serif !important;
    font-weight: 500 !important;
    text-transform: uppercase;
}

.wx-table.x2-dnsr36>.wx-grid .wx-body .wx-cell {
    font-family: 'Inter', sans-serif !important;
    color: #181C32 !important;
}

/* Add this to the bottom of the file */
.wx-bar .wx-content {
    font-family: 'Inter', sans-serif !important;
    color: #2a326b !important;
    padding: var(--wx-gantt-bar-padding) !important;
    height: 100%;
    display: flex;
    align-items: center;

}

/* For text that appears outside the task bar */
.wx-bars .wx-text-out {
    font-family: 'Inter', sans-serif !important;
    color: #181C32 !important;
}

.wx-content {
    .wx-scale {
        .x2-nufjbd {
            font-family: 'Inter', sans-serif !important;
            font-weight: 500 !important;
            font-size: var(--kt-font-size) !important;
            justify-content: flex-start;
            padding: 0;
        }
    }
}

.wx-fullscreen {
    position: relative;
    height: 100%;
    width: 100%;
    outline: none;
    overflow: scroll;

    .wx-fullscreen-icon {
        position: fixed;
        right: 25px;
        bottom: 35px;
        z-index: 4;
    }
}