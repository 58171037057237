  /* ==================== Toast Message Icon ==================== */
  
  .Toastify__toast-container{
    padding: 0;
    .Toastify__toast{
        padding: 0;
        min-height: auto;
    }
    .Toastify__toast-body{
        padding: 0;
    }
}

  .bc-toast-message{
    padding: 15px 20px 15px 20px;
    line-height: 1.5;
    border-left: 4px solid #8162BE;
    font-size: 1rem;
    font-weight: 700;
    &.bc-toast-success{
        border-color: #38C838;
    }
    &.bc-toast-warning{
        border-color: #F28D00;
    }
    &.bc-toast-info{
        border-color: #F3B33F;
    }
    &.bc-toast-error{
        border-color: #F71F14;
    }
    svg{
        width: 22px;
        height: 22px;
        display: inline-block;
        vertical-align: middle;
        fill: #000000;
     }
  }
