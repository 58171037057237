.timeline-task {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;

    .timeline-name {
        opacity: 0;
        transition: opacity 0.3s ease-in-out !important;
    }

    .timeline-icon {
        min-width: 23px;
        height: 23px;
        border-radius: 50%;
        margin-bottom: 1px
    }

    &:hover {
        .timeline-name {
            opacity: 1
        }
    }
}