.multiselect-container {
    .searchWrapper {
        border: 1px solid var(--kt-form-select-border-color);
        border-radius: 0.65rem;
        padding: 7px 12px;
        min-height: auto;
        line-height: normal;

        .icon_cancel {
            width: 10px;
            opacity: 0.5;
        }

        .searchBox {
            padding: 0;
            height: 20px;
            line-height: 20px;
            margin: 0;
            width: 90%;
        }

        .chip {
            background-color: var(--kt-primary);
            padding: 6px 8px;
            padding-right: 25px;
            border-radius: 15px;
            white-space: nowrap;
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            position: relative;

            .icon_cancel {
                width: 16px;
                height: 16px;
                opacity: 1;
                margin-left: 10px;
                position: absolute;
                right: 6px;
                top: 50%;
                margin-top: -8px;
            }
        }
    }

    .optionListContainer {
        // display: block !important;

        >ul {
            border: 1px solid var(--kt-form-select-border-color);
            border-radius: 0.65rem;
            padding: 10px;
            list-style: none;

            li {
                padding: 5px 0;
                padding-left: 25px;
                // font-weight: 600;
                color: #5E6278;
                font-size: 14px;
                position: relative;


                input[type="checkbox"] {
                    @extend .form-check-input;
                    position: absolute;
                    top: 10px;
                    left: 0;
                    width: 18px;
                    height: 18px;
                    background-size: 10px auto;
                    border-radius: 2px;
                    margin: 0;
                }

                &:hover {
                    background: none;
                    color: #333;
                }
            }
        }
    }

    &.disable_ms {
        .optionListContainer {
            display: none !important;
        }
    }
}





.select-2-custom-wrapper {
    position: relative;
    padding-bottom: 24px;
  
    .select-2-count {
      position: absolute;
      bottom: 0px;
      left: 0;
      font-size: 12px;
      font-weight: 600;
    }
  
    div[class*='multiValue'] {
      display: none;
    }
  
    div[class*='control'] {
      height: 35px;
      min-height: 35px;
      border: 1px solid #ccc;
      border-color: #e4e6ef !important;
      border-radius: 0.35rem;
      padding: 7px 0 7px 12px;
      box-shadow: none;
      border-radius: 0.65rem !important;
    }
  
    div[class*='control']>div:first-child,
    div[class*='ValueContainer'] {
      margin: 0;
      padding: 0;
      line-height: 20px;
      height: 20px;
    }
  
    div[class*='control']>div:last-child,
    div[class*='IndicatorsContainer'] {
      height: 20px;
    }
  
    div[class*='control']>div:first-child>div:last-child,
    div[class*='Input'] {
      line-height: 20px;
      height: 20px;
      padding: 0;
      margin: 0;
      position: relative;
    }
  
    span[class*='indicatorSeparator'] {
      display: none;
    }
  
    div[class*='indicatorContainer'] {
      width: 20px;
      height: 20px;
      padding: 2px;
      margin-right: 5px;
  
      svg {
        height: auto;
        width: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }
  
    div[class*='menu'],
    div[class*='control']+div {
      // width: 350px !important;
      min-width: 300px !important;
      // max-width: 500px !important;
    }
  }
  
  .select-2-css-placeholder {
    div[class*='Input'] {
      position: relative;
  
      &:before {
        content: 'Select Option';
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.8;
      }
    }
  }