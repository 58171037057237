// /* stylelint-disable */
// * {
//     -webkit-box-sizing: border-box;
//             box-sizing: border-box;
//   }
//   *::before,
//   *::after {
//     -webkit-box-sizing: border-box;
//             box-sizing: border-box;
//   }
//   body {
//     margin: 0;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//   }
//   [hidden],
//   template {
//     display: none;
//   }
//   a {
//     background-color: transparent;
//   }
//   a:active,
//   a:hover {
//     outline: 0;
//   }
//   b,
//   strong {
//     font-weight: bold;
//   }
//   sub,
//   sup {
//     font-size: 75%;
//     line-height: 0;
//     position: relative;
//     vertical-align: baseline;
//   }
//   sup {
//     top: -0.5em;
//   }
//   sub {
//     bottom: -0.25em;
//   }
//   img {
//     border: 0;
//   }
//   svg:not(:root) {
//     overflow: hidden;
//   }
//   hr {
//     -webkit-box-sizing: content-box;
//             box-sizing: content-box;
//     height: 0;
//   }
//   pre {
//     overflow: auto;
//   }
//   button,
//   input,
//   optgroup,
//   select,
//   textarea {
//     color: inherit;
//     font: inherit;
//     margin: 0;
//   }
//   button {
//     overflow: visible;
//   }
//   button {
//     border-radius: 0;
//   }
//   button,
//   select {
//     text-transform: none;
//   }
//   button,
//   html input[type='button'],
//   input[type='reset'],
//   input[type='submit'] {
//     -webkit-appearance: button;
//     cursor: pointer;
//   }
//   button[disabled],
//   html input[disabled] {
//     cursor: not-allowed;
//   }
//   button::-moz-focus-inner,
//   input::-moz-focus-inner {
//     border: 0;
//     padding: 0;
//   }
//   input {
//     line-height: normal;
//   }
//   input[type='checkbox'],
//   input[type='radio'] {
//     -webkit-box-sizing: border-box;
//             box-sizing: border-box;
//     padding: 0;
//   }
//   input[type='number']::-webkit-inner-spin-button,
//   input[type='number']::-webkit-outer-spin-button {
//     height: auto;
//   }
//   textarea {
//     overflow: auto;
//   }
//   table {
//     border-collapse: collapse;
//     border-spacing: 0;
//   }
//   td,
//   th {
//     padding: 0;
//   }
//   [tabindex='-1'] {
//     outline: none;
//   }
//   input[type='button'],
//   input[type='submit'],
//   input[type='reset'],
//   button {
//     border-width: 0;
//   }
//   input::-ms-clear {
//     display: none;
//   }
//   input[type='file']::-webkit-file-upload-button,
//   input[type='file']::-ms-browse {
//     border-width: 0;
//     background: transparent;
//     color: currentColor;
//   }
//   input,
//   button,
//   select,
//   textarea {
//     font-family: inherit;
//     font-size: inherit;
//     line-height: inherit;
//   }
//   html {
//     -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
//   }
//   body {
//     font-family: Apple-System, Arial, Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif;
//     font-size: 14px;
//     line-height: 1.42857143;
//     color: #575757;
//     color: #575757;
//     background-color: #fff;
//     background-color: #fff;
//   }
//   a {
//     color: #1675e0;
//     color: #1675e0;
//     text-decoration: none;
//   }
//   a:hover {
//     color: #0a5dc2;
//     color: #0a5dc2;
//     text-decoration: underline;
//   }
//   a:focus {
//     color: #0a5dc2;
//     color: #0a5dc2;
//     text-decoration: underline;
//   }
//   a:active {
//     color: #004299;
//     color: #004299;
//     text-decoration: underline;
//   }
//   img {
//     vertical-align: middle;
//   }
//   hr {
//     margin-top: 20px;
//     margin-bottom: 20px;
//     border: 0;
//     border-top: 1px solid #e5e5ea;
//     border-top: 1px solid #e5e5ea;
//   }
//   [role='button'] {
//     cursor: pointer;
//   }
//   h1,
//   h2,
//   h3,
//   h4,
//   h5,
//   h6 {
//     font-family: inherit;
//     font-weight: bolder;
//     color: inherit;
//     margin: 0;
//   }
//   h1 small {
//     font-weight: normal;
//     line-height: 1;
//     color: #c5c6c7;
//     color: #c5c6c7;
//   }
//   h2 small {
//     font-weight: normal;
//     line-height: 1;
//     color: #c5c6c7;
//     color: #c5c6c7;
//   }
//   h3 small {
//     font-weight: normal;
//     line-height: 1;
//     color: #c5c6c7;
//     color: #c5c6c7;
//   }
//   h4 small {
//     font-weight: normal;
//     line-height: 1;
//     color: #c5c6c7;
//     color: #c5c6c7;
//   }
//   h5 small {
//     font-weight: normal;
//     line-height: 1;
//     color: #c5c6c7;
//     color: #c5c6c7;
//   }
//   h6 small {
//     font-weight: normal;
//     line-height: 1;
//     color: #c5c6c7;
//     color: #c5c6c7;
//   }
//   h1 {
//     font-size: 46px;
//     line-height: 62px;
//   }
//   h2 {
//     font-size: 36px;
//     line-height: 50px;
//   }
//   h3 {
//     font-size: 28px;
//     line-height: 42px;
//   }
//   h4 {
//     font-size: 22px;
//     line-height: 34px;
//   }
//   h5 {
//     font-size: 18px;
//     line-height: 24px;
//   }
//   h6 {
//     font-size: 16px;
//     line-height: 22px;
//   }
//   p {
//     margin: 0;
//   }
//   p + p {
//     margin-top: 8px;
//   }
//   small {
//     font-size: 85%;
//   }
//   mark {
//     background-color: #fff9e6;
//     background-color: #fff9e6;
//     padding: 0.2em;
//     font-weight: bolder;
//   }
//   ul,
//   ol {
//     margin-top: 0;
//     margin-bottom: 10px;
//   }
//   ul ul,
//   ol ul,
//   ul ol,
//   ol ol {
//     margin-bottom: 0;
//   }
//   dl {
//     margin-top: 0;
//     margin-bottom: 10px;
//   }
//   dt,
//   dd {
//     line-height: 1.42857143;
//   }
//   dt {
//     font-weight: bold;
//   }
//   dd {
//     margin-left: 0;
//     margin-bottom: 10px;
//   }
//   @font-face {
//     font-family: Apple-System;
//     src: local(-apple-system), local(BlinkMacSystemFont), local(system-ui);
//   }
  @-webkit-keyframes slideInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @keyframes slideInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @-webkit-keyframes slideOutLeft {
    from {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
    }
  }
  @keyframes slideOutLeft {
    from {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
    }
  }
  @-webkit-keyframes slideInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @keyframes slideInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @-webkit-keyframes slideOutRight {
    from {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
  }
  @keyframes slideOutRight {
    from {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
  }
  @-webkit-keyframes slideInTop {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
              transform: translate3d(0, -100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @keyframes slideInTop {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
              transform: translate3d(0, -100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @-webkit-keyframes slideOutTop {
    from {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
              transform: translate3d(0, -100%, 0);
    }
  }
  @keyframes slideOutTop {
    from {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
              transform: translate3d(0, -100%, 0);
    }
  }
  @-webkit-keyframes slideInBottom {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
              transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @keyframes slideInBottom {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
              transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @-webkit-keyframes slideOutBottom {
    from {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
              transform: translate3d(0, 100%, 0);
    }
  }
  @keyframes slideOutBottom {
    from {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
              transform: translate3d(0, 100%, 0);
    }
  }
  .rs-anim-slide-out {
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  .rs-anim-slide-in {
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  .rs-anim-right.rs-anim-slide-in {
    -webkit-animation-name: slideInRight;
            animation-name: slideInRight;
  }
  .rs-anim-right.rs-anim-slide-out {
    -webkit-animation-name: slideOutRight;
            animation-name: slideOutRight;
  }
  .rs-anim-left.rs-anim-slide-in {
    -webkit-animation-name: slideInLeft;
            animation-name: slideInLeft;
  }
  .rs-anim-left.rs-anim-slide-out {
    -webkit-animation-name: slideOutLeft;
            animation-name: slideOutLeft;
  }
  .rs-anim-top.rs-anim-slide-in {
    -webkit-animation-name: slideInTop;
            animation-name: slideInTop;
  }
  .rs-anim-top.rs-anim-slide-out {
    -webkit-animation-name: slideOutTop;
            animation-name: slideOutTop;
  }
  .rs-anim-bottom.rs-anim-slide-in {
    -webkit-animation-name: slideInBottom;
            animation-name: slideInBottom;
  }
  .rs-anim-bottom.rs-anim-slide-out {
    -webkit-animation-name: slideOutBottom;
            animation-name: slideOutBottom;
  }
  .rs-anim-bounce-in {
    -webkit-animation-name: bounceIn;
            animation-name: bounceIn;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
            animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  @-webkit-keyframes bounceIn {
    from {
      opacity: 0;
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
    }
    to {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes bounceIn {
    from {
      opacity: 0;
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
    }
    to {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  .rs-anim-bounce-out {
    -webkit-animation-name: bounceOut;
            animation-name: bounceOut;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  @-webkit-keyframes bounceOut {
    from {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    to {
      opacity: 0;
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
    }
  }
  @keyframes bounceOut {
    from {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    to {
      opacity: 0;
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
    }
  }
  .rs-anim-fade {
    opacity: 0;
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
    pointer-events: none;
  }
  .rs-anim-fade.rs-anim-in {
    opacity: 1;
    pointer-events: unset;
  }
  .rs-anim-collapse {
    display: none;
  }
  .rs-anim-collapse.rs-anim-in {
    display: block;
  }
  tr.rs-anim-collapse.rs-anim-in {
    display: table-row;
  }
  tbody.rs-anim-collapse.rs-anim-in {
    display: table-row-group;
  }
  .rs-anim-collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.35s ease, width 0.35s ease, visibility 0.35s ease;
    transition: height 0.35s ease, width 0.35s ease, visibility 0.35s ease;
  }
  .rs-auto-complete-menu {
    margin-bottom: 6px;
    overflow-y: auto;
    margin-top: 6px;
  }
  .rs-auto-complete-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .rs-auto-complete-item {
    display: block;
    padding: 8px 12px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #575757;
    color: #575757;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
  }
  .rs-auto-complete-item:hover {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-auto-complete-item:focus {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-auto-complete-item-focus {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-theme-high-contrast .rs-auto-complete-item:hover {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-auto-complete-item:focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-auto-complete-item-focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-auto-complete-item:hover,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-auto-complete-item:focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-auto-complete-item-focus {
    outline-offset: 2px;
  }
  .rs-auto-complete .rs-input:focus::-webkit-textfield-decoration-container {
    visibility: hidden;
    pointer-events: none;
  }
  .rs-avatar {
    background-color: #d9d9d9;
    background-color: #d9d9d9;
    color: #fff;
    color: #fff;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
  .rs-avatar > .rs-icon {
    font-size: 29px;
    height: 29px;
  }
  .rs-avatar > .rs-avatar-image {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .rs-avatar > .rs-icon {
    vertical-align: middle;
  }
  .rs-avatar-image::before {
    content: attr(alt);
    position: absolute;
    width: 100%;
    height: inherit;
    top: 0;
    left: 0;
    background: #d9d9d9;
    background: #d9d9d9;
    text-align: center;
    padding: 0 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .rs-avatar-circle {
    border-radius: 50%;
  }
  .rs-avatar-lg {
    width: 60px;
    height: 60px;
    font-size: 26px;
  }
  .rs-avatar-lg > .rs-icon {
    font-size: 43px;
    height: 43px;
  }
  .rs-avatar-lg > .rs-avatar-image {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
  .rs-avatar-sm {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  .rs-avatar-sm > .rs-icon {
    font-size: 22px;
    height: 22px;
  }
  .rs-avatar-sm > .rs-avatar-image {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .rs-avatar-xs {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
  .rs-avatar-xs > .rs-icon {
    font-size: 16px;
    height: 16px;
  }
  .rs-avatar-xs > .rs-avatar-image {
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
  .rs-avatar-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .rs-avatar-group-stack .rs-avatar {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    margin-right: -10px;
    -webkit-transition: margin 0.15s;
    transition: margin 0.15s;
  }
  .rs-avatar-group-stack .rs-avatar:last-child {
    margin-right: 0;
  }
  .rs-avatar-group-stack .rs-avatar:hover {
    margin-right: 0;
  }
  .rs-badge-wrapper {
    position: relative;
  }
  .rs-badge {
    display: inline-block;
  }
  .rs-badge-independent {
    background-color: #f44336;
    background-color: #f44336;
    color: #fff;
    color: #fff;
    border-radius: 8px;
    font-size: 12px;
    line-height: 16px;
    padding: 0 5px;
  }
  .rs-badge-content {
    background-color: #f44336;
    background-color: #f44336;
    color: #fff;
    color: #fff;
    border-radius: 8px;
    font-size: 12px;
    line-height: 16px;
    padding: 0 5px;
  }
  .rs-badge-independent.rs-badge-dot,
  .rs-badge-wrapper .rs-badge-content:empty {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    padding: 0;
  }
  .rs-badge-wrapper .rs-badge-content {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
    z-index: 1;
  }
  .rs-badge-red.rs-badge-independent {
    background-color: #f44336;
    background-color: #f44336;
  }
  .rs-badge-red .rs-badge-content {
    background-color: #f44336;
    background-color: #f44336;
  }
  .rs-badge-orange.rs-badge-independent {
    background-color: #fa8900;
    background-color: #fa8900;
  }
  .rs-badge-orange .rs-badge-content {
    background-color: #fa8900;
    background-color: #fa8900;
  }
  .rs-badge-yellow.rs-badge-independent {
    background-color: #ffb300;
    background-color: #ffb300;
  }
  .rs-badge-yellow .rs-badge-content {
    background-color: #ffb300;
    background-color: #ffb300;
  }
  .rs-badge-green.rs-badge-independent {
    background-color: #4caf50;
    background-color: #4caf50;
  }
  .rs-badge-green .rs-badge-content {
    background-color: #4caf50;
    background-color: #4caf50;
  }
  .rs-badge-cyan.rs-badge-independent {
    background-color: #00bcd4;
    background-color: #00bcd4;
  }
  .rs-badge-cyan .rs-badge-content {
    background-color: #00bcd4;
    background-color: #00bcd4;
  }
  .rs-badge-blue.rs-badge-independent {
    background-color: #2196f3;
    background-color: #2196f3;
  }
  .rs-badge-blue .rs-badge-content {
    background-color: #2196f3;
    background-color: #2196f3;
  }
  .rs-badge-violet.rs-badge-independent {
    background-color: #673ab7;
    background-color: #673ab7;
  }
  .rs-badge-violet .rs-badge-content {
    background-color: #673ab7;
    background-color: #673ab7;
  }
  .rs-breadcrumb {
    margin-bottom: 20px;
    font-size: 12px;
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-breadcrumb-item {
    -webkit-transition: color 0.3s linear;
    transition: color 0.3s linear;
  }
  .rs-breadcrumb-item:focus {
    outline: 0;
  }
  .rs-breadcrumb-item-active {
    color: #272c36;
    color: #272c36;
  }
  .rs-breadcrumb-separator {
    margin: 0 4px;
  }
  .rs-btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
    transition: color 0.15s ease-out, background-color 0.15s ease-out;
    border: none;
    border: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-decoration: none;
    color: #575757;
    color: #575757;
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px;
    overflow: hidden;
    position: relative;
  }
  .rs-theme-high-contrast .rs-btn {
    -webkit-transition: none;
    transition: none;
  }
  .rs-btn-ghost.rs-btn {
    padding: 7px 11px;
  }
  .rs-btn-icon.rs-btn {
    padding: 10px 10px;
    line-height: 16px;
  }
  .rs-btn-icon.rs-btn > .rs-icon {
    font-size: 16px;
  }
  .rs-btn-icon-with-text.rs-btn {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-btn > .rs-icon {
    padding: 10px 10px;
    width: 36px;
    height: 36px;
  }
  .rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;
  }
  .rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: none;
  }
  .rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;
  }
  .rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: none;
  }
  .rs-btn:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
  }
  .rs-theme-high-contrast .rs-btn:focus-visible {
    outline-offset: 2px;
  }
  .rs-btn:hover {
    color: undefined;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    text-decoration: none;
  }
  .rs-btn:focus {
    color: undefined;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    text-decoration: none;
  }
  .rs-btn:active {
    color: #272c36;
    color: #272c36;
    background-color: #d9d9d9;
    background-color: #d9d9d9;
  }
  .rs-btn.rs-btn-active {
    color: #272c36;
    color: #272c36;
    background-color: #d9d9d9;
    background-color: #d9d9d9;
  }
  .rs-btn:disabled {
    cursor: not-allowed;
    color: #c5c6c7;
    color: #c5c6c7;
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-btn.rs-btn-disabled {
    cursor: not-allowed;
    color: #c5c6c7;
    color: #c5c6c7;
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-theme-high-contrast .rs-btn:disabled,
  .rs-theme-high-contrast .rs-btn.rs-btn-disabled {
    opacity: 0.5;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-btn {
      /* stylelint-disable */
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      /* stylelint-enable */
    }
  }
  .rs-btn-start-icon {
    line-height: 0;
    margin-right: 5px;
  }
  .rs-btn-end-icon {
    line-height: 0;
    margin-left: 5px;
  }
  .rs-btn-primary {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    border: none;
  }
  .rs-btn-primary:hover {
    color: #fff;
    color: #fff;
    background-color: #2589f5;
    background-color: #2589f5;
  }
  .rs-btn-primary:focus {
    color: #fff;
    color: #fff;
    background-color: #2589f5;
    background-color: #2589f5;
  }
  .rs-btn-primary:active {
    color: #fff;
    color: #fff;
    background-color: #1675e0;
    background-color: #1675e0;
  }
  .rs-btn-primary.rs-btn-active {
    color: #fff;
    color: #fff;
    background-color: #1675e0;
    background-color: #1675e0;
  }
  .rs-btn-primary:disabled {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    opacity: 0.3;
  }
  .rs-btn-primary.rs-btn-disabled {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    opacity: 0.3;
  }
  .rs-theme-high-contrast .rs-btn-primary:disabled,
  .rs-theme-high-contrast .rs-btn-primary.rs-btn-disabled {
    opacity: 0.5;
  }
  .rs-btn-subtle {
    color: #8e8e93;
    color: #8e8e93;
    background-color: transparent;
    border: none;
  }
  .rs-btn-subtle:hover {
    color: #575757;
    color: #575757;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-btn-subtle:focus {
    color: #575757;
    color: #575757;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-btn-subtle:active {
    color: #272c36;
    color: #272c36;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-btn-subtle.rs-btn-active {
    color: #272c36;
    color: #272c36;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-btn-subtle:disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-btn-subtle.rs-btn-disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-theme-high-contrast .rs-btn-subtle:disabled,
  .rs-theme-high-contrast .rs-btn-subtle.rs-btn-disabled {
    opacity: 0.5;
  }
  .rs-btn-link {
    color: #1675e0;
    color: #1675e0;
    background-color: transparent;
    border: none;
  }
  .rs-btn-link:hover {
    color: #0a5dc2;
    color: #0a5dc2;
    background-color: transparent;
    text-decoration: underline;
  }
  .rs-btn-link:focus {
    color: #0a5dc2;
    color: #0a5dc2;
    background-color: transparent;
    text-decoration: underline;
  }
  .rs-btn-link:active {
    color: #004299;
    color: #004299;
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-active {
    color: #004299;
    color: #004299;
    background-color: transparent;
  }
  .rs-btn-link:disabled {
    color: #0a5dc2;
    color: #0a5dc2;
    background-color: transparent;
    text-decoration: none;
    opacity: 0.3;
  }
  .rs-btn-link.rs-btn-disabled {
    color: #0a5dc2;
    color: #0a5dc2;
    background-color: transparent;
    text-decoration: none;
    opacity: 0.3;
  }
  .rs-theme-high-contrast .rs-btn-link:disabled,
  .rs-theme-high-contrast .rs-btn-link.rs-btn-disabled {
    opacity: 0.5;
  }
  .rs-btn-ghost {
    color: #1675e0;
    color: #1675e0;
    background-color: transparent;
    border: 1px solid #1675e0;
    border: 1px solid #1675e0;
  }
  .rs-btn-ghost:hover {
    color: #0a5dc2;
    color: #0a5dc2;
    background-color: transparent;
    border-color: #0a5dc2;
    border-color: #0a5dc2;
    -webkit-box-shadow: 0 0 0 1px #0a5dc2;
    -webkit-box-shadow: 0 0 0 1px #0a5dc2;
            box-shadow: 0 0 0 1px #0a5dc2;
            box-shadow: 0 0 0 1px #0a5dc2;
  }
  .rs-btn-ghost:focus {
    color: #0a5dc2;
    color: #0a5dc2;
    background-color: transparent;
    border-color: #0a5dc2;
    border-color: #0a5dc2;
    -webkit-box-shadow: 0 0 0 1px #0a5dc2;
    -webkit-box-shadow: 0 0 0 1px #0a5dc2;
            box-shadow: 0 0 0 1px #0a5dc2;
            box-shadow: 0 0 0 1px #0a5dc2;
  }
  .rs-btn-ghost:active {
    color: #004299;
    color: #004299;
    background-color: transparent;
    border-color: #004299;
    border-color: #004299;
  }
  .rs-btn-ghost.rs-btn-active {
    color: #004299;
    color: #004299;
    background-color: transparent;
    border-color: #004299;
    border-color: #004299;
  }
  .rs-btn-ghost:disabled {
    color: #1675e0;
    color: #1675e0;
    background-color: transparent;
    opacity: 0.3;
    border-color: #1675e0;
    border-color: #1675e0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-btn-ghost.rs-btn-disabled {
    color: #1675e0;
    color: #1675e0;
    background-color: transparent;
    opacity: 0.3;
    border-color: #1675e0;
    border-color: #1675e0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-theme-high-contrast .rs-btn-ghost:disabled,
  .rs-theme-high-contrast .rs-btn-ghost.rs-btn-disabled {
    opacity: 0.5;
  }
  .rs-btn-lg {
    font-size: 16px;
    line-height: 22px;
    padding: 10px 16px;
  }
  .rs-btn-ghost.rs-btn-lg {
    padding: 9px 15px;
  }
  .rs-btn-icon.rs-btn-lg {
    padding: 11px 11px;
    line-height: 20px;
  }
  .rs-btn-icon.rs-btn-lg > .rs-icon {
    font-size: 20px;
  }
  .rs-btn-icon-with-text.rs-btn-lg {
    line-height: 22px;
  }
  .rs-btn-icon-with-text.rs-btn-lg > .rs-icon {
    padding: 11px 11px;
    width: 42px;
    height: 42px;
  }
  .rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-left {
    padding: 10px 16px 10px 58px;
  }
  .rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: none;
  }
  .rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-right {
    padding: 10px 58px 10px 16px;
  }
  .rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: none;
  }
  .rs-btn-md {
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px;
  }
  .rs-btn-ghost.rs-btn-md {
    padding: 7px 11px;
  }
  .rs-btn-icon.rs-btn-md {
    padding: 10px 10px;
    line-height: 16px;
  }
  .rs-btn-icon.rs-btn-md > .rs-icon {
    font-size: 16px;
  }
  .rs-btn-icon-with-text.rs-btn-md {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-btn-md > .rs-icon {
    padding: 10px 10px;
    width: 36px;
    height: 36px;
  }
  .rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;
  }
  .rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: none;
  }
  .rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;
  }
  .rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: none;
  }
  .rs-btn-sm {
    font-size: 14px;
    line-height: 20px;
    padding: 5px 10px;
  }
  .rs-btn-ghost.rs-btn-sm {
    padding: 4px 9px;
  }
  .rs-btn-icon.rs-btn-sm {
    padding: 7px 7px;
    line-height: 16px;
  }
  .rs-btn-icon.rs-btn-sm > .rs-icon {
    font-size: 16px;
  }
  .rs-btn-icon-with-text.rs-btn-sm {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-btn-sm > .rs-icon {
    padding: 7px 7px;
    width: 30px;
    height: 30px;
  }
  .rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-left {
    padding: 5px 10px 5px 40px;
  }
  .rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: none;
  }
  .rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-right {
    padding: 5px 40px 5px 10px;
  }
  .rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: none;
  }
  .rs-btn-xs {
    font-size: 12px;
    line-height: 20px;
    padding: 2px 8px;
  }
  .rs-btn-ghost.rs-btn-xs {
    padding: 1px 7px;
  }
  .rs-btn-icon.rs-btn-xs {
    padding: 6px 6px;
    line-height: 12px;
  }
  .rs-btn-icon.rs-btn-xs > .rs-icon {
    font-size: 12px;
  }
  .rs-btn-icon-with-text.rs-btn-xs {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-btn-xs > .rs-icon {
    padding: 6px 6px;
    width: 24px;
    height: 24px;
  }
  .rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-left {
    padding: 2px 8px 2px 32px;
  }
  .rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: none;
  }
  .rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-right {
    padding: 2px 32px 2px 8px;
  }
  .rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: none;
  }
  .rs-btn-block {
    display: block;
    width: 100%;
  }
  .rs-btn-block + .rs-btn-block {
    margin-top: 5px;
  }
  .rs-btn-loading {
    color: transparent !important;
    position: relative;
    cursor: default;
    pointer-events: none;
  }
  .rs-btn-loading > .rs-btn-spin::before,
  .rs-btn-loading > .rs-btn-spin::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    z-index: 1;
  }
  .rs-btn-xs.rs-btn-loading > .rs-btn-spin::before,
  .rs-btn-xs.rs-btn-loading > .rs-btn-spin::after {
    width: 16px;
    height: 16px;
  }
  .rs-btn-loading > .rs-btn-spin::before {
    border: 3px solid rgba(247, 247, 250, 0.8);
    border: 3px solid rgba(247, 247, 250, 0.8);
  }
  .rs-btn-primary.rs-btn-loading > .rs-btn-spin::before {
    border-color: rgba(248, 247, 250, 0.3);
  }
  .rs-theme-high-contrast .rs-btn-primary.rs-btn-loading > .rs-btn-spin::before {
    border-color: rgba(247, 247, 250, 0.3);
    border-color: rgba(233, 235, 240, 0.8);
  }
  .rs-btn-loading > .rs-btn-spin::after {
    border-width: 3px;
    border-color: #a6a6a6 transparent transparent;
    border-color: #a6a6a6 transparent transparent;
    border-style: solid;
    -webkit-animation: buttonSpin 0.6s infinite linear;
            animation: buttonSpin 0.6s infinite linear;
  }
  .rs-btn-primary.rs-btn-loading > .rs-btn-spin::after {
    border-top-color: #fff;
  }
  .rs-theme-high-contrast .rs-btn-primary.rs-btn-loading > .rs-btn-spin::after {
    border-top-color: #fff;
    border-top-color: #5c6066;
  }
  @-webkit-keyframes buttonSpin {
    from {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes buttonSpin {
    from {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .rs-btn-group {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  .rs-btn-group > .rs-btn {
    position: relative;
  }
  .rs-btn-group > .rs-btn:focus,
  .rs-btn-group > .rs-btn:active {
    z-index: 2;
  }
  .rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn {
    float: left;
  }
  .rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn-ghost + .rs-btn-ghost {
    margin-left: -1px;
  }
  .rs-btn-group-vertical > .rs-btn {
    display: block;
    width: 100%;
    max-width: 100%;
  }
  .rs-btn-group-vertical > .rs-btn:not(:last-child) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .rs-btn-group-vertical > .rs-btn:not(:first-child) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .rs-btn-group-vertical > .rs-btn-ghost + .rs-btn-ghost {
    margin-top: -1px;
  }
  .rs-btn-group-justified {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  .rs-btn-group-justified > .rs-btn {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 1%;
            flex: 1 1 1%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .rs-btn-toolbar {
    line-height: 0;
  }
  .rs-picker-subtle .picker-subtle-toggle {
    position: relative;
    z-index: 5;
    padding-right: 32px;
    display: inline-block;
  }
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle {
    cursor: not-allowed;
  }
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover,
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus,
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active {
    background: none;
  }
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover::after,
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus::after,
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active::after {
    display: none;
  }
  .rs-calendar {
    padding-top: 12px;
    position: relative;
    min-height: 266px;
    overflow: hidden;
  }
  .rs-calendar-bordered .rs-calendar-table {
    border: 1px solid #e5e5ea;
    border: 1px solid #e5e5ea;
    border-radius: 6px;
  }
  .rs-calendar-bordered .rs-calendar-table-row:not(:last-child) .rs-calendar-table-cell {
    border-bottom: 1px solid #f2f2f5;
    border-bottom: 1px solid #f2f2f5;
  }
  .rs-calendar-bordered .rs-calendar-table-header-row .rs-calendar-table-header-cell {
    border-bottom: 1px solid #f2f2f5;
    border-bottom: 1px solid #f2f2f5;
  }
  .rs-calendar-bordered .rs-calendar-month-dropdown {
    border: 1px solid #e5e5ea;
    border: 1px solid #e5e5ea;
    border-radius: 6px;
  }
  .rs-calendar-panel .rs-calendar-header {
    width: 100%;
    padding-bottom: 6px;
  }
  .rs-calendar-panel .rs-calendar-header::before,
  .rs-calendar-panel .rs-calendar-header::after {
    content: ' ';
    display: table;
  }
  .rs-calendar-panel .rs-calendar-header::after {
    clear: both;
  }
  .rs-calendar-panel .rs-calendar-header .rs-calendar-btn-today {
    float: right;
  }
  .rs-calendar-panel .rs-calendar-header-forward,
  .rs-calendar-panel .rs-calendar-header-backward {
    float: none !important;
  }
  .rs-calendar-panel.rs-calendar-month-view .rs-calendar-header-forward,
  .rs-calendar-panel.rs-calendar-month-view .rs-calendar-header-backward {
    display: inline-block;
    visibility: hidden;
    pointer-events: none;
  }
  .rs-calendar-panel .rs-calendar-header-month-toolbar {
    width: auto !important;
  }
  .rs-calendar-panel.rs-calendar-month-view .rs-calendar-header-month-toolbar {
    padding-left: 0;
    padding-right: 0;
  }
  .rs-calendar-panel .rs-calendar-month-view {
    padding-left: 0;
    padding-right: 0;
  }
  .rs-calendar-panel .rs-calendar-table-header-row .rs-calendar-table-header-cell-content {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
    color: #fff;
    color: #fff;
    width: 20px;
    height: 20px;
    background-color: #3498ff;
    background-color: #3498ff;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: inherit;
    color: inherit;
    -webkit-box-shadow: inset 0 0 0 1px #3498ff;
    -webkit-box-shadow: inset 0 0 0 1px #3498ff;
            box-shadow: inset 0 0 0 1px #3498ff;
            box-shadow: inset 0 0 0 1px #3498ff;
  }
  .rs-calendar-panel .rs-calendar-table-cell-day {
    margin-top: 3px;
    display: block;
    text-align: center;
  }
  .rs-calendar-panel .rs-calendar-month-dropdown {
    margin-top: -2px;
    margin-left: 12px;
    margin-right: 12px;
    top: 54px;
    width: calc(100% - 24px);
  }
  .rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-month-dropdown-scroll {
    height: 644px;
  }
  .rs-calendar-panel.rs-calendar-compact .rs-calendar-month-dropdown-scroll {
    height: 344px;
  }
  .rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
    width: 100%;
    height: 100px;
    overflow: hidden;
  }
  .rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-cell {
    vertical-align: top;
  }
  .rs-calendar-panel .rs-calendar-table-cell {
    padding: 0 1px;
    line-height: 0;
  }
  .rs-calendar-panel .rs-calendar-table-cell-content {
    width: 100%;
    border-radius: 0;
  }
  .rs-calendar-panel .rs-calendar-table-row:last-child :first-child .rs-calendar-table-cell-content {
    border-bottom-left-radius: 6px;
  }
  .rs-calendar-panel .rs-calendar-table-row:last-child :last-child .rs-calendar-table-cell-content {
    border-bottom-right-radius: 6px;
  }
  .rs-calendar-panel.rs-calendar-compact .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
    height: 50px;
  }
  .rs-calendar-btn-close {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 14px;
    padding: 0;
    border: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background: #fff;
    background: #fff;
    z-index: 1;
  }
  .rs-calendar-panel .rs-calendar-btn-close {
    bottom: 11px;
    margin: 0 20px;
  }
  .rs-calendar-month-view .rs-calendar-header-month-toolbar {
    padding-left: 24px;
    padding-right: 24px;
  }
  .rs-calendar-month-view .rs-calendar-header-backward,
  .rs-calendar-month-view .rs-calendar-header-forward {
    display: none;
  }
  .rs-calendar-month-view .rs-calendar-header-title-date {
    color: #1675e0;
    color: #1675e0;
    background: transparent;
  }
  .rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error {
    color: #f44336;
    color: #f44336;
  }
  .rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error:focus,
  .rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error:hover:active {
    color: #fff !important;
  }
  .rs-calendar-time-view .rs-calendar-header-title-time {
    color: #1675e0;
    color: #1675e0;
    background: transparent;
  }
  .rs-calendar-time-view .rs-calendar-header-title-time.rs-calendar-header-error {
    color: #f44336;
    color: #f44336;
  }
  .rs-calendar-time-view .rs-calendar-header-title-time.rs-calendar-header-error:hover:active {
    color: #fff;
  }
  .rs-calendar-table-cell-selected,
  .rs-calendar-table-cell-in-range {
    position: relative;
  }
  .rs-calendar-table-cell-selected::before,
  .rs-calendar-table-cell-in-range::before {
    content: '';
    display: block;
    width: 100%;
    margin-top: 4px;
    height: 24px;
    position: absolute;
    z-index: 0;
    top: 0;
  }
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-in-range .rs-calendar-table-cell-content {
    z-index: 1;
    position: relative;
  }
  .rs-calendar-table-cell-in-range::before {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
  }
  .rs-calendar-table-cell-in-range:hover::before {
    display: none;
  }
  .rs-calendar-month-dropdown-scroll div:focus {
    outline: none;
  }
  .rs-calendar-header {
    width: 280px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .rs-calendar-header::before,
  .rs-calendar-header::after {
    content: ' ';
    display: table;
  }
  .rs-calendar-header::after {
    clear: both;
  }
  .rs-calendar-header-month-toolbar,
  .rs-calendar-header-time-toolbar {
    display: inline-block;
  }
  .rs-calendar-header-month-toolbar {
    float: left;
  }
  .rs-calendar-header-time-toolbar {
    float: right;
  }
  .rs-calendar-header-title {
    margin: 0 4px;
  }
  .rs-calendar-header-meridian {
    font-size: 12px;
    margin-left: 4px;
  }
  .rs-calendar-header-error {
    color: #8e8e93;
    color: #f44336;
    background-color: transparent;
    border: none;
  }
  .rs-calendar-header-error:hover {
    color: #575757;
    color: #fff;
    background-color: #e5e5ea;
    background-color: #f44336;
  }
  .rs-calendar-header-error:focus {
    color: #575757;
    color: #fff;
    background-color: #e5e5ea;
    background-color: #f44336;
  }
  .rs-calendar-header-error:active {
    color: #272c36;
    color: #fff;
    background-color: #e5e5ea;
    background-color: #eb3626;
  }
  .rs-calendar-header-error.rs-btn-active {
    color: #272c36;
    color: #272c36;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-calendar-header-error:disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-calendar-header-error.rs-btn-disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-theme-high-contrast .rs-calendar-header-error:disabled,
  .rs-theme-high-contrast .rs-calendar-header-error.rs-btn-disabled {
    opacity: 0.5;
  }
  .rs-calendar-header-btn-disabled,
  .rs-calendar-header-btn-disabled:hover,
  .rs-calendar-header-btn-disabled:hover:focus {
    cursor: not-allowed;
    opacity: 0.3;
    background: none;
  }
  .rs-calendar-header-btn-disabled::after {
    display: none !important;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) {
    margin: 0 auto;
    padding-left: 12px;
    padding-right: 12px;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar {
    display: block;
    text-align: center;
    width: 100%;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::before,
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
    content: ' ';
    display: table;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
    clear: both;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-backward {
    float: left;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-forward {
    float: right;
  }
  .rs-calendar-header-has-time:not(.rs-calendar-header-has-month) {
    margin: 0 auto;
    text-align: center;
  }
  .rs-calendar-header-has-time:not(.rs-calendar-header-has-month) .rs-calendar-header-time-toolbar {
    float: none;
    display: inline-block;
  }
  .rs-calendar-header-has-month.rs-calendar-header-has-time {
    margin: 0 auto;
  }
  /* rtl:begin:ignore */
  /* stylelint-disable-next-line */
  [dir='rtl'] .rs-calendar-header-backward .rs-icon,
  [dir='rtl'] .rs-calendar-header-forward .rs-icon {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  /* rtl:end:ignore */
  .rs-calendar-body {
    padding: 4px 12px 12px;
  }
  .rs-calendar-table {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .rs-calendar-table-row {
    display: table-row;
  }
  .rs-calendar-table-row:nth-child(2) .rs-calendar-table-cell-week-number {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .rs-calendar-table-row:last-child .rs-calendar-table-cell-week-number {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .rs-calendar-table-cell,
  .rs-calendar-table-header-cell {
    display: table-cell;
    width: 1%;
    padding: 1px 0;
    text-align: center;
    vertical-align: middle;
  }
  .rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content {
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-calendar-table-cell-un-same-month .rs-calendar-table-header-cell-content {
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-calendar-table-header-cell-un-same-month .rs-calendar-table-cell-content {
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-calendar-table-header-cell-un-same-month .rs-calendar-table-header-cell-content {
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content {
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content {
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content {
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content {
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content:hover {
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-calendar-table-cell-un-same-month .rs-calendar-table-header-cell-content:hover {
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-calendar-table-header-cell-un-same-month .rs-calendar-table-cell-content:hover {
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-calendar-table-header-cell-un-same-month .rs-calendar-table-header-cell-content:hover {
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover {
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content:hover {
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content:hover {
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content:hover {
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content,
  .rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content,
  .rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content,
  .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover,
  .rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content:hover,
  .rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content:hover,
  .rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content:hover {
    background: none;
    text-decoration: line-through;
    cursor: not-allowed;
  }
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
  .rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
  .rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
  .rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
  .rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
  .rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
  .rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
  .rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
  .rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
  .rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
  .rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
  .rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
  .rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
  .rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
  .rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
  .rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover {
    text-decoration: underline;
  }
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content,
  .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content,
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content,
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content,
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content {
    opacity: 0.3;
  }
  .rs-calendar-table-cell-content {
    display: inline-block;
    font-size: 14px;
    line-height: 1.42857143;
    padding: 5px;
    cursor: pointer;
    border-radius: 6px;
  }
  .rs-calendar-table-cell-content:hover {
    background-color: #f2faff;
    background-color: #f2faff;
  }
  .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    -webkit-box-shadow: inset 0 0 0 1px #3498ff;
    -webkit-box-shadow: inset 0 0 0 1px #3498ff;
            box-shadow: inset 0 0 0 1px #3498ff;
            box-shadow: inset 0 0 0 1px #3498ff;
  }
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-theme-high-contrast .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    text-decoration: underline;
  }
  .rs-calendar-table-header-cell-content {
    display: inline-block;
    color: #8e8e93;
    color: #8e8e93;
    font-size: 12px;
    line-height: 1.66666667;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .rs-calendar-table-header-cell-content,
  .rs-calendar-table-header-cell-content:hover,
  .rs-calendar-table-header-cell-content:focus,
  .rs-calendar-table-header-cell-content:hover:focus {
    background: none;
    cursor: auto;
  }
  .rs-calendar-table-cell-week-number {
    display: table-cell;
    min-width: 30px;
    padding: 1px;
    text-align: center;
    vertical-align: middle;
    color: #8e8e93;
    color: #8e8e93;
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    font-size: 12px;
  }
  .rs-calendar-month-dropdown {
    display: none;
    position: absolute;
    top: 44px;
    border-top: 1px solid #e5e5ea;
    border-top: 1px solid #e5e5ea;
    margin-top: 0;
    width: 100%;
    background-color: #fff;
    background-color: #fff;
    overflow: hidden;
    z-index: 1;
  }
  .rs-calendar-month-view .rs-calendar-month-dropdown {
    display: block;
  }
  .rs-calendar-month-view .rs-calendar-month-dropdown-content {
    -webkit-animation: 0.3s linear slideDown;
            animation: 0.3s linear slideDown;
  }
  .rs-calendar-month-dropdown-scroll {
    height: 230px;
  }
  .rs-calendar-month-dropdown-row {
    position: relative;
    padding-left: 50px;
    padding-right: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .rs-calendar-month-dropdown-row:not(:last-child) {
    border-bottom: 1px dashed #e5e5ea;
    border-bottom: 1px dashed #e5e5ea;
  }
  .rs-calendar-month-dropdown-year {
    position: absolute;
    top: calc(50% - 0.5em);
    left: 12px;
  }
  .rs-calendar-month-dropdown-year-active {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-calendar-month-dropdown-list {
    display: block;
    min-width: 200px;
  }
  .rs-calendar-month-dropdown-list::before,
  .rs-calendar-month-dropdown-list::after {
    content: ' ';
    display: table;
  }
  .rs-calendar-month-dropdown-list::after {
    clear: both;
  }
  .rs-calendar-month-dropdown-cell {
    display: inline-block;
    float: left;
    margin: 1px;
    text-align: center;
    vertical-align: middle;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-calendar-month-dropdown-cell {
      width: calc((100% - 1px * 12 - 1px) / 6);
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-calendar-month-dropdown-cell {
      width: calc((100% - 1px * 12 - 1px) / 6);
    }
  }
  .rs-calendar-month-dropdown-cell-content {
    display: inline-block;
    font-size: 14px;
    line-height: 1.42857143;
    padding: 5px;
    cursor: pointer;
    border-radius: 6px;
  }
  .rs-calendar-month-dropdown-cell-content:hover {
    background-color: #f2faff;
    background-color: #f2faff;
  }
  .rs-calendar-month-dropdown-cell:not(.rs-calendar-month-dropdown-cell-active).disabled .rs-calendar-month-dropdown-cell-content {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
    text-decoration: line-through;
    cursor: not-allowed;
  }
  .rs-calendar-month-dropdown-cell-active.disabled .rs-calendar-month-dropdown-cell-content {
    opacity: 0.3;
    cursor: not-allowed;
  }
  .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
    color: #fff;
    color: #fff;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-theme-high-contrast .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
    text-decoration: underline;
  }
  .rs-calendar-time-dropdown {
    display: none;
    position: absolute;
    top: 44px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
    background-color: #fff;
    background-color: #fff;
    color: #575757;
    color: #575757;
    overflow: hidden;
    z-index: 1;
  }
  .rs-calendar-time-view .rs-calendar-time-dropdown {
    display: block;
  }
  .rs-calendar-time-view .rs-calendar-time-dropdown-content {
    -webkit-animation: 0.3s linear slideDown;
            animation: 0.3s linear slideDown;
  }
  .rs-calendar-time-dropdown-row {
    display: table;
    width: 100%;
  }
  .rs-calendar-time-dropdown-column {
    display: table-cell;
    width: 1%;
  }
  .rs-calendar-time-dropdown-column > ul,
  .rs-calendar-time-dropdown-column > ul > li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .rs-calendar-time-dropdown-column > ul {
    height: 230px;
    overflow-y: auto;
    padding-bottom: 200px;
  }
  .rs-calendar-time-dropdown-column-title {
    width: 100%;
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #8e8e93;
    color: #8e8e93;
    text-align: center;
    font-size: 12px;
    line-height: 1.66666667;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .rs-calendar-time-dropdown-cell {
    font-size: 14px;
    line-height: 20px;
    color: inherit;
    padding: 5px;
    display: block;
    text-align: center;
    cursor: pointer;
  }
  .rs-calendar-time-dropdown-cell,
  .rs-calendar-time-dropdown-cell:hover,
  .rs-calendar-time-dropdown-cell:focus {
    text-decoration: none;
  }
  .rs-calendar-time-dropdown-cell:hover {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-theme-high-contrast .rs-calendar-time-dropdown-cell:hover {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-calendar-time-dropdown-cell:hover {
    outline-offset: 2px;
  }
  .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
    background-color: #3498ff;
    background-color: #3498ff;
    color: #fff;
    color: #fff;
  }
  .rs-theme-high-contrast .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: transparent;
    color: #1675e0;
    color: #ffff00;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-theme-high-contrast .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
    outline-offset: 2px;
  }
  .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
    cursor: not-allowed;
    text-decoration: line-through;
  }
  .rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell-disabled.rs-calendar-time-dropdown-cell {
    opacity: 0.3;
    cursor: not-allowed;
  }
  @-webkit-keyframes slideDown {
    from {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }
    to {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  @keyframes slideDown {
    from {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }
    to {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  .rs-carousel {
    position: relative;
    height: 400px;
    overflow: hidden;
    background-color: #8e8e93;
    background-color: #8e8e93;
  }
  .rs-carousel-content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .rs-carousel-slider {
    position: relative;
    left: 0;
    height: 100%;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    will-change: transform;
  }
  .rs-carousel-slider-item {
    background-color: #8e8e93;
    background-color: #8e8e93;
    float: left;
    height: 100%;
    width: 100%;
  }
  .rs-carousel-slider-after {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #8e8e93;
    background-color: #8e8e93;
    top: 0;
    -webkit-animation: moveLeftHalf 0.3s ease forwards;
            animation: moveLeftHalf 0.3s ease forwards;
  }
  .rs-carousel-slider-after-vertical {
    -webkit-animation: moveLeftHalf-vertical 0.3s ease forwards;
            animation: moveLeftHalf-vertical 0.3s ease forwards;
  }
  .rs-carousel-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: absolute;
  }
  .rs-carousel-toolbar > ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .rs-carousel-label-wrapper {
    margin: 3px;
  }
  .rs-carousel-label-wrapper input {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
  }
  .rs-carousel-label-wrapper input:checked ~ label {
    background-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-carousel-label {
    cursor: pointer;
    display: block;
    background-color: rgba(255, 255, 255, 0.4);
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-transition: background 0.3s linear;
    transition: background 0.3s linear;
    -webkit-transition-property: background, width, height;
    transition-property: background, width, height;
    position: relative;
  }
  .rs-carousel-label::after {
    content: '';
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
  }
  .rs-carousel-label:hover {
    background-color: #fff;
    background-color: #fff;
  }
  .rs-carousel-shape-dot .rs-carousel-label {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  .rs-carousel-shape-bar .rs-carousel-label {
    border-radius: 2px;
  }
  .rs-carousel-placement-top.rs-carousel-shape-bar .rs-carousel-label,
  .rs-carousel-placement-bottom.rs-carousel-shape-bar .rs-carousel-label {
    width: 18px;
    height: 4px;
  }
  .rs-carousel-placement-top.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label,
  .rs-carousel-placement-bottom.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label {
    width: 28px;
  }
  .rs-carousel-placement-left.rs-carousel-shape-bar .rs-carousel-label,
  .rs-carousel-placement-right.rs-carousel-shape-bar .rs-carousel-label {
    width: 4px;
    height: 18px;
  }
  .rs-carousel-placement-left.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label,
  .rs-carousel-placement-right.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label {
    height: 28px;
  }
  .rs-carousel-placement-top .rs-carousel-toolbar,
  .rs-carousel-placement-bottom .rs-carousel-toolbar {
    left: 0;
    width: 100%;
  }
  .rs-carousel-placement-top .rs-carousel-toolbar > ul,
  .rs-carousel-placement-bottom .rs-carousel-toolbar > ul {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .rs-carousel-placement-top .rs-carousel-toolbar {
    top: 17px;
  }
  .rs-carousel-placement-bottom .rs-carousel-toolbar {
    bottom: 17px;
  }
  .rs-carousel-placement-left .rs-carousel-toolbar,
  .rs-carousel-placement-right .rs-carousel-toolbar {
    top: 0;
    width: 1.2vw;
    height: 100%;
  }
  .rs-carousel-placement-left .rs-carousel-toolbar > ul,
  .rs-carousel-placement-right .rs-carousel-toolbar > ul {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .rs-carousel-placement-left .rs-carousel-toolbar {
    left: 17px;
  }
  .rs-carousel-placement-right .rs-carousel-toolbar {
    right: 17px;
  }
  @-webkit-keyframes moveLeftHalf {
    0% {
      -webkit-transform: none;
              transform: none;
    }
    100% {
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
    }
  }
  @keyframes moveLeftHalf {
    0% {
      -webkit-transform: none;
              transform: none;
    }
    100% {
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
    }
  }
  @-webkit-keyframes moveLeftHalf-vertical {
    0% {
      -webkit-transform: none;
              transform: none;
    }
    100% {
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
    }
  }
  @keyframes moveLeftHalf-vertical {
    0% {
      -webkit-transform: none;
              transform: none;
    }
    100% {
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
    }
  }
  .rs-picker-cascader-menu-items > div::before,
  .rs-picker-cascader-menu-items > div::after {
    content: ' ';
    display: table;
  }
  .rs-picker-cascader-menu-items > div::after {
    clear: both;
  }
  .rs-picker-cascader-search-panel {
    max-height: 300px;
    overflow: auto;
  }
  .rs-picker-cascader-row {
    display: block;
    padding: 8px 12px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #575757;
    color: #575757;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
  }
  .rs-picker-cascader-row:hover {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-theme-high-contrast .rs-picker-cascader-row:hover {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-cascader-row:hover {
    outline-offset: 2px;
  }
  .rs-picker-cascader-row.rs-picker-cascader-row-focus {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-theme-high-contrast .rs-picker-cascader-row.rs-picker-cascader-row-focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-cascader-row.rs-picker-cascader-row-focus {
    outline-offset: 2px;
  }
  .rs-picker-cascader-row:disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
    cursor: not-allowed;
  }
  .rs-picker-cascader-row.rs-picker-cascader-row-disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
    cursor: not-allowed;
  }
  .rs-picker-cascader-row:disabled .rs-picker-cascader-search-match,
  .rs-picker-cascader-row.rs-picker-cascader-row-disabled .rs-picker-cascader-search-match {
    opacity: 0.7;
    font-weight: normal;
  }
  .rs-picker-cascader-col {
    display: inline-block;
  }
  .rs-picker-cascader-col::after {
    content: '\00a0/\00a0';
  }
  .rs-picker-cascader-col:last-child::after {
    display: none;
  }
  .rs-picker-cascader-search-match {
    color: #3498ff;
    color: #3498ff;
    font-weight: bold;
  }
  .rs-picker-cascader-menu-column {
    float: left;
    overflow-y: auto;
  }
  .rs-picker-cascader-menu-column-loading {
    padding: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .rs-picker-cascader-menu-column-loading .rs-icon {
    margin: 4px;
  }
  .rs-picker-cascader-menu-items {
    padding: 6px 0;
  }
  .rs-picker-cascader-menu-items ul,
  .rs-picker-cascader-menu-items li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .rs-picker-cascader-menu-item {
    display: block;
    padding: 8px 12px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #575757;
    color: #575757;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
    position: relative;
    padding: 8px 28px 8px 12px;
    word-break: break-word;
  }
  .rs-picker-cascader-menu-items-has-children .rs-picker-cascader-menu-item {
    padding-right: 32px;
  }
  .rs-picker-cascader-menu-item:hover {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-picker-cascader-menu-item:focus {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-picker-cascader-menu-item.rs-picker-select-menu-item-focus {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-focus {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-theme-high-contrast .rs-picker-cascader-menu-item:hover {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-picker-cascader-menu-item:focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-picker-cascader-menu-item.rs-picker-select-menu-item-focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-cascader-menu-item:hover,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-cascader-menu-item:focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-cascader-menu-item.rs-picker-select-menu-item-focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-focus {
    outline-offset: 2px;
  }
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active {
    background-color: #f2faff;
    background-color: #f2faff;
    font-weight: bold;
    color: #1675e0;
    color: #1675e0;
  }
  .rs-theme-high-contrast .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active {
    text-decoration: underline;
  }
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active .rs-picker-cascader-menu-caret {
    color: #575757;
    color: #575757;
  }
  .rs-picker-cascader-menu-item:disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
    cursor: not-allowed;
  }
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
    cursor: not-allowed;
  }
  .rs-picker-cascader-menu-caret {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: 2px;
    position: absolute;
    top: 8px;
    right: 12px;
    height: 20px;
    font-size: 12px;
  }
  .rs-checkbox {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  .rs-checkbox-disabled label {
    cursor: not-allowed;
  }
  .rs-checkbox label {
    line-height: 1.14285714;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
  }
  .rs-checkbox-inner::before {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-checkbox.rs-checkbox-disabled label {
    cursor: not-allowed;
  }
  .rs-checkbox-disabled > .rs-checkbox-checker > label {
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-checkbox-inline {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    font-weight: normal;
    margin-top: 0;
    margin-right: 10px;
  }
  .rs-plaintext .rs-checkbox-inline:first-child {
    margin-left: 0;
  }
  .rs-checkbox [type='checkbox']:focus-visible ~ .rs-checkbox-inner::before {
    -webkit-box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
    -webkit-box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
            box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
            box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  }
  .rs-checkbox-checker {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 36px;
    min-height: 36px;
    line-height: 1;
    position: relative;
  }
  /* rtl:begin:ignore */
  .rs-checkbox-wrapper::before,
  .rs-checkbox-wrapper::after,
  .rs-checkbox-wrapper .rs-checkbox-inner::before,
  .rs-checkbox-wrapper .rs-checkbox-inner::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
  }
  .rs-checkbox-wrapper .rs-checkbox-inner::after {
    opacity: 0;
    -webkit-transform: rotate(45deg) scale(0);
            transform: rotate(45deg) scale(0);
    -webkit-transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46), -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  }
  .rs-theme-high-contrast .rs-checkbox-wrapper .rs-checkbox-inner::after {
    -webkit-transition: none;
    transition: none;
  }
  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after {
    border: solid #fff;
    border: solid #fff;
    width: 6px;
    height: 9px;
    margin-top: 2px;
    margin-left: 5px;
    opacity: 1;
  }
  .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
    border: solid #fff;
    border: solid #fff;
    width: 6px;
    height: 9px;
    margin-top: 2px;
    margin-left: 5px;
    opacity: 1;
  }
  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after {
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg) scale(1);
            transform: rotate(45deg) scale(1);
  }
  .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
    border-width: 0 0 2px;
    -webkit-transform: rotate(0deg) scale(1);
            transform: rotate(0deg) scale(1);
    width: 10px;
    margin-top: 0;
    margin-left: 3px;
  }
  /* rtl:end:ignore */
  /* stylelint-disable-next-line */
  .rs-checkbox-wrapper {
    position: absolute;
    width: 16px;
    height: 16px;
    display: inline-block;
    left: 10px;
    top: 10px;
  }
  .rs-checkbox-wrapper [type='checkbox'] {
    width: 0;
    height: 0;
    opacity: 0;
  }
  .rs-checkbox-wrapper::before,
  .rs-checkbox-wrapper .rs-checkbox-inner::before,
  .rs-checkbox-wrapper .rs-checkbox-inner::after {
    width: 16px;
    height: 16px;
  }
  .rs-checkbox-wrapper::before {
    border: 1px solid #3498ff;
    border: 1px solid #3498ff;
    background-color: transparent;
    border-radius: 3px;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.7;
    visibility: hidden;
    -webkit-transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear, -webkit-transform 0.2s linear;
  }
  .rs-theme-high-contrast .rs-checkbox-wrapper::before {
    -webkit-transition: none;
    transition: none;
  }
  .rs-checkbox-checked .rs-checkbox-wrapper::before {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
    visibility: visible;
  }
  .rs-checkbox-wrapper::after {
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }
  .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border: 1px solid #d9d9d9;
    border: 1px solid #d9d9d9;
    background-color: transparent;
    border-radius: 3px;
    -webkit-transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear, -webkit-box-shadow 0.2s linear;
  }
  .rs-theme-high-contrast .rs-checkbox-wrapper .rs-checkbox-inner::before {
    -webkit-transition: none;
    transition: none;
  }
  label:hover .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-checkbox-disabled:not(.rs-checkbox-checked):not(.rs-checkbox-indeterminate) .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: #f7f7fa;
    border-color: #f7f7fa;
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: #3498ff;
    border-color: #3498ff;
    background-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: #3498ff;
    border-color: #3498ff;
    background-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-checkbox-disabled.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
  .rs-checkbox-disabled.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
    opacity: 0.3;
  }
  .rs-theme-high-contrast .rs-checkbox-disabled.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
  .rs-theme-high-contrast .rs-checkbox-disabled.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
    opacity: 0.5;
  }
  .rs-checkbox-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .rs-checkbox-group > .rs-checkbox {
    margin-left: -10px;
  }
  .rs-checkbox-group-inline {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .rs-picker-check-menu-items {
    margin-bottom: 6px;
    overflow-y: auto;
  }
  .rs-picker-check-menu-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .rs-picker-check-menu-items .rs-picker-menu-group:not(:first-child) {
    margin-top: 6px;
    border-top: 1px solid #e5e5ea;
    border-top: 1px solid #e5e5ea;
    padding-top: 6px;
  }
  .rs-picker-check-menu-items .rs-picker-menu-group-title {
    padding: 8px 12px;
    padding-right: 32px;
    position: relative;
    cursor: pointer;
    color: #272c36;
    color: #272c36;
  }
  .rs-picker-check-menu-items .rs-picker-menu-group-title .rs-picker-menu-group-caret {
    display: inline-block;
    margin-left: 2px;
    position: absolute;
    top: 8px;
    right: 12px;
    padding: 3px;
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-picker-check-menu-items .rs-picker-menu-group.folded .rs-picker-menu-group-caret {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .rs-picker-check-menu-items .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
    font-weight: bold;
  }
  .rs-picker-check-menu-items.rs-picker-check-menu-grouped .rs-check-item .rs-checkbox-checker > label {
    padding-left: 52px;
  }
  .rs-picker-check-menu-items.rs-picker-check-menu-grouped .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper {
    left: 26px;
  }
  .rs-check-tree {
    max-height: 360px;
    height: 100%;
    overflow-y: auto;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }
  .rs-check-tree.rs-check-tree-virtualized {
    overflow: hidden;
  }
  .rs-check-tree .rs-check-item .rs-checkbox-checker > label {
    text-align: left;
    position: relative;
    margin: 0;
    padding: 8px 12px;
    padding-left: 50px;
  }
  .rs-check-tree .rs-check-item .rs-checkbox-checker > label::before {
    content: '';
    position: absolute;
    width: 46px;
    height: 100%;
    top: 0;
    margin-left: -52px;
  }
  .rs-check-tree .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper {
    left: 20px;
  }
  .rs-check-tree-without-children .rs-check-item .rs-checkbox-checker > label {
    padding-left: 32px;
  }
  .rs-check-tree-without-children .rs-check-item .rs-checkbox-checker > label::before {
    width: 28px;
    margin-left: -34px;
  }
  .rs-check-tree-without-children .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper {
    left: 0;
  }
  .rs-check-tree-node {
    position: relative;
    font-size: 14px;
    line-height: 22px;
  }
  .rs-check-tree-node .rs-check-item {
    display: inline-block;
  }
  .rs-picker-menu .rs-check-tree-node .rs-check-item {
    display: block;
  }
  .rs-check-tree-node:focus .rs-check-item .rs-checkbox-checker > label {
    color: #575757;
    color: #575757;
    background-color: #f2faff;
    background-color: #f2faff;
  }
  .rs-check-tree-node .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
    font-weight: bold;
  }
  .rs-check-tree-node-all-uncheckable .rs-check-item .rs-checkbox-checker > label {
    padding-left: 22px;
  }
  .rs-check-tree-node-all-uncheckable .rs-check-item .rs-checkbox-checker > label::before {
    width: 14px;
    margin-left: 0;
    left: 0;
  }
  .rs-check-tree-node-expand-icon-wrapper {
    display: inline-block;
    position: absolute;
    cursor: pointer;
    z-index: 1;
  }
  .rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expand-icon {
    display: inline-block;
    padding: 8px 0;
    padding-right: 8px;
    height: 36px;
    font-size: 16px;
    line-height: 22px;
    -webkit-transform: rotate(-90deg) /* rtl: rotate(90deg) translateX(-13px) translateY(-15px) */;
            transform: rotate(-90deg) /* rtl: rotate(90deg) translateX(-13px) translateY(-15px) */;
    -webkit-transform-origin: 1.5px 14px;
            transform-origin: 1.5px 14px;
    margin-top: 6px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .rs-check-tree-open > .rs-check-tree-node .rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expand-icon {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    margin-left: 0;
    margin-top: 0;
  }
  .rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expanded {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    margin-left: 0;
    margin-top: 0;
  }
  .rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-custom-icon,
  .rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-loading-icon {
    width: 14px;
    height: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 14px;
  }
  .rs-check-tree-node-children > .rs-check-tree-children {
    position: relative;
    display: none;
  }
  .rs-check-tree-open.rs-check-tree-node-children > .rs-check-tree-children {
    display: block;
  }
  .rs-check-tree-indent-line {
    height: 100%;
    width: 1px;
    position: absolute;
    top: 0;
    margin-left: -13px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
  .rs-picker-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .rs-picker-menu.rs-check-tree-menu {
    padding-top: 12px;
  }
  .rs-picker-menu.rs-check-tree-menu .rs-picker-search-bar {
    padding-top: 0;
  }
  .rs-picker-menu .rs-check-tree {
    padding: 0 12px 12px 0;
  }
  .rs-picker-menu .rs-check-tree-node > .rs-check-tree-node-label .rs-check-tree-node-text-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: inline-block;
    vertical-align: top;
  }
  /* rtl:begin:ignore */
  [dir='rtl'] .rs-check-tree-node-expand-icon-wrapper {
    right: 0;
    padding-right: inherit;
  }
  /* rtl:end:ignore */
  .rs-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
  }
  .rs-container-has-sidebar {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .rs-content {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }
  .rs-form-control-label {
    margin-bottom: 4px;
  }
  .rs-form-plaintext .rs-form-control-label {
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-picker-date.rs-picker-default .rs-picker-toggle-caret,
  .rs-picker-daterange.rs-picker-default .rs-picker-toggle-caret,
  .rs-picker-date.rs-picker-default .rs-picker-toggle-clean,
  .rs-picker-daterange.rs-picker-default .rs-picker-toggle-clean {
    top: 7px;
  }
  .rs-picker-toolbar {
    padding: 12px;
    border-top: 1px solid #e5e5ea;
    border-top: 1px solid #e5e5ea;
  }
  .rs-picker-date-inline {
    height: 299px;
  }
  .rs-picker-date-inline .rs-calendar {
    height: 286px;
  }
  .rs-picker-date-predefined {
    height: 325px;
    border-right: 1px solid #e5e5ea;
    border-right: 1px solid #e5e5ea;
    padding: 4px 0;
  }
  .rs-picker-date-predefined .rs-btn {
    display: block;
  }
  .rs-picker-menu .rs-picker-toolbar {
    max-width: 100%;
  }
  .rs-picker-menu .rs-picker-toolbar-ranges {
    max-width: 400px;
  }
  .rs-picker-menu .rs-calendar {
    min-width: 264px;
    display: block;
    margin: 0 auto;
  }
  .rs-picker-menu .rs-calendar-show-week-numbers {
    min-width: 278px;
  }
  .rs-picker-menu .rs-calendar-show-week-numbers .rs-calendar-body {
    padding-left: 12px;
    padding-right: 12px;
  }
  .rs-picker-menu .rs-calendar-header {
    width: 100%;
  }
  .rs-picker-menu .rs-calendar-body {
    padding-left: 15px;
    padding-right: 15px;
  }
  .rs-picker-menu .rs-calendar-table {
    width: unset;
  }
  .rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content,
  .rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
    width: 30px;
    height: 30px;
  }
  .rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-theme-high-contrast .rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
    outline-offset: 2px;
  }
  .rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content:hover {
    background-color: #1675e0;
    background-color: #1675e0;
    color: #fff;
    color: #fff;
  }
  .rs-theme-high-contrast .rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content:hover {
    color: #fff;
    color: #0f131a;
  }
  .rs-picker-menu .rs-calendar .rs-calendar-table-header-row .rs-calendar-table-cell-content {
    height: 24px;
    padding-top: 0;
  }
  .rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
    padding-left: 0;
    padding-right: 0;
    display: inline-block;
  }
  .rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-theme-high-contrast .rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    outline-offset: 2px;
  }
  .rs-picker-menu .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
    background-color: #1675e0;
    background-color: #1675e0;
    color: #fff;
    color: #fff;
  }
  .rs-theme-high-contrast .rs-picker-menu .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
    color: #fff;
    color: #0f131a;
  }
  .rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-scroll {
    height: 227px;
  }
  .rs-picker-menu .rs-calendar .rs-calendar-time-dropdown-column > ul {
    height: 214px;
    padding-bottom: 184px;
  }
  .rs-picker-daterange-menu .rs-calendar {
    display: inline-block;
    height: 274px;
    padding-bottom: 12px;
  }
  .rs-picker-daterange-menu .rs-calendar:first-child {
    border-right: 1px solid #e5e5ea;
    border-right: 1px solid #e5e5ea;
  }
  .rs-picker-daterange-menu .rs-calendar-header {
    width: 100%;
    text-align: center;
  }
  .rs-picker-daterange-menu .rs-calendar-header-month-toolbar {
    float: none;
  }
  .rs-picker-daterange-menu .rs-calendar-month-dropdown {
    z-index: 1;
  }
  .rs-picker-daterange-menu .rs-calendar-month-dropdown-list {
    width: 185px;
  }
  .rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar {
    max-width: 255px;
  }
  .rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar-ranges {
    width: 190px;
  }
  .rs-picker-daterange-menu .rs-picker-daterange-calendar-single .rs-calendar {
    border: 0;
    display: block;
    margin: auto;
  }
  .rs-picker-daterange-header {
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-bottom: 1px solid #e5e5ea;
    border-bottom: 1px solid #e5e5ea;
  }
  .rs-picker-daterange-calendar-group {
    height: 274px;
    min-width: 492px;
  }
  .rs-picker-daterange-predefined {
    height: 366px;
    border-right: 1px solid #e5e5ea;
    border-right: 1px solid #e5e5ea;
    padding: 4px 0;
  }
  .rs-picker-daterange-predefined .rs-btn {
    display: block;
  }
  .rs-divider {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-divider-vertical {
    display: inline-block;
    height: 1em;
    width: 1px;
    vertical-align: middle;
    margin: 0 12px;
  }
  .rs-divider-horizontal {
    height: 1px;
    margin: 24px 0;
  }
  .rs-divider-horizontal.rs-divider-with-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: transparent;
    margin: 30px 0;
  }
  .rs-divider-horizontal.rs-divider-with-text::before {
    content: '';
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    border-top: 1px solid #e5e5ea;
    border-top: 1px solid #e5e5ea;
  }
  .rs-divider-horizontal.rs-divider-with-text::after {
    content: '';
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    border-top: 1px solid #e5e5ea;
    border-top: 1px solid #e5e5ea;
  }
  .rs-divider-inner-text {
    padding: 0 12px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
  @-webkit-keyframes shakeHead {
    0%,
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    25%,
    75% {
      -webkit-transform: translate3d(-10px, 0, 0);
              transform: translate3d(-10px, 0, 0);
    }
    50% {
      -webkit-transform: translate3d(10px, 0, 0);
              transform: translate3d(10px, 0, 0);
    }
  }
  @keyframes shakeHead {
    0%,
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    25%,
    75% {
      -webkit-transform: translate3d(-10px, 0, 0);
              transform: translate3d(-10px, 0, 0);
    }
    50% {
      -webkit-transform: translate3d(10px, 0, 0);
              transform: translate3d(10px, 0, 0);
    }
  }
  .rs-drawer-wrapper {
    position: fixed;
    z-index: 1050;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .rs-drawer {
    display: none;
    overflow: hidden;
    position: fixed;
    z-index: 1050;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    outline: 0;
  }
  .rs-drawer-open.rs-drawer-has-backdrop {
    overflow: hidden;
  }
  .rs-drawer-left,
  .rs-drawer-right {
    top: 0;
    height: 100%;
  }
  .rs-drawer-left.rs-drawer-lg,
  .rs-drawer-right.rs-drawer-lg {
    width: 968px;
  }
  .rs-drawer-left.rs-drawer-md,
  .rs-drawer-right.rs-drawer-md {
    width: 800px;
  }
  .rs-drawer-left.rs-drawer-sm,
  .rs-drawer-right.rs-drawer-sm {
    width: 600px;
  }
  .rs-drawer-left.rs-drawer-xs,
  .rs-drawer-right.rs-drawer-xs {
    width: 400px;
  }
  .rs-drawer-top,
  .rs-drawer-bottom {
    width: 100%;
  }
  .rs-drawer-top.rs-drawer-lg,
  .rs-drawer-bottom.rs-drawer-lg {
    height: 568px;
  }
  .rs-drawer-top.rs-drawer-md,
  .rs-drawer-bottom.rs-drawer-md {
    height: 480px;
  }
  .rs-drawer-top.rs-drawer-sm,
  .rs-drawer-bottom.rs-drawer-sm {
    height: 400px;
  }
  .rs-drawer-top.rs-drawer-xs,
  .rs-drawer-bottom.rs-drawer-xs {
    height: 290px;
  }
  .rs-drawer-full.rs-drawer-top,
  .rs-drawer-full.rs-drawer-bottom {
    height: calc(100% - 60px);
  }
  .rs-drawer-full.rs-drawer-top .rs-drawer-content,
  .rs-drawer-full.rs-drawer-bottom .rs-drawer-content {
    height: 100%;
  }
  .rs-drawer-full.rs-drawer-left,
  .rs-drawer-full.rs-drawer-right {
    width: calc(100% - 60px);
  }
  .rs-drawer-right {
    right: 0;
  }
  .rs-drawer-left {
    left: 0;
  }
  .rs-drawer-top {
    top: 0;
  }
  .rs-drawer-bottom {
    bottom: 0;
  }
  .rs-drawer-open .rs-drawer {
    overflow: visible;
  }
  .rs-drawer-dialog {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .rs-drawer-shake .rs-drawer-dialog {
    -webkit-animation: 0.3s linear shakeHead;
            animation: 0.3s linear shakeHead;
  }
  .rs-drawer-content {
    position: absolute;
    background-color: #fff;
    background-color: #fff;
    outline: 0;
    width: 100%;
    height: 100%;
  }
  .rs-theme-high-contrast .rs-drawer-content {
    background-color: #292d33;
  }
  .rs-drawer-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    background-color: rgba(39, 44, 54, 0.3);
    background-color: rgba(39, 44, 54, 0.3);
  }
  .rs-drawer-backdrop.rs-anim-fade {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in;
  }
  .rs-drawer-backdrop.rs-anim-in {
    opacity: 1;
  }
  .rs-drawer-header {
    position: relative;
    padding: 20px 40px 20px 60px;
    border-bottom: 1px solid #e5e5ea;
    border-bottom: 1px solid #e5e5ea;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rs-drawer-header::before,
  .rs-drawer-header::after {
    content: ' ';
    display: table;
  }
  .rs-drawer-header::after {
    clear: both;
  }
  .rs-drawer-header .rs-drawer-header-close {
    position: absolute;
    left: 15px;
    top: 23px;
  }
  .rs-drawer-title {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    margin: 0;
    color: #272c36;
    color: #272c36;
    font-weight: normal;
    font-size: 16px;
    line-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .rs-drawer-actions {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    text-align: right;
    border-top: none;
    margin-left: auto;
  }
  .rs-drawer-actions::before,
  .rs-drawer-actions::after {
    content: ' ';
    display: table;
  }
  .rs-drawer-actions::after {
    clear: both;
  }
  .rs-drawer-title ~ .rs-drawer-actions {
    margin-left: 10px;
  }
  .rs-drawer-actions .rs-btn + .rs-btn {
    margin-left: 10px;
    margin-bottom: 0;
  }
  .rs-drawer-actions .rs-btn-group .rs-btn + .rs-btn {
    margin-left: -1px;
  }
  .rs-drawer-actions .rs-btn-block + .rs-btn-block {
    margin-left: 0;
  }
  .rs-drawer-body {
    position: relative;
    padding: 30px 60px;
    height: 100%;
    overflow: auto;
  }
  .rs-drawer-header + .rs-drawer-body {
    height: calc(100% - 76px);
  }
  .rs-drawer-body-close {
    position: absolute;
    left: 15px;
    top: 25px;
  }
  .rs-drawer-header ~ .rs-drawer-body .rs-drawer-body-close {
    display: none;
  }
  .rs-drawer-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
  .rs-drawer-footer {
    text-align: right;
    border-top: none;
    margin: 0 20px 20px;
  }
  .rs-drawer-footer::before,
  .rs-drawer-footer::after {
    content: ' ';
    display: table;
  }
  .rs-drawer-footer::after {
    clear: both;
  }
  .rs-drawer-footer .rs-btn + .rs-btn {
    margin-left: 10px;
    margin-bottom: 0;
  }
  .rs-drawer-footer .rs-btn-group .rs-btn + .rs-btn {
    margin-left: -1px;
  }
  .rs-drawer-footer .rs-btn-block + .rs-btn-block {
    margin-left: 0;
  }
  .rs-dropdown-toggle-caret {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: 2px;
    position: absolute;
    top: 8px;
    right: 12px;
    height: 20px;
    font-size: 12px;
  }
  .rs-dropdown {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  .rs-dropdown .rs-btn > .rs-dropdown-toggle-icon {
    margin-right: 6px;
  }
  .rs-dropdown .rs-btn > .rs-dropdown-toggle-caret {
    margin-left: 4px;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn {
    cursor: not-allowed !important;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-subtle {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-subtle:focus {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-subtle:active {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-primary {
    opacity: 0.3;
    background-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-primary:focus {
    opacity: 0.3;
    background-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-primary:active {
    opacity: 0.3;
    background-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-link,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-link:focus,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-link:active {
    opacity: 0.3;
    text-decoration: none;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-ghost,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-ghost:focus,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-ghost:active {
    opacity: 0.3;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn .rs-ripple-pond {
    display: none !important;
  }
  .rs-dropdown-toggle.rs-btn-lg {
    padding-right: 36px !important;
  }
  .rs-dropdown-toggle.rs-btn-lg .rs-dropdown-toggle-caret {
    top: 10px;
    right: 16px;
    font-size: 14px;
  }
  .rs-dropdown-toggle.rs-btn-sm {
    padding-right: 30px !important;
  }
  .rs-dropdown-toggle.rs-btn-sm .rs-dropdown-toggle-caret {
    top: 5px;
    right: 10px;
    font-size: 10px;
  }
  .rs-dropdown-toggle.rs-btn-xs {
    padding-right: 28px !important;
  }
  .rs-dropdown-toggle.rs-btn-xs .rs-dropdown-toggle-caret {
    top: 2px;
    right: 8px;
    font-size: 8px;
  }
  .rs-dropdown-toggle,
  .rs-dropdown-toggle.rs-btn {
    position: relative;
    z-index: 5;
    padding-right: 32px;
    display: inline-block;
  }
  .rs-dropdown-toggle.rs-dropdown-toggle-no-caret {
    padding-right: 12px;
  }
  .rs-dropdown-menu {
    margin: 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    background-color: #fff;
    border-radius: 6px;
    padding: 6px 0;
    outline: 0;
  }
  .rs-dropdown-menu:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
  }
  .rs-theme-high-contrast .rs-dropdown-menu:focus-visible {
    outline-offset: 2px;
  }
  .rs-dropdown .rs-dropdown-menu {
    position: absolute;
    z-index: 6;
    float: left;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
    outline: none;
  }
  .rs-theme-high-contrast .rs-dropdown .rs-dropdown-menu {
    border: 1px solid #e5e5ea;
    border: 1px solid #cbced4;
  }
  .rs-dropdown-menu[hidden] {
    display: none;
  }
  .rs-dropdown-item {
    display: block;
    padding: 8px 12px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #575757;
    color: #575757;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;
  }
  .rs-dropdown-item:hover,
  .rs-dropdown-item:focus,
  .rs-dropdown-item:active {
    text-decoration: none;
  }
  .rs-dropdown-item > .rs-icon {
    width: 14px;
    text-align: center;
    margin-right: 6px;
  }
  .rs-dropdown-item.rs-dropdown-item-active {
    outline: 0;
    font-weight: bold;
  }
  .rs-dropdown-item.rs-dropdown-item-active {
    color: #1675e0;
    color: #1675e0;
    background-color: #f2faff;
    background-color: #f2faff;
  }
  .rs-dropdown-item.rs-dropdown-item-active:hover {
    color: #1675e0;
    color: #1675e0;
    background-color: #f2faff;
    background-color: #f2faff;
  }
  .rs-dropdown-item.rs-dropdown-item-active:focus {
    color: #1675e0;
    color: #1675e0;
    background-color: #f2faff;
    background-color: #f2faff;
  }
  .rs-dropdown-item:focus-visible {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-dropdown-item.rs-dropdown-item-focus {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-theme-high-contrast .rs-dropdown-item:focus-visible,
  .rs-theme-high-contrast .rs-dropdown-item.rs-dropdown-item-focus {
    text-decoration: underline;
    -webkit-box-shadow: inset 0 0 0 2px #fff;
            box-shadow: inset 0 0 0 2px #fff;
  }
  .rs-dropdown-item:focus {
    outline: 0;
  }
  .rs-dropdown-item-disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    cursor: not-allowed;
    pointer-events: none;
  }
  .rs-dropdown-item-disabled:hover,
  .rs-dropdown-item-disabled:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none;
  }
  .rs-dropdown-item-divider {
    height: 1px;
    margin: 6px 0;
    overflow: hidden;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-dropdown-item-submenu {
    position: relative;
  }
  .rs-dropdown-item-submenu:hover > .rs-dropdown-item-toggle {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-theme-high-contrast .rs-dropdown-item-submenu:hover > .rs-dropdown-item-toggle {
    text-decoration: underline;
    -webkit-box-shadow: inset 0 0 0 2px #fff;
            box-shadow: inset 0 0 0 2px #fff;
  }
  .rs-dropdown-item-submenu.rs-dropdown-item {
    position: relative;
  }
  .rs-dropdown-item-submenu.rs-dropdown-item > .rs-dropdown-item-toggle .rs-dropdown-menu-toggle-icon {
    position: absolute;
    top: 11px;
    margin: 0;
  }
  .rs-dropdown-item-submenu.rs-dropdown-item > .rs-dropdown-menu {
    position: absolute;
    top: -6px;
  }
  .rs-dropdown-item.rs-dropdown-item-submenu {
    padding: 0;
  }
  .rs-dropdown-item.rs-dropdown-item-submenu .rs-dropdown-item-toggle {
    padding: 8px 12px;
    padding-right: 27px;
  }
  .rs-dropdown-item.rs-dropdown-item-submenu .rs-dropdown-menu-toggle-icon {
    right: 9px;
  }
  .rs-dropdown-item-submenu .rs-dropdown-menu {
    left: 100%;
  }
  .rs-dropdown-item-submenu .rs-dropdown-menu[data-direction='start'] {
    left: unset;
    right: 100%;
  }
  .rs-dropdown-item-submenu.rs-dropdown-item-open > .rs-dropdown-menu {
    display: table;
    -webkit-animation-name: slideUpIn;
            animation-name: slideUpIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .rs-dropdown-menu > .rs-dropdown-menu-item-focus {
    outline: 0;
    background-color: #f2faff;
    background-color: #f2faff;
  }
  .rs-dropdown-menu > .rs-dropdown-menu-item-focus:hover {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-dropdown-menu > .rs-dropdown-menu-item-focus:focus {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-theme-high-contrast .rs-dropdown-menu > .rs-dropdown-menu-item-focus:hover,
  .rs-theme-high-contrast .rs-dropdown-menu > .rs-dropdown-menu-item-focus:focus {
    text-decoration: underline;
    -webkit-box-shadow: inset 0 0 0 2px #fff;
            box-shadow: inset 0 0 0 2px #fff;
  }
  .rs-dropdown-open > .rs-dropdown-menu {
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .rs-dropdown-open.rs-dropdown-placement-top-start > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-top-end > .rs-dropdown-menu {
    bottom: 100%;
  }
  .rs-dropdown-open.rs-dropdown-placement-left-start > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-left-end > .rs-dropdown-menu {
    right: 100%;
  }
  .rs-dropdown-open.rs-dropdown-placement-right-start > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-right-end > .rs-dropdown-menu {
    left: 100%;
  }
  .rs-dropdown-open.rs-dropdown-placement-bottom-start > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-bottom-end > .rs-dropdown-menu {
    top: 100%;
  }
  .rs-dropdown-open.rs-dropdown-placement-top-start > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-bottom-start > .rs-dropdown-menu {
    left: 0;
  }
  .rs-dropdown-open.rs-dropdown-placement-top-end > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-bottom-end > .rs-dropdown-menu {
    right: 0;
  }
  .rs-dropdown-open.rs-dropdown-placement-left-start > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-right-start > .rs-dropdown-menu {
    top: 0;
  }
  .rs-dropdown-open.rs-dropdown-placement-right-end > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-left-end > .rs-dropdown-menu {
    bottom: 0;
  }
  .rs-dropdown-open > a {
    outline: 0;
  }
  .rs-dropdown-header {
    display: block;
    padding: 8px 12px;
    line-height: 1.42857143;
    color: #a6a6a6;
    color: #a6a6a6;
    border-bottom: 1px solid #e5e5ea;
    border-bottom: 1px solid #e5e5ea;
    white-space: nowrap;
  }
  @-webkit-keyframes slideUpIn {
    0% {
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
    }
    100% {
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
  }
  @keyframes slideUpIn {
    0% {
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
    }
    100% {
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
  }
  .rs-flex-box-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
  }
  .rs-flex-box-grid-item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
  .rs-flex-box-grid-top {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .rs-flex-box-grid-middle {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .rs-flex-box-grid-bottom {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .rs-flex-box-grid-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .rs-flex-box-grid-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .rs-flex-box-grid-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .rs-flex-box-grid-space-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .rs-flex-box-grid-space-around {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .rs-flex-box-grid-item-1 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 4.16666667%;
  }
  .rs-flex-box-grid-item-order-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .rs-flex-box-grid-item-2 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 8.33333333%;
  }
  .rs-flex-box-grid-item-order-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .rs-flex-box-grid-item-3 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 12.5%;
  }
  .rs-flex-box-grid-item-order-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .rs-flex-box-grid-item-4 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 16.66666667%;
  }
  .rs-flex-box-grid-item-order-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .rs-flex-box-grid-item-5 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 20.83333333%;
  }
  .rs-flex-box-grid-item-order-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .rs-flex-box-grid-item-6 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 25%;
  }
  .rs-flex-box-grid-item-order-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .rs-flex-box-grid-item-7 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 29.16666667%;
  }
  .rs-flex-box-grid-item-order-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .rs-flex-box-grid-item-8 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 33.33333333%;
  }
  .rs-flex-box-grid-item-order-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .rs-flex-box-grid-item-9 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 37.5%;
  }
  .rs-flex-box-grid-item-order-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .rs-flex-box-grid-item-10 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 41.66666667%;
  }
  .rs-flex-box-grid-item-order-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .rs-flex-box-grid-item-11 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 45.83333333%;
  }
  .rs-flex-box-grid-item-order-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .rs-flex-box-grid-item-12 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 50%;
  }
  .rs-flex-box-grid-item-order-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .rs-flex-box-grid-item-13 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 54.16666667%;
  }
  .rs-flex-box-grid-item-order-13 {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .rs-flex-box-grid-item-14 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 58.33333333%;
  }
  .rs-flex-box-grid-item-order-14 {
    -webkit-box-ordinal-group: 15;
        -ms-flex-order: 14;
            order: 14;
  }
  .rs-flex-box-grid-item-15 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 62.5%;
  }
  .rs-flex-box-grid-item-order-15 {
    -webkit-box-ordinal-group: 16;
        -ms-flex-order: 15;
            order: 15;
  }
  .rs-flex-box-grid-item-16 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 66.66666667%;
  }
  .rs-flex-box-grid-item-order-16 {
    -webkit-box-ordinal-group: 17;
        -ms-flex-order: 16;
            order: 16;
  }
  .rs-flex-box-grid-item-17 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 70.83333333%;
  }
  .rs-flex-box-grid-item-order-17 {
    -webkit-box-ordinal-group: 18;
        -ms-flex-order: 17;
            order: 17;
  }
  .rs-flex-box-grid-item-18 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 75%;
  }
  .rs-flex-box-grid-item-order-18 {
    -webkit-box-ordinal-group: 19;
        -ms-flex-order: 18;
            order: 18;
  }
  .rs-flex-box-grid-item-19 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 79.16666667%;
  }
  .rs-flex-box-grid-item-order-19 {
    -webkit-box-ordinal-group: 20;
        -ms-flex-order: 19;
            order: 19;
  }
  .rs-flex-box-grid-item-20 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 83.33333333%;
  }
  .rs-flex-box-grid-item-order-20 {
    -webkit-box-ordinal-group: 21;
        -ms-flex-order: 20;
            order: 20;
  }
  .rs-flex-box-grid-item-21 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 87.5%;
  }
  .rs-flex-box-grid-item-order-21 {
    -webkit-box-ordinal-group: 22;
        -ms-flex-order: 21;
            order: 21;
  }
  .rs-flex-box-grid-item-22 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 91.66666667%;
  }
  .rs-flex-box-grid-item-order-22 {
    -webkit-box-ordinal-group: 23;
        -ms-flex-order: 22;
            order: 22;
  }
  .rs-flex-box-grid-item-23 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 95.83333333%;
  }
  .rs-flex-box-grid-item-order-23 {
    -webkit-box-ordinal-group: 24;
        -ms-flex-order: 23;
            order: 23;
  }
  .rs-flex-box-grid-item-24 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 100%;
  }
  .rs-flex-box-grid-item-order-24 {
    -webkit-box-ordinal-group: 25;
        -ms-flex-order: 24;
            order: 24;
  }
  .rs-footer {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
  .rs-form-inline > *,
  .rs-form-inline > .rs-btn {
    margin-right: 20px;
    margin-bottom: 24px;
    vertical-align: top;
  }
  .rs-form-inline .rs-form-control-label {
    vertical-align: top;
    margin-bottom: auto;
    margin-right: 12px;
    margin-top: 8px;
    display: inline-block;
  }
  .rs-form-fluid .rs-form-control-wrapper {
    width: 100%;
  }
  .rs-form-fluid .rs-form-control-wrapper > .rs-input-number,
  .rs-form-fluid .rs-form-control-wrapper > .rs-input {
    width: 100%;
  }
  .rs-form-fluid.rs-form-vertical .rs-form-group .rs-input-group {
    width: 100%;
  }
  .rs-form-control-wrapper {
    position: relative;
  }
  .rs-form-control-wrapper > .rs-input-number,
  .rs-form-control-wrapper > .rs-input {
    width: 300px;
  }
  .rs-form-control-wrapper.read-only {
    pointer-events: none;
    -ms-touch-action: none;
        touch-action: none;
  }
  .rs-form-vertical .rs-form-group .rs-input-group {
    width: 300px;
  }
  .rs-form-vertical .rs-form-group .rs-form-control-wrapper {
    display: inline-block;
    max-width: 100%;
  }
  .rs-form-horizontal .rs-form-group .rs-form-control-wrapper {
    float: left;
  }
  .rs-form-horizontal .rs-form-group .rs-form-control-wrapper + .rs-form-help-text {
    clear: both;
  }
  .rs-form-horizontal .rs-form-group .rs-form-control-wrapper + .rs-form-help-text:not(.rs-form-help-text-tooltip) {
    margin-left: 182px;
  }
  .rs-form-inline .rs-form-group .rs-form-control-wrapper {
    display: inline-block;
  }
  .rs-form-inline .rs-form-group .rs-sr-only + .rs-form-control-wrapper {
    margin-left: 0;
  }
  .rs-form:not(.rs-form-inline) .rs-form-group:not(:last-child) {
    margin-bottom: 24px;
  }
  .rs-form-group .rs-input {
    display: inline-block;
  }
  .rs-form-group textarea.rs-input {
    vertical-align: bottom;
  }
  .rs-form-vertical .rs-form-group .rs-form-control-label {
    display: block;
  }
  .rs-form-vertical .rs-form-group .rs-form-help-text:not(.rs-form-help-text-tooltip) {
    padding-top: 4px;
  }
  .rs-form-horizontal .rs-form-group::before,
  .rs-form-horizontal .rs-form-group::after {
    content: ' ';
    display: table;
  }
  .rs-form-horizontal .rs-form-group::after {
    clear: both;
  }
  .rs-form-horizontal .rs-form-group .rs-form-control-label {
    float: left;
    display: inline-block;
    min-height: 20px;
    line-height: 1.42857143;
    font-size: 14px;
    padding: 8px 0;
    width: 170px;
    margin-right: 12px;
    text-align: right;
  }
  .rs-form-horizontal .rs-form-group .rs-btn-toolbar {
    padding-left: 182px;
  }
  .rs-form-inline .rs-form-group {
    display: inline-block;
  }
  @-webkit-keyframes errorMessageSlideUpIn {
    0% {
      -webkit-transform: translate3d(0, 2px, 0);
              transform: translate3d(0, 2px, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @keyframes errorMessageSlideUpIn {
    0% {
      -webkit-transform: translate3d(0, 2px, 0);
              transform: translate3d(0, 2px, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @-webkit-keyframes errorMessageSlideDownIn {
    0% {
      -webkit-transform: translate3d(0, -2px, 0);
              transform: translate3d(0, -2px, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @keyframes errorMessageSlideDownIn {
    0% {
      -webkit-transform: translate3d(0, -2px, 0);
              transform: translate3d(0, -2px, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @-webkit-keyframes errorMessageSlideLeftIn {
    0% {
      -webkit-transform: translate3d(-2px, 0, 0);
              transform: translate3d(-2px, 0, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @keyframes errorMessageSlideLeftIn {
    0% {
      -webkit-transform: translate3d(-2px, 0, 0);
              transform: translate3d(-2px, 0, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @-webkit-keyframes errorMessageSlideRightIn {
    0% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @keyframes errorMessageSlideRightIn {
    0% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  .rs-form-error-message {
    position: absolute;
    padding: 3px 7px;
    background-color: #fff;
    background-color: #fff;
    border: 1px solid #e5e5ea;
    border: 1px solid #e5e5ea;
    border-radius: 6px;
    -webkit-filter: drop-shadow(0 0 6px rgba(0,0,0,.1));
            filter: drop-shadow(0 0 6px rgba(0,0,0,.1));
    z-index: 5;
    color: #f44336;
    color: #f44336;
    font-size: 12px;
    line-height: 20px;
    display: none;
    white-space: nowrap;
  }
  .rs-form-error-message-wrapper {
    position: absolute;
  }
  .rs-form-error-message-show {
    display: block;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  .rs-form-error-message-arrow::before,
  .rs-form-error-message-arrow::after {
    content: '';
    position: absolute;
    border-color: transparent;
    border-style: solid;
  }
  .rs-form-error-message-placement-bottom-start,
  .rs-form-error-message-placement-bottom-end {
    bottom: 0;
  }
  .rs-form-error-message-placement-bottom-start .rs-form-error-message,
  .rs-form-error-message-placement-bottom-end .rs-form-error-message {
    top: 0;
  }
  .rs-form-error-message-placement-bottom-start .rs-form-error-message-show,
  .rs-form-error-message-placement-bottom-end .rs-form-error-message-show {
    -webkit-animation-name: errorMessageSlideDownIn;
            animation-name: errorMessageSlideDownIn;
  }
  .rs-form-error-message-placement-bottom-start .rs-form-error-message-arrow::before {
    border-width: 0 6px 6px;
    border-bottom-color: #e5e5ea;
    border-bottom-color: #e5e5ea;
    top: -6px;
  }
  .rs-form-error-message-placement-bottom-end .rs-form-error-message-arrow::before {
    border-width: 0 6px 6px;
    border-bottom-color: #e5e5ea;
    border-bottom-color: #e5e5ea;
    top: -6px;
  }
  .rs-form-error-message-placement-bottom-start .rs-form-error-message-arrow::after {
    border-width: 0 6px 6px;
    border-bottom-color: #e5e5ea;
    border-bottom-color: #e5e5ea;
    top: -6px;
  }
  .rs-form-error-message-placement-bottom-end .rs-form-error-message-arrow::after {
    border-width: 0 6px 6px;
    border-bottom-color: #e5e5ea;
    border-bottom-color: #e5e5ea;
    top: -6px;
  }
  .rs-form-error-message-placement-bottom-start .rs-form-error-message-arrow::after {
    top: -5px;
    border-bottom-color: #fff;
    border-bottom-color: #fff;
  }
  .rs-form-error-message-placement-bottom-end .rs-form-error-message-arrow::after {
    top: -5px;
    border-bottom-color: #fff;
    border-bottom-color: #fff;
  }
  .rs-form-error-message-placement-top-start,
  .rs-form-error-message-placement-top-end {
    top: 0;
  }
  .rs-form-error-message-placement-top-start .rs-form-error-message,
  .rs-form-error-message-placement-top-end .rs-form-error-message {
    bottom: 0;
  }
  .rs-form-error-message-placement-top-start .rs-form-error-message-show,
  .rs-form-error-message-placement-top-end .rs-form-error-message-show {
    -webkit-animation-name: errorMessageSlideUpIn;
            animation-name: errorMessageSlideUpIn;
  }
  .rs-form-error-message-placement-top-start .rs-form-error-message-arrow::before {
    border-width: 6px 6px 0;
    border-top-color: #e5e5ea;
    border-top-color: #e5e5ea;
    bottom: -6px;
  }
  .rs-form-error-message-placement-top-end .rs-form-error-message-arrow::before {
    border-width: 6px 6px 0;
    border-top-color: #e5e5ea;
    border-top-color: #e5e5ea;
    bottom: -6px;
  }
  .rs-form-error-message-placement-top-start .rs-form-error-message-arrow::after {
    border-width: 6px 6px 0;
    border-top-color: #e5e5ea;
    border-top-color: #e5e5ea;
    bottom: -6px;
  }
  .rs-form-error-message-placement-top-end .rs-form-error-message-arrow::after {
    border-width: 6px 6px 0;
    border-top-color: #e5e5ea;
    border-top-color: #e5e5ea;
    bottom: -6px;
  }
  .rs-form-error-message-placement-top-start .rs-form-error-message-arrow::after {
    bottom: -5px;
    border-top-color: #fff;
    border-top-color: #fff;
  }
  .rs-form-error-message-placement-top-end .rs-form-error-message-arrow::after {
    bottom: -5px;
    border-top-color: #fff;
    border-top-color: #fff;
  }
  .rs-form-error-message-placement-bottom-start,
  .rs-form-error-message-placement-top-start {
    left: 0;
  }
  .rs-form-error-message-placement-bottom-start .rs-form-error-message,
  .rs-form-error-message-placement-top-start .rs-form-error-message {
    left: 0;
  }
  .rs-form-error-message-placement-bottom-start .rs-form-error-message-arrow::before,
  .rs-form-error-message-placement-top-start .rs-form-error-message-arrow::before,
  .rs-form-error-message-placement-bottom-start .rs-form-error-message-arrow::after,
  .rs-form-error-message-placement-top-start .rs-form-error-message-arrow::after {
    left: 10px;
  }
  .rs-form-error-message-placement-bottom-end,
  .rs-form-error-message-placement-top-end {
    right: 0;
  }
  .rs-form-error-message-placement-bottom-end .rs-form-error-message,
  .rs-form-error-message-placement-top-end .rs-form-error-message {
    right: 0;
  }
  .rs-form-error-message-placement-bottom-end .rs-form-error-message-arrow::before,
  .rs-form-error-message-placement-top-end .rs-form-error-message-arrow::before,
  .rs-form-error-message-placement-bottom-end .rs-form-error-message-arrow::after,
  .rs-form-error-message-placement-top-end .rs-form-error-message-arrow::after {
    right: 10px;
  }
  /* rtl:begin:ignore */
  /* stylelint-disable-next-line */
  .rs-form-error-message-placement-left-start,
  .rs-form-error-message-placement-left-end {
    left: 0;
  }
  .rs-form-error-message-placement-left-start .rs-form-error-message,
  .rs-form-error-message-placement-left-end .rs-form-error-message {
    right: 0;
  }
  .rs-form-error-message-placement-left-start .rs-form-error-message-show,
  .rs-form-error-message-placement-left-end .rs-form-error-message-show {
    -webkit-animation-name: errorMessageSlideRightIn;
            animation-name: errorMessageSlideRightIn;
  }
  .rs-form-error-message-placement-left-start .rs-form-error-message-arrow::before {
    border-width: 6px 0 6px 6px;
    border-left-color: #e5e5ea;
    border-left-color: #e5e5ea;
    right: -6px;
  }
  .rs-form-error-message-placement-left-end .rs-form-error-message-arrow::before {
    border-width: 6px 0 6px 6px;
    border-left-color: #e5e5ea;
    border-left-color: #e5e5ea;
    right: -6px;
  }
  .rs-form-error-message-placement-left-start .rs-form-error-message-arrow::after {
    border-width: 6px 0 6px 6px;
    border-left-color: #e5e5ea;
    border-left-color: #e5e5ea;
    right: -6px;
  }
  .rs-form-error-message-placement-left-end .rs-form-error-message-arrow::after {
    border-width: 6px 0 6px 6px;
    border-left-color: #e5e5ea;
    border-left-color: #e5e5ea;
    right: -6px;
  }
  .rs-form-error-message-placement-left-start .rs-form-error-message-arrow::after {
    right: -5px;
    border-left-color: #fff;
    border-left-color: #fff;
  }
  .rs-form-error-message-placement-left-end .rs-form-error-message-arrow::after {
    right: -5px;
    border-left-color: #fff;
    border-left-color: #fff;
  }
  .rs-form-error-message-placement-right-start,
  .rs-form-error-message-placement-right-end {
    right: 0;
  }
  .rs-form-error-message-placement-right-start .rs-form-error-message,
  .rs-form-error-message-placement-right-end .rs-form-error-message {
    left: 0;
  }
  .rs-form-error-message-placement-right-start .rs-form-error-message-show,
  .rs-form-error-message-placement-right-end .rs-form-error-message-show {
    -webkit-animation-name: errorMessageSlideLeftIn;
            animation-name: errorMessageSlideLeftIn;
  }
  .rs-form-error-message-placement-right-start .rs-form-error-message-arrow::before {
    border-width: 6px 6px 6px 0;
    border-right-color: #e5e5ea;
    border-right-color: #e5e5ea;
    left: -6px;
  }
  .rs-form-error-message-placement-right-end .rs-form-error-message-arrow::before {
    border-width: 6px 6px 6px 0;
    border-right-color: #e5e5ea;
    border-right-color: #e5e5ea;
    left: -6px;
  }
  .rs-form-error-message-placement-right-start .rs-form-error-message-arrow::after {
    border-width: 6px 6px 6px 0;
    border-right-color: #e5e5ea;
    border-right-color: #e5e5ea;
    left: -6px;
  }
  .rs-form-error-message-placement-right-end .rs-form-error-message-arrow::after {
    border-width: 6px 6px 6px 0;
    border-right-color: #e5e5ea;
    border-right-color: #e5e5ea;
    left: -6px;
  }
  .rs-form-error-message-placement-right-start .rs-form-error-message-arrow::after {
    left: -5px;
    border-right-color: #fff;
    border-right-color: #fff;
  }
  .rs-form-error-message-placement-right-end .rs-form-error-message-arrow::after {
    left: -5px;
    border-right-color: #fff;
    border-right-color: #fff;
  }
  .rs-form-error-message-placement-left-start,
  .rs-form-error-message-placement-right-start {
    top: 0;
  }
  .rs-form-error-message-placement-left-start .rs-form-error-message,
  .rs-form-error-message-placement-right-start .rs-form-error-message {
    top: 0;
  }
  .rs-form-error-message-placement-left-start .rs-form-error-message-arrow::before,
  .rs-form-error-message-placement-right-start .rs-form-error-message-arrow::before,
  .rs-form-error-message-placement-left-start .rs-form-error-message-arrow::after,
  .rs-form-error-message-placement-right-start .rs-form-error-message-arrow::after {
    top: 4px;
  }
  .rs-form-error-message-placement-left-end,
  .rs-form-error-message-placement-right-end {
    bottom: 0;
  }
  .rs-form-error-message-placement-left-end .rs-form-error-message,
  .rs-form-error-message-placement-right-end .rs-form-error-message {
    bottom: 0;
  }
  .rs-form-error-message-placement-left-end .rs-form-error-message-arrow::before,
  .rs-form-error-message-placement-right-end .rs-form-error-message-arrow::before,
  .rs-form-error-message-placement-left-end .rs-form-error-message-arrow::after,
  .rs-form-error-message-placement-right-end .rs-form-error-message-arrow::after {
    bottom: 4px;
  }
  /* rtl:end:ignore */
  .rs-grid-container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 5px;
    padding-right: 5px;
  }
  .rs-grid-container::before,
  .rs-grid-container::after {
    content: ' ';
    display: table;
  }
  .rs-grid-container::after {
    clear: both;
  }
  @media (min-width: 576px) {
    .rs-grid-container {
      width: 550px;
    }
  }
  @media (min-width: 768px) {
    .rs-grid-container {
      width: 730px;
    }
  }
  @media (min-width: 992px) {
    .rs-grid-container {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .rs-grid-container {
      width: 970px;
    }
  }
  @media (min-width: 1400px) {
    .rs-grid-container {
      width: 970px;
    }
  }
  .rs-grid-container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 5px;
    padding-right: 5px;
  }
  .rs-grid-container-fluid::before,
  .rs-grid-container-fluid::after {
    content: ' ';
    display: table;
  }
  .rs-grid-container-fluid::after {
    clear: both;
  }
  .rs-col {
    float: left;
    position: relative;
    min-height: 1px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .rs-col-xs-1 {
    width: 4.16666667%;
  }
  .rs-col-xs-pull-1 {
    right: 4.16666667%;
  }
  .rs-col-xs-push-1 {
    left: 4.16666667%;
  }
  .rs-col-xs-offset-1 {
    margin-left: 4.16666667%;
  }
  .rs-col-xs-2 {
    width: 8.33333333%;
  }
  .rs-col-xs-pull-2 {
    right: 8.33333333%;
  }
  .rs-col-xs-push-2 {
    left: 8.33333333%;
  }
  .rs-col-xs-offset-2 {
    margin-left: 8.33333333%;
  }
  .rs-col-xs-3 {
    width: 12.5%;
  }
  .rs-col-xs-pull-3 {
    right: 12.5%;
  }
  .rs-col-xs-push-3 {
    left: 12.5%;
  }
  .rs-col-xs-offset-3 {
    margin-left: 12.5%;
  }
  .rs-col-xs-4 {
    width: 16.66666667%;
  }
  .rs-col-xs-pull-4 {
    right: 16.66666667%;
  }
  .rs-col-xs-push-4 {
    left: 16.66666667%;
  }
  .rs-col-xs-offset-4 {
    margin-left: 16.66666667%;
  }
  .rs-col-xs-5 {
    width: 20.83333333%;
  }
  .rs-col-xs-pull-5 {
    right: 20.83333333%;
  }
  .rs-col-xs-push-5 {
    left: 20.83333333%;
  }
  .rs-col-xs-offset-5 {
    margin-left: 20.83333333%;
  }
  .rs-col-xs-6 {
    width: 25%;
  }
  .rs-col-xs-pull-6 {
    right: 25%;
  }
  .rs-col-xs-push-6 {
    left: 25%;
  }
  .rs-col-xs-offset-6 {
    margin-left: 25%;
  }
  .rs-col-xs-7 {
    width: 29.16666667%;
  }
  .rs-col-xs-pull-7 {
    right: 29.16666667%;
  }
  .rs-col-xs-push-7 {
    left: 29.16666667%;
  }
  .rs-col-xs-offset-7 {
    margin-left: 29.16666667%;
  }
  .rs-col-xs-8 {
    width: 33.33333333%;
  }
  .rs-col-xs-pull-8 {
    right: 33.33333333%;
  }
  .rs-col-xs-push-8 {
    left: 33.33333333%;
  }
  .rs-col-xs-offset-8 {
    margin-left: 33.33333333%;
  }
  .rs-col-xs-9 {
    width: 37.5%;
  }
  .rs-col-xs-pull-9 {
    right: 37.5%;
  }
  .rs-col-xs-push-9 {
    left: 37.5%;
  }
  .rs-col-xs-offset-9 {
    margin-left: 37.5%;
  }
  .rs-col-xs-10 {
    width: 41.66666667%;
  }
  .rs-col-xs-pull-10 {
    right: 41.66666667%;
  }
  .rs-col-xs-push-10 {
    left: 41.66666667%;
  }
  .rs-col-xs-offset-10 {
    margin-left: 41.66666667%;
  }
  .rs-col-xs-11 {
    width: 45.83333333%;
  }
  .rs-col-xs-pull-11 {
    right: 45.83333333%;
  }
  .rs-col-xs-push-11 {
    left: 45.83333333%;
  }
  .rs-col-xs-offset-11 {
    margin-left: 45.83333333%;
  }
  .rs-col-xs-12 {
    width: 50%;
  }
  .rs-col-xs-pull-12 {
    right: 50%;
  }
  .rs-col-xs-push-12 {
    left: 50%;
  }
  .rs-col-xs-offset-12 {
    margin-left: 50%;
  }
  .rs-col-xs-13 {
    width: 54.16666667%;
  }
  .rs-col-xs-pull-13 {
    right: 54.16666667%;
  }
  .rs-col-xs-push-13 {
    left: 54.16666667%;
  }
  .rs-col-xs-offset-13 {
    margin-left: 54.16666667%;
  }
  .rs-col-xs-14 {
    width: 58.33333333%;
  }
  .rs-col-xs-pull-14 {
    right: 58.33333333%;
  }
  .rs-col-xs-push-14 {
    left: 58.33333333%;
  }
  .rs-col-xs-offset-14 {
    margin-left: 58.33333333%;
  }
  .rs-col-xs-15 {
    width: 62.5%;
  }
  .rs-col-xs-pull-15 {
    right: 62.5%;
  }
  .rs-col-xs-push-15 {
    left: 62.5%;
  }
  .rs-col-xs-offset-15 {
    margin-left: 62.5%;
  }
  .rs-col-xs-16 {
    width: 66.66666667%;
  }
  .rs-col-xs-pull-16 {
    right: 66.66666667%;
  }
  .rs-col-xs-push-16 {
    left: 66.66666667%;
  }
  .rs-col-xs-offset-16 {
    margin-left: 66.66666667%;
  }
  .rs-col-xs-17 {
    width: 70.83333333%;
  }
  .rs-col-xs-pull-17 {
    right: 70.83333333%;
  }
  .rs-col-xs-push-17 {
    left: 70.83333333%;
  }
  .rs-col-xs-offset-17 {
    margin-left: 70.83333333%;
  }
  .rs-col-xs-18 {
    width: 75%;
  }
  .rs-col-xs-pull-18 {
    right: 75%;
  }
  .rs-col-xs-push-18 {
    left: 75%;
  }
  .rs-col-xs-offset-18 {
    margin-left: 75%;
  }
  .rs-col-xs-19 {
    width: 79.16666667%;
  }
  .rs-col-xs-pull-19 {
    right: 79.16666667%;
  }
  .rs-col-xs-push-19 {
    left: 79.16666667%;
  }
  .rs-col-xs-offset-19 {
    margin-left: 79.16666667%;
  }
  .rs-col-xs-20 {
    width: 83.33333333%;
  }
  .rs-col-xs-pull-20 {
    right: 83.33333333%;
  }
  .rs-col-xs-push-20 {
    left: 83.33333333%;
  }
  .rs-col-xs-offset-20 {
    margin-left: 83.33333333%;
  }
  .rs-col-xs-21 {
    width: 87.5%;
  }
  .rs-col-xs-pull-21 {
    right: 87.5%;
  }
  .rs-col-xs-push-21 {
    left: 87.5%;
  }
  .rs-col-xs-offset-21 {
    margin-left: 87.5%;
  }
  .rs-col-xs-22 {
    width: 91.66666667%;
  }
  .rs-col-xs-pull-22 {
    right: 91.66666667%;
  }
  .rs-col-xs-push-22 {
    left: 91.66666667%;
  }
  .rs-col-xs-offset-22 {
    margin-left: 91.66666667%;
  }
  .rs-col-xs-23 {
    width: 95.83333333%;
  }
  .rs-col-xs-pull-23 {
    right: 95.83333333%;
  }
  .rs-col-xs-push-23 {
    left: 95.83333333%;
  }
  .rs-col-xs-offset-23 {
    margin-left: 95.83333333%;
  }
  .rs-col-xs-24 {
    width: 100%;
  }
  .rs-col-xs-pull-24 {
    right: 100%;
  }
  .rs-col-xs-push-24 {
    left: 100%;
  }
  .rs-col-xs-offset-24 {
    margin-left: 100%;
  }
  .rs-col-xs-pull-0 {
    right: auto;
  }
  .rs-col-xs-push-0 {
    left: auto;
  }
  @media (min-width: 576px) {
    .rs-col-sm-1 {
      width: 4.16666667%;
    }
    .rs-col-sm-pull-1 {
      right: 4.16666667%;
    }
    .rs-col-sm-push-1 {
      left: 4.16666667%;
    }
    .rs-col-sm-offset-1 {
      margin-left: 4.16666667%;
    }
    .rs-col-sm-2 {
      width: 8.33333333%;
    }
    .rs-col-sm-pull-2 {
      right: 8.33333333%;
    }
    .rs-col-sm-push-2 {
      left: 8.33333333%;
    }
    .rs-col-sm-offset-2 {
      margin-left: 8.33333333%;
    }
    .rs-col-sm-3 {
      width: 12.5%;
    }
    .rs-col-sm-pull-3 {
      right: 12.5%;
    }
    .rs-col-sm-push-3 {
      left: 12.5%;
    }
    .rs-col-sm-offset-3 {
      margin-left: 12.5%;
    }
    .rs-col-sm-4 {
      width: 16.66666667%;
    }
    .rs-col-sm-pull-4 {
      right: 16.66666667%;
    }
    .rs-col-sm-push-4 {
      left: 16.66666667%;
    }
    .rs-col-sm-offset-4 {
      margin-left: 16.66666667%;
    }
    .rs-col-sm-5 {
      width: 20.83333333%;
    }
    .rs-col-sm-pull-5 {
      right: 20.83333333%;
    }
    .rs-col-sm-push-5 {
      left: 20.83333333%;
    }
    .rs-col-sm-offset-5 {
      margin-left: 20.83333333%;
    }
    .rs-col-sm-6 {
      width: 25%;
    }
    .rs-col-sm-pull-6 {
      right: 25%;
    }
    .rs-col-sm-push-6 {
      left: 25%;
    }
    .rs-col-sm-offset-6 {
      margin-left: 25%;
    }
    .rs-col-sm-7 {
      width: 29.16666667%;
    }
    .rs-col-sm-pull-7 {
      right: 29.16666667%;
    }
    .rs-col-sm-push-7 {
      left: 29.16666667%;
    }
    .rs-col-sm-offset-7 {
      margin-left: 29.16666667%;
    }
    .rs-col-sm-8 {
      width: 33.33333333%;
    }
    .rs-col-sm-pull-8 {
      right: 33.33333333%;
    }
    .rs-col-sm-push-8 {
      left: 33.33333333%;
    }
    .rs-col-sm-offset-8 {
      margin-left: 33.33333333%;
    }
    .rs-col-sm-9 {
      width: 37.5%;
    }
    .rs-col-sm-pull-9 {
      right: 37.5%;
    }
    .rs-col-sm-push-9 {
      left: 37.5%;
    }
    .rs-col-sm-offset-9 {
      margin-left: 37.5%;
    }
    .rs-col-sm-10 {
      width: 41.66666667%;
    }
    .rs-col-sm-pull-10 {
      right: 41.66666667%;
    }
    .rs-col-sm-push-10 {
      left: 41.66666667%;
    }
    .rs-col-sm-offset-10 {
      margin-left: 41.66666667%;
    }
    .rs-col-sm-11 {
      width: 45.83333333%;
    }
    .rs-col-sm-pull-11 {
      right: 45.83333333%;
    }
    .rs-col-sm-push-11 {
      left: 45.83333333%;
    }
    .rs-col-sm-offset-11 {
      margin-left: 45.83333333%;
    }
    .rs-col-sm-12 {
      width: 50%;
    }
    .rs-col-sm-pull-12 {
      right: 50%;
    }
    .rs-col-sm-push-12 {
      left: 50%;
    }
    .rs-col-sm-offset-12 {
      margin-left: 50%;
    }
    .rs-col-sm-13 {
      width: 54.16666667%;
    }
    .rs-col-sm-pull-13 {
      right: 54.16666667%;
    }
    .rs-col-sm-push-13 {
      left: 54.16666667%;
    }
    .rs-col-sm-offset-13 {
      margin-left: 54.16666667%;
    }
    .rs-col-sm-14 {
      width: 58.33333333%;
    }
    .rs-col-sm-pull-14 {
      right: 58.33333333%;
    }
    .rs-col-sm-push-14 {
      left: 58.33333333%;
    }
    .rs-col-sm-offset-14 {
      margin-left: 58.33333333%;
    }
    .rs-col-sm-15 {
      width: 62.5%;
    }
    .rs-col-sm-pull-15 {
      right: 62.5%;
    }
    .rs-col-sm-push-15 {
      left: 62.5%;
    }
    .rs-col-sm-offset-15 {
      margin-left: 62.5%;
    }
    .rs-col-sm-16 {
      width: 66.66666667%;
    }
    .rs-col-sm-pull-16 {
      right: 66.66666667%;
    }
    .rs-col-sm-push-16 {
      left: 66.66666667%;
    }
    .rs-col-sm-offset-16 {
      margin-left: 66.66666667%;
    }
    .rs-col-sm-17 {
      width: 70.83333333%;
    }
    .rs-col-sm-pull-17 {
      right: 70.83333333%;
    }
    .rs-col-sm-push-17 {
      left: 70.83333333%;
    }
    .rs-col-sm-offset-17 {
      margin-left: 70.83333333%;
    }
    .rs-col-sm-18 {
      width: 75%;
    }
    .rs-col-sm-pull-18 {
      right: 75%;
    }
    .rs-col-sm-push-18 {
      left: 75%;
    }
    .rs-col-sm-offset-18 {
      margin-left: 75%;
    }
    .rs-col-sm-19 {
      width: 79.16666667%;
    }
    .rs-col-sm-pull-19 {
      right: 79.16666667%;
    }
    .rs-col-sm-push-19 {
      left: 79.16666667%;
    }
    .rs-col-sm-offset-19 {
      margin-left: 79.16666667%;
    }
    .rs-col-sm-20 {
      width: 83.33333333%;
    }
    .rs-col-sm-pull-20 {
      right: 83.33333333%;
    }
    .rs-col-sm-push-20 {
      left: 83.33333333%;
    }
    .rs-col-sm-offset-20 {
      margin-left: 83.33333333%;
    }
    .rs-col-sm-21 {
      width: 87.5%;
    }
    .rs-col-sm-pull-21 {
      right: 87.5%;
    }
    .rs-col-sm-push-21 {
      left: 87.5%;
    }
    .rs-col-sm-offset-21 {
      margin-left: 87.5%;
    }
    .rs-col-sm-22 {
      width: 91.66666667%;
    }
    .rs-col-sm-pull-22 {
      right: 91.66666667%;
    }
    .rs-col-sm-push-22 {
      left: 91.66666667%;
    }
    .rs-col-sm-offset-22 {
      margin-left: 91.66666667%;
    }
    .rs-col-sm-23 {
      width: 95.83333333%;
    }
    .rs-col-sm-pull-23 {
      right: 95.83333333%;
    }
    .rs-col-sm-push-23 {
      left: 95.83333333%;
    }
    .rs-col-sm-offset-23 {
      margin-left: 95.83333333%;
    }
    .rs-col-sm-24 {
      width: 100%;
    }
    .rs-col-sm-pull-24 {
      right: 100%;
    }
    .rs-col-sm-push-24 {
      left: 100%;
    }
    .rs-col-sm-offset-24 {
      margin-left: 100%;
    }
    .rs-col-sm-pull-0 {
      right: auto;
    }
    .rs-col-sm-push-0 {
      left: auto;
    }
  }
  @media (min-width: 768px) {
    .rs-col-md-1 {
      width: 4.16666667%;
    }
    .rs-col-md-pull-1 {
      right: 4.16666667%;
    }
    .rs-col-md-push-1 {
      left: 4.16666667%;
    }
    .rs-col-md-offset-1 {
      margin-left: 4.16666667%;
    }
    .rs-col-md-2 {
      width: 8.33333333%;
    }
    .rs-col-md-pull-2 {
      right: 8.33333333%;
    }
    .rs-col-md-push-2 {
      left: 8.33333333%;
    }
    .rs-col-md-offset-2 {
      margin-left: 8.33333333%;
    }
    .rs-col-md-3 {
      width: 12.5%;
    }
    .rs-col-md-pull-3 {
      right: 12.5%;
    }
    .rs-col-md-push-3 {
      left: 12.5%;
    }
    .rs-col-md-offset-3 {
      margin-left: 12.5%;
    }
    .rs-col-md-4 {
      width: 16.66666667%;
    }
    .rs-col-md-pull-4 {
      right: 16.66666667%;
    }
    .rs-col-md-push-4 {
      left: 16.66666667%;
    }
    .rs-col-md-offset-4 {
      margin-left: 16.66666667%;
    }
    .rs-col-md-5 {
      width: 20.83333333%;
    }
    .rs-col-md-pull-5 {
      right: 20.83333333%;
    }
    .rs-col-md-push-5 {
      left: 20.83333333%;
    }
    .rs-col-md-offset-5 {
      margin-left: 20.83333333%;
    }
    .rs-col-md-6 {
      width: 25%;
    }
    .rs-col-md-pull-6 {
      right: 25%;
    }
    .rs-col-md-push-6 {
      left: 25%;
    }
    .rs-col-md-offset-6 {
      margin-left: 25%;
    }
    .rs-col-md-7 {
      width: 29.16666667%;
    }
    .rs-col-md-pull-7 {
      right: 29.16666667%;
    }
    .rs-col-md-push-7 {
      left: 29.16666667%;
    }
    .rs-col-md-offset-7 {
      margin-left: 29.16666667%;
    }
    .rs-col-md-8 {
      width: 33.33333333%;
    }
    .rs-col-md-pull-8 {
      right: 33.33333333%;
    }
    .rs-col-md-push-8 {
      left: 33.33333333%;
    }
    .rs-col-md-offset-8 {
      margin-left: 33.33333333%;
    }
    .rs-col-md-9 {
      width: 37.5%;
    }
    .rs-col-md-pull-9 {
      right: 37.5%;
    }
    .rs-col-md-push-9 {
      left: 37.5%;
    }
    .rs-col-md-offset-9 {
      margin-left: 37.5%;
    }
    .rs-col-md-10 {
      width: 41.66666667%;
    }
    .rs-col-md-pull-10 {
      right: 41.66666667%;
    }
    .rs-col-md-push-10 {
      left: 41.66666667%;
    }
    .rs-col-md-offset-10 {
      margin-left: 41.66666667%;
    }
    .rs-col-md-11 {
      width: 45.83333333%;
    }
    .rs-col-md-pull-11 {
      right: 45.83333333%;
    }
    .rs-col-md-push-11 {
      left: 45.83333333%;
    }
    .rs-col-md-offset-11 {
      margin-left: 45.83333333%;
    }
    .rs-col-md-12 {
      width: 50%;
    }
    .rs-col-md-pull-12 {
      right: 50%;
    }
    .rs-col-md-push-12 {
      left: 50%;
    }
    .rs-col-md-offset-12 {
      margin-left: 50%;
    }
    .rs-col-md-13 {
      width: 54.16666667%;
    }
    .rs-col-md-pull-13 {
      right: 54.16666667%;
    }
    .rs-col-md-push-13 {
      left: 54.16666667%;
    }
    .rs-col-md-offset-13 {
      margin-left: 54.16666667%;
    }
    .rs-col-md-14 {
      width: 58.33333333%;
    }
    .rs-col-md-pull-14 {
      right: 58.33333333%;
    }
    .rs-col-md-push-14 {
      left: 58.33333333%;
    }
    .rs-col-md-offset-14 {
      margin-left: 58.33333333%;
    }
    .rs-col-md-15 {
      width: 62.5%;
    }
    .rs-col-md-pull-15 {
      right: 62.5%;
    }
    .rs-col-md-push-15 {
      left: 62.5%;
    }
    .rs-col-md-offset-15 {
      margin-left: 62.5%;
    }
    .rs-col-md-16 {
      width: 66.66666667%;
    }
    .rs-col-md-pull-16 {
      right: 66.66666667%;
    }
    .rs-col-md-push-16 {
      left: 66.66666667%;
    }
    .rs-col-md-offset-16 {
      margin-left: 66.66666667%;
    }
    .rs-col-md-17 {
      width: 70.83333333%;
    }
    .rs-col-md-pull-17 {
      right: 70.83333333%;
    }
    .rs-col-md-push-17 {
      left: 70.83333333%;
    }
    .rs-col-md-offset-17 {
      margin-left: 70.83333333%;
    }
    .rs-col-md-18 {
      width: 75%;
    }
    .rs-col-md-pull-18 {
      right: 75%;
    }
    .rs-col-md-push-18 {
      left: 75%;
    }
    .rs-col-md-offset-18 {
      margin-left: 75%;
    }
    .rs-col-md-19 {
      width: 79.16666667%;
    }
    .rs-col-md-pull-19 {
      right: 79.16666667%;
    }
    .rs-col-md-push-19 {
      left: 79.16666667%;
    }
    .rs-col-md-offset-19 {
      margin-left: 79.16666667%;
    }
    .rs-col-md-20 {
      width: 83.33333333%;
    }
    .rs-col-md-pull-20 {
      right: 83.33333333%;
    }
    .rs-col-md-push-20 {
      left: 83.33333333%;
    }
    .rs-col-md-offset-20 {
      margin-left: 83.33333333%;
    }
    .rs-col-md-21 {
      width: 87.5%;
    }
    .rs-col-md-pull-21 {
      right: 87.5%;
    }
    .rs-col-md-push-21 {
      left: 87.5%;
    }
    .rs-col-md-offset-21 {
      margin-left: 87.5%;
    }
    .rs-col-md-22 {
      width: 91.66666667%;
    }
    .rs-col-md-pull-22 {
      right: 91.66666667%;
    }
    .rs-col-md-push-22 {
      left: 91.66666667%;
    }
    .rs-col-md-offset-22 {
      margin-left: 91.66666667%;
    }
    .rs-col-md-23 {
      width: 95.83333333%;
    }
    .rs-col-md-pull-23 {
      right: 95.83333333%;
    }
    .rs-col-md-push-23 {
      left: 95.83333333%;
    }
    .rs-col-md-offset-23 {
      margin-left: 95.83333333%;
    }
    .rs-col-md-24 {
      width: 100%;
    }
    .rs-col-md-pull-24 {
      right: 100%;
    }
    .rs-col-md-push-24 {
      left: 100%;
    }
    .rs-col-md-offset-24 {
      margin-left: 100%;
    }
    .rs-col-md-pull-0 {
      right: auto;
    }
    .rs-col-md-push-0 {
      left: auto;
    }
  }
  @media (min-width: 992px) {
    .rs-col-lg-1 {
      width: 4.16666667%;
    }
    .rs-col-lg-pull-1 {
      right: 4.16666667%;
    }
    .rs-col-lg-push-1 {
      left: 4.16666667%;
    }
    .rs-col-lg-offset-1 {
      margin-left: 4.16666667%;
    }
    .rs-col-lg-2 {
      width: 8.33333333%;
    }
    .rs-col-lg-pull-2 {
      right: 8.33333333%;
    }
    .rs-col-lg-push-2 {
      left: 8.33333333%;
    }
    .rs-col-lg-offset-2 {
      margin-left: 8.33333333%;
    }
    .rs-col-lg-3 {
      width: 12.5%;
    }
    .rs-col-lg-pull-3 {
      right: 12.5%;
    }
    .rs-col-lg-push-3 {
      left: 12.5%;
    }
    .rs-col-lg-offset-3 {
      margin-left: 12.5%;
    }
    .rs-col-lg-4 {
      width: 16.66666667%;
    }
    .rs-col-lg-pull-4 {
      right: 16.66666667%;
    }
    .rs-col-lg-push-4 {
      left: 16.66666667%;
    }
    .rs-col-lg-offset-4 {
      margin-left: 16.66666667%;
    }
    .rs-col-lg-5 {
      width: 20.83333333%;
    }
    .rs-col-lg-pull-5 {
      right: 20.83333333%;
    }
    .rs-col-lg-push-5 {
      left: 20.83333333%;
    }
    .rs-col-lg-offset-5 {
      margin-left: 20.83333333%;
    }
    .rs-col-lg-6 {
      width: 25%;
    }
    .rs-col-lg-pull-6 {
      right: 25%;
    }
    .rs-col-lg-push-6 {
      left: 25%;
    }
    .rs-col-lg-offset-6 {
      margin-left: 25%;
    }
    .rs-col-lg-7 {
      width: 29.16666667%;
    }
    .rs-col-lg-pull-7 {
      right: 29.16666667%;
    }
    .rs-col-lg-push-7 {
      left: 29.16666667%;
    }
    .rs-col-lg-offset-7 {
      margin-left: 29.16666667%;
    }
    .rs-col-lg-8 {
      width: 33.33333333%;
    }
    .rs-col-lg-pull-8 {
      right: 33.33333333%;
    }
    .rs-col-lg-push-8 {
      left: 33.33333333%;
    }
    .rs-col-lg-offset-8 {
      margin-left: 33.33333333%;
    }
    .rs-col-lg-9 {
      width: 37.5%;
    }
    .rs-col-lg-pull-9 {
      right: 37.5%;
    }
    .rs-col-lg-push-9 {
      left: 37.5%;
    }
    .rs-col-lg-offset-9 {
      margin-left: 37.5%;
    }
    .rs-col-lg-10 {
      width: 41.66666667%;
    }
    .rs-col-lg-pull-10 {
      right: 41.66666667%;
    }
    .rs-col-lg-push-10 {
      left: 41.66666667%;
    }
    .rs-col-lg-offset-10 {
      margin-left: 41.66666667%;
    }
    .rs-col-lg-11 {
      width: 45.83333333%;
    }
    .rs-col-lg-pull-11 {
      right: 45.83333333%;
    }
    .rs-col-lg-push-11 {
      left: 45.83333333%;
    }
    .rs-col-lg-offset-11 {
      margin-left: 45.83333333%;
    }
    .rs-col-lg-12 {
      width: 50%;
    }
    .rs-col-lg-pull-12 {
      right: 50%;
    }
    .rs-col-lg-push-12 {
      left: 50%;
    }
    .rs-col-lg-offset-12 {
      margin-left: 50%;
    }
    .rs-col-lg-13 {
      width: 54.16666667%;
    }
    .rs-col-lg-pull-13 {
      right: 54.16666667%;
    }
    .rs-col-lg-push-13 {
      left: 54.16666667%;
    }
    .rs-col-lg-offset-13 {
      margin-left: 54.16666667%;
    }
    .rs-col-lg-14 {
      width: 58.33333333%;
    }
    .rs-col-lg-pull-14 {
      right: 58.33333333%;
    }
    .rs-col-lg-push-14 {
      left: 58.33333333%;
    }
    .rs-col-lg-offset-14 {
      margin-left: 58.33333333%;
    }
    .rs-col-lg-15 {
      width: 62.5%;
    }
    .rs-col-lg-pull-15 {
      right: 62.5%;
    }
    .rs-col-lg-push-15 {
      left: 62.5%;
    }
    .rs-col-lg-offset-15 {
      margin-left: 62.5%;
    }
    .rs-col-lg-16 {
      width: 66.66666667%;
    }
    .rs-col-lg-pull-16 {
      right: 66.66666667%;
    }
    .rs-col-lg-push-16 {
      left: 66.66666667%;
    }
    .rs-col-lg-offset-16 {
      margin-left: 66.66666667%;
    }
    .rs-col-lg-17 {
      width: 70.83333333%;
    }
    .rs-col-lg-pull-17 {
      right: 70.83333333%;
    }
    .rs-col-lg-push-17 {
      left: 70.83333333%;
    }
    .rs-col-lg-offset-17 {
      margin-left: 70.83333333%;
    }
    .rs-col-lg-18 {
      width: 75%;
    }
    .rs-col-lg-pull-18 {
      right: 75%;
    }
    .rs-col-lg-push-18 {
      left: 75%;
    }
    .rs-col-lg-offset-18 {
      margin-left: 75%;
    }
    .rs-col-lg-19 {
      width: 79.16666667%;
    }
    .rs-col-lg-pull-19 {
      right: 79.16666667%;
    }
    .rs-col-lg-push-19 {
      left: 79.16666667%;
    }
    .rs-col-lg-offset-19 {
      margin-left: 79.16666667%;
    }
    .rs-col-lg-20 {
      width: 83.33333333%;
    }
    .rs-col-lg-pull-20 {
      right: 83.33333333%;
    }
    .rs-col-lg-push-20 {
      left: 83.33333333%;
    }
    .rs-col-lg-offset-20 {
      margin-left: 83.33333333%;
    }
    .rs-col-lg-21 {
      width: 87.5%;
    }
    .rs-col-lg-pull-21 {
      right: 87.5%;
    }
    .rs-col-lg-push-21 {
      left: 87.5%;
    }
    .rs-col-lg-offset-21 {
      margin-left: 87.5%;
    }
    .rs-col-lg-22 {
      width: 91.66666667%;
    }
    .rs-col-lg-pull-22 {
      right: 91.66666667%;
    }
    .rs-col-lg-push-22 {
      left: 91.66666667%;
    }
    .rs-col-lg-offset-22 {
      margin-left: 91.66666667%;
    }
    .rs-col-lg-23 {
      width: 95.83333333%;
    }
    .rs-col-lg-pull-23 {
      right: 95.83333333%;
    }
    .rs-col-lg-push-23 {
      left: 95.83333333%;
    }
    .rs-col-lg-offset-23 {
      margin-left: 95.83333333%;
    }
    .rs-col-lg-24 {
      width: 100%;
    }
    .rs-col-lg-pull-24 {
      right: 100%;
    }
    .rs-col-lg-push-24 {
      left: 100%;
    }
    .rs-col-lg-offset-24 {
      margin-left: 100%;
    }
    .rs-col-lg-pull-0 {
      right: auto;
    }
    .rs-col-lg-push-0 {
      left: auto;
    }
  }
  @media (min-width: 1200px) {
    .rs-col-xl-1 {
      width: 4.16666667%;
    }
    .rs-col-xl-pull-1 {
      right: 4.16666667%;
    }
    .rs-col-xl-push-1 {
      left: 4.16666667%;
    }
    .rs-col-xl-offset-1 {
      margin-left: 4.16666667%;
    }
    .rs-col-xl-2 {
      width: 8.33333333%;
    }
    .rs-col-xl-pull-2 {
      right: 8.33333333%;
    }
    .rs-col-xl-push-2 {
      left: 8.33333333%;
    }
    .rs-col-xl-offset-2 {
      margin-left: 8.33333333%;
    }
    .rs-col-xl-3 {
      width: 12.5%;
    }
    .rs-col-xl-pull-3 {
      right: 12.5%;
    }
    .rs-col-xl-push-3 {
      left: 12.5%;
    }
    .rs-col-xl-offset-3 {
      margin-left: 12.5%;
    }
    .rs-col-xl-4 {
      width: 16.66666667%;
    }
    .rs-col-xl-pull-4 {
      right: 16.66666667%;
    }
    .rs-col-xl-push-4 {
      left: 16.66666667%;
    }
    .rs-col-xl-offset-4 {
      margin-left: 16.66666667%;
    }
    .rs-col-xl-5 {
      width: 20.83333333%;
    }
    .rs-col-xl-pull-5 {
      right: 20.83333333%;
    }
    .rs-col-xl-push-5 {
      left: 20.83333333%;
    }
    .rs-col-xl-offset-5 {
      margin-left: 20.83333333%;
    }
    .rs-col-xl-6 {
      width: 25%;
    }
    .rs-col-xl-pull-6 {
      right: 25%;
    }
    .rs-col-xl-push-6 {
      left: 25%;
    }
    .rs-col-xl-offset-6 {
      margin-left: 25%;
    }
    .rs-col-xl-7 {
      width: 29.16666667%;
    }
    .rs-col-xl-pull-7 {
      right: 29.16666667%;
    }
    .rs-col-xl-push-7 {
      left: 29.16666667%;
    }
    .rs-col-xl-offset-7 {
      margin-left: 29.16666667%;
    }
    .rs-col-xl-8 {
      width: 33.33333333%;
    }
    .rs-col-xl-pull-8 {
      right: 33.33333333%;
    }
    .rs-col-xl-push-8 {
      left: 33.33333333%;
    }
    .rs-col-xl-offset-8 {
      margin-left: 33.33333333%;
    }
    .rs-col-xl-9 {
      width: 37.5%;
    }
    .rs-col-xl-pull-9 {
      right: 37.5%;
    }
    .rs-col-xl-push-9 {
      left: 37.5%;
    }
    .rs-col-xl-offset-9 {
      margin-left: 37.5%;
    }
    .rs-col-xl-10 {
      width: 41.66666667%;
    }
    .rs-col-xl-pull-10 {
      right: 41.66666667%;
    }
    .rs-col-xl-push-10 {
      left: 41.66666667%;
    }
    .rs-col-xl-offset-10 {
      margin-left: 41.66666667%;
    }
    .rs-col-xl-11 {
      width: 45.83333333%;
    }
    .rs-col-xl-pull-11 {
      right: 45.83333333%;
    }
    .rs-col-xl-push-11 {
      left: 45.83333333%;
    }
    .rs-col-xl-offset-11 {
      margin-left: 45.83333333%;
    }
    .rs-col-xl-12 {
      width: 50%;
    }
    .rs-col-xl-pull-12 {
      right: 50%;
    }
    .rs-col-xl-push-12 {
      left: 50%;
    }
    .rs-col-xl-offset-12 {
      margin-left: 50%;
    }
    .rs-col-xl-13 {
      width: 54.16666667%;
    }
    .rs-col-xl-pull-13 {
      right: 54.16666667%;
    }
    .rs-col-xl-push-13 {
      left: 54.16666667%;
    }
    .rs-col-xl-offset-13 {
      margin-left: 54.16666667%;
    }
    .rs-col-xl-14 {
      width: 58.33333333%;
    }
    .rs-col-xl-pull-14 {
      right: 58.33333333%;
    }
    .rs-col-xl-push-14 {
      left: 58.33333333%;
    }
    .rs-col-xl-offset-14 {
      margin-left: 58.33333333%;
    }
    .rs-col-xl-15 {
      width: 62.5%;
    }
    .rs-col-xl-pull-15 {
      right: 62.5%;
    }
    .rs-col-xl-push-15 {
      left: 62.5%;
    }
    .rs-col-xl-offset-15 {
      margin-left: 62.5%;
    }
    .rs-col-xl-16 {
      width: 66.66666667%;
    }
    .rs-col-xl-pull-16 {
      right: 66.66666667%;
    }
    .rs-col-xl-push-16 {
      left: 66.66666667%;
    }
    .rs-col-xl-offset-16 {
      margin-left: 66.66666667%;
    }
    .rs-col-xl-17 {
      width: 70.83333333%;
    }
    .rs-col-xl-pull-17 {
      right: 70.83333333%;
    }
    .rs-col-xl-push-17 {
      left: 70.83333333%;
    }
    .rs-col-xl-offset-17 {
      margin-left: 70.83333333%;
    }
    .rs-col-xl-18 {
      width: 75%;
    }
    .rs-col-xl-pull-18 {
      right: 75%;
    }
    .rs-col-xl-push-18 {
      left: 75%;
    }
    .rs-col-xl-offset-18 {
      margin-left: 75%;
    }
    .rs-col-xl-19 {
      width: 79.16666667%;
    }
    .rs-col-xl-pull-19 {
      right: 79.16666667%;
    }
    .rs-col-xl-push-19 {
      left: 79.16666667%;
    }
    .rs-col-xl-offset-19 {
      margin-left: 79.16666667%;
    }
    .rs-col-xl-20 {
      width: 83.33333333%;
    }
    .rs-col-xl-pull-20 {
      right: 83.33333333%;
    }
    .rs-col-xl-push-20 {
      left: 83.33333333%;
    }
    .rs-col-xl-offset-20 {
      margin-left: 83.33333333%;
    }
    .rs-col-xl-21 {
      width: 87.5%;
    }
    .rs-col-xl-pull-21 {
      right: 87.5%;
    }
    .rs-col-xl-push-21 {
      left: 87.5%;
    }
    .rs-col-xl-offset-21 {
      margin-left: 87.5%;
    }
    .rs-col-xl-22 {
      width: 91.66666667%;
    }
    .rs-col-xl-pull-22 {
      right: 91.66666667%;
    }
    .rs-col-xl-push-22 {
      left: 91.66666667%;
    }
    .rs-col-xl-offset-22 {
      margin-left: 91.66666667%;
    }
    .rs-col-xl-23 {
      width: 95.83333333%;
    }
    .rs-col-xl-pull-23 {
      right: 95.83333333%;
    }
    .rs-col-xl-push-23 {
      left: 95.83333333%;
    }
    .rs-col-xl-offset-23 {
      margin-left: 95.83333333%;
    }
    .rs-col-xl-24 {
      width: 100%;
    }
    .rs-col-xl-pull-24 {
      right: 100%;
    }
    .rs-col-xl-push-24 {
      left: 100%;
    }
    .rs-col-xl-offset-24 {
      margin-left: 100%;
    }
    .rs-col-xl-pull-0 {
      right: auto;
    }
    .rs-col-xl-push-0 {
      left: auto;
    }
  }
  @media (min-width: 1400px) {
    .rs-col-xxl-1 {
      width: 4.16666667%;
    }
    .rs-col-xxl-pull-1 {
      right: 4.16666667%;
    }
    .rs-col-xxl-push-1 {
      left: 4.16666667%;
    }
    .rs-col-xxl-offset-1 {
      margin-left: 4.16666667%;
    }
    .rs-col-xxl-2 {
      width: 8.33333333%;
    }
    .rs-col-xxl-pull-2 {
      right: 8.33333333%;
    }
    .rs-col-xxl-push-2 {
      left: 8.33333333%;
    }
    .rs-col-xxl-offset-2 {
      margin-left: 8.33333333%;
    }
    .rs-col-xxl-3 {
      width: 12.5%;
    }
    .rs-col-xxl-pull-3 {
      right: 12.5%;
    }
    .rs-col-xxl-push-3 {
      left: 12.5%;
    }
    .rs-col-xxl-offset-3 {
      margin-left: 12.5%;
    }
    .rs-col-xxl-4 {
      width: 16.66666667%;
    }
    .rs-col-xxl-pull-4 {
      right: 16.66666667%;
    }
    .rs-col-xxl-push-4 {
      left: 16.66666667%;
    }
    .rs-col-xxl-offset-4 {
      margin-left: 16.66666667%;
    }
    .rs-col-xxl-5 {
      width: 20.83333333%;
    }
    .rs-col-xxl-pull-5 {
      right: 20.83333333%;
    }
    .rs-col-xxl-push-5 {
      left: 20.83333333%;
    }
    .rs-col-xxl-offset-5 {
      margin-left: 20.83333333%;
    }
    .rs-col-xxl-6 {
      width: 25%;
    }
    .rs-col-xxl-pull-6 {
      right: 25%;
    }
    .rs-col-xxl-push-6 {
      left: 25%;
    }
    .rs-col-xxl-offset-6 {
      margin-left: 25%;
    }
    .rs-col-xxl-7 {
      width: 29.16666667%;
    }
    .rs-col-xxl-pull-7 {
      right: 29.16666667%;
    }
    .rs-col-xxl-push-7 {
      left: 29.16666667%;
    }
    .rs-col-xxl-offset-7 {
      margin-left: 29.16666667%;
    }
    .rs-col-xxl-8 {
      width: 33.33333333%;
    }
    .rs-col-xxl-pull-8 {
      right: 33.33333333%;
    }
    .rs-col-xxl-push-8 {
      left: 33.33333333%;
    }
    .rs-col-xxl-offset-8 {
      margin-left: 33.33333333%;
    }
    .rs-col-xxl-9 {
      width: 37.5%;
    }
    .rs-col-xxl-pull-9 {
      right: 37.5%;
    }
    .rs-col-xxl-push-9 {
      left: 37.5%;
    }
    .rs-col-xxl-offset-9 {
      margin-left: 37.5%;
    }
    .rs-col-xxl-10 {
      width: 41.66666667%;
    }
    .rs-col-xxl-pull-10 {
      right: 41.66666667%;
    }
    .rs-col-xxl-push-10 {
      left: 41.66666667%;
    }
    .rs-col-xxl-offset-10 {
      margin-left: 41.66666667%;
    }
    .rs-col-xxl-11 {
      width: 45.83333333%;
    }
    .rs-col-xxl-pull-11 {
      right: 45.83333333%;
    }
    .rs-col-xxl-push-11 {
      left: 45.83333333%;
    }
    .rs-col-xxl-offset-11 {
      margin-left: 45.83333333%;
    }
    .rs-col-xxl-12 {
      width: 50%;
    }
    .rs-col-xxl-pull-12 {
      right: 50%;
    }
    .rs-col-xxl-push-12 {
      left: 50%;
    }
    .rs-col-xxl-offset-12 {
      margin-left: 50%;
    }
    .rs-col-xxl-13 {
      width: 54.16666667%;
    }
    .rs-col-xxl-pull-13 {
      right: 54.16666667%;
    }
    .rs-col-xxl-push-13 {
      left: 54.16666667%;
    }
    .rs-col-xxl-offset-13 {
      margin-left: 54.16666667%;
    }
    .rs-col-xxl-14 {
      width: 58.33333333%;
    }
    .rs-col-xxl-pull-14 {
      right: 58.33333333%;
    }
    .rs-col-xxl-push-14 {
      left: 58.33333333%;
    }
    .rs-col-xxl-offset-14 {
      margin-left: 58.33333333%;
    }
    .rs-col-xxl-15 {
      width: 62.5%;
    }
    .rs-col-xxl-pull-15 {
      right: 62.5%;
    }
    .rs-col-xxl-push-15 {
      left: 62.5%;
    }
    .rs-col-xxl-offset-15 {
      margin-left: 62.5%;
    }
    .rs-col-xxl-16 {
      width: 66.66666667%;
    }
    .rs-col-xxl-pull-16 {
      right: 66.66666667%;
    }
    .rs-col-xxl-push-16 {
      left: 66.66666667%;
    }
    .rs-col-xxl-offset-16 {
      margin-left: 66.66666667%;
    }
    .rs-col-xxl-17 {
      width: 70.83333333%;
    }
    .rs-col-xxl-pull-17 {
      right: 70.83333333%;
    }
    .rs-col-xxl-push-17 {
      left: 70.83333333%;
    }
    .rs-col-xxl-offset-17 {
      margin-left: 70.83333333%;
    }
    .rs-col-xxl-18 {
      width: 75%;
    }
    .rs-col-xxl-pull-18 {
      right: 75%;
    }
    .rs-col-xxl-push-18 {
      left: 75%;
    }
    .rs-col-xxl-offset-18 {
      margin-left: 75%;
    }
    .rs-col-xxl-19 {
      width: 79.16666667%;
    }
    .rs-col-xxl-pull-19 {
      right: 79.16666667%;
    }
    .rs-col-xxl-push-19 {
      left: 79.16666667%;
    }
    .rs-col-xxl-offset-19 {
      margin-left: 79.16666667%;
    }
    .rs-col-xxl-20 {
      width: 83.33333333%;
    }
    .rs-col-xxl-pull-20 {
      right: 83.33333333%;
    }
    .rs-col-xxl-push-20 {
      left: 83.33333333%;
    }
    .rs-col-xxl-offset-20 {
      margin-left: 83.33333333%;
    }
    .rs-col-xxl-21 {
      width: 87.5%;
    }
    .rs-col-xxl-pull-21 {
      right: 87.5%;
    }
    .rs-col-xxl-push-21 {
      left: 87.5%;
    }
    .rs-col-xxl-offset-21 {
      margin-left: 87.5%;
    }
    .rs-col-xxl-22 {
      width: 91.66666667%;
    }
    .rs-col-xxl-pull-22 {
      right: 91.66666667%;
    }
    .rs-col-xxl-push-22 {
      left: 91.66666667%;
    }
    .rs-col-xxl-offset-22 {
      margin-left: 91.66666667%;
    }
    .rs-col-xxl-23 {
      width: 95.83333333%;
    }
    .rs-col-xxl-pull-23 {
      right: 95.83333333%;
    }
    .rs-col-xxl-push-23 {
      left: 95.83333333%;
    }
    .rs-col-xxl-offset-23 {
      margin-left: 95.83333333%;
    }
    .rs-col-xxl-24 {
      width: 100%;
    }
    .rs-col-xxl-pull-24 {
      right: 100%;
    }
    .rs-col-xxl-push-24 {
      left: 100%;
    }
    .rs-col-xxl-offset-24 {
      margin-left: 100%;
    }
    .rs-col-xxl-pull-0 {
      right: auto;
    }
    .rs-col-xxl-push-0 {
      left: auto;
    }
  }
  @media (max-width: 575px) {
    .rs-hidden-xs {
      display: none;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .rs-hidden-sm {
      display: none;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .rs-hidden-md {
      display: none;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .rs-hidden-lg {
      display: none;
    }
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    .rs-hidden-xl {
      display: none;
    }
  }
  @media (min-width: 1400px) {
    .rs-hidden-xxl {
      display: none;
    }
  }
  .rs-header {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
  .rs-form-help-text {
    display: block;
    color: #8e8e93;
    color: #8e8e93;
    min-height: 20px;
    line-height: 1.66666667;
    font-size: 12px;
  }
  .rs-form-help-text-tooltip {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: 10px;
    margin-top: 8px;
  }
  .rs-btn-icon > .rs-icon {
    vertical-align: bottom;
  }
  .rs-btn-icon-with-text > .rs-icon {
    position: absolute;
    top: 0;
    display: block;
    background-color: #f2f2f5;
    background-color: #f2f2f5;
    -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
    transition: color 0.15s ease-out, background-color 0.15s ease-out;
  }
  .rs-btn-icon-with-text:hover > .rs-icon {
    background-color: #d9d9d9;
    background-color: #d9d9d9;
  }
  .rs-btn-icon-with-text:focus > .rs-icon {
    background-color: #d9d9d9;
    background-color: #d9d9d9;
  }
  .rs-btn-icon-with-text:active > .rs-icon {
    background-color: #c5c6c7;
    background-color: #c5c6c7;
  }
  .rs-btn-icon-with-text.rs-btn-active > .rs-icon {
    background-color: #c5c6c7;
    background-color: #c5c6c7;
  }
  .rs-btn-icon-with-text:disabled > .rs-icon {
    background-color: #f2f2f5;
    background-color: #f2f2f5;
  }
  .rs-btn-icon-with-text.rs-btn-disabled > .rs-icon {
    background-color: #f2f2f5;
    background-color: #f2f2f5;
  }
  .rs-theme-high-contrast .rs-btn-icon-with-text:disabled,
  .rs-theme-high-contrast .rs-btn-icon-with-text.rs-btn-disabled {
    opacity: 0.5;
  }
  .rs-btn-icon-with-text.rs-btn-primary > .rs-icon {
    background-color: #2589f5;
    background-color: #2589f5;
  }
  .rs-btn-icon-with-text.rs-btn-primary:hover > .rs-icon {
    background-color: #1675e0;
    background-color: #1675e0;
  }
  .rs-btn-icon-with-text.rs-btn-primary:focus > .rs-icon {
    background-color: #1675e0;
    background-color: #1675e0;
  }
  .rs-btn-icon-with-text.rs-btn-primary:active > .rs-icon {
    background-color: #0a5dc2;
    background-color: #0a5dc2;
  }
  .rs-btn-icon-with-text.rs-btn-primary.rs-btn-active > .rs-icon {
    background-color: #0a5dc2;
    background-color: #0a5dc2;
  }
  .rs-btn-icon-with-text.rs-btn-primary:disabled > .rs-icon {
    background-color: #2589f5;
    background-color: #2589f5;
  }
  .rs-btn-icon-with-text.rs-btn-primary.rs-btn-disabled > .rs-icon {
    background-color: #2589f5;
    background-color: #2589f5;
  }
  .rs-theme-high-contrast .rs-btn-icon-with-text.rs-btn-primary:disabled,
  .rs-theme-high-contrast .rs-btn-icon-with-text.rs-btn-primary.rs-btn-disabled {
    opacity: 0.5;
  }
  .rs-btn-icon-circle {
    border-radius: 50% !important;
  }
  .rs-input {
    display: block;
    width: 100%;
    color: #575757;
    color: #575757;
    background-color: #fff;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e5e5ea;
    border: 1px solid #e5e5ea;
    -webkit-transition: border-color ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s;
    border-radius: 6px;
    /* stylelint-disable */
    padding: 7px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    /* stylelint-enable */
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input {
      height: 36px;
    }
  }
  textarea.rs-input {
    height: auto;
  }
  .rs-theme-high-contrast .rs-input {
    -webkit-transition: none;
    transition: none;
  }
  .rs-input::-webkit-input-placeholder {
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-input::-moz-placeholder {
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-input::-ms-input-placeholder {
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-input::placeholder {
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-input:focus {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-input:hover:not(:disabled) {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-input:focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
  }
  .rs-theme-high-contrast .rs-input:focus {
    outline-offset: 2px;
  }
  .rs-input:disabled {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #c5c6c7;
    color: #c5c6c7;
  }
  textarea.rs-input {
    overflow: auto;
    resize: vertical;
  }
  .rs-input-xs {
    /* stylelint-disable */
    padding: 1px 11px;
    font-size: 12px;
    line-height: 1.66666667;
    /* stylelint-enable */
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-xs {
      height: 24px;
    }
  }
  textarea.rs-input-xs {
    height: auto;
  }
  .rs-input-lg {
    /* stylelint-disable */
    padding: 9px 11px;
    font-size: 16px;
    line-height: 1.375;
    /* stylelint-enable */
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-lg {
      height: 42px;
    }
  }
  textarea.rs-input-lg {
    height: auto;
  }
  .rs-input-sm {
    /* stylelint-disable */
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    /* stylelint-enable */
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-sm {
      height: 30px;
    }
  }
  textarea.rs-input-sm {
    height: auto;
  }
  .rs-input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 6px;
    -webkit-transition: border-color ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s;
    width: 100%;
    cursor: text;
    color: #575757;
    color: #575757;
    border: 1px solid #e5e5ea;
    border: 1px solid #e5e5ea;
  }
  .rs-theme-high-contrast .rs-input-group {
    -webkit-transition: none;
    transition: none;
  }
  .rs-input-group:not(.rs-input-group-disabled):hover {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-input-group:focus-within {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
  }
  .rs-input-group:focus-within {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
  }
  .rs-theme-high-contrast .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
  .rs-theme-high-contrast .rs-input-group:focus-within {
    outline-offset: 2px;
  }
  .rs-input-group .rs-input-group {
    outline: none !important;
  }
  .rs-input-group .rs-input-number,
  .rs-input-group .rs-input-group-addon,
  .rs-input-group .rs-input-group-btn,
  .rs-input-group .rs-picker-date .rs-picker-toggle {
    border: none;
    border-radius: 0;
    outline: none;
  }
  .rs-input-group:not(.rs-input-group-inside) .rs-input {
    border: none;
    border-radius: 0;
    outline: none;
  }
  .rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-btn-group-vertical {
    border-radius: 0;
  }
  .rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-touchspin-up {
    border-top-right-radius: 0;
  }
  .rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-touchspin-down {
    border-bottom-right-radius: 0;
  }
  .rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:first-child) .rs-input {
    border-radius: 0 !important;
  }
  .rs-input-group:not(.rs-input-group-inside) > :first-child,
  .rs-input-group:not(.rs-input-group-inside) .rs-auto-complete:first-child .rs-input,
  .rs-input-group:not(.rs-input-group-inside) .rs-picker-date:first-child .rs-picker-toggle,
  .rs-input-group:not(.rs-input-group-inside) .rs-form-control-wrapper:first-child > .rs-input {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .rs-input-group:not(.rs-input-group-inside) > :last-child,
  .rs-input-group:not(.rs-input-group-inside) .rs-auto-complete:last-child .rs-input,
  .rs-input-group:not(.rs-input-group-inside) .rs-picker-date:last-child .rs-picker-toggle,
  .rs-input-group:not(.rs-input-group-inside) .rs-form-control-wrapper:last-child > .rs-input {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  .rs-input-group > .rs-input,
  .rs-input-group > .rs-form-control-wrapper,
  .rs-input-group > .rs-auto-complete {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }
  .rs-input-group .rs-form-control-wrapper > .rs-input {
    width: 100%;
  }
  .rs-input-group > .rs-input,
  .rs-input-group > .rs-auto-complete {
    position: relative;
    z-index: 2;
  }
  .rs-input-group > .rs-input:focus {
    z-index: 3;
  }
  .rs-input-group input.rs-input ~ .rs-input-group-addon,
  .rs-input-group .rs-form-control-wrapper ~ .rs-input-group-addon {
    border-left: none;
    left: auto;
    right: 0;
  }
  .rs-input-group input.rs-input ~ .rs-input-group-addon:not(:last-child),
  .rs-input-group .rs-form-control-wrapper ~ .rs-input-group-addon:not(:last-child) {
    border-right: 0;
  }
  .rs-input-group.rs-input-group-inside {
    width: 100%;
  }
  .rs-input-group.rs-input-group-inside .rs-input {
    display: block;
    width: 100%;
    border: none;
    outline: none;
    padding-right: 36px;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn,
  .rs-input-group.rs-input-group-inside .rs-input-group-addon {
    z-index: 4;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn {
    color: #8e8e93;
    color: #8e8e93;
    background-color: transparent;
    border: none;
    color: inherit;
    position: absolute;
    height: 34px;
    padding: 7px 11px;
    border-radius: 0 6px 6px 0;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:hover {
    color: #575757;
    color: #575757;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:focus {
    color: #575757;
    color: #575757;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:active {
    color: #272c36;
    color: #272c36;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-active {
    color: #272c36;
    color: #272c36;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-theme-high-contrast .rs-input-group.rs-input-group-inside .rs-input-group-btn:disabled,
  .rs-theme-high-contrast .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled {
    opacity: 0.5;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn > .rs-icon {
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:focus {
    outline: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:first-child {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:last-child {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-addon {
    top: 0;
    background: none;
    border: none;
    padding: 10px 12px;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-addon.rs-input-group-btn {
    padding: 8px 13px;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn ~ input.rs-input,
  .rs-input-group.rs-input-group-inside .rs-input-group-addon ~ input.rs-input,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn ~ .rs-auto-complete > input.rs-input,
  .rs-input-group.rs-input-group-inside .rs-input-group-addon ~ .rs-auto-complete > input.rs-input,
  .rs-input-group.rs-input-group-inside .rs-input-group-addon ~ .rs-form-control-wrapper > input.rs-input,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn ~ .rs-form-control-wrapper > input.rs-input {
    padding-left: 0;
    padding-right: 12px;
  }
  .rs-input-group.rs-input-group-inside input.rs-input ~ .rs-input-group-btn,
  .rs-input-group.rs-input-group-inside .rs-auto-complete ~ .rs-input-group-addon,
  .rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-addon,
  .rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-btn {
    left: inherit;
    right: 0;
  }
  .rs-input-group.rs-input-group-inside .rs-auto-complete ~ .rs-input-group-btn,
  .rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-btn {
    left: inherit;
    right: 1px;
  }
  .rs-input-group-disabled {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #c5c6c7;
    color: #c5c6c7;
    cursor: not-allowed;
  }
  .rs-input-group-disabled .rs-input,
  .rs-input-group-disabled .rs-input-group-btn,
  .rs-input-group-disabled .rs-input-group-addon {
    color: inherit;
  }
  .rs-input-group-lg > .rs-input {
    /* stylelint-disable */
    padding: 9px 11px;
    font-size: 16px;
    line-height: 1.375;
    /* stylelint-enable */
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-lg > .rs-input {
      height: 42px;
    }
  }
  textarea.rs-input-group-lg > .rs-input {
    height: auto;
  }
  .rs-input-group-lg.rs-input-group-inside > .rs-input {
    padding-right: 46px;
  }
  .rs-input-group-lg.rs-input-group > .rs-input {
    height: 40px;
  }
  .rs-input-group-lg.rs-input-group > .rs-input-group-addon {
    height: 40px;
  }
  .rs-input-group-lg.rs-input-group > .rs-input-group-addon > .rs-icon {
    font-size: inherit;
  }
  .rs-input-group-lg.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .rs-input-group-md > .rs-input {
    /* stylelint-disable */
    padding: 7px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    /* stylelint-enable */
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-md > .rs-input {
      height: 36px;
    }
  }
  textarea.rs-input-group-md > .rs-input {
    height: auto;
  }
  .rs-input-group-md.rs-input-group-inside > .rs-input {
    padding-right: 36px;
  }
  .rs-input-group-md.rs-input-group > .rs-input {
    height: 34px;
  }
  .rs-input-group-md.rs-input-group > .rs-input-group-addon {
    height: 34px;
  }
  .rs-input-group-md.rs-input-group > .rs-input-group-addon > .rs-icon {
    font-size: inherit;
  }
  .rs-input-group-md.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .rs-input-group-sm > .rs-input {
    /* stylelint-disable */
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    /* stylelint-enable */
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-sm > .rs-input {
      height: 30px;
    }
  }
  textarea.rs-input-group-sm > .rs-input {
    height: auto;
  }
  .rs-input-group-sm.rs-input-group-inside > .rs-input {
    padding-right: 30px;
  }
  .rs-input-group-sm.rs-input-group > .rs-input {
    height: 28px;
  }
  .rs-input-group-sm.rs-input-group > .rs-input-group-addon {
    height: 28px;
  }
  .rs-input-group-sm.rs-input-group > .rs-input-group-addon > .rs-icon {
    font-size: inherit;
  }
  .rs-input-group-sm.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .rs-input-group-xs > .rs-input {
    /* stylelint-disable */
    padding: 1px 11px;
    font-size: 12px;
    line-height: 1.66666667;
    /* stylelint-enable */
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-xs > .rs-input {
      height: 24px;
    }
  }
  textarea.rs-input-group-xs > .rs-input {
    height: auto;
  }
  .rs-input-group-xs.rs-input-group-inside > .rs-input {
    padding-right: 26px;
  }
  .rs-input-group-xs.rs-input-group > .rs-input {
    height: 22px;
  }
  .rs-input-group-xs.rs-input-group > .rs-input-group-addon {
    height: 22px;
  }
  .rs-input-group-xs.rs-input-group > .rs-input-group-addon > .rs-icon {
    font-size: inherit;
  }
  .rs-input-group.rs-input-group-lg > .rs-input-group-addon {
    /* stylelint-disable */
    padding: 9px 11px;
    font-size: 16px;
    line-height: 1.375;
    /* stylelint-enable */
    min-width: 44px;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group.rs-input-group-lg > .rs-input-group-addon {
      height: 42px;
    }
  }
  textarea.rs-input-group.rs-input-group-lg > .rs-input-group-addon {
    height: auto;
  }
  .rs-input-group.rs-input-group-md > .rs-input-group-addon {
    /* stylelint-disable */
    padding: 7px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    /* stylelint-enable */
    min-width: 36px;
    padding-left: 11px;
    padding-right: 11px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group.rs-input-group-md > .rs-input-group-addon {
      height: 36px;
    }
  }
  textarea.rs-input-group.rs-input-group-md > .rs-input-group-addon {
    height: auto;
  }
  .rs-input-group.rs-input-group-sm > .rs-input-group-addon {
    /* stylelint-disable */
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    /* stylelint-enable */
    min-width: 32px;
    padding-left: 9px;
    padding-right: 9px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group.rs-input-group-sm > .rs-input-group-addon {
      height: 30px;
    }
  }
  textarea.rs-input-group.rs-input-group-sm > .rs-input-group-addon {
    height: auto;
  }
  .rs-input-group.rs-input-group-xs > .rs-input-group-addon {
    /* stylelint-disable */
    padding: 1px 11px;
    font-size: 12px;
    line-height: 1.66666667;
    /* stylelint-enable */
    min-width: 28px;
    padding-left: 7px;
    padding-right: 7px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group.rs-input-group-xs > .rs-input-group-addon {
      height: 24px;
    }
  }
  textarea.rs-input-group.rs-input-group-xs > .rs-input-group-addon {
    height: auto;
  }
  .rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
    /* stylelint-disable */
    padding: 9px 11px;
    font-size: 16px;
    line-height: 1.375;
    /* stylelint-enable */
    height: 40px;
    padding: 9px 15px;
    border-radius: 0 6px 6px 0;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
      height: 42px;
    }
  }
  textarea.rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
    height: auto;
  }
  .rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn > .rs-icon {
    font-size: 16px;
    line-height: 1.375;
  }
  .rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
    /* stylelint-disable */
    font-size: 14px;
    line-height: 1.42857143;
    /* stylelint-enable */
    height: 34px;
    padding: 7px 11px;
    border-radius: 0 6px 6px 0;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
      height: 36px;
    }
  }
  textarea.rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
    height: auto;
  }
  .rs-input-group-inside.rs-input-group-md > .rs-input-group-btn > .rs-icon {
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
    /* stylelint-disable */
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    /* stylelint-enable */
    height: 28px;
    padding: 4px 9px;
    border-radius: 0 6px 6px 0;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
      height: 30px;
    }
  }
  textarea.rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
    height: auto;
  }
  .rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn > .rs-icon {
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
    /* stylelint-disable */
    padding: 1px 11px;
    font-size: 12px;
    line-height: 1.66666667;
    /* stylelint-enable */
    height: 22px;
    padding: 1px 7px;
    border-radius: 0 6px 6px 0;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
      height: 24px;
    }
  }
  textarea.rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
    height: auto;
  }
  .rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn > .rs-icon {
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-input-group-addon:not(:first-child):not(:last-child),
  .rs-input-group-btn:not(:first-child):not(:last-child),
  .rs-input-group:not(.rs-input-group-inside) .rs-input:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .rs-input-group-addon {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    white-space: nowrap;
    vertical-align: middle;
    color: #575757;
    color: #575757;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    text-align: center;
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    border: 1px solid #e5e5ea;
    border: 1px solid #e5e5ea;
    border-radius: 6px;
  }
  .rs-input-group-addon.rs-input-sm {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 6px;
  }
  .rs-input-group-addon.rs-input-xs {
    padding: 8px 8px;
    font-size: 12px;
    border-radius: 6px;
  }
  .rs-input-group-addon.rs-input-lg {
    padding: 10px 16px;
    font-size: 16px;
    border-radius: 6px;
  }
  .rs-input-group-addon input[type='radio'],
  .rs-input-group-addon input[type='checkbox'] {
    margin-top: 0;
  }
  .rs-input-group-btn {
    position: relative;
    line-height: 1;
    white-space: nowrap;
    border-radius: 0;
  }
  .rs-input-group-btn > .rs-btn {
    position: relative;
  }
  .rs-input-group-btn > .rs-btn + .rs-btn {
    margin-left: -1px;
  }
  .rs-input-group-btn > .rs-btn:hover,
  .rs-input-group-btn > .rs-btn:focus,
  .rs-input-group-btn > .rs-btn:active {
    z-index: 2;
  }
  .rs-input-group-btn:first-child > .rs-btn,
  .rs-input-group-btn:first-child > .rs-btn-group {
    margin-right: -1px;
    border-right: medium none;
  }
  .rs-input-group-btn:last-child > .rs-btn,
  .rs-input-group-btn:last-child > .rs-btn-group {
    z-index: 2;
    margin-left: -1px;
  }
  .rs-input-number {
    background-color: #fff;
    background-color: #fff;
  }
  .rs-input-number-btn-group-vertical {
    position: relative;
    width: 20px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20px;
            flex: 0 0 20px;
    display: block;
  }
  .rs-input-number-btn-group-vertical > .rs-btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    position: relative;
    border: none;
  }
  .rs-input-number-touchspin-up,
  .rs-input-number-touchspin-down {
    position: relative;
    border-radius: 0;
    padding: 0;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    height: 17px;
  }
  .rs-input-number-touchspin-up > .rs-icon,
  .rs-input-number-touchspin-down > .rs-icon {
    height: 17px;
  }
  .rs-input-group-lg .rs-input-number-touchspin-up,
  .rs-input-group-lg .rs-input-number-touchspin-down {
    height: 20px;
  }
  .rs-input-group-lg .rs-input-number-touchspin-up > .rs-icon,
  .rs-input-group-lg .rs-input-number-touchspin-down > .rs-icon {
    height: 20px;
  }
  .rs-input-group-sm .rs-input-number-touchspin-up,
  .rs-input-group-sm .rs-input-number-touchspin-down {
    height: 14px;
  }
  .rs-input-group-sm .rs-input-number-touchspin-up > .rs-icon,
  .rs-input-group-sm .rs-input-number-touchspin-down > .rs-icon {
    height: 14px;
  }
  .rs-input-group-xs .rs-input-number-touchspin-up,
  .rs-input-group-xs .rs-input-number-touchspin-down {
    height: 11px;
  }
  .rs-input-group-xs .rs-input-number-touchspin-up > .rs-icon,
  .rs-input-group-xs .rs-input-number-touchspin-down > .rs-icon {
    height: 11px;
  }
  .rs-input-number-touchspin-up > .rs-icon,
  .rs-input-number-touchspin-down > .rs-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .rs-input-number-btn-group-vertical:last-child .rs-input-number-touchspin-up {
    border-top-right-radius: 6px;
  }
  .rs-input-number-btn-group-vertical:last-child .rs-input-number-touchspin-down {
    border-bottom-right-radius: 6px;
  }
  .rs-input-number > .rs-input {
    border-bottom-left-radius: 6px !important;
    border-top-left-radius: 6px !important;
  }
  .rs-input-number > .rs-input:disabled + .rs-input-number-btn-group-vertical .rs-input-number-touchspin-up {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-input-number > .rs-input:disabled + .rs-input-number-btn-group-vertical .rs-input-number-touchspin-down {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-input-number input[type='number'] {
    -webkit-appearance: textfield;
       -moz-appearance: textfield;
            appearance: textfield;
  }
  .rs-input-number input[type='number']::-webkit-inner-spin-button {
    /* stylelint-disable */
    -webkit-appearance: none;
  }
  .rs-picker-input {
    position: relative;
    border: 1px solid #e5e5ea;
    border: 1px solid #e5e5ea;
    border-radius: 6px;
    -webkit-transition: border-color ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s;
    background-color: #fff;
    background-color: #fff;
  }
  .rs-picker-input .rs-picker-toggle {
    border: none !important;
    background: transparent !important;
    height: 34px;
    cursor: text;
  }
  .rs-picker-input .rs-picker-toggle-clean,
  .rs-picker-input .rs-picker-toggle-caret {
    top: 7px;
  }
  .rs-picker-input .rs-picker-toggle .rs-ripple-pond {
    display: none;
  }
  .rs-picker-input:not(.rs-picker-disabled) .rs-picker-toggle {
    position: absolute !important;
  }
  .rs-picker-input .rs-picker-toggle.rs-btn-lg {
    height: 40px;
  }
  .rs-picker-input .rs-picker-toggle.rs-btn-md {
    height: 34px;
  }
  .rs-picker-input .rs-picker-toggle.rs-btn-sm {
    height: 28px;
  }
  .rs-picker-input .rs-picker-toggle.rs-btn-xs {
    height: 22px;
  }
  .rs-picker-tag-wrapper {
    margin-right: 32px;
    min-height: 34px;
  }
  .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper {
    min-height: 40px;
  }
  .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper {
    min-height: 28px;
  }
  .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper {
    min-height: 22px;
  }
  .rs-picker-cleanable .rs-picker-tag-wrapper {
    margin-right: 44px;
  }
  .rs-picker-search {
    border: none;
    width: 100%;
  }
  .rs-picker-search-input {
    /* stylelint-disable */
    padding: 7px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    /* stylelint-enable */
    background: none;
    outline: none;
    border: none;
    width: 100%;
    position: relative;
    padding-right: 0;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-picker-search-input {
      height: 36px;
    }
  }
  textarea.rs-picker-search-input {
    height: auto;
  }
  .rs-picker-tag .rs-picker-search-input {
    font-size: 14px;
    line-height: 1.42857143;
    padding: 2px 12px 2px 11px;
  }
  .rs-picker-tag .rs-picker-search-input > input {
    background: none;
    outline: none;
    border: none;
    width: 100%;
  }
  .rs-picker-focused .rs-picker-search-input {
    z-index: 6;
  }
  .rs-list {
    position: relative;
    -webkit-box-shadow: 0 1px 0 #e5e5ea, 0 -1px 0 #e5e5ea;
    -webkit-box-shadow: 0 1px 0 #e5e5ea, 0 -1px 0 #e5e5ea;
            box-shadow: 0 1px 0 #e5e5ea, 0 -1px 0 #e5e5ea;
            box-shadow: 0 1px 0 #e5e5ea, 0 -1px 0 #e5e5ea;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .rs-list-bordered {
    border-radius: 6px;
    -webkit-box-shadow: 0 0 0 1px #e5e5ea;
    -webkit-box-shadow: 0 0 0 1px #e5e5ea;
            box-shadow: 0 0 0 1px #e5e5ea;
            box-shadow: 0 0 0 1px #e5e5ea;
  }
  .rs-list-hover .rs-list-item:hover {
    background-color: #f2faff;
    background-color: #f2faff;
  }
  .rs-list-sortable .rs-list-item {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }
  .rs-list-sortable .rs-list-item-disabled,
  .rs-list-sortable .rs-list-item-disabled:active {
    cursor: not-allowed;
  }
  .rs-list-item {
    position: relative;
  }
  .rs-list-item-bordered {
    padding-left: 20px;
    padding-right: 20px;
  }
  .rs-list-item-lg {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .rs-list-item-md {
    padding-top: 13px;
    padding-bottom: 13px;
  }
  .rs-list-item-sm {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  /* rtl:begin:ignore */
  /* stylelint-disable-next-line */
  .rs-list-item {
    background-color: #fff;
    background-color: #fff;
    -webkit-box-shadow: 0 -1px 0 #e5e5ea, 0 1px 0 #e5e5ea;
    -webkit-box-shadow: 0 -1px 0 #e5e5ea, 0 1px 0 #e5e5ea;
            box-shadow: 0 -1px 0 #e5e5ea, 0 1px 0 #e5e5ea;
            box-shadow: 0 -1px 0 #e5e5ea, 0 1px 0 #e5e5ea;
  }
  .rs-list-item-helper {
    position: absolute;
    background-color: #fff;
    background-color: #fff;
    -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
            box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    width: calc(100% - 2px);
    border-radius: 6px;
    top: 0;
    left: 0;
    z-index: 99;
    border: none;
    cursor: move;
  }
  .rs-modal-open .rs-list-item-helper {
    z-index: 1149;
  }
  .rs-drawer-open .rs-list-item-helper {
    z-index: 1149;
  }
  .rs-list-item-holder {
    background-color: rgba(242, 250, 255, 0.5);
    background-color: rgba(242, 250, 255, 0.5);
    visibility: hidden;
  }
  .rs-list-item-holder::after {
    content: '';
    position: absolute;
    visibility: visible;
    top: 1px;
    left: 1px;
    width: calc(100% - 2 * 1px);
    height: calc(100% - 2 * 1px);
    border: 1px #3498ff dashed;
    border: 1px #3498ff dashed;
  }
  /* rtl:end:ignore */
  .rs-loader {
    display: inline-block;
  }
  .rs-loader::before,
  .rs-loader::after {
    content: ' ';
    display: table;
  }
  .rs-loader::after {
    clear: both;
  }
  .rs-loader .rs-loader-spin {
    width: 18px;
    height: 18px;
  }
  .rs-loader .rs-loader-spin::before,
  .rs-loader .rs-loader-spin::after {
    width: 18px;
    height: 18px;
  }
  .rs-loader .rs-loader-content {
    font-size: 12px;
    line-height: 1.5;
  }
  .rs-loader .rs-loader-vertical .rs-loader .rs-loader {
    height: 48px;
  }
  .rs-loader .rs-loader-vertical .rs-loader .rs-loader-content {
    line-height: 1.66666667;
  }
  .rs-loader-spin {
    position: relative;
    display: inline-block;
    float: left;
  }
  .rs-loader-spin::before,
  .rs-loader-spin::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    border-radius: 50%;
  }
  .rs-loader-spin::before {
    border: 3px solid rgba(247, 247, 250, 0.8);
    border: 3px solid rgba(247, 247, 250, 0.8);
  }
  .rs-loader-spin::after {
    border-width: 3px;
    border-style: solid;
    border-color: #a6a6a6 transparent transparent;
    border-color: #a6a6a6 transparent transparent;
    -webkit-animation: loaderSpin 0.6s infinite linear;
            animation: loaderSpin 0.6s infinite linear;
  }
  .rs-loader-content {
    float: left;
    display: inline-block;
  }
  .rs-loader-spin + .rs-loader-content {
    margin-left: 12px;
  }
  .rs-loader-xs .rs-loader-spin + .rs-loader-content {
    margin-left: 10px;
  }
  .rs-loader-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    background: rgba(255, 255, 255, 0.9);
  }
  .rs-loader-vertical {
    width: auto;
    display: inline-block;
  }
  .rs-loader-vertical .rs-loader {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .rs-loader-vertical .rs-loader-spin {
    margin: 0 auto;
  }
  .rs-loader-vertical .rs-loader-content {
    line-height: 1.66666667;
    text-align: center;
  }
  .rs-loader-vertical .rs-loader-spin,
  .rs-loader-vertical .rs-loader-content {
    display: block;
    float: none;
  }
  .rs-loader-vertical .rs-loader-spin + .rs-loader-content {
    margin-left: 0;
    margin-top: 10px;
  }
  .rs-loader-inverse .rs-loader-content {
    color: #f7f7fa;
    color: #f7f7fa;
  }
  .rs-loader-inverse .rs-loader-backdrop {
    background: rgba(39, 44, 54, 0.83);
    background: rgba(39, 44, 54, 0.83);
  }
  .rs-loader-inverse .rs-loader-spin::before {
    border-color: rgba(247, 247, 250, 0.3);
    border-color: rgba(247, 247, 250, 0.3);
  }
  .rs-loader-inverse .rs-loader-spin::after {
    border-top-color: #fff;
    border-top-color: #fff;
  }
  .rs-loader-speed-fast .rs-loader-spin::after {
    -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
  }
  .rs-loader-speed-normal .rs-loader-spin::after {
    -webkit-animation-duration: 0.6s;
            animation-duration: 0.6s;
  }
  .rs-loader-speed-slow .rs-loader-spin::after {
    -webkit-animation-duration: 0.8s;
            animation-duration: 0.8s;
  }
  .rs-loader-center,
  .rs-loader-backdrop-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
  }
  .rs-loader-center .rs-loader,
  .rs-loader-backdrop-wrapper .rs-loader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 100%;
  }
  .rs-loader-center:not(.rs-loader-vertical) .rs-loader,
  .rs-loader-backdrop-wrapper:not(.rs-loader-vertical) .rs-loader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rs-loader-center.rs-loader-vertical .rs-loader,
  .rs-loader-backdrop-wrapper.rs-loader-vertical .rs-loader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 100%;
  }
  .rs-loader-center .rs-loader-content,
  .rs-loader-backdrop-wrapper .rs-loader-content {
    z-index: 1;
  }
  .rs-loader-lg .rs-loader-spin {
    width: 64px;
    height: 64px;
  }
  .rs-loader-lg .rs-loader-spin::before,
  .rs-loader-lg .rs-loader-spin::after {
    width: 64px;
    height: 64px;
  }
  .rs-loader-lg .rs-loader-content {
    font-size: 16px;
    line-height: 4;
  }
  .rs-loader-lg .rs-loader-vertical .rs-loader-lg .rs-loader {
    height: 94px;
  }
  .rs-loader-lg .rs-loader-vertical .rs-loader-lg .rs-loader-content {
    line-height: 1.25;
  }
  .rs-loader-md .rs-loader-spin {
    width: 36px;
    height: 36px;
  }
  .rs-loader-md .rs-loader-spin::before,
  .rs-loader-md .rs-loader-spin::after {
    width: 36px;
    height: 36px;
  }
  .rs-loader-md .rs-loader-content {
    font-size: 14px;
    line-height: 2.57142857;
  }
  .rs-loader-md .rs-loader-vertical .rs-loader-md .rs-loader {
    height: 66px;
  }
  .rs-loader-md .rs-loader-vertical .rs-loader-md .rs-loader-content {
    line-height: 1.42857143;
  }
  .rs-loader-sm .rs-loader-spin {
    width: 18px;
    height: 18px;
  }
  .rs-loader-sm .rs-loader-spin::before,
  .rs-loader-sm .rs-loader-spin::after {
    width: 18px;
    height: 18px;
  }
  .rs-loader-sm .rs-loader-content {
    font-size: 14px;
    line-height: 1.28571429;
  }
  .rs-loader-sm .rs-loader-vertical .rs-loader-sm .rs-loader {
    height: 48px;
  }
  .rs-loader-sm .rs-loader-vertical .rs-loader-sm .rs-loader-content {
    line-height: 1.42857143;
  }
  .rs-loader-xs .rs-loader-spin {
    width: 16px;
    height: 16px;
  }
  .rs-loader-xs .rs-loader-spin::before,
  .rs-loader-xs .rs-loader-spin::after {
    width: 16px;
    height: 16px;
  }
  .rs-loader-xs .rs-loader-content {
    font-size: 12px;
    line-height: 1.33333333;
  }
  .rs-loader-xs .rs-loader-vertical .rs-loader-xs .rs-loader {
    height: 46px;
  }
  .rs-loader-xs .rs-loader-vertical .rs-loader-xs .rs-loader-content {
    line-height: 1.66666667;
  }
  .rs-loader-wrapper {
    display: inline-block;
    width: auto;
    line-height: 0;
  }
  @-webkit-keyframes loaderSpin {
    from {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes loaderSpin {
    from {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @-webkit-keyframes notificationMoveIn {
    0% {
      opacity: 0;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
    }
    100% {
      opacity: 1;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
  }
  @keyframes notificationMoveIn {
    0% {
      opacity: 0;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
    }
    100% {
      opacity: 1;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
  }
  @-webkit-keyframes notificationMoveInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @keyframes notificationMoveInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @-webkit-keyframes notificationMoveInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @keyframes notificationMoveInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @-webkit-keyframes notificationMoveOut {
    0% {
      opacity: 1;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      max-height: 100px;
    }
    100% {
      opacity: 0;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      max-height: 0;
      overflow: hidden;
    }
  }
  @keyframes notificationMoveOut {
    0% {
      opacity: 1;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      max-height: 100px;
    }
    100% {
      opacity: 0;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      max-height: 0;
      overflow: hidden;
    }
  }
  .rs-message {
    border-radius: 6px;
    font-size: 14px;
    line-height: 1.42857143;
    position: relative;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  .rs-message-container {
    padding: 20px;
    padding-right: 28px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rs-message-icon-wrapper {
    -ms-flex-item-align: center;
        align-self: center;
    font-size: 0;
    margin-right: 10px;
  }
  .rs-message-icon-wrapper .rs-icon {
    font-size: 12px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .rs-message-content {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
  .rs-message-header {
    color: #272c36;
    color: #272c36;
    line-height: 1.71428571;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-message-header + .rs-message-body {
    margin-top: 4px;
  }
  .rs-message-body {
    color: #575757;
    color: #575757;
  }
  .rs-message.rs-message-has-title .rs-message-icon-wrapper {
    -ms-flex-item-align: start;
        align-self: flex-start;
  }
  .rs-message.rs-message-has-title .rs-message-icon-wrapper .rs-icon {
    font-size: 24px;
    line-height: 1;
  }
  .rs-message:not(.rs-message-hiding) + .rs-message {
    margin-top: 10px;
  }
  .rs-message:not(.rs-message-hiding) + .rs-message.rs-message-hiding:last-child {
    -webkit-transition: margin-top 0.1s linear 0.3s;
    transition: margin-top 0.1s linear 0.3s;
    margin-top: 0;
  }
  .rs-message.rs-message-hiding {
    -webkit-animation: notificationMoveOut 0.3s ease-in forwards;
            animation: notificationMoveOut 0.3s ease-in forwards;
  }
  .rs-theme-dark .rs-message .rs-btn-link,
  .rs-theme-dark .rs-message a {
    color: inherit;
    text-decoration: underline;
  }
  .rs-message-full {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
  }
  .rs-message-success {
    background-color: #eeffed;
    background-color: #eeffed;
  }
  .rs-message-success .rs-message-header {
    color: #272c36;
    color: #272c36;
  }
  .rs-message-success .rs-message-body {
    color: #575757;
    color: #575757;
  }
  .rs-message-success .rs-message-icon-wrapper > .rs-icon {
    color: #4caf50;
    color: #4caf50;
  }
  .rs-message-success .rs-btn-close {
    color: #4caf50;
    color: #4caf50;
  }
  .rs-theme-high-contrast .rs-message-success {
    border: 1px solid #82cf82;
  }
  .rs-message-info {
    background-color: #f0f9ff;
    background-color: #f0f9ff;
  }
  .rs-message-info .rs-message-header {
    color: #272c36;
    color: #272c36;
  }
  .rs-message-info .rs-message-body {
    color: #575757;
    color: #575757;
  }
  .rs-message-info .rs-message-icon-wrapper > .rs-icon {
    color: #2196f3;
    color: #2196f3;
  }
  .rs-message-info .rs-btn-close {
    color: #2196f3;
    color: #2196f3;
  }
  .rs-theme-high-contrast .rs-message-info {
    border: 1px solid #2196f3;
  }
  .rs-message-warning {
    background-color: #fffaf2;
    background-color: #fffaf2;
  }
  .rs-message-warning .rs-message-header {
    color: #272c36;
    color: #272c36;
  }
  .rs-message-warning .rs-message-body {
    color: #575757;
    color: #575757;
  }
  .rs-message-warning .rs-message-icon-wrapper > .rs-icon {
    color: #ffb300;
    color: #ffb300;
  }
  .rs-message-warning .rs-btn-close {
    color: #ffb300;
    color: #ffb300;
  }
  .rs-theme-high-contrast .rs-message-warning {
    border: 1px solid #ffb300;
  }
  .rs-message-error {
    background-color: #fff2f2;
    background-color: #fff2f2;
  }
  .rs-message-error .rs-message-header {
    color: #272c36;
    color: #272c36;
  }
  .rs-message-error .rs-message-body {
    color: #575757;
    color: #575757;
  }
  .rs-message-error .rs-message-icon-wrapper > .rs-icon {
    color: #f44336;
    color: #f44336;
  }
  .rs-message-error .rs-btn-close {
    color: #f44336;
    color: #f44336;
  }
  .rs-theme-high-contrast .rs-message-error {
    border: 1px solid #fa8682;
  }
  .rs-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1049;
    background-color: rgba(39, 44, 54, 0.3);
    background-color: rgba(39, 44, 54, 0.3);
  }
  .rs-modal-backdrop.rs-anim-fade {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in;
  }
  .rs-modal-backdrop.rs-anim-in {
    opacity: 1;
  }
  .rs-modal-open {
    overflow: hidden;
  }
  .rs-modal-wrapper {
    position: fixed;
    overflow: auto;
    z-index: 1050;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .rs-modal {
    display: none;
    overflow: visible;
    outline: 0;
    margin: 30px auto 0 auto;
    position: relative;
    width: auto;
    z-index: 1050;
  }
  .rs-modal-lg {
    width: 968px;
    max-width: calc(100% - 10px);
  }
  .rs-modal-md {
    width: 800px;
    max-width: calc(100% - 10px);
  }
  .rs-modal-sm {
    width: 600px;
    max-width: calc(100% - 10px);
  }
  .rs-modal-xs {
    width: 400px;
    max-width: calc(100% - 10px);
  }
  .rs-modal-full {
    width: calc(100% - 120px);
    max-width: calc(100% - 10px);
  }
  .rs-modal-shake .rs-modal-dialog {
    -webkit-animation: 0.3s linear shakeHead;
            animation: 0.3s linear shakeHead;
  }
  .rs-modal-content {
    position: relative;
    background-color: #fff;
    background-color: #fff;
    border: none;
    border: none;
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    padding: 20px;
  }
  .rs-modal-header {
    padding-right: 20px;
  }
  .rs-modal-header::before,
  .rs-modal-header::after {
    content: ' ';
    display: table;
  }
  .rs-modal-header::after {
    clear: both;
  }
  .rs-modal-header .rs-modal-header-close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 12px;
    color: #8e8e93;
    color: #8e8e93;
    padding: 0;
  }
  .rs-modal-header .rs-modal-header-close:hover {
    color: #575757;
    color: #575757;
  }
  .rs-modal-title {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.25;
    display: block;
    color: #272c36;
    color: #272c36;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-modal-body {
    position: relative;
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .rs-modal-footer {
    text-align: right;
    border-top: none;
  }
  .rs-modal-footer::before,
  .rs-modal-footer::after {
    content: ' ';
    display: table;
  }
  .rs-modal-footer::after {
    clear: both;
  }
  .rs-modal-footer .rs-btn + .rs-btn {
    margin-left: 10px;
    margin-bottom: 0;
  }
  .rs-modal-footer .rs-btn-group .rs-btn + .rs-btn {
    margin-left: -1px;
  }
  .rs-modal-footer .rs-btn-block + .rs-btn-block {
    margin-left: 0;
  }
  .rs-modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
  .rs-picker-cascader-menu .rs-check-item .rs-checkbox-checker > label {
    padding-right: 26px;
  }
  .rs-picker-cascader-menu .rs-check-item.rs-check-item-focus .rs-checkbox-checker > label,
  .rs-picker-cascader-menu .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
    font-weight: bold;
  }
  .rs-picker-cascader-menu-column.rs-picker-cascader-menu-column-uncheckable .rs-check-item .rs-checkbox-checker > label {
    padding-left: 12px;
  }
  .rs-picker-multi-cascader-menu .rs-picker-cascader-col {
    padding: 0;
  }
  .rs-picker-multi-cascader-menu .rs-picker-cascader-row {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .rs-nav {
    position: relative;
    outline: 0;
  }
  .rs-nav .rs-dropdown > .rs-dropdown-toggle {
    height: 36px;
    vertical-align: bottom;
  }
  .rs-nav-default .rs-nav-item,
  .rs-nav-tabs .rs-nav-item {
    overflow: hidden;
    position: relative;
    -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
    transition: color 0.15s ease-out, background-color 0.15s ease-out;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-nav-default .rs-nav-item,
    .rs-nav-tabs .rs-nav-item {
      /* stylelint-disable */
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      /* stylelint-enable */
    }
  }
  .rs-nav-item {
    padding: 8px 12px;
    cursor: pointer;
    color: #8e8e93;
    color: #8e8e93;
    -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
    transition: color 0.15s ease-out, background-color 0.15s ease-out;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    outline: none;
    text-decoration: none;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-nav-item {
      /* stylelint-disable */
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      /* stylelint-enable */
    }
  }
  .rs-nav-item:hover,
  .rs-nav-item:focus,
  .rs-nav-item:active {
    text-decoration: none;
  }
  .rs-theme-high-contrast .rs-nav-item {
    -webkit-transition: none;
    transition: none;
  }
  .rs-nav-item:hover {
    color: #575757;
    color: #575757;
  }
  .rs-nav-item:focus {
    color: #575757;
    color: #575757;
  }
  .rs-nav-item.rs-nav-item-focus {
    color: #575757;
    color: #575757;
  }
  .rs-theme-high-contrast .rs-nav-item:hover,
  .rs-theme-high-contrast .rs-nav-item:focus,
  .rs-theme-high-contrast .rs-nav-item.rs-nav-item-focus {
    text-decoration: underline;
  }
  .rs-nav-item:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline-width: 2px;
    outline-offset: 0;
    z-index: 2;
  }
  .rs-theme-high-contrast .rs-nav-item:focus-visible {
    outline-offset: 2px;
  }
  .rs-nav-item:active {
    color: #272c36;
    color: #272c36;
  }
  .rs-theme-high-contrast .rs-nav-item:active {
    text-decoration: underline;
  }
  .rs-nav-item.rs-nav-item-disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-nav-item.rs-nav-item-active {
    position: relative;
    color: #1675e0;
    color: #1675e0;
    z-index: 1;
  }
  .rs-theme-high-contrast .rs-nav-item.rs-nav-item-active {
    text-decoration: underline;
  }
  .rs-nav-item-icon {
    margin-right: 6px;
  }
  .rs-nav-item-caret {
    font-size: 16px;
    vertical-align: text-bottom;
    margin-left: 6px;
  }
  .rs-nav-horizontal {
    white-space: nowrap;
  }
  .rs-nav-horizontal > .rs-nav-item {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    vertical-align: top;
  }
  .rs-nav-horizontal .rs-nav-bar {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .rs-nav-reversed.rs-nav-horizontal .rs-nav-bar {
    bottom: auto;
    top: 0;
  }
  .rs-nav-vertical > .rs-nav-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .rs-nav-vertical > .rs-dropdown {
    width: 100%;
  }
  .rs-nav-vertical > .rs-dropdown > .rs-dropdown-toggle {
    width: 100%;
    text-align: left;
    z-index: 0;
  }
  .rs-nav-vertical .rs-nav-bar {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }
  .rs-nav-reversed.rs-nav-vertical .rs-nav-bar {
    right: auto;
  }
  .rs-nav-default .rs-nav-item {
    border-radius: 6px;
  }
  .rs-nav-default .rs-nav-item:hover {
    background: #e5e5ea;
    background: #e5e5ea;
  }
  .rs-nav-default .rs-nav-item:focus {
    background: #e5e5ea;
    background: #e5e5ea;
  }
  .rs-nav-tabs .rs-nav-item:hover {
    background: #e5e5ea;
    background: #e5e5ea;
  }
  .rs-nav-tabs .rs-nav-item:focus {
    background: #e5e5ea;
    background: #e5e5ea;
  }
  .rs-nav-tabs .rs-nav-item.rs-nav-item-active {
    border: 1px solid #d9d9d9;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    background-color: #fff;
    z-index: 1;
  }
  .rs-nav-tabs.rs-nav-horizontal > .rs-nav-item,
  .rs-nav-tabs.rs-nav-horizontal > .rs-dropdown .rs-dropdown-toggle {
    border-radius: 6px 6px 0 0;
  }
  .rs-nav-reversed.rs-nav-tabs.rs-nav-horizontal > .rs-nav-item,
  .rs-nav-reversed.rs-nav-tabs.rs-nav-horizontal > .rs-dropdown .rs-dropdown-toggle {
    border-radius: 0 0 6px 6px;
  }
  .rs-nav-tabs.rs-nav-horizontal .rs-nav-bar {
    border-top: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
  }
  .rs-nav-tabs.rs-nav-horizontal .rs-nav-item.rs-nav-item-active {
    border-bottom-width: 0;
  }
  .rs-nav-reversed.rs-nav-tabs.rs-nav-horizontal .rs-nav-item.rs-nav-item-active {
    border-bottom-width: 1px;
    border-top-width: 0;
  }
  .rs-nav-tabs.rs-nav-vertical > .rs-nav-item,
  .rs-nav-tabs.rs-nav-vertical > .rs-dropdown .rs-dropdown-toggle {
    border-radius: 6px 0 0 6px;
  }
  .rs-nav-reversed.rs-nav-tabs.rs-nav-vertical > .rs-nav-item,
  .rs-nav-reversed.rs-nav-tabs.rs-nav-vertical > .rs-dropdown .rs-dropdown-toggle {
    border-radius: 0 6px 6px 0;
  }
  .rs-nav-tabs.rs-nav-vertical .rs-nav-bar {
    width: 1px;
    background: #d9d9d9;
    background: #d9d9d9;
  }
  .rs-nav-tabs.rs-nav-vertical .rs-nav-item.rs-nav-item-active {
    border-right-width: 0;
  }
  .rs-nav-reversed.rs-nav-tabs.rs-nav-vertical .rs-nav-item.rs-nav-item-active {
    border-right-width: 1px;
    border-left-width: 0;
  }
  .rs-nav-subtle .rs-nav-item {
    position: relative;
  }
  .rs-nav-subtle .rs-nav-item:hover {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-nav-subtle .rs-nav-item:focus {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-nav-subtle .rs-nav-item.rs-nav-item-active::before {
    content: '';
    position: absolute;
    background-color: #1675e0;
    background-color: #1675e0;
    display: block;
    z-index: 1;
  }
  .rs-nav-subtle.rs-nav-horizontal .rs-nav-bar {
    border-top: 2px solid #f7f7fa;
    border-top: 2px solid #f7f7fa;
  }
  .rs-nav-subtle.rs-nav-horizontal .rs-nav-item.rs-nav-item-active::before {
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
  }
  .rs-nav-reversed.rs-nav-subtle.rs-nav-horizontal .rs-nav-item.rs-nav-item-active::before {
    bottom: auto;
    top: 0;
  }
  .rs-nav-subtle.rs-nav-vertical .rs-nav-bar {
    width: 2px;
    background: #f7f7fa;
    background: #f7f7fa;
  }
  .rs-nav-subtle.rs-nav-vertical .rs-nav-item.rs-nav-item-active::before {
    right: 0;
    top: 0;
    bottom: 0;
    width: 2px;
  }
  .rs-nav-reversed.rs-nav-subtle.rs-nav-vertical .rs-nav-item.rs-nav-item-active::before {
    right: auto;
    left: 0;
  }
  .rs-nav-justified {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rs-nav-justified > .rs-nav-item,
  .rs-nav-justified > .rs-dropdown {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 1%;
            flex: 1 1 1%;
  }
  .rs-nav-justified > .rs-dropdown .rs-dropdown-toggle {
    width: 100%;
    text-align: left;
  }
  .rs-navbar::before,
  .rs-navbar::after {
    content: ' ';
    display: table;
  }
  .rs-navbar::after {
    clear: both;
  }
  .rs-navbar-header,
  .rs-navbar-brand {
    float: left;
    display: inline-block;
    height: 56px;
    color: inherit;
  }
  .rs-navbar-header a {
    color: inherit;
  }
  .rs-navbar-brand {
    padding: 18px 20px;
  }
  .rs-navbar-nav {
    float: left;
  }
  .rs-navbar-nav.rs-navbar-right {
    float: right;
  }
  .rs-navbar-nav:focus .rs-navbar-item:focus-visible {
    z-index: 1;
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
  }
  .rs-navbar-nav:focus .rs-navbar-item.rs-navbar-item-focus {
    z-index: 1;
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
  }
  .rs-navbar-nav:focus .rs-dropdown .rs-dropdown-toggle:focus-visible {
    z-index: 1;
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
  }
  .rs-navbar-nav:focus .rs-dropdown.rs-dropdown-focus .rs-dropdown-toggle {
    z-index: 1;
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
  }
  .rs-theme-high-contrast .rs-navbar-nav:focus .rs-navbar-item:focus-visible,
  .rs-theme-high-contrast .rs-navbar-nav:focus .rs-navbar-item.rs-navbar-item-focus,
  .rs-theme-high-contrast .rs-navbar-nav:focus .rs-dropdown .rs-dropdown-toggle:focus-visible,
  .rs-theme-high-contrast .rs-navbar-nav:focus .rs-dropdown.rs-dropdown-focus .rs-dropdown-toggle {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-navbar-nav:focus .rs-navbar-item:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-navbar-nav:focus .rs-navbar-item.rs-navbar-item-focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-navbar-nav:focus .rs-dropdown .rs-dropdown-toggle:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-navbar-nav:focus .rs-dropdown.rs-dropdown-focus .rs-dropdown-toggle {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-nav:focus .rs-navbar-item:focus-visible,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-nav:focus .rs-navbar-item.rs-navbar-item-focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-nav:focus .rs-dropdown .rs-dropdown-toggle:focus-visible,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-nav:focus .rs-dropdown.rs-dropdown-focus .rs-dropdown-toggle {
    outline-offset: 2px;
  }
  .rs-navbar-item,
  .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
    padding: 18px 16px;
    height: 56px;
    border-radius: 0;
    color: inherit;
    float: left;
    background-color: transparent;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .rs-navbar-item-icon {
    font-size: 16px;
    margin-right: 5px;
  }
  .rs-navbar-item-caret {
    font-size: 16px;
    margin-left: 6px;
  }
  .rs-navbar-brand,
  .rs-navbar-item,
  .rs-navbar-nav > .rs-dropdown-item,
  .rs-navbar-brand:hover,
  .rs-navbar-item:hover,
  .rs-navbar-nav > .rs-dropdown-item:hover,
  .rs-navbar-brand:focus,
  .rs-navbar-item:focus,
  .rs-navbar-nav > .rs-dropdown-item:focus,
  .rs-navbar-brand:active,
  .rs-navbar-item:active,
  .rs-navbar-nav > .rs-dropdown-item:active,
  .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
    text-decoration: none;
  }
  .rs-navbar-brand:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline-offset: -3px;
    z-index: 2;
  }
  .rs-navbar-item:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline-offset: -3px;
    z-index: 2;
  }
  .rs-navbar-nav > .rs-dropdown-item:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline-offset: -3px;
    z-index: 2;
  }
  .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle:focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline-offset: -3px;
    z-index: 2;
  }
  .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline-offset: -3px;
    z-index: 2;
  }
  .rs-theme-high-contrast .rs-navbar-brand:focus-visible,
  .rs-theme-high-contrast .rs-navbar-item:focus-visible,
  .rs-theme-high-contrast .rs-navbar-nav > .rs-dropdown-item:focus-visible {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-navbar-brand:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-navbar-item:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-navbar-nav > .rs-dropdown-item:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-brand:focus-visible,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-item:focus-visible,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-nav > .rs-dropdown-item:focus-visible {
    outline-offset: 2px;
  }
  .rs-navbar-item,
  .rs-navbar-nav > .rs-dropdown,
  .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
    margin: 0 !important;
  }
  .rs-theme-high-contrast .rs-navbar-item.rs-navbar-item-active {
    text-decoration: underline;
  }
  .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
    padding-right: 36px;
  }
  .rs-theme-high-contrast .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
    border: none;
  }
  .rs-theme-high-contrast .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle:focus-visible {
    outline-offset: 2px;
  }
  .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle .rs-dropdown-toggle-caret {
    top: 18px;
    right: 16px;
  }
  .rs-navbar .rs-dropdown-item:hover {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-theme-high-contrast .rs-navbar .rs-dropdown-item:hover {
    text-decoration: underline;
    -webkit-box-shadow: inset 0 0 0 2px #fff;
            box-shadow: inset 0 0 0 2px #fff;
  }
  .rs-navbar-default {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #575757;
    color: #575757;
  }
  .rs-navbar-default .rs-navbar-item:hover {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-navbar-default .rs-dropdown .rs-dropdown-toggle:hover {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-navbar-default .rs-dropdown.rs-dropdown-open .rs-dropdown-toggle {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-navbar-default .rs-navbar-item.rs-navbar-item-active {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-navbar-default .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-navbar-inverse {
    background-color: #3498ff;
    background-color: #3498ff;
    color: #fff;
    color: #fff;
  }
  .rs-navbar-inverse .rs-navbar-item:hover {
    background-color: #2589f5;
    background-color: #2589f5;
    color: #fff;
    color: #fff;
  }
  .rs-navbar-inverse .rs-dropdown .rs-dropdown-toggle:hover {
    background-color: #2589f5;
    background-color: #2589f5;
    color: #fff;
    color: #fff;
  }
  .rs-navbar-inverse .rs-navbar-item:focus {
    background-color: #2589f5;
    background-color: #2589f5;
    color: #fff;
    color: #fff;
  }
  .rs-navbar-inverse .rs-dropdown .rs-dropdown-toggle:focus {
    background-color: #2589f5;
    background-color: #2589f5;
    color: #fff;
    color: #fff;
  }
  .rs-navbar-inverse .rs-navbar-item.rs-navbar-item-active {
    background-color: #1675e0;
    background-color: #1675e0;
  }
  .rs-navbar-inverse .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle {
    background-color: #1675e0;
    background-color: #1675e0;
  }
  .rs-theme-high-contrast .rs-navbar-inverse .rs-navbar-item.rs-navbar-item-active {
    color: #ffff00;
  }
  .rs-theme-high-contrast .rs-navbar-inverse .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle {
    color: #ffff00;
  }
  .rs-navbar-subtle {
    background-color: transparent;
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-navbar-subtle .rs-navbar-item:hover {
    background-color: transparent;
    color: #575757;
    color: #575757;
  }
  .rs-navbar-subtle .rs-dropdown .rs-dropdown-toggle:hover {
    background-color: transparent;
    color: #575757;
    color: #575757;
  }
  .rs-navbar-subtle .rs-navbar-item:focus {
    background-color: transparent;
    color: #575757;
    color: #575757;
  }
  .rs-navbar-subtle .rs-dropdown .rs-dropdown-toggle:focus {
    background-color: transparent;
    color: #575757;
    color: #575757;
  }
  .rs-navbar-subtle .rs-navbar-item.rs-navbar-item-active {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-navbar-subtle .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-notification {
    pointer-events: auto;
    position: relative;
    display: inline-block;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    border-radius: 6px;
    background: #fff;
    background: #fff;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    overflow: hidden;
  }
  .rs-notification:not(.rs-toast-fade-exited) ~ .rs-notification {
    margin-top: 10px;
  }
  .rs-theme-high-contrast .rs-notification {
    border: 1px solid #e5e5ea;
    border: 1px solid #cbced4;
  }
  .rs-notification-content {
    padding: 20px;
    max-width: 400px;
  }
  .rs-notification-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    color: #272c36;
    color: #272c36;
    font-size: 16px;
    line-height: 1.5;
  }
  .rs-notification-title > p {
    margin: 0;
  }
  .rs-notification-title .rs-icon {
    font-size: 24px;
    width: 24px;
    line-height: 1;
    margin-right: 10px;
  }
  .rs-notification-title-with-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-notification-description {
    color: #575757;
    color: #575757;
  }
  .rs-notification-title + .rs-notification-description {
    margin-top: 8px;
  }
  .rs-notification.rs-notification-hiding {
    -webkit-animation: notificationMoveOut 0.3s ease-in forwards;
            animation: notificationMoveOut 0.3s ease-in forwards;
  }
  .rs-notification.rs-notification-hiding ~ .rs-notification {
    margin-top: 0;
  }
  .rs-notification-success .rs-notification-content .rs-notification-title + .rs-notification-description {
    margin-left: 34px;
  }
  .rs-notification-success .rs-notification-content .rs-icon {
    color: #4caf50;
    color: #4caf50;
  }
  .rs-notification-success .rs-notification-content .rs-icon::before {
    vertical-align: middle;
  }
  .rs-notification-info .rs-notification-content .rs-notification-title + .rs-notification-description {
    margin-left: 34px;
  }
  .rs-notification-info .rs-notification-content .rs-icon {
    color: #2196f3;
    color: #2196f3;
  }
  .rs-notification-info .rs-notification-content .rs-icon::before {
    vertical-align: middle;
  }
  .rs-notification-warning .rs-notification-content .rs-notification-title + .rs-notification-description {
    margin-left: 34px;
  }
  .rs-notification-warning .rs-notification-content .rs-icon {
    color: #ffb300;
    color: #ffb300;
  }
  .rs-notification-warning .rs-notification-content .rs-icon::before {
    vertical-align: middle;
  }
  .rs-notification-error .rs-notification-content .rs-notification-title + .rs-notification-description {
    margin-left: 34px;
  }
  .rs-notification-error .rs-notification-content .rs-icon {
    color: #f44336;
    color: #f44336;
  }
  .rs-notification-error .rs-notification-content .rs-icon::before {
    vertical-align: middle;
  }
  .rs-pagination-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .rs-pagination-group-lg {
    font-size: 16px;
  }
  .rs-pagination-group-md,
  .rs-pagination-group-sm {
    font-size: 14px;
  }
  .rs-pagination-group-xs {
    font-size: 12px;
  }
  .rs-pagination-group-grow {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
  .rs-pagination-group .rs-pagination {
    vertical-align: middle;
  }
  .rs-pagination-group-limit + .rs-pagination-group-total {
    margin-left: 18px;
  }
  .rs-pagination-group-skip {
    vertical-align: middle;
    display: inline-block;
    margin-left: 10px;
  }
  .rs-pagination-group-skip .rs-input {
    width: 46px;
    margin: 0 5px;
    display: inline-block;
  }
  .rs-pagination {
    display: inline-block;
    padding-left: 0;
    margin-bottom: 0;
  }
  .rs-pagination-lg .rs-pagination-btn {
    font-size: 16px;
    line-height: 22px;
    padding: 10px 16px;
    min-width: 42px;
  }
  .rs-btn-ghost.rs-pagination-lg .rs-pagination-btn {
    padding: 9px 15px;
  }
  .rs-btn-icon.rs-pagination-lg .rs-pagination-btn {
    padding: 11px 11px;
    line-height: 20px;
  }
  .rs-btn-icon.rs-pagination-lg .rs-pagination-btn > .rs-icon {
    font-size: 20px;
  }
  .rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn {
    line-height: 22px;
  }
  .rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn > .rs-icon {
    padding: 11px 11px;
    width: 42px;
    height: 42px;
  }
  .rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-left {
    padding: 10px 16px 10px 58px;
  }
  .rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: none;
  }
  .rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-right {
    padding: 10px 58px 10px 16px;
  }
  .rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: none;
  }
  .rs-pagination-lg .rs-pagination-btn .rs-pagination-symbol {
    height: 22px;
  }
  .rs-pagination-lg .rs-pagination-btn-active {
    padding: 9px 15px;
  }
  .rs-pagination-md .rs-pagination-btn {
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px;
    min-width: 36px;
  }
  .rs-btn-ghost.rs-pagination-md .rs-pagination-btn {
    padding: 7px 11px;
  }
  .rs-btn-icon.rs-pagination-md .rs-pagination-btn {
    padding: 10px 10px;
    line-height: 16px;
  }
  .rs-btn-icon.rs-pagination-md .rs-pagination-btn > .rs-icon {
    font-size: 16px;
  }
  .rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn > .rs-icon {
    padding: 10px 10px;
    width: 36px;
    height: 36px;
  }
  .rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;
  }
  .rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: none;
  }
  .rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;
  }
  .rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: none;
  }
  .rs-pagination-md .rs-pagination-btn-active {
    padding: 7px 11px;
  }
  .rs-pagination-sm .rs-pagination-btn {
    font-size: 14px;
    line-height: 20px;
    padding: 5px 10px;
    min-width: 30px;
  }
  .rs-btn-ghost.rs-pagination-sm .rs-pagination-btn {
    padding: 4px 9px;
  }
  .rs-btn-icon.rs-pagination-sm .rs-pagination-btn {
    padding: 7px 7px;
    line-height: 16px;
  }
  .rs-btn-icon.rs-pagination-sm .rs-pagination-btn > .rs-icon {
    font-size: 16px;
  }
  .rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn > .rs-icon {
    padding: 7px 7px;
    width: 30px;
    height: 30px;
  }
  .rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-left {
    padding: 5px 10px 5px 40px;
  }
  .rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: none;
  }
  .rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-right {
    padding: 5px 40px 5px 10px;
  }
  .rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: none;
  }
  .rs-pagination-sm .rs-pagination-btn-active {
    padding: 4px 9px;
  }
  .rs-pagination-xs .rs-pagination-btn {
    font-size: 12px;
    line-height: 20px;
    padding: 2px 8px;
    min-width: 24px;
  }
  .rs-btn-ghost.rs-pagination-xs .rs-pagination-btn {
    padding: 1px 7px;
  }
  .rs-btn-icon.rs-pagination-xs .rs-pagination-btn {
    padding: 6px 6px;
    line-height: 12px;
  }
  .rs-btn-icon.rs-pagination-xs .rs-pagination-btn > .rs-icon {
    font-size: 12px;
  }
  .rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn > .rs-icon {
    padding: 6px 6px;
    width: 24px;
    height: 24px;
  }
  .rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-left {
    padding: 2px 8px 2px 32px;
  }
  .rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: none;
  }
  .rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-right {
    padding: 2px 32px 2px 8px;
  }
  .rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: none;
  }
  .rs-pagination-xs .rs-pagination-btn-active {
    padding: 1px 7px;
  }
  .rs-pagination-btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
    transition: color 0.15s ease-out, background-color 0.15s ease-out;
    border: none;
    border: none;
    color: #575757;
    color: #575757;
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    border-radius: 6px;
    padding: 8px 12px;
    overflow: hidden;
    color: #8e8e93;
    color: #8e8e93;
    background-color: transparent;
    border: none;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 10px;
    margin: 0 2px;
    position: relative;
    float: left;
    text-decoration: none;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .rs-theme-high-contrast .rs-pagination-btn {
    -webkit-transition: none;
    transition: none;
  }
  .rs-btn-ghost.rs-pagination-btn {
    padding: 7px 11px;
  }
  .rs-btn-icon.rs-pagination-btn {
    padding: 10px 10px;
    line-height: 16px;
  }
  .rs-btn-icon.rs-pagination-btn > .rs-icon {
    font-size: 16px;
  }
  .rs-btn-icon-with-text.rs-pagination-btn {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-pagination-btn > .rs-icon {
    padding: 10px 10px;
    width: 36px;
    height: 36px;
  }
  .rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;
  }
  .rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: none;
  }
  .rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;
  }
  .rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: none;
  }
  .rs-pagination-btn:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
  }
  .rs-theme-high-contrast .rs-pagination-btn:focus-visible {
    outline-offset: 2px;
  }
  .rs-pagination-btn:hover {
    color: undefined;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    text-decoration: none;
  }
  .rs-pagination-btn:focus {
    color: undefined;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    text-decoration: none;
  }
  .rs-pagination-btn:active {
    color: #272c36;
    color: #272c36;
    background-color: #d9d9d9;
    background-color: #d9d9d9;
  }
  .rs-pagination-btn.rs-btn-active {
    color: #272c36;
    color: #272c36;
    background-color: #d9d9d9;
    background-color: #d9d9d9;
  }
  .rs-pagination-btn:disabled {
    cursor: not-allowed;
    color: #c5c6c7;
    color: #c5c6c7;
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-pagination-btn.rs-btn-disabled {
    cursor: not-allowed;
    color: #c5c6c7;
    color: #c5c6c7;
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-theme-high-contrast .rs-pagination-btn:disabled,
  .rs-theme-high-contrast .rs-pagination-btn.rs-btn-disabled {
    opacity: 0.5;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-pagination-btn {
      /* stylelint-disable */
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      /* stylelint-enable */
    }
  }
  .rs-picker-default .rs-pagination-btn {
    -webkit-transition: border-color ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s;
  }
  .rs-theme-high-contrast .rs-picker-default .rs-pagination-btn {
    -webkit-transition: none;
    transition: none;
  }
  .rs-picker-default:not(.rs-picker-disabled) .rs-pagination-btn:hover {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-picker-default:not(.rs-picker-disabled) .rs-pagination-btn:focus {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-picker-default:not(.rs-picker-disabled) .rs-pagination-btn-active {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-picker-subtle .rs-pagination-btn {
    color: #8e8e93;
    color: #8e8e93;
    background-color: transparent;
    border: none;
    -webkit-transition: none;
    transition: none;
  }
  .rs-picker-subtle .rs-pagination-btn:hover {
    color: #575757;
    color: #575757;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-pagination-btn:focus {
    color: #575757;
    color: #575757;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-pagination-btn:active {
    color: #272c36;
    color: #272c36;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-pagination-btn.rs-btn-active {
    color: #272c36;
    color: #272c36;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-pagination-btn:disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-picker-subtle .rs-pagination-btn.rs-btn-disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-theme-high-contrast .rs-picker-subtle .rs-pagination-btn:disabled,
  .rs-theme-high-contrast .rs-picker-subtle .rs-pagination-btn.rs-btn-disabled {
    opacity: 0.5;
  }
  .rs-picker-subtle .rs-pagination-btn-active {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-pagination-btn:hover {
    color: #575757;
    color: #575757;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-pagination-btn:focus {
    color: #575757;
    color: #575757;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-pagination-btn:active {
    color: #272c36;
    color: #272c36;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-pagination-btn.rs-btn-active {
    color: #272c36;
    color: #272c36;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-pagination-btn:disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-pagination-btn.rs-btn-disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-theme-high-contrast .rs-pagination-btn:disabled,
  .rs-theme-high-contrast .rs-pagination-btn.rs-btn-disabled {
    opacity: 0.5;
  }
  .rs-btn-ghost.rs-pagination-btn {
    padding: 4px 9px;
  }
  .rs-btn-icon.rs-pagination-btn {
    padding: 7px 7px;
    line-height: 16px;
  }
  .rs-btn-icon.rs-pagination-btn > .rs-icon {
    font-size: 16px;
  }
  .rs-btn-icon-with-text.rs-pagination-btn {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-pagination-btn > .rs-icon {
    padding: 7px 7px;
    width: 30px;
    height: 30px;
  }
  .rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-left {
    padding: 5px 10px 5px 40px;
  }
  .rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: none;
  }
  .rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-right {
    padding: 5px 40px 5px 10px;
  }
  .rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: none;
  }
  .rs-pagination-btn .rs-pagination-symbol {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 20px;
  }
  .rs-theme-high-contrast .rs-pagination-btn {
    -webkit-transition: none;
    transition: none;
    color: #e9ebf0;
  }
  .rs-theme-high-contrast .rs-pagination-btn:hover {
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-pagination-btn:focus-visible {
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-pagination-btn.rs-pagination-btn-active {
    color: #1675e0;
    color: #1675e0;
    background-color: transparent;
    border: 1px solid #1675e0;
    border: 1px solid #1675e0;
  }
  .rs-pagination-btn.rs-pagination-btn-active:hover {
    color: #0a5dc2;
    color: #0a5dc2;
    background-color: transparent;
    border-color: #0a5dc2;
    border-color: #0a5dc2;
    -webkit-box-shadow: 0 0 0 1px #0a5dc2;
    -webkit-box-shadow: 0 0 0 1px #0a5dc2;
            box-shadow: 0 0 0 1px #0a5dc2;
            box-shadow: 0 0 0 1px #0a5dc2;
  }
  .rs-pagination-btn.rs-pagination-btn-active:focus {
    color: #0a5dc2;
    color: #0a5dc2;
    background-color: transparent;
    border-color: #0a5dc2;
    border-color: #0a5dc2;
    -webkit-box-shadow: 0 0 0 1px #0a5dc2;
    -webkit-box-shadow: 0 0 0 1px #0a5dc2;
            box-shadow: 0 0 0 1px #0a5dc2;
            box-shadow: 0 0 0 1px #0a5dc2;
  }
  .rs-pagination-btn.rs-pagination-btn-active:active {
    color: #004299;
    color: #004299;
    background-color: transparent;
    border-color: #004299;
    border-color: #004299;
  }
  .rs-pagination-btn.rs-pagination-btn-active.rs-btn-active {
    color: #004299;
    color: #004299;
    background-color: transparent;
    border-color: #004299;
    border-color: #004299;
  }
  .rs-pagination-btn.rs-pagination-btn-active:disabled {
    color: #1675e0;
    color: #1675e0;
    background-color: transparent;
    opacity: 0.3;
    border-color: #1675e0;
    border-color: #1675e0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-pagination-btn.rs-pagination-btn-active.rs-btn-disabled {
    color: #1675e0;
    color: #1675e0;
    background-color: transparent;
    opacity: 0.3;
    border-color: #1675e0;
    border-color: #1675e0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-theme-high-contrast .rs-pagination-btn.rs-pagination-btn-active:disabled,
  .rs-theme-high-contrast .rs-pagination-btn.rs-pagination-btn-active.rs-btn-disabled {
    opacity: 0.5;
  }
  .rs-theme-high-contrast .rs-pagination-btn.rs-pagination-btn-active {
    color: #ffff00;
    text-decoration: underline;
  }
  /* rtl:begin:ignore */
  [dir='rtl'] .rs-pagination-btn [class*='rs-icon-page'] {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  /* rtl:end:ignore */
  .rs-panel {
    border-radius: 6px;
    overflow: hidden;
  }
  .rs-panel-bordered {
    border: 1px solid #e5e5ea;
    border: 1px solid #e5e5ea;
  }
  .rs-panel-shaded {
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  }
  .rs-panel-header,
  .rs-panel-body {
    padding: 20px;
  }
  .rs-panel-body-fill {
    padding: 0 !important;
  }
  .rs-panel-header {
    color: #272c36;
    color: #272c36;
    font-size: 16px;
    line-height: 1.25;
  }
  .rs-panel-title {
    margin: 0;
  }
  .rs-panel-title a {
    color: inherit;
  }
  .rs-panel-title a:hover,
  .rs-panel-title a:focus,
  .rs-panel-title a:active {
    text-decoration: none;
  }
  .rs-panel-header + .rs-panel-collapse .rs-panel-body,
  .rs-panel-header + .rs-panel-body {
    padding-top: 0;
  }
  .rs-panel-collapsible > .rs-panel-header {
    cursor: pointer;
    -webkit-transition: background-color 0.3s linear, border-radius 0.3s linear;
    transition: background-color 0.3s linear, border-radius 0.3s linear;
    position: relative;
  }
  .rs-panel-collapsible > .rs-panel-header > .rs-icon {
    color: #8e8e93;
    color: #8e8e93;
    position: absolute;
    top: 24px;
    right: 20px;
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
  }
  .rs-panel-group {
    border-radius: 6px;
    overflow: hidden;
  }
  .rs-panel-group-bordered {
    border: 1px solid #e5e5ea;
    border: 1px solid #e5e5ea;
  }
  .rs-panel-group > .rs-panel {
    border: none;
  }
  .rs-panel-group > .rs-panel + .rs-panel {
    position: relative;
  }
  .rs-panel-group > .rs-panel + .rs-panel::before {
    content: '';
    position: absolute;
    top: 0;
    border-top: 1px solid #e5e5ea;
    border-top: 1px solid #e5e5ea;
    left: 20px;
    right: 20px;
  }
  .rs-picker-toggle-wrapper {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
  }
  .rs-picker-toggle {
    min-width: 75px;
  }
  .rs-picker-toggle.rs-btn .rs-ripple-pond {
    display: none !important;
  }
  .rs-picker-block {
    display: block;
  }
  .rs-picker-disabled {
    opacity: 0.3;
  }
  .rs-picker-toggle .rs-picker-toggle-placeholder {
    color: #8e8e93;
    color: #8e8e93;
  }
  // .rs-picker-has-value .rs-btn .rs-picker-toggle-value {
  //   color: #1675e0;
  //   color: #1675e0;
  // }
  // .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  //   color: #1675e0;
  //   color: #1675e0;
  // }
  .rs-picker-none {
    padding: 6px 12px 12px;
    color: #8e8e93;
    color: #8e8e93;
    cursor: default;
  }
  .rs-picker-countable .rs-picker-toggle-value {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rs-picker-value-list {
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-picker-value-list {
      max-width: 100%;
    }
  }
  .rs-picker-value-count {
    margin: 0 4px;
    background-color: #3498ff;
    background-color: #3498ff;
    color: #fff;
    color: #fff;
    border-radius: 10px;
    padding: 0 8px;
    line-height: 20px;
  }
  .rs-picker-value-separator {
    margin: 0 4px 0 0;
  }
  .rs-picker:not(.rs-picker-disabled):hover {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-picker.rs-picker-focused {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-picker-toggle-active {
    -webkit-box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
    -webkit-box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
            box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
            box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  }
  .rs-picker.rs-picker-focused {
    -webkit-box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
    -webkit-box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
            box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
            box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  }
  .rs-picker-toggle {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
    transition: color 0.15s ease-out, background-color 0.15s ease-out;
    border: none;
    border: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-decoration: none;
    color: #575757;
    color: #575757;
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px;
    overflow: hidden;
    position: relative;
  }
  .rs-theme-high-contrast .rs-picker-toggle {
    -webkit-transition: none;
    transition: none;
  }
  .rs-btn-ghost.rs-picker-toggle {
    padding: 7px 11px;
  }
  .rs-btn-icon.rs-picker-toggle {
    padding: 10px 10px;
    line-height: 16px;
  }
  .rs-btn-icon.rs-picker-toggle > .rs-icon {
    font-size: 16px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle > .rs-icon {
    padding: 10px 10px;
    width: 36px;
    height: 36px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: none;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: none;
  }
  .rs-picker-toggle:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
  }
  .rs-theme-high-contrast .rs-picker-toggle:focus-visible {
    outline-offset: 2px;
  }
  .rs-picker-toggle:hover {
    color: undefined;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    text-decoration: none;
  }
  .rs-picker-toggle:focus {
    color: undefined;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    text-decoration: none;
  }
  .rs-picker-toggle:active {
    color: #272c36;
    color: #272c36;
    background-color: #d9d9d9;
    background-color: #d9d9d9;
  }
  .rs-picker-toggle.rs-btn-active {
    color: #272c36;
    color: #272c36;
    background-color: #d9d9d9;
    background-color: #d9d9d9;
  }
  .rs-picker-toggle:disabled {
    cursor: not-allowed;
    color: #c5c6c7;
    color: #c5c6c7;
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-picker-toggle.rs-btn-disabled {
    cursor: not-allowed;
    color: #c5c6c7;
    color: #c5c6c7;
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-theme-high-contrast .rs-picker-toggle:disabled,
  .rs-theme-high-contrast .rs-picker-toggle.rs-btn-disabled {
    opacity: 0.5;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-picker-toggle {
      /* stylelint-disable */
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      /* stylelint-enable */
    }
  }
  .rs-picker-default .rs-picker-toggle {
    -webkit-transition: border-color ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s;
  }
  .rs-theme-high-contrast .rs-picker-default .rs-picker-toggle {
    -webkit-transition: none;
    transition: none;
  }
  // .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  //   border-color: #3498ff;
  //   border-color: #3498ff;
  // }
  // .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus {
  //   border-color: #3498ff;
  //   border-color: #3498ff;
  // }
  // .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  //   border-color: #3498ff;
  //   border-color: #3498ff;
  // }
  .rs-picker-subtle .rs-picker-toggle {
    color: #8e8e93;
    color: #8e8e93;
    background-color: transparent;
    border: none;
    -webkit-transition: none;
    transition: none;
  }
  .rs-picker-subtle .rs-picker-toggle:hover {
    color: #575757;
    color: #575757;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-picker-toggle:focus {
    color: #575757;
    color: #575757;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-picker-toggle:active {
    color: #272c36;
    color: #272c36;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-picker-toggle.rs-btn-active {
    color: #272c36;
    color: #272c36;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-picker-toggle:disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle:disabled,
  .rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
    opacity: 0.5;
  }
  .rs-picker-subtle .rs-picker-toggle-active {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-picker-default .rs-picker-toggle {
    padding: 7px 11px;
  }
  .rs-picker-toggle-textbox {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: 1px solid #0000;
    padding-left: 10px;
    padding-right: 32px;
    color: #575757;
    color: #575757;
    background-color: #fff;
    background-color: #fff;
    outline: none;
  }
  .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-textbox {
    padding-left: 14px;
  }
  .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-textbox {
    padding-left: 8px;
  }
  .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-textbox {
    padding-left: 6px;
  }
  .rs-picker-toggle-read-only {
    opacity: 0;
  }
  .rs-picker-default .rs-btn,
  .rs-picker-default .rs-picker-toggle {
    -webkit-transition: border-color ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s;
  }
  .rs-theme-high-contrast .rs-picker-default .rs-btn,
  .rs-theme-high-contrast .rs-picker-default .rs-picker-toggle {
    -webkit-transition: none;
    transition: none;
  }
  // .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover {
  //   border-color: #3498ff;
  //   border-color: #3498ff;
  // }
  // .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  //   border-color: #3498ff;
  //   border-color: #3498ff;
  // }
  // .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus {
  //   border-color: #3498ff;
  //   border-color: #3498ff;
  // }
  // .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus {
  //   border-color: #3498ff;
  //   border-color: #3498ff;
  // }
  // .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active {
  //   border-color: #3498ff;
  //   border-color: #3498ff;
  // }
  // .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  //   border-color: #3498ff;
  //   border-color: #3498ff;
  // }
  .rs-picker-subtle .rs-btn {
    color: #8e8e93;
    color: #8e8e93;
    background-color: transparent;
    border: none;
    -webkit-transition: none;
    transition: none;
  }
  .rs-picker-subtle .rs-picker-toggle {
    color: #8e8e93;
    color: #8e8e93;
    background-color: transparent;
    border: none;
    -webkit-transition: none;
    transition: none;
  }
  .rs-picker-subtle .rs-btn:hover {
    color: #575757;
    color: #575757;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-picker-toggle:hover {
    color: #575757;
    color: #575757;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-btn:focus {
    color: #575757;
    color: #575757;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-picker-toggle:focus {
    color: #575757;
    color: #575757;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-btn:active {
    color: #272c36;
    color: #272c36;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-picker-toggle:active {
    color: #272c36;
    color: #272c36;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-btn.rs-btn-active {
    color: #272c36;
    color: #272c36;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-picker-toggle.rs-btn-active {
    color: #272c36;
    color: #272c36;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-btn:disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-picker-subtle .rs-picker-toggle:disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-picker-subtle .rs-btn.rs-btn-disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-theme-high-contrast .rs-picker-subtle .rs-btn:disabled,
  .rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle:disabled,
  .rs-theme-high-contrast .rs-picker-subtle .rs-btn.rs-btn-disabled,
  .rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
    opacity: 0.5;
  }
  .rs-picker-subtle .rs-btn-active {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-picker-subtle .rs-picker-toggle-active {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  /* stylelint-disable-next-line */
  .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
    padding-right: 32px;
  }
  .rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn {
    padding-right: 32px;
  }
  .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
  .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 8px;
    right: 12px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 7px;
  }
  .rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding: 7px 12px;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper {
    padding-bottom: 5px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-tag {
    margin-top: 5px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding-left: 12px;
    margin-top: 5px;
    font-size: 14px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper input {
    height: 18px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn {
    padding-top: 7px;
    padding-bottom: 7px;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn,
  .rs-picker-daterange .rs-picker-toggle.rs-btn {
    padding-right: 36px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
  .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    top: 8px;
    right: 12px;
  }
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    top: 7px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .rs-picker-toggle.rs-btn-lg {
    font-size: 16px;
    line-height: 22px;
    padding: 10px 16px;
  }
  .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-lg {
    padding-right: 36px;
  }
  .rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-lg {
    padding-right: 36px;
  }
  .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
  .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
    top: 10px;
    right: 16px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
  .rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
    top: 9px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-lg {
    padding-left: 15px;
  }
  .rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding: 8px 16px;
    font-size: 16px;
    line-height: 1.375;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper {
    padding-bottom: 6px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-tag {
    margin-top: 6px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding-left: 16px;
    margin-top: 6px;
    font-size: 16px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper input {
    height: 20px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg {
    padding-top: 8px;
    padding-bottom: 8px;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-lg,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-lg {
    padding-right: 42px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
  .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
    top: 10px;
    right: 16px;
  }
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
    top: 9px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-lg {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .rs-btn-ghost.rs-picker-toggle.rs-btn-lg {
    padding: 9px 15px;
  }
  .rs-btn-icon.rs-picker-toggle.rs-btn-lg {
    padding: 11px 11px;
    line-height: 20px;
  }
  .rs-btn-icon.rs-picker-toggle.rs-btn-lg > .rs-icon {
    font-size: 20px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg {
    line-height: 22px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg > .rs-icon {
    padding: 11px 11px;
    width: 42px;
    height: 42px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-left {
    padding: 10px 16px 10px 58px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: none;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-right {
    padding: 10px 58px 10px 16px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: none;
  }
  .rs-picker-toggle.rs-btn-lg .rs-picker-value-count {
    line-height: 22px;
  }
  .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-md {
    padding-right: 32px;
  }
  .rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-md {
    padding-right: 32px;
  }
  .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
  .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
    top: 8px;
    right: 12px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
  .rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
    top: 7px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-md {
    padding-left: 11px;
  }
  .rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding: 7px 12px;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper {
    padding-bottom: 5px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-tag {
    margin-top: 5px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding-left: 12px;
    margin-top: 5px;
    font-size: 14px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper input {
    height: 18px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md {
    padding-top: 7px;
    padding-bottom: 7px;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-md,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-md {
    padding-right: 36px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
  .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
    top: 8px;
    right: 12px;
  }
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
    top: 7px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-md {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .rs-picker-toggle.rs-btn-sm {
    font-size: 14px;
    line-height: 20px;
    padding: 5px 10px;
  }
  .rs-btn-ghost.rs-picker-toggle.rs-btn-sm {
    padding: 4px 9px;
  }
  .rs-btn-icon.rs-picker-toggle.rs-btn-sm {
    padding: 7px 7px;
    line-height: 16px;
  }
  .rs-btn-icon.rs-picker-toggle.rs-btn-sm > .rs-icon {
    font-size: 16px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm > .rs-icon {
    padding: 7px 7px;
    width: 30px;
    height: 30px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-left {
    padding: 5px 10px 5px 40px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: none;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-right {
    padding: 5px 40px 5px 10px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: none;
  }
  .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-sm {
    padding-right: 30px;
  }
  .rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-sm {
    padding-right: 30px;
  }
  .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
  .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
    top: 5px;
    right: 10px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
  .rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
    top: 4px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-sm {
    padding-left: 9px;
  }
  .rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding: 4px 10px;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper {
    padding-bottom: 2px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-tag {
    margin-top: 2px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding-left: 10px;
    margin-top: 2px;
    font-size: 14px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper input {
    height: 18px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm {
    padding-top: 4px;
    padding-bottom: 4px;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-sm,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-sm {
    padding-right: 34px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
  .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
    top: 4px;
    right: 10px;
  }
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
    top: 3px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-sm {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .rs-picker-toggle.rs-btn-xs {
    font-size: 12px;
    line-height: 20px;
    padding: 2px 8px;
    /* stylelint-disable */
    /* stylelint-enable */
  }
  .rs-btn-ghost.rs-picker-toggle.rs-btn-xs {
    padding: 1px 7px;
  }
  .rs-btn-icon.rs-picker-toggle.rs-btn-xs {
    padding: 6px 6px;
    line-height: 12px;
  }
  .rs-btn-icon.rs-picker-toggle.rs-btn-xs > .rs-icon {
    font-size: 12px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs > .rs-icon {
    padding: 6px 6px;
    width: 24px;
    height: 24px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-left {
    padding: 2px 8px 2px 32px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: none;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-right {
    padding: 2px 32px 2px 8px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: none;
  }
  .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-xs {
    padding-right: 28px;
  }
  .rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-xs {
    padding-right: 28px;
  }
  .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
  .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
    top: 2px;
    right: 8px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
  .rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
    top: 1px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-xs {
    padding-left: 7px;
  }
  .rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding: 1px 8px;
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper {
    padding-bottom: -1px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-tag {
    margin-top: -1px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding-left: 8px;
    margin-top: -1px;
    font-size: 12px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper input {
    height: 18px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs {
    padding-top: 1px;
    padding-bottom: 1px;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper {
    padding-bottom: 1px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-tag {
    margin-top: 1px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-xs,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-xs {
    padding-right: 30px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
  .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
    top: 2px;
    right: 8px;
  }
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
    top: 1px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-xs {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  /* stylelint-disable-next-line */
  .rs-picker-toggle {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-picker-default .rs-picker-toggle {
    position: relative;
    z-index: 5;
    padding-right: 32px;
    display: inline-block;
    color: #575757;
    color: #575757;
    border: 1px solid #e5e5ea;
    border: 1px solid #e5e5ea;
  }
  .rs-picker-disabled .rs-picker-toggle {
    cursor: not-allowed;
  }
  .rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):hover {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):focus {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-picker-subtle .rs-picker-toggle {
    position: relative;
    z-index: 5;
    padding-right: 32px;
    display: inline-block;
  }
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle {
    cursor: not-allowed;
  }
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover,
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus,
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active {
    background: none;
  }
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover::after,
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus::after,
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active::after {
    display: none;
  }
  .rs-picker-toggle-label {
    color: #575757;
    color: #575757;
  }
  .rs-picker-toggle-label::after {
    content: ':';
    margin: 0 4px 0 2px;
  }
  .rs-picker-toggle-value {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
    padding-right: 44px;
  }
  .rs-picker-toggle-clean {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: 2px;
    position: absolute;
    top: 8px;
    right: 30px;
    height: 20px;
    font-size: 12px;
    background: inherit;
    color: #8e8e93;
    color: #8e8e93;
    -webkit-transition: 0.2s color linear;
    transition: 0.2s color linear;
    cursor: pointer;
  }
  .rs-picker-toggle-clean.rs-btn-close {
    padding: 4px 0;
  }
  .rs-picker-toggle-clean:hover {
    color: #f44336;
    color: #f44336;
  }
  .rs-picker-toggle-clean:hover svg path {
    stroke: #f44336;
    stroke: #f44336;
    stroke-width: 1;
  }
  .rs-picker-date .rs-picker-toggle-clean,
  .rs-picker-daterange .rs-picker-toggle-clean {
    right: 38px;
  }
  .rs-picker-toggle-caret {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: 2px;
    position: absolute;
    top: 8px;
    right: 12px;
    height: 20px;
    font-size: 12px;
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-picker-menu {
    position: absolute;
    text-align: left;
    z-index: 7;
    border-radius: 6px;
    background-color: #fff;
    background-color: #fff;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    overflow: hidden;
    -webkit-transition: none;
    transition: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .rs-theme-high-contrast .rs-picker-menu {
    border: 1px solid #e5e5ea;
    border: 1px solid #cbced4;
  }
  .rs-modal-open .rs-picker-menu {
    z-index: 1055;
  }
  .rs-drawer-open .rs-picker-menu {
    z-index: 1055;
  }
  .rs-picker-menu.rs-picker-select-menu,
  .rs-picker-menu.rs-picker-check-menu {
    padding-top: 6px;
  }
  .rs-picker-menu .rs-picker-search-bar {
    position: relative;
    padding: 6px 12px 12px;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
    display: block;
    width: 100%;
    color: #575757;
    color: #575757;
    background-color: #fff;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e5e5ea;
    border: 1px solid #e5e5ea;
    -webkit-transition: border-color ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s;
    border-radius: 6px;
    /* stylelint-disable */
    padding: 7px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    /* stylelint-enable */
    min-width: 200px;
    padding-right: 36px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
      height: 36px;
    }
  }
  textarea.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
    height: auto;
  }
  .rs-theme-high-contrast .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
    -webkit-transition: none;
    transition: none;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::-webkit-input-placeholder {
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::-moz-placeholder {
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::-ms-input-placeholder {
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::placeholder {
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover:not(:disabled) {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
  }
  .rs-theme-high-contrast .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus {
    outline-offset: 2px;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:disabled {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-search-icon {
    position: absolute;
    width: 14px;
    color: #8e8e93;
    color: #8e8e93;
    font-size: 14px;
    height: 20px;
    top: 14px;
    right: 24px;
  }
  .rs-tree-menu.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-search-icon,
  .rs-check-tree-menu.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-search-icon {
    top: 7px;
  }
  .rs-picker-menu.rs-picker-inline {
    position: relative;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-picker-default .rs-btn {
    background-color: #fff !important;
    background-color: #fff !important;
  }
  .rs-picker-input .rs-btn {
    background-color: #fff !important;
    background-color: #fff !important;
  }
  .rs-picker-default .rs-picker-toggle {
    background-color: #fff !important;
    background-color: #fff !important;
  }
  .rs-picker-input .rs-picker-toggle {
    background-color: #fff !important;
    background-color: #fff !important;
  }
  .rs-picker-default.rs-picker-disabled .rs-picker-toggle {
    background-color: #f7f7fa !important;
    background-color: #f7f7fa !important;
  }
  .rs-picker-input.rs-picker-disabled .rs-picker-toggle {
    background-color: #f7f7fa !important;
    background-color: #f7f7fa !important;
  }
  .rs-picker-default.rs-picker-disabled .rs-btn {
    background-color: #f7f7fa !important;
    background-color: #f7f7fa !important;
  }
  .rs-picker-input.rs-picker-disabled .rs-btn {
    background-color: #f7f7fa !important;
    background-color: #f7f7fa !important;
  }
  .rs-check-item {
    display: block;
  }
  .rs-check-item:not(.rs-checkbox-disabled):hover {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
  }
  .rs-check-item:focus {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
  }
  .rs-check-item.rs-check-item-focus {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
  }
  .rs-theme-high-contrast .rs-check-item:not(.rs-checkbox-disabled):hover {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-check-item:focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-check-item.rs-check-item-focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-check-item:not(.rs-checkbox-disabled):hover,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-check-item:focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-check-item.rs-check-item-focus {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-check-item:not(.rs-checkbox-disabled):hover .rs-check-tree-node-text-wrapper,
  .rs-theme-high-contrast .rs-check-item:focus .rs-check-tree-node-text-wrapper,
  .rs-theme-high-contrast .rs-check-item.rs-check-item-focus .rs-check-tree-node-text-wrapper {
    text-decoration: underline;
  }
  .rs-check-item .rs-checkbox-checker {
    padding: 0;
  }
  .rs-check-item .rs-checkbox-checker > label {
    position: relative;
    cursor: pointer;
    display: block;
    padding: 8px 12px 8px 38px;
    line-height: 20px;
  }
  .rs-theme-high-contrast .rs-check-item .rs-checkbox-checker > label {
    -webkit-transition: none;
    transition: none;
  }
  .rs-check-item .rs-checkbox-checker > label::before {
    background-color: #fff;
    background-color: #fff;
  }
  .rs-theme-high-contrast .rs-check-item .rs-checkbox-checker > label::before {
    background: none;
  }
  .rs-checkbox-disabled.rs-check-item .rs-checkbox-checker > label {
    cursor: not-allowed;
  }
  .grouped .rs-check-item .rs-checkbox-checker > label,
  .rs-picker-check-menu-group-children .rs-check-item .rs-checkbox-checker > label {
    padding-left: 52px;
  }
  .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper {
    left: 12px;
  }
  .grouped .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper,
  .rs-picker-check-menu-group-children .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper {
    left: 26px;
  }
  .rs-placeholder {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rs-placeholder-paragraph {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .rs-placeholder-paragraph-graph {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 60px;
    height: 60px;
    border-radius: 2px;
    margin-right: 20px;
    background-color: #f2f2f5;
    background-color: #f2f2f5;
  }
  .rs-placeholder-paragraph-graph-circle {
    border-radius: 50%;
  }
  .rs-placeholder-paragraph-graph-image {
    width: 80px;
    height: 60px;
    position: relative;
    overflow: hidden;
  }
  .rs-placeholder-paragraph-graph-image::after {
    content: '';
    width: 0;
    height: 0;
    left: 10px;
    bottom: 10px;
    position: absolute;
    border-bottom: 36px solid #fff;
    border-bottom: 36px solid #fff;
    border-left: 20px solid transparent;
    border-right: 30px solid transparent;
  }
  .rs-placeholder-paragraph-graph-image::before {
    content: '';
    width: 0;
    height: 0;
    left: 40px;
    bottom: 10px;
    position: absolute;
    border-bottom: 22px solid #fff;
    border-bottom: 22px solid #fff;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
  }
  .rs-placeholder-paragraph-graph-image .rs-placeholder-paragraph-graph-inner {
    width: 12px;
    height: 12px;
    right: 18px;
    top: 10px;
    border-radius: 50%;
    background: #fff;
    background: #fff;
    position: absolute;
  }
  .rs-placeholder-paragraph-rows {
    width: 100%;
  }
  .rs-placeholder-paragraph-rows > p {
    background-color: #f2f2f5;
    background-color: #f2f2f5;
  }
  .rs-placeholder-grid-col {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .rs-placeholder-grid-col > p {
    background-color: #f2f2f5;
    background-color: #f2f2f5;
  }
  .rs-placeholder-grid-col:first-child {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .rs-placeholder-graph {
    display: inline-block;
    width: 100%;
    background-color: #f2f2f5;
    background-color: #f2f2f5;
  }
  .rs-placeholder-active.rs-placeholder-graph {
    background: linear-gradient(-45deg, #f2f2f5 25%, #e5e5ea 37%, #f2f2f5 63%);
    background: linear-gradient(-45deg, #f2f2f5 25%, #e5e5ea 37%, #f2f2f5 63%);
    background-size: 400% 100% !important;
    -webkit-animation: placeholder-active 3s ease infinite;
            animation: placeholder-active 3s ease infinite;
  }
  .rs-placeholder-active .rs-placeholder-paragraph-graph-image {
    background: linear-gradient(-45deg, #f2f2f5 25%, #e5e5ea 37%, #f2f2f5 63%);
    background: linear-gradient(-45deg, #f2f2f5 25%, #e5e5ea 37%, #f2f2f5 63%);
    background-size: 400% 100% !important;
    -webkit-animation: placeholder-active 3s ease infinite;
            animation: placeholder-active 3s ease infinite;
  }
  .rs-placeholder-active .rs-placeholder-grid-col > p {
    background: linear-gradient(-45deg, #f2f2f5 25%, #e5e5ea 37%, #f2f2f5 63%);
    background: linear-gradient(-45deg, #f2f2f5 25%, #e5e5ea 37%, #f2f2f5 63%);
    background-size: 400% 100% !important;
    -webkit-animation: placeholder-active 3s ease infinite;
            animation: placeholder-active 3s ease infinite;
  }
  .rs-placeholder-active .rs-placeholder-paragraph-rows > p {
    background: linear-gradient(-45deg, #f2f2f5 25%, #e5e5ea 37%, #f2f2f5 63%);
    background: linear-gradient(-45deg, #f2f2f5 25%, #e5e5ea 37%, #f2f2f5 63%);
    background-size: 400% 100% !important;
    -webkit-animation: placeholder-active 3s ease infinite;
            animation: placeholder-active 3s ease infinite;
  }
  @-webkit-keyframes placeholder-active {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  @keyframes placeholder-active {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  /* rtl:begin:ignore */
  /* rtl:end:ignore */
  .rs-popover {
    position: absolute;
    top: 0;
    left: 0 /* rtl:ignore */;
    z-index: 1060;
    display: none;
    padding: 12px;
    font-size: 14px;
    background-color: #fff;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    opacity: 0;
    -webkit-filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.12));
    -webkit-filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.12));
            filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.12));
            filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.12));
    -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
            box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
            box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
  }
  .rs-popover.rs-anim-fade {
    -webkit-transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
  }
  .rs-popover.rs-anim-in {
    opacity: 1;
    -webkit-transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in, -webkit-transform 0.15s ease-in;
  }
  .rs-theme-high-contrast .rs-popover {
    border: 1px solid #e5e5ea;
    border: 1px solid #cbced4;
  }
  .rs-popover > .rs-popover-arrow {
    border-width: 6px;
  }
  .rs-popover > .rs-popover-arrow,
  .rs-popover > .rs-popover-arrow::before,
  .rs-popover > .rs-popover-arrow::after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .rs-popover > .rs-popover-arrow::before,
  .rs-popover > .rs-popover-arrow::after {
    border-width: 6px;
    content: '';
  }
  .rs-popover > .rs-popover-arrow::before {
    display: none;
  }
  .rs-theme-high-contrast .rs-popover > .rs-popover-arrow::before {
    display: block;
  }
  .rs-popover-title {
    margin: 0;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-popover-content {
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-popover-title ~ .rs-popover-content {
    margin-top: 8px;
  }
  .rs-popover[class*='placement-top'] {
    margin-top: -8px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .rs-popover[class*='placement-top'].rs-anim-in {
    -webkit-transform: translate(0, -2px);
            transform: translate(0, -2px);
  }
  .rs-popover[class*='placement-top'] > .rs-popover-arrow {
    margin-left: -6px;
    bottom: -6px;
  }
  .rs-popover[class*='placement-top'] > .rs-popover-arrow::after {
    bottom: -6px;
    margin-left: -6px;
    border-width: 6px 6px 0;
    border-top-color: #fff;
    border-top-color: #fff;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-popover[class*='placement-top'] > .rs-popover-arrow::after {
      bottom: -7px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-popover[class*='placement-top'] > .rs-popover-arrow::after {
      bottom: -7px;
    }
  }
  .rs-theme-high-contrast .rs-popover[class*='placement-top'] > .rs-popover-arrow::before {
    bottom: -7px;
    margin-left: -7px;
    border-width: 7px 7px 0;
    border-top-color: #e5e5ea;
    border-top-color: #cbced4;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-theme-high-contrast .rs-popover[class*='placement-top'] > .rs-popover-arrow::before {
      bottom: -8px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-theme-high-contrast .rs-popover[class*='placement-top'] > .rs-popover-arrow::before {
      bottom: -8px;
    }
  }
  /* rtl:begin:ignore */
  .rs-popover[class*='placement-right'] {
    margin-left: 8px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .rs-popover[class*='placement-right'].rs-anim-in {
    -webkit-transform: translate(2px, 0);
            transform: translate(2px, 0);
  }
  .rs-popover[class*='placement-right'] > .rs-popover-arrow {
    left: -6px;
    margin-top: -6px;
  }
  .rs-popover[class*='placement-right'] > .rs-popover-arrow::after {
    left: -6px;
    margin-top: -6px;
    border-width: 6px 6px 6px 0;
    border-right-color: #fff;
    border-right-color: #fff;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-popover[class*='placement-right'] > .rs-popover-arrow::after {
      left: -7px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-popover[class*='placement-right'] > .rs-popover-arrow::after {
      left: -7px;
    }
  }
  .rs-theme-high-contrast .rs-popover[class*='placement-right'] > .rs-popover-arrow::before {
    left: -7px;
    margin-top: -7px;
    border-width: 7px 7px 7px 0;
    border-right-color: #e5e5ea;
    border-right-color: #cbced4;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-theme-high-contrast .rs-popover[class*='placement-right'] > .rs-popover-arrow::before {
      left: -8px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-theme-high-contrast .rs-popover[class*='placement-right'] > .rs-popover-arrow::before {
      left: -8px;
    }
  }
  .rs-popover[class*='placement-left'] {
    margin-left: -8px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .rs-popover[class*='placement-left'].rs-anim-in {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  .rs-popover[class*='placement-left'] > .rs-popover-arrow {
    right: -6px;
    margin-top: -6px;
  }
  .rs-popover[class*='placement-left'] > .rs-popover-arrow::after {
    right: -6px;
    margin-top: -6px;
    border-width: 6px 0 6px 6px;
    border-left-color: #fff;
    border-left-color: #fff;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-popover[class*='placement-left'] > .rs-popover-arrow::after {
      right: -7px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-popover[class*='placement-left'] > .rs-popover-arrow::after {
      right: -7px;
    }
  }
  .rs-theme-high-contrast .rs-popover[class*='placement-left'] > .rs-popover-arrow::before {
    right: -7px;
    margin-top: -7px;
    border-width: 7px 0 7px 7px;
    border-left-color: #e5e5ea;
    border-left-color: #cbced4;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-theme-high-contrast .rs-popover[class*='placement-left'] > .rs-popover-arrow::before {
      right: -8px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-theme-high-contrast .rs-popover[class*='placement-left'] > .rs-popover-arrow::before {
      right: -8px;
    }
  }
  /* rtl:end:ignore */
  .rs-popover[class*='placement-bottom'] {
    margin-top: 8px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .rs-popover[class*='placement-bottom'].rs-anim-in {
    -webkit-transform: translate(0, 2px);
            transform: translate(0, 2px);
  }
  .rs-popover[class*='placement-bottom'] > .rs-popover-arrow {
    margin-left: -6px;
    top: -6px;
  }
  .rs-popover[class*='placement-bottom'] > .rs-popover-arrow::after {
    top: -6px;
    margin-left: -6px;
    border-width: 0 6px 6px;
    border-bottom-color: #fff;
    border-bottom-color: #fff;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-popover[class*='placement-bottom'] > .rs-popover-arrow::after {
      top: -7px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-popover[class*='placement-bottom'] > .rs-popover-arrow::after {
      top: -7px;
    }
  }
  .rs-theme-high-contrast .rs-popover[class*='placement-bottom'] > .rs-popover-arrow::before {
    top: -7px;
    margin-left: -7px;
    border-width: 0 7px 7px;
    border-bottom-color: #e5e5ea;
    border-bottom-color: #cbced4;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-theme-high-contrast .rs-popover[class*='placement-bottom'] > .rs-popover-arrow::before {
      top: -8px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-theme-high-contrast .rs-popover[class*='placement-bottom'] > .rs-popover-arrow::before {
      top: -8px;
    }
  }
  .rs-popover.placement-bottom > .rs-popover-arrow,
  .rs-popover.placement-top > .rs-popover-arrow {
    left: 50%;
  }
  .rs-popover.placement-bottom-end > .rs-popover-arrow,
  .rs-popover.placement-top-end > .rs-popover-arrow {
    right: 4px;
  }
  .rs-popover.placement-left > .rs-popover-arrow,
  .rs-popover.placement-right > .rs-popover-arrow {
    top: 50%;
  }
  .rs-popover.placement-left-end > .rs-popover-arrow,
  .rs-popover.placement-right-end > .rs-popover-arrow {
    bottom: 4px;
  }
  .rs-popover-full {
    padding: 0;
  }
  .rs-popover-full .rs-popover-content {
    margin-top: 0;
  }
  .rs-popover.placement-top-start.rs-popover-full > .rs-popover-arrow,
  .rs-popover.placement-bottom-start.rs-popover-full > .rs-popover-arrow {
    margin-left: 6px;
  }
  .rs-popover.placement-right-start.rs-popover-full > .rs-popover-arrow,
  .rs-popover.placement-left-start.rs-popover-full > .rs-popover-arrow {
    margin-top: 6px;
  }
  @-webkit-keyframes progress-active {
    0% {
      opacity: 0.1;
      width: 0;
    }
    20% {
      opacity: 0.5;
      width: 0;
    }
    100% {
      opacity: 0;
      width: 100%;
    }
  }
  @keyframes progress-active {
    0% {
      opacity: 0.1;
      width: 0;
    }
    20% {
      opacity: 0.5;
      width: 0;
    }
    100% {
      opacity: 0;
      width: 100%;
    }
  }
  @-webkit-keyframes progress-active-vertical {
    0% {
      opacity: 0.1;
      height: 0;
    }
    20% {
      opacity: 0.5;
      height: 0;
    }
    100% {
      opacity: 0;
      height: 100%;
    }
  }
  @keyframes progress-active-vertical {
    0% {
      opacity: 0.1;
      height: 0;
    }
    20% {
      opacity: 0.5;
      height: 0;
    }
    100% {
      opacity: 0;
      height: 100%;
    }
  }
  .rs-progress-info {
    font-size: 0;
  }
  .rs-progress,
  .rs-progress-icon-success,
  .rs-progress-icon-fail,
  .rs-progress-info-status {
    font-size: 16px;
    line-height: 1.25;
  }
  .rs-progress-circle {
    position: relative;
    font-size: 0;
  }
  .rs-progress-circle-info {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    font-size: 16px;
    line-height: 2.25;
    height: 36px;
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-progress-circle .rs-progress-trail {
    stroke: #e5e5ea;
    stroke: #e5e5ea;
  }
  .rs-progress-circle .rs-progress-stroke {
    stroke: #3498ff;
    stroke: #3498ff;
    -webkit-transition: stroke-dashoffset 0.3s ease, stroke-dasharray 0.2s ease-in-out, stroke 0.3s;
    transition: stroke-dashoffset 0.3s ease, stroke-dasharray 0.2s ease-in-out, stroke 0.3s;
  }
  .rs-progress-circle-success .rs-progress-circle-info {
    color: #4caf50;
    color: #4caf50;
  }
  .rs-progress-circle-success .rs-progress-stroke {
    stroke: #4caf50;
    stroke: #4caf50;
  }
  .rs-progress-circle-fail .rs-progress-circle-info {
    color: #f44336;
    color: #f44336;
  }
  .rs-progress-circle-fail .rs-progress-stroke {
    stroke: #f44336;
    stroke: #f44336;
  }
  .rs-progress-line {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    padding: 8px 12px;
  }
  .rs-progress-line-active .rs-progress-line-bg {
    position: relative;
  }
  .rs-progress-line-active .rs-progress-line-bg::before {
    content: '';
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border-radius: 10px;
    -webkit-animation: progress-active 2s cubic-bezier(0.23, 1, 0.32, 1) infinite;
            animation: progress-active 2s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  }
  .rs-progress-line .rs-progress-info {
    width: 50px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50px;
            flex: 0 0 50px;
    height: 20px;
    padding-left: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .rs-progress-line-outer {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    border-radius: 5px;
    overflow: hidden;
    vertical-align: middle;
  }
  .rs-progress-line-inner {
    background: #e5e5ea;
    background: #e5e5ea;
    border-radius: 5px;
  }
  .rs-progress-line-bg {
    background-color: #3498ff;
    background-color: #3498ff;
    border-radius: 5px;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    -webkit-transition-property: width, background-color;
    transition-property: width, background-color;
  }
  .rs-progress-line-inner,
  .rs-progress-line-bg {
    height: 10px;
  }
  .rs-progress-line-success {
    color: #4caf50;
    color: #4caf50;
  }
  .rs-progress-line-success .rs-progress-line-bg {
    background-color: #4caf50;
    background-color: #4caf50;
  }
  .rs-progress-line-fail {
    color: #f44336;
    color: #f44336;
  }
  .rs-progress-line-fail .rs-progress-line-bg {
    background-color: #f44336;
    background-color: #f44336;
  }
  .rs-progress-line.rs-progress-line-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    width: auto;
  }
  .rs-progress-line.rs-progress-line-vertical .rs-progress-line-outer {
    width: auto;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }
  .rs-progress-line.rs-progress-line-vertical .rs-progress-line-inner {
    min-height: 100px;
    width: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    height: 100%;
  }
  .rs-progress-line.rs-progress-line-vertical .rs-progress-info {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    padding-left: 0;
    width: auto;
  }
  .rs-progress-line.rs-progress-line-vertical .rs-progress-line-bg {
    width: 100%;
    -webkit-transition-property: height, background-color;
    transition-property: height, background-color;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    position: absolute;
    bottom: 0;
  }
  .rs-progress-line.rs-progress-line-vertical.rs-progress-line-active .rs-progress-line-bg::before {
    -webkit-animation-name: progress-active-vertical;
            animation-name: progress-active-vertical;
  }
  .rs-progress-icon-success,
  .rs-progress-icon-fail {
    display: inline-block;
  }
  .rs-progress-icon-success svg,
  .rs-progress-icon-fail svg {
    stroke: currentColor;
    stroke-width: 0.5px;
    stroke-linecap: round;
  }
  .rs-progress-line .rs-progress-icon-success,
  .rs-progress-line .rs-progress-icon-fail {
    line-height: 0;
    font-size: 14px;
  }
  .rs-progress-circle .rs-progress-icon-success,
  .rs-progress-circle .rs-progress-icon-fail {
    line-height: 0;
    font-size: 36px;
  }
  .rs-radio {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  .rs-radio-disabled label {
    cursor: not-allowed;
  }
  .rs-radio label {
    line-height: 1.14285714;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
  }
  .rs-radio-inner::before {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-radio.rs-radio-disabled label {
    cursor: not-allowed;
  }
  .rs-radio-disabled > .rs-radio-checker > label {
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-radio-inline {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    font-weight: normal;
    margin-top: 0;
    margin-right: 10px;
  }
  .rs-plaintext .rs-radio-inline:first-child {
    margin-left: 0;
  }
  .rs-radio [type='radio']:focus ~ .rs-radio-inner::before {
    -webkit-box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
    -webkit-box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
            box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
            box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  }
  .rs-radio-checker {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 36px;
    min-height: 36px;
    line-height: 1;
    position: relative;
  }
  .rs-radio-wrapper {
    position: absolute;
    width: 16px;
    height: 16px;
    display: inline-block;
    left: 10px;
    top: 10px;
  }
  .rs-radio-wrapper [type='radio'] {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .rs-radio-wrapper::before,
  .rs-radio-wrapper::after,
  .rs-radio-wrapper .rs-radio-inner::before,
  .rs-radio-wrapper .rs-radio-inner::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
  }
  .rs-radio-wrapper::before,
  .rs-radio-wrapper .rs-radio-inner::before {
    width: 16px;
    height: 16px;
  }
  .rs-radio-wrapper::before {
    border: 1px solid #3498ff;
    border: 1px solid #3498ff;
    background-color: transparent;
    border-radius: 50%;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.7;
    visibility: hidden;
    -webkit-transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear, -webkit-transform 0.2s linear;
  }
  .rs-theme-high-contrast .rs-radio-wrapper::before {
    -webkit-transition: none;
    transition: none;
  }
  .rs-radio-checked .rs-radio-wrapper::before {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
    visibility: visible;
  }
  .rs-radio-wrapper::after {
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }
  .rs-radio-inner::before {
    border: 1px solid #d9d9d9;
    border: 1px solid #d9d9d9;
    background-color: transparent;
    border-radius: 50%;
    -webkit-transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear, -webkit-box-shadow 0.2s linear;
  }
  .rs-theme-high-contrast .rs-radio-inner::before {
    -webkit-transition: none;
    transition: none;
  }
  .rs-radio:hover .rs-radio-inner::before {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-radio.rs-radio-disabled .rs-radio-inner::before {
    border-color: #f7f7fa;
    border-color: #f7f7fa;
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-radio.rs-radio-checked .rs-radio-inner::before {
    border-color: #3498ff;
    border-color: #3498ff;
    background-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-radio.rs-radio-disabled.rs-radio-checked .rs-radio-inner::before {
    opacity: 0.3;
  }
  .rs-theme-high-contrast .rs-radio.rs-radio-disabled.rs-radio-checked .rs-radio-inner::before {
    opacity: 0.5;
  }
  .rs-radio-inner::after {
    width: 6px;
    height: 6px;
    background-color: #fff;
    background-color: #fff;
    margin-top: 5px;
    margin-left: 5px;
    border-radius: 50%;
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46), -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  }
  .rs-theme-high-contrast .rs-radio-inner::after {
    -webkit-transition: none;
    transition: none;
  }
  .rs-radio-checked .rs-radio-inner::after {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  .rs-radio-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .rs-radio-group > .rs-radio {
    margin-left: -10px;
  }
  .rs-radio-group-inline {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .rs-radio-group-picker {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    color: #575757;
    color: #575757;
    border: 1px solid #e5e5ea;
    border: 1px solid #e5e5ea;
    border-radius: 6px;
  }
  .rs-radio-group-picker .rs-radio-inline {
    padding: 0 10px;
    margin: 0;
  }
  .rs-radio-group-picker .rs-radio-inline:first-child {
    padding-left: 12px;
  }
  .rs-radio-group-picker .rs-radio-inline:last-child {
    padding-right: 12px;
  }
  .rs-radio-group-picker .rs-radio-checker {
    padding: 0;
    min-height: auto;
  }
  .rs-radio-group-picker .rs-radio-wrapper {
    display: none;
  }
  .rs-radio-group-picker .rs-radio-checker > label {
    display: inline-block;
    white-space: nowrap;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 0;
    color: #8e8e93;
    color: #8e8e93;
    background: none;
    -webkit-transition: color 0.3s linear;
    transition: color 0.3s linear;
    padding: 7px 0 5px;
    border-bottom: 2px solid transparent;
  }
  .rs-theme-high-contrast .rs-radio-group-picker .rs-radio-checker > label {
    -webkit-transition: none;
    transition: none;
  }
  .rs-radio-group-picker .rs-radio-checker > label:hover {
    color: #1675e0;
    color: #1675e0;
    background: none;
  }
  .rs-radio-group-picker .rs-radio-checker > label:active {
    color: #1675e0;
    color: #1675e0;
    background: none;
  }
  .rs-radio-group-picker .rs-radio-checked .rs-radio-checker > label {
    color: #1675e0;
    color: #1675e0;
    border-color: #1675e0;
    border-color: #1675e0;
  }
  .rs-radio-group-picker .rs-radio-disabled .rs-radio-checker {
    opacity: 0.3;
    cursor: not-allowed;
  }
  .rs-radio-group-picker .rs-radio-disabled:not(.rs-radio-checked) .rs-radio-checker > label {
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-radio-tile {
    border-radius: 6px;
    overflow: hidden;
    border: 2px solid #d9d9d9;
    border: 2px solid #d9d9d9;
    padding: 10px;
    position: relative;
    cursor: pointer;
  }
  .rs-radio-tile-label {
    font-weight: bold;
  }
  .rs-radio-tile-content {
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-radio-tile-mark {
    background: #3498ff;
    background: #3498ff;
    border-bottom-left-radius: 50%;
    height: 48px;
    position: absolute;
    right: -24px;
    top: -24px;
    width: 48px;
    z-index: 3;
    opacity: 0;
  }
  .rs-radio-tile-mark-icon {
    position: absolute;
    font-size: 16px;
    top: 25px;
    left: 7px;
    color: #fff;
    color: #fff;
  }
  .rs-radio-tile-checked .rs-radio-tile-mark {
    opacity: 1;
  }
  .rs-radio-tile-checked.rs-radio-tile-disabled {
    border-color: #cce9ff;
    border-color: #cce9ff;
  }
  .rs-radio-tile-checked.rs-radio-tile-disabled .rs-radio-tile-mark {
    background-color: #cce9ff;
    background-color: #cce9ff;
  }
  .rs-radio-tile-checked {
    border: 2px solid #3498ff;
    border: 2px solid #3498ff;
  }
  .rs-radio-tile:hover:not(.rs-radio-tile-disabled) {
    border: 2px solid #3498ff;
    border: 2px solid #3498ff;
  }
  .rs-radio-tile-disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    cursor: not-allowed;
  }
  .rs-radio-tile-disabled .rs-radio-tile-content {
    color: #c5c6c7;
    color: #c5c6c7;
    cursor: not-allowed;
  }
  .rs-radio-tile input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
  }
  .rs-radio-tile-icon {
    font-size: 32px;
    font-size: 32px;
  }
  .rs-ripple {
    position: absolute;
    display: block;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition: 0s;
    transition: 0s;
  }
  .rs-ripple-rippling {
    -webkit-transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
  .rs-ripple-pond {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  .rs-theme-high-contrast .rs-ripple-pond {
    display: none !important;
  }
  .rs-row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .rs-row::before,
  .rs-row::after {
    content: ' ';
    display: table;
  }
  .rs-row::after {
    clear: both;
  }
  .rs-picker-select-menu-items {
    margin-bottom: 6px;
    overflow-y: auto;
  }
  .rs-picker-select-menu-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .rs-picker-select-menu-items .rs-picker-menu-group:not(:first-child) {
    margin-top: 6px;
    border-top: 1px solid #e5e5ea;
    border-top: 1px solid #e5e5ea;
    padding-top: 6px;
  }
  .rs-picker-select-menu-items .rs-picker-menu-group-title {
    padding: 8px 12px;
    padding-right: 32px;
    position: relative;
    cursor: pointer;
    color: #272c36;
    color: #272c36;
  }
  .rs-picker-select-menu-items .rs-picker-menu-group-title .rs-picker-menu-group-caret {
    display: inline-block;
    margin-left: 2px;
    position: absolute;
    top: 8px;
    right: 12px;
    padding: 3px;
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-picker-select-menu-items .rs-picker-menu-group.folded .rs-picker-menu-group-caret {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .rs-picker-select-menu-item {
    display: block;
    padding: 8px 12px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #575757;
    color: #575757;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
  }
  .rs-picker-select-menu-item:hover {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-picker-select-menu-item:focus {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-theme-high-contrast .rs-picker-select-menu-item:hover {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-picker-select-menu-item:focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-select-menu-item:hover,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-select-menu-item:focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
    outline-offset: 2px;
  }
  .rs-picker-select-menu-item.rs-picker-select-menu-item-active {
    font-weight: bold;
    color: #1675e0;
    color: #1675e0;
    background-color: #f2faff;
    background-color: #f2faff;
  }
  .rs-picker-select-menu-item.rs-picker-select-menu-item-disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
    cursor: not-allowed;
  }
  .grouped .rs-picker-select-menu-item,
  .rs-picker-select-menu-group-children .rs-picker-select-menu-item {
    padding-left: 26px;
  }
  .rs-picker-menu-group .rs-picker-select-menu-item {
    padding-left: 26px;
  }
  .rs-picker-menu-group.folded [role='option'] {
    display: none;
  }
  .rs-sidebar-collapse {
    -webkit-transition: width 0.15s ease-in, -webkit-box-flex 0.15s ease-in;
    transition: width 0.15s ease-in, -webkit-box-flex 0.15s ease-in;
    transition: flex 0.15s ease-in, width 0.15s ease-in;
    transition: flex 0.15s ease-in, width 0.15s ease-in, -webkit-box-flex 0.15s ease-in, -ms-flex 0.15s ease-in;
    min-width: 0;
  }
  .rs-sidenav {
    -webkit-transition: width 0.15s ease-in;
    transition: width 0.15s ease-in;
    width: 56px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .rs-sidenav-nav {
    list-style: none;
    padding: 0;
  }
  .rs-sidenav-nav .rs-sidenav-item,
  .rs-sidenav-nav .rs-dropdown-toggle,
  .rs-sidenav-nav .rs-dropdown-item,
  .rs-sidenav-nav .rs-dropdown-item-toggle {
    padding: 15px 20px;
    -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
    transition: color 0.15s ease-out, background-color 0.15s ease-out;
    border-radius: 0;
    color: inherit;
  }
  .rs-theme-high-contrast .rs-sidenav-nav .rs-sidenav-item,
  .rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-toggle,
  .rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-item,
  .rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-item-toggle {
    -webkit-transition: none;
    transition: none;
  }
  .rs-sidenav-nav > .rs-sidenav-item,
  .rs-sidenav-nav > .rs-dropdown {
    margin: 0 !important;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle {
    display: block;
    width: 100%;
    text-align: start;
    background: none;
    padding-right: 40px;
    position: relative;
  }
  .rs-sidenav-collapse-in .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle {
    padding-left: 56px;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle:focus {
    outline: 0;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline-offset: -3px;
    z-index: 1;
  }
  .rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle:focus-visible {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle:focus-visible {
    outline-offset: 2px;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle-icon {
    font-size: 16px;
    position: absolute;
    right: 20px;
    top: 11px;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-expand-icon {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-collapse-icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-toggle,
  .rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-menu {
    border: none;
  }
  .rs-sidenav-nav .rs-sidenav-item:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline-offset: -3px;
    z-index: 1;
  }
  .rs-theme-high-contrast .rs-sidenav-nav .rs-sidenav-item:focus-visible {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-sidenav-nav .rs-sidenav-item:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-nav .rs-sidenav-item:focus-visible {
    outline-offset: 2px;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-menu-item-focus,
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-active {
    font-weight: normal;
    background: transparent;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-menu {
    -webkit-transition: height 0.15s ease-in;
    transition: height 0.15s ease-in;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle {
    height: auto;
    padding-right: 40px;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle .rs-dropdown-toggle-caret {
    font-size: 16px;
    right: 20px;
    top: 15px;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle .rs-dropdown-toggle-caret[aria-label='angle-right'] {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .rs-sidenav-nav > .rs-sidenav-item,
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle {
    font-size: 16px;
    line-height: 1.25;
    padding-left: 56px;
    width: 100%;
    white-space: normal;
  }
  .rs-sidenav-nav > .rs-sidenav-item:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline-offset: -3px;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline-offset: -3px;
  }
  .rs-theme-high-contrast .rs-sidenav-nav > .rs-sidenav-item:focus-visible,
  .rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle:focus-visible {
    outline-offset: 2px;
  }
  .rs-sidenav-nav > .rs-sidenav-item > .rs-icon:not(.rs-dropdown-toggle-caret),
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle > .rs-icon:not(.rs-dropdown-toggle-caret) {
    font-size: 16px;
    margin-right: 20px;
    position: absolute;
    left: 20px;
    top: 15px;
    line-height: 1.25;
    height: 16px;
  }
  .rs-sidenav-nav > .rs-dropdown-focus .rs-dropdown-toggle {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
    z-index: 2;
  }
  .rs-sidenav-nav .rs-dropdown-toggle:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
    z-index: 2;
  }
  .rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown-focus .rs-dropdown-toggle,
  .rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-toggle:focus-visible {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown-focus .rs-dropdown-toggle {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-toggle:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown-focus .rs-dropdown-toggle,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-toggle:focus-visible {
    outline-offset: 2px;
  }
  .rs-sidenav-item {
    position: relative;
    display: block;
    outline: 0;
    overflow: hidden;
  }
  .rs-sidenav-item:hover,
  .rs-sidenav-item:focus {
    text-decoration: none;
  }
  .rs-sidenav-item:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline-offset: -3px;
    outline-width: 2px;
    z-index: 2;
  }
  .rs-sidenav-item.rs-sidenav-item-focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline-offset: -3px;
    outline-width: 2px;
    z-index: 2;
  }
  .rs-theme-high-contrast .rs-sidenav-item:focus-visible,
  .rs-theme-high-contrast .rs-sidenav-item.rs-sidenav-item-focus {
    outline-offset: 2px;
  }
  .rs-sidenav-toggle {
    height: 50px;
    border-top: 1px solid;
    margin-top: auto;
  }
  .rs-sidenav-toggle-button {
    float: right;
    width: 56px;
    height: 100%;
    border: none;
    border-radius: 0;
    color: inherit;
  }
  .rs-sidenav-collapse-in {
    width: 100%;
  }
  .rs-sidenav-collapse-in .rs-sidenav-item {
    border-radius: 0;
  }
  .rs-sidenav-collapse-in .rs-dropdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline-offset: -3px;
    background: none;
    z-index: 2;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline-offset: -3px;
    background: none;
    z-index: 2;
  }
  .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible,
  .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item-focus {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item-focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item-focus {
    outline-offset: 2px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible.rs-dropdown-item-submenu,
  .rs-sidenav-collapse-in .rs-dropdown-item-focus.rs-dropdown-item-submenu {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible.rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline-offset: -3px;
    z-index: 2;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-focus.rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline-offset: -3px;
    z-index: 2;
  }
  .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible.rs-dropdown-item-submenu > .rs-dropdown-item-toggle,
  .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item-focus.rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible.rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item-focus.rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible.rs-dropdown-item-submenu > .rs-dropdown-item-toggle,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item-focus.rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
    outline-offset: 2px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu),
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
    overflow: hidden;
    position: relative;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu),
    .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
      /* stylelint-disable */
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      /* stylelint-enable */
    }
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-disabled .rs-sidenav-collapse-in .rs-dropdown-item::after {
    display: none;
  }
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-toggle-caret::before,
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-item-toggle > .rs-dropdown-item-toggle-icon::before {
    display: none;
  }
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-toggle-caret,
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-item-toggle > .rs-dropdown-item-toggle-icon {
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
  }
  .rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-expand .rs-dropdown-toggle-caret {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-menu {
    display: block;
    position: static;
    float: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-animation-name: none;
            animation-name: none;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-item-submenu {
    padding: 0;
  }
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-item-submenu:hover {
    background-color: transparent;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu),
  .rs-sidenav-collapse-in .rs-dropdown-menu .rs-dropdown-menu-toggle {
    width: 100%;
    white-space: normal;
  }
  .rs-sidenav-collapse-in .rs-dropdown-menu {
    overflow: hidden;
  }
  .rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-collapse > .rs-dropdown-menu.rs-dropdown-menu-collapse-out,
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu.rs-dropdown-item-collapse > .rs-dropdown-menu.rs-dropdown-menu-collapse-out {
    display: none;
  }
  .rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-menu-collapse-in > .rs-dropdown-menu.dropdown-menu-collapse-in,
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu.rs-dropdown-menu-collapse-in > .rs-dropdown-menu.rs-dropdown-menu-collapse-in {
    display: block;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-menu-icon {
    padding-top: 11px;
    padding-bottom: 11px;
    line-height: 1.42857143;
    width: 14px;
    position: absolute;
    z-index: 1;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item,
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
    padding: 11px 20px 11px 56px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > .rs-dropdown-item-menu-icon {
    left: 56px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
    padding-left: 76px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item .rs-dropdown-item:not(.rs-dropdown-item-submenu),
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-menu .rs-dropdown-item-toggle {
    padding-left: 65px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > .rs-dropdown-item-menu-icon {
    left: 65px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
    padding-left: 85px;
  }
  .rs-sidenav-collapse-out .rs-sidenav-item,
  .rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle {
    padding-right: 0;
    height: 50px;
    padding-left: 56px;
  }
  .rs-sidenav-collapse-out .rs-sidenav-item:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
  }
  .rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
  }
  .rs-theme-high-contrast .rs-sidenav-collapse-out .rs-sidenav-item:focus-visible,
  .rs-theme-high-contrast .rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle:focus-visible {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-sidenav-collapse-out .rs-sidenav-item:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-collapse-out .rs-sidenav-item:focus-visible,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle:focus-visible {
    outline-offset: 2px;
  }
  .rs-sidenav-collapse-out .rs-dropdown-item {
    padding: 8px 27px 8px 12px;
  }
  .rs-sidenav-collapse-out .rs-dropdown-item-submenu {
    padding: 0;
  }
  .rs-sidenav-collapse-out .rs-dropdown-toggle-caret {
    display: none;
  }
  .rs-sidenav-collapse-out .rs-sidenav-item-text,
  .rs-sidenav-collapse-out .rs-dropdown-toggle > span {
    -webkit-animation: sideNavFoldedText 0.15s ease-in forwards;
            animation: sideNavFoldedText 0.15s ease-in forwards;
  }
  .rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-sidenav-item,
  .rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-sidenav-item,
  .rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-toggle,
  .rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle,
  .rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-dropdown-item,
  .rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown-item,
  .rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-menu-toggle,
  .rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown-item-submenu > .rs-dropdown-menu-toggle {
    white-space: nowrap;
    text-overflow: clip;
  }
  .rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-dropdown-toggle-caret {
    display: none;
  }
  .rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-menu {
    padding-left: 0px;
  }
  .rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown-item {
    -webkit-animation: sideNavFoldedText 0.15s ease-in;
            animation: sideNavFoldedText 0.15s ease-in;
  }
  .rs-sidenav-default {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default .rs-sidenav-item {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-sidenav-default .rs-dropdown-toggle {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-sidenav-default .rs-sidenav-toggle-button {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-sidenav-default .rs-sidenav-item:hover {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default .rs-dropdown .rs-dropdown-toggle:hover {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default .rs-sidenav-toggle-button:hover {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default .rs-dropdown.rs-dropdown-open .rs-dropdown-toggle {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default .rs-sidenav-item.rs-sidenav-item-active {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-sidenav-default .rs-dropdown-item.rs-dropdown-item-active {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-sidenav-default .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-sidenav-default .rs-sidenav-toggle {
    border-top-color: #e5e5ea;
    border-top-color: #e5e5ea;
  }
  .rs-sidenav-default.rs-sidenav-collapse-out .rs-dropdown-item.rs-dropdown-item-active {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-theme-high-contrast .rs-sidenav-default.rs-sidenav-collapse-out .rs-dropdown-item.rs-dropdown-item-active {
    text-decoration: underline;
    -webkit-box-shadow: inset 0 0 0 2px #fff;
            box-shadow: inset 0 0 0 2px #fff;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle {
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle {
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle {
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle {
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle:hover {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle:hover {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle:hover {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle:hover {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle:focus {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle:focus {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle:focus {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle:focus {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle.rs-dropdown-item-focus {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle.rs-dropdown-item-focus {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle.rs-dropdown-item-focus {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle.rs-dropdown-item-focus {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-sidenav-item.rs-sidenav-item-active {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-sidenav-default.rs-sidenav-collapsing .rs-sidenav-item.rs-sidenav-item-active {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-theme-high-contrast .rs-sidenav-default.rs-sidenav-collapse-in .rs-sidenav-item.rs-sidenav-item-active,
  .rs-theme-high-contrast .rs-sidenav-default.rs-sidenav-collapsing .rs-sidenav-item.rs-sidenav-item-active,
  .rs-theme-high-contrast .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active,
  .rs-theme-high-contrast .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active {
    text-decoration: underline;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-sidenav-default.rs-sidenav-collapse-out .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-sidenav-inverse {
    background-color: #3498ff;
    background-color: #3498ff;
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-sidenav-item {
    background-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle {
    background-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-sidenav-inverse .rs-sidenav-toggle-button {
    background-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-sidenav-inverse .rs-dropdown-item-submenu:hover > .rs-dropdown-item-toggle {
    background-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-sidenav-inverse .rs-sidenav-item:hover {
    background-color: #2589f5;
    background-color: #2589f5;
  }
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle:hover {
    background-color: #2589f5;
    background-color: #2589f5;
  }
  .rs-sidenav-inverse .rs-sidenav-toggle-button:hover {
    background-color: #2589f5;
    background-color: #2589f5;
  }
  .rs-sidenav-inverse .rs-dropdown-item-submenu:hover > .rs-dropdown-item-toggle:hover {
    background-color: #2589f5;
    background-color: #2589f5;
  }
  .rs-sidenav-inverse .rs-sidenav-item:focus {
    background-color: #2589f5;
    background-color: #2589f5;
  }
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle:focus {
    background-color: #2589f5;
    background-color: #2589f5;
  }
  .rs-sidenav-inverse .rs-sidenav-toggle-button:focus {
    background-color: #2589f5;
    background-color: #2589f5;
  }
  .rs-sidenav-inverse .rs-dropdown-item-submenu:hover > .rs-dropdown-item-toggle:focus {
    background-color: #2589f5;
    background-color: #2589f5;
  }
  .rs-sidenav-inverse .rs-dropdown-item {
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-menu-toggle {
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-item-toggle {
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-sidenav-toggle-button {
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-dropdown-item:hover {
    background-color: #2589f5;
    background-color: #2589f5;
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-menu-toggle:hover {
    background-color: #2589f5;
    background-color: #2589f5;
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-item-toggle:hover {
    background-color: #2589f5;
    background-color: #2589f5;
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-sidenav-toggle-button:hover {
    background-color: #2589f5;
    background-color: #2589f5;
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-dropdown-item:focus {
    background-color: #2589f5;
    background-color: #2589f5;
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-menu-toggle:focus {
    background-color: #2589f5;
    background-color: #2589f5;
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-item-toggle:focus {
    background-color: #2589f5;
    background-color: #2589f5;
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-sidenav-toggle-button:focus {
    background-color: #2589f5;
    background-color: #2589f5;
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-dropdown-item.rs-dropdown-item-focus {
    background-color: #2589f5;
    background-color: #2589f5;
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-menu-toggle.rs-dropdown-item-focus {
    background-color: #2589f5;
    background-color: #2589f5;
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-item-toggle.rs-dropdown-item-focus {
    background-color: #2589f5;
    background-color: #2589f5;
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-sidenav-toggle-button.rs-dropdown-item-focus {
    background-color: #2589f5;
    background-color: #2589f5;
    color: #fff;
    color: #fff;
  }
  .rs-theme-high-contrast .rs-sidenav-inverse .rs-sidenav-item.rs-sidenav-item-active {
    color: #ffff00;
  }
  .rs-theme-high-contrast .rs-sidenav-inverse .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle {
    color: #ffff00;
  }
  .rs-sidenav-inverse .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle {
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-sidenav-item {
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle {
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:hover {
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-sidenav-item:hover {
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle:hover {
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:focus {
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-sidenav-item:focus {
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle:focus {
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle.rs-dropdown-item-focus {
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-sidenav-item.rs-dropdown-item-focus {
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle.rs-dropdown-item-focus {
    color: #fff;
    color: #fff;
  }
  .rs-sidenav-inverse .rs-sidenav-item.rs-sidenav-item-active {
    background-color: #1675e0;
    background-color: #1675e0;
  }
  .rs-sidenav-inverse .rs-dropdown-item.rs-dropdown-item-active {
    background-color: #1675e0;
    background-color: #1675e0;
  }
  .rs-sidenav-inverse .rs-sidenav-toggle {
    border-top-color: #2589f5;
    border-top-color: #2589f5;
  }
  .rs-sidenav-subtle {
    background-color: transparent;
  }
  .rs-sidenav-subtle .rs-sidenav-item {
    background-color: transparent;
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-sidenav-subtle .rs-dropdown-toggle {
    background-color: transparent;
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-sidenav-subtle .rs-dropdown-item {
    background-color: transparent;
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-sidenav-subtle .rs-sidenav-toggle-button {
    background-color: transparent;
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-sidenav-subtle .rs-sidenav-item:hover {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-subtle .rs-dropdown-toggle:hover {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-subtle .rs-dropdown-item:hover {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-subtle .rs-sidenav-toggle-button:hover {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-subtle .rs-sidenav-item:focus-visible {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-subtle .rs-dropdown-toggle:focus-visible {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-subtle .rs-dropdown-item:focus-visible {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-subtle .rs-sidenav-toggle-button:focus-visible {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-subtle .rs-sidenav-item.rs-sidenav-item-active {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-sidenav-subtle .rs-dropdown-item.rs-dropdown-item-active {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-sidenav-subtle .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-sidenav-subtle .rs-dropdown-open > .rs-dropdown-toggle.btn {
    background-color: transparent;
  }
  .rs-sidenav-subtle .rs-dropdown-open > .rs-dropdown-toggle.btn:hover {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-sidenav-subtle .rs-dropdown-open > .rs-dropdown-toggle.btn:focus {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-sidenav-subtle .rs-sidenav-toggle {
    border-top-color: #e5e5ea;
    border-top-color: #e5e5ea;
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle {
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle {
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle {
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle {
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle:hover {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle:hover {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle:hover {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle:hover {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle:focus-visible {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle:focus-visible {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle:focus-visible {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle:focus-visible {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #575757;
    color: #575757;
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:hover {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:hover {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-out .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:hover {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle-icon {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle-icon {
    color: #1675e0;
    color: #1675e0;
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-out .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle-icon {
    color: #1675e0;
    color: #1675e0;
  }
  @-webkit-keyframes sideNavFoldedText {
    0% {
      max-width: 200px;
      opacity: 0.8;
    }
    100% {
      max-width: 0;
      opacity: 0;
    }
  }
  @keyframes sideNavFoldedText {
    0% {
      max-width: 200px;
      opacity: 0.8;
    }
    100% {
      max-width: 0;
      opacity: 0;
    }
  }
  .rs-slider {
    position: relative;
  }
  .rs-slider .rs-tooltip {
    display: none;
  }
  .rs-slider .rs-tooltip.rs-tooltip-placement-top .rs-tooltip::after {
    margin: auto;
    left: 0;
    right: 0;
  }
  .rs-slider-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  .rs-slider-disabled .rs-slider-bar,
  .rs-slider-disabled .rs-slider-handle::before {
    cursor: not-allowed;
  }
  .rs-slider-with-mark:not(.rs-slider-vertical) {
    margin-bottom: 29px;
  }
  .rs-slider-bar {
    height: 6px;
    border-radius: 3px;
    background-color: #f2f2f5;
    background-color: #f2f2f5;
    -webkit-transition: background-color ease-in-out 0.3s;
    transition: background-color ease-in-out 0.3s;
    cursor: pointer;
  }
  .rs-slider:hover .rs-slider-bar {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-slider-vertical .rs-slider-bar {
    height: 100%;
    width: 6px;
  }
  .rs-slider-handle {
    position: absolute;
    top: -50%;
    outline: none;
  }
  .rs-slider-handle::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #3498ff;
    border: 2px solid #3498ff;
    background-color: #fff;
    background-color: #fff;
    margin-left: -6px;
    cursor: pointer;
    /* stylelint-disable */
    -webkit-transition: background-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out, transform 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out, transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    /* stylelint-enable */
  }
  .rs-slider-handle:hover::before {
    -webkit-box-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
    -webkit-box-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
            box-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
            box-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
  }
  .rs-slider-handle:focus::before {
    -webkit-box-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
    -webkit-box-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
            box-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
            box-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
  }
  .rs-slider-handle:active::before,
  .rs-slider-handle.active::before {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  .rs-slider-vertical .rs-slider-handle {
    top: unset;
  }
  .rs-slider-vertical .rs-slider-handle::before {
    left: 3px;
    margin-top: -6px;
  }
  .rs-slider-handle:hover .rs-tooltip,
  .rs-slider-handle.active .rs-tooltip {
    display: block;
    opacity: 1;
    top: -30px;
  }
  .rs-slider-vertical .rs-slider-handle:hover .rs-tooltip,
  .rs-slider-vertical .rs-slider-handle.active .rs-tooltip {
    top: -33px;
    margin-left: 3px;
  }
  .rs-slider-mark {
    position: absolute;
    top: 15px;
    left: -2px;
    white-space: nowrap;
  }
  .rs-slider-mark-content {
    margin-left: -50%;
  }
  .rs-slider-mark-last {
    left: auto;
    right: -2px;
  }
  .rs-slider-mark-last .rs-slider-mark-content {
    margin-left: 50%;
  }
  .rs-slider-graduator {
    width: 100%;
  }
  .rs-slider-graduator ol,
  .rs-slider-graduator li {
    list-style: none;
  }
  .rs-slider-graduator > ol {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    width: 100%;
  }
  .rs-slider-graduator > ol > li {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 1%;
            flex: 1 1 1%;
    position: relative;
  }
  .rs-slider-graduator > ol > li:last-child::after {
    content: '';
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    background-color: #fff;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border: 2px solid #f2f2f5;
    border: 2px solid #f2f2f5;
    margin-left: -4px;
    top: -1px;
  }
  .rs-slider-graduator > ol > li::before {
    content: '';
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    background-color: #fff;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border: 2px solid #f2f2f5;
    border: 2px solid #f2f2f5;
    margin-left: -4px;
    top: -1px;
  }
  .rs-slider-vertical .rs-slider-graduator > ol > li:last-child::after,
  .rs-slider-vertical .rs-slider-graduator > ol > li::before {
    top: unset;
    bottom: -4px;
    margin-left: -1px;
  }
  .rs-slider-graduator > ol > li:last-child::after {
    right: -4px;
  }
  .rs-slider-vertical .rs-slider-graduator > ol > li:last-child::after {
    left: 0;
    bottom: unset;
    top: -4px;
  }
  .rs-slider-graduator > ol > li.rs-slider-pass::before {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-slider-graduator > ol > li.rs-slider-active::before {
    visibility: hidden;
  }
  .rs-slider-vertical .rs-slider-graduator {
    display: block;
    height: 100%;
  }
  .rs-slider-vertical .rs-slider-graduator > ol {
    width: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    height: 100%;
    padding: 0;
  }
  .rs-slider-vertical .rs-slider-graduator > ol > li {
    display: block;
    padding: 0;
  }
  .rs-slider-progress-bar {
    position: absolute;
    height: 6px;
    border-radius: 3px 0 0 3px;
    background-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-slider-vertical .rs-slider-progress-bar {
    width: 6px;
    border-radius: 0 0 3px 3px;
  }
  .rs-slider-vertical {
    height: 100%;
  }
  .rs-slider-vertical .rs-slider-mark {
    top: unset;
    bottom: -8px;
    left: 15px;
  }
  .rs-slider-vertical .rs-slider-mark-content {
    margin-left: auto;
  }
  .rs-slider-vertical .rs-slider-mark-last {
    bottom: unset;
    top: -8px;
  }
  .rs-steps {
    min-height: 30px;
  }
  .rs-steps-item {
    padding-left: 40px;
    position: relative;
    overflow: hidden;
  }
  .rs-steps-item-icon-wrapper {
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 16px;
    line-height: 1.75;
    color: #8e8e93;
    color: #8e8e93;
    position: absolute;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 1px solid #8e8e93;
    border: 1px solid #8e8e93;
    border-radius: 50%;
  }
  .rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
    border-color: #3498ff;
    border-color: #3498ff;
    color: #3498ff;
    color: #3498ff;
  }
  .rs-steps-item-status-error .rs-steps-item-icon-wrapper {
    border-color: #f44336;
    border-color: #f44336;
    color: #f44336;
    color: #f44336;
  }
  .rs-steps-item-status-process .rs-steps-item-icon-wrapper {
    border-color: #3498ff;
    border-color: #3498ff;
    background-color: #3498ff;
    background-color: #3498ff;
    color: #f7f7fa;
    color: #f7f7fa;
  }
  .rs-steps-item-status-process .rs-steps-item-icon-wrapper .rs-icon {
    color: #3498ff;
    color: #3498ff;
  }
  .rs-steps-item-icon-wrapper.rs-steps-item-custom-icon {
    border: none;
    background: none;
  }
  .rs-steps-item-icon-wrapper > .rs-steps-item-icon {
    width: 100%;
    display: block;
    text-align: center;
    position: relative;
  }
  .rs-steps-item-icon-wrapper > .rs-steps-item-icon .rs-icon {
    font-size: 14px;
    vertical-align: middle;
  }
  .rs-steps-item-content {
    color: #8e8e93;
    color: #8e8e93;
    display: inline-block;
    position: relative;
    width: 100%;
  }
  .rs-steps-item-status-process .rs-steps-item-content {
    color: #575757;
    color: #575757;
  }
  .rs-steps-item-status-error .rs-steps-item-content {
    color: #f44336;
    color: #f44336;
  }
  .rs-steps-item-title {
    position: relative;
    font-size: 16px;
    line-height: 1.875;
    min-height: 16px;
    padding-right: 10px;
    display: inline-block;
  }
  .rs-steps-item-title:empty {
    padding-right: 0;
  }
  .rs-steps-item-description {
    margin-top: 12px;
  }
  .rs-steps-item-tail {
    position: absolute;
    border-color: #8e8e93;
    border-color: #8e8e93;
  }
  .rs-steps-item-title::after {
    position: absolute;
    border-color: #8e8e93;
    border-color: #8e8e93;
  }
  .rs-steps-item-status-process .rs-steps-item-tail {
    border-color: #8e8e93;
    border-color: #8e8e93;
  }
  .rs-steps-item-status-process .rs-steps-item-title::after {
    border-color: #8e8e93;
    border-color: #8e8e93;
  }
  .rs-steps-item-status-finish .rs-steps-item-tail {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-steps-item-status-finish .rs-steps-item-title::after {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-steps-next-error .rs-steps-item-tail {
    border-color: #f44336;
    border-color: #f44336;
  }
  .rs-steps-next-error .rs-steps-item-title::after {
    border-color: #f44336;
    border-color: #f44336;
  }
  .rs-steps-horizontal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .rs-steps-horizontal .rs-steps-item {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    min-height: 30px;
  }
  .rs-steps-horizontal .rs-steps-item:last-child {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }
  .rs-steps-horizontal .rs-steps-item:last-child .rs-steps-item-title::after {
    display: none;
  }
  .rs-steps-horizontal .rs-steps-item:not(:first-child) {
    padding-left: 50px;
  }
  .rs-steps-horizontal .rs-steps-item:not(:first-child) .rs-steps-item-icon-wrapper {
    left: 10px;
  }
  .rs-steps-horizontal .rs-steps-item-content {
    height: 30px;
  }
  .rs-steps-horizontal .rs-steps-item-title::after {
    content: '';
    top: 15px;
    left: 100%;
    width: 9999px;
    border-top-width: 1px;
    border-top-style: solid;
  }
  .rs-steps-vertical .rs-steps-item {
    padding-bottom: 20px;
  }
  .rs-steps-vertical .rs-steps-item:not(:first-child) {
    margin-top: 10px;
  }
  .rs-steps-vertical .rs-steps-item:last-child .rs-steps-item-tail {
    display: none;
  }
  .rs-steps-vertical .rs-steps-item-tail {
    top: 40px;
    bottom: 0;
    left: 15px;
    border-left-width: 1px;
    border-left-style: solid;
  }
  .rs-steps-small {
    min-height: 24px;
  }
  .rs-steps-small.rs-steps-horizontal .rs-steps-item {
    min-height: 24px;
  }
  .rs-steps-small.rs-steps-horizontal .rs-steps-item:not(:first-child) {
    padding-left: 44px;
  }
  .rs-steps-small.rs-steps-vertical .rs-steps-item-tail {
    left: 12px;
  }
  .rs-steps-small.rs-steps-horizontal .rs-steps-item-title::after {
    top: 12px;
  }
  .rs-steps-small .rs-steps-item {
    padding-left: 34px;
  }
  .rs-steps-small .rs-steps-item-icon-wrapper {
    width: 24px;
    height: 24px;
    font-size: 14px;
    line-height: 1.57142857;
  }
  .rs-steps-small .rs-steps-item-title {
    font-size: 14px;
    line-height: 1.71428571;
  }
  .rs-steps-small .rs-steps-item-description {
    margin-top: 12px;
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-table {
    position: relative;
    overflow: hidden;
  }
  .rs-table-column-resizing {
    cursor: ew-resize;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .rs-table-row {
    overflow: hidden;
    position: absolute;
    height: 36px;
    width: 100%;
    top: 0;
  }
  .rs-table-row.virtualized {
    pointer-events: none;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-table-row.virtualized {
      visibility: hidden;
    }
  }
  .rs-table-row-expanded {
    position: absolute;
    height: 46px;
    bottom: 0;
    width: 100%;
    z-index: 4;
    border-top: 1px solid #f2f2f5;
    border-top: 1px solid #f2f2f5;
    padding: 10px;
    background-color: #fff;
    background-color: #fff;
  }
  .rs-table-hover .rs-table-row:hover .rs-table-cell-group {
    background-color: #fff;
    background-color: #fff;
  }
  .rs-table-hover .rs-table-row:hover .rs-table-cell {
    background-color: #fff;
    background-color: #fff;
  }
  .rs-table-row-header {
    background-color: #fff;
    background-color: #fff;
  }
  .rs-table-row-header .rs-table-cell {
    background-color: #fff;
    background-color: #fff;
  }
  .rs-table-row-header .rs-table-cell-group-shadow {
    z-index: 5;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover {
    background-color: #f2faff;
    background-color: #f2faff;
  }
  .rs-table-hover .rs-table-row:hover .rs-table-cell-group {
    background-color: #f2faff;
    background-color: #f2faff;
  }
  .rs-table-hover .rs-table-row:hover .rs-table-cell {
    background-color: #f2faff;
    background-color: #f2faff;
  }
  .rs-table-hover .rs-table-row-header:hover .rs-table-cell-group {
    background-color: #fff;
    background-color: #fff;
  }
  .rs-table-hover .rs-table-row-header:hover .rs-table-cell {
    background-color: #fff;
    background-color: #fff;
  }
  .rs-table-header-row-wrapper {
    z-index: 2;
    position: relative;
    font-size: 12px;
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-table-affix-header {
    z-index: 3;
    visibility: hidden;
  }
  .rs-table-affix-header.fixed {
    visibility: visible;
  }
  .rs-table-body-row-wrapper {
    position: relative;
    overflow: hidden;
    z-index: 0;
  }
  .rs-table-body-info {
    width: 100%;
    text-align: center;
    top: 50%;
    position: absolute;
    margin-top: -20px;
    line-height: 40px;
  }
  .rs-table-body-info .icon {
    margin: 0 10px;
  }
  .rs-table-body-info-wheel-area {
    width: 100%;
  }
  .rs-table-body-wheel-area {
    width: 100%;
  }
  .rs-table-loader-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    background-color: rgba(255, 255, 255, 0.9);
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
    pointer-events: none;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-table-loader-wrapper {
      visibility: hidden;
    }
  }
  .rs-table-loader {
    width: 100%;
    text-align: center;
    top: 50%;
    position: absolute;
    margin-top: -20px;
    line-height: 40px;
  }
  .rs-table-loader-icon {
    width: 18px;
    height: 18px;
    display: inline-block;
    position: relative;
    margin-right: 12px;
    padding-top: 3px;
  }
  .rs-table-loader-icon::before,
  .rs-table-loader-icon::after {
    width: 18px;
    height: 18px;
  }
  .rs-table-loader-icon::before,
  .rs-table-loader-icon::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    border-radius: 50%;
  }
  .rs-table-loader-icon::before {
    border: 3px solid rgba(247, 247, 250, 0.8);
    border: 3px solid rgba(247, 247, 250, 0.8);
  }
  .rs-table-loader-icon::after {
    border-width: 3px;
    border-style: solid;
    border-color: #a6a6a6 transparent transparent;
    border-color: #a6a6a6 transparent transparent;
    -webkit-animation: loaderSpin 0.6s infinite linear;
            animation: loaderSpin 0.6s infinite linear;
  }
  .rs-table-loading .rs-table-loader-wrapper {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    z-index: 1;
  }
  .rs-table-cell {
    height: 36px;
    display: block;
    overflow: hidden;
    position: absolute;
    white-space: normal;
    background-color: #fff;
    background-color: #fff;
    border-bottom: 1px solid #f2f2f5;
    border-bottom: 1px solid #f2f2f5;
  }
  .rs-table-cell.first {
    border-left-width: 0;
  }
  .rs-table-cell-wrap1 {
    display: table;
  }
  .rs-table-cell-wrap2 {
    display: table-row;
  }
  .rs-table-cell-wrap3 {
    display: table-cell;
    vertical-align: middle;
  }
  .rs-table-cell-content {
    padding: 13px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-table-cell-header .rs-table-cell-content {
    padding: 10px 10px;
    line-height: 1.66666667;
  }
  .rs-table-cell-header-sort-wrapper {
    cursor: pointer;
    margin-left: 5px;
    display: inline-block;
  }
  .rs-table-cell-header-icon-sort {
    color: #3498ff;
    color: #3498ff;
    font-size: 16px;
    line-height: 1.66666667;
  }
  .rs-table-cell-rowspan {
    border-bottom: 1px solid #f2f2f5 !important;
    border-bottom: 1px solid #f2f2f5 !important;
  }
  .rs-table-cell-full-text:hover {
    z-index: 1 !important;
    width: auto !important;
    -webkit-box-shadow: inset #3498ff 0px 0px 2px;
    -webkit-box-shadow: inset #3498ff 0px 0px 2px;
            box-shadow: inset #3498ff 0px 0px 2px;
            box-shadow: inset #3498ff 0px 0px 2px;
  }
  .rs-table-cell-full-text:hover .rs-table-cell-content {
    width: auto !important;
  }
  .rs-table-cell-header-sortable .rs-table-cell-content {
    cursor: pointer;
  }
  .rs-table-column-resize-spanner {
    height: 36px;
    width: 3px;
    z-index: 3;
    position: absolute;
    cursor: ew-resize !important;
    outline: none;
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
  }
  .rs-table-column-resize-spanner::after,
  .rs-table-column-resize-spanner::before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    margin: auto;
    bottom: 0;
    top: 0;
    display: none;
  }
  .rs-table-column-resize-spanner::before {
    border-style: dashed solid dashed dashed;
    border-color: transparent #3498ff transparent transparent;
    border-color: transparent #3498ff transparent transparent;
    border-width: 3px;
    right: 4px;
  }
  .rs-table-column-resize-spanner::after {
    border-style: dashed dashed dashed solid;
    border-color: transparent transparent transparent #3498ff;
    border-color: transparent transparent transparent #3498ff;
    border-width: 3px;
    left: 4px;
  }
  .rs-table:not(.rs-table-column-resizing) .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner {
    background-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-table:not(.rs-table-column-resizing) .rs-table-column-resize-spanner:hover {
    background-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-table:not(.rs-table-column-resizing) .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner::before,
  .rs-table:not(.rs-table-column-resizing) .rs-table-column-resize-spanner:hover::before,
  .rs-table:not(.rs-table-column-resizing) .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner::after,
  .rs-table:not(.rs-table-column-resizing) .rs-table-column-resize-spanner:hover::after {
    display: block;
  }
  .rs-table-cell-group-fixed-right {
    position: absolute;
    z-index: 4;
    background-color: #fff;
    background-color: #fff;
  }
  .rs-table-cell-group-fixed-left {
    position: absolute;
    z-index: 4;
    background-color: #fff;
    background-color: #fff;
  }
  .rs-table-cell-group-shadow {
    -webkit-box-shadow: 3px 0 5px rgba(9, 9, 9, 0.08) /* rtl:ignore */;
    -webkit-box-shadow: 3px 0 5px rgba(9, 9, 9, 0.08) ;
            box-shadow: 3px 0 5px rgba(9, 9, 9, 0.08) /* rtl:ignore */;
            box-shadow: 3px 0 5px rgba(9, 9, 9, 0.08) ;
  }
  .rs-table-cell-group-left-shadow {
    -webkit-box-shadow: 3px 0 5px rgba(9, 9, 9, 0.08) /* rtl:ignore */;
    -webkit-box-shadow: 3px 0 5px rgba(9, 9, 9, 0.08) ;
            box-shadow: 3px 0 5px rgba(9, 9, 9, 0.08) /* rtl:ignore */;
            box-shadow: 3px 0 5px rgba(9, 9, 9, 0.08) ;
  }
  .rs-table-cell-group-right-shadow {
    -webkit-box-shadow: -3px 0 5px rgba(9, 9, 9, 0.08) /* rtl:ignore */;
    -webkit-box-shadow: -3px 0 5px rgba(9, 9, 9, 0.08) ;
            box-shadow: -3px 0 5px rgba(9, 9, 9, 0.08) /* rtl:ignore */;
            box-shadow: -3px 0 5px rgba(9, 9, 9, 0.08) ;
  }
  .rs-table-mouse-area {
    display: none;
    background-color: #3498ff;
    background-color: #3498ff;
    left: -1px;
    top: 0;
    position: absolute;
    width: 1px;
    z-index: 6;
  }
  .rs-table-mouse-area > span {
    height: 36px;
    width: 3px;
    z-index: 3;
    cursor: ew-resize !important;
    outline: none;
    background-color: #3498ff;
    background-color: #3498ff;
    position: absolute;
    left: -1.5px;
  }
  .rs-table-word-wrap .rs-table-cell-content {
    white-space: normal;
  }
  .rs-table-bordered {
    border: 1px solid #f2f2f5;
    border: 1px solid #f2f2f5;
  }
  .rs-table-cell-bordered .rs-table-cell {
    border-right: 1px solid #f2f2f5;
    border-right: 1px solid #f2f2f5;
  }
  .rs-table-column-group {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
  }
  .rs-table-column-group-header {
    border-bottom: 1px solid #f2f2f5;
    border-bottom: 1px solid #f2f2f5;
    position: absolute;
    width: 100%;
  }
  .rs-table-column-group-header-content {
    display: table-cell;
    padding: 10px;
  }
  .rs-table-column-group-cell {
    position: absolute;
    border-right: 1px solid #f2f2f5;
    border-right: 1px solid #f2f2f5;
  }
  .rs-table-cell-expand-wrapper {
    margin-right: 10px;
    display: inline-block;
    cursor: pointer;
  }
  .rs-table-cell-expand-icon {
    cursor: pointer;
    outline: none;
    font-size: 16px;
  }
  .rs-table-scrollbar {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    position: absolute;
    -webkit-transition: background 0.1s linear, -webkit-transform 0.1s linear;
    transition: background 0.1s linear, -webkit-transform 0.1s linear;
    transition: background 0.1s linear, transform 0.1s linear;
    transition: background 0.1s linear, transform 0.1s linear, -webkit-transform 0.1s linear;
    opacity: 0.6;
    z-index: 1;
  }
  .rs-table-scrollbar-hide {
    display: none;
  }
  .rs-table-scrollbar-handle {
    position: absolute;
    background-color: #575757;
    background-color: #575757;
    border-radius: 4px;
  }
  .rs-table-scrollbar-pressed {
    z-index: 1;
  }
  .rs-table-scrollbar-pressed .rs-table-scrollbar-handle {
    background-color: #272c36;
    background-color: #272c36;
  }
  .rs-table-scrollbar-horizontal {
    width: 100%;
    height: 10px;
    bottom: 0;
  }
  .rs-table-scrollbar-horizontal.fixed {
    position: fixed;
  }
  .rs-table-scrollbar-horizontal .rs-table-scrollbar-handle {
    height: 8px;
    left: 0 /* rtl:ignore */;
    top: 1px;
  }
  .rs-table-scrollbar-pressed.rs-table-scrollbar-horizontal:hover,
  .rs-table-scrollbar-horizontal:hover {
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
    -webkit-transform: scaleY(1.3);
            transform: scaleY(1.3);
  }
  .rs-table-scrollbar-vertical {
    top: 0;
    right: 0;
    width: 10px;
    bottom: 10px;
    background-color: rgba(229, 229, 234, 0.4);
    background-color: rgba(229, 229, 234, 0.4);
  }
  .rs-table-scrollbar-vertical .rs-table-scrollbar-handle {
    min-height: 20px;
    width: 8px;
    top: 0;
    left: 1px;
  }
  .rs-table-scrollbar-pressed.rs-table-scrollbar-vertical:hover,
  .rs-table-scrollbar-vertical:hover {
    -webkit-transform-origin: right center;
            transform-origin: right center;
    -webkit-transform: scaleX(1.3);
            transform: scaleX(1.3);
  }
  .rs-tag {
    display: inline-block;
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #575757;
    color: #575757;
    border-radius: 6px;
    line-height: 20px;
  }
  .rs-tag-closable {
    position: relative;
  }
  .rs-tag-icon-close {
    position: absolute;
    font-size: 12px;
  }
  .rs-tag-default .rs-tag-icon-close:hover {
    color: #f44336;
    color: #f44336;
  }
  .rs-tag-group {
    margin: -10px 0 0 -10px;
  }
  .rs-tag-group > .rs-tag {
    margin-top: 10px;
    margin-left: 10px;
  }
  .rs-tag-lg {
    padding: 5px 10px;
    font-size: 14px;
  }
  .rs-tag-lg .rs-tag-icon-close {
    padding: 9px 8px;
  }
  .rs-tag-closable.rs-tag-lg {
    padding-right: 27px;
  }
  .rs-tag-md {
    padding: 2px 8px;
    font-size: 12px;
  }
  .rs-tag-md .rs-tag-icon-close {
    padding: 6px 8px;
  }
  .rs-tag-closable.rs-tag-md {
    padding-right: 25px;
  }
  .rs-tag-sm {
    padding: 0 8px;
    font-size: 12px;
  }
  .rs-tag-sm .rs-tag-icon-close {
    padding: 4px 5px;
  }
  .rs-tag-closable.rs-tag-sm {
    padding-right: 25px;
  }
  .rs-tag + .rs-tag {
    margin-left: 10px;
  }
  .rs-tag-red {
    background-color: #f44336;
    background-color: #f44336;
    color: #ffffff;
  }
  .rs-tag-orange {
    background-color: #fa8900;
    background-color: #fa8900;
    color: #ffffff;
  }
  .rs-tag-yellow {
    background-color: #ffb300;
    background-color: #ffb300;
    color: #ffffff;
  }
  .rs-tag-green {
    background-color: #4caf50;
    background-color: #4caf50;
    color: #ffffff;
  }
  .rs-tag-cyan {
    background-color: #00bcd4;
    background-color: #00bcd4;
    color: #ffffff;
  }
  .rs-tag-blue {
    background-color: #2196f3;
    background-color: #2196f3;
    color: #ffffff;
  }
  .rs-tag-violet {
    background-color: #673ab7;
    background-color: #673ab7;
    color: #ffffff;
  }
  .rs-picker-tag .rs-picker-toggle {
    left: 0;
    cursor: text;
  }
  .rs-picker-tag.rs-picker-disabled {
    cursor: not-allowed;
  }
  .rs-picker-tag.rs-picker-disabled .rs-picker-toggle {
    position: absolute;
  }
  .rs-picker-tag .rs-tag {
    max-width: calc(100% - 6px);
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .rs-picker-tag .rs-picker-search-input,
  .rs-picker-tag .rs-tag {
    margin-top: 5px;
  }
  .rs-picker-tag.rs-picker-has-value .rs-picker-search-input {
    padding-left: 6px;
  }
  .rs-picker-tag .rs-tag,
  .rs-picker-tag .rs-tag + .rs-tag {
    margin-left: 6px;
  }
  .rs-picker-tag .rs-picker-search,
  .rs-picker-tag .rs-picker-search-input,
  .rs-picker-tag .rs-picker-search-input > input {
    display: inline-block;
    width: auto;
    min-width: 14px;
  }
  .rs-picker-tag .rs-picker-search-input > input {
    padding: 0;
  }
  .rs-picker-tag .rs-picker-tag-wrapper {
    position: relative;
    z-index: 6;
    padding-bottom: 5px;
  }
  .rs-plaintext .rs-tag {
    margin-left: 6px;
    margin-bottom: 6px;
  }
  .rs-picker-disabled-options .rs-picker-toggle {
    padding-right: 32px !important;
  }
  .rs-picker-disabled-options .rs-picker-tag-wrapper {
    margin-right: 32px !important;
  }
  .rs-picker-disabled-options .rs-picker-toggle-clean {
    right: 10px !important;
  }
  .rs-timeline {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    direction: ltr;
  }
  .rs-timeline-item {
    position: relative;
    text-align: left;
  }
  .rs-timeline-item:not(:last-child) .rs-timeline-item-content {
    padding-bottom: 18px;
  }
  .rs-timeline-item-dot {
    position: absolute;
    top: 5px;
  }
  .rs-timeline-item-dot::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: #d9d9d9;
    background-color: #d9d9d9;
    border-radius: 50%;
  }
  .rs-timeline-item-custom-dot {
    text-align: center;
  }
  .rs-timeline-item-custom-dot::before {
    display: none;
  }
  .rs-timeline-item-active .rs-timeline-item-dot::before {
    background-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-timeline-item-tail {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #d9d9d9;
    background-color: #d9d9d9;
  }
  .rs-timeline-item:first-child .rs-timeline-item-tail {
    top: 5px;
  }
  .rs-timeline-item:last-child .rs-timeline-item-tail {
    bottom: auto;
    height: 15px;
  }
  .rs-timeline-endless .rs-timeline-item:last-child .rs-timeline-item-tail {
    height: auto;
    min-height: 38px;
  }
  .rs-timeline-item:only-child .rs-timeline-item-tail {
    display: none;
  }
  .rs-timeline-align-left .rs-timeline-item-dot {
    left: 0;
  }
  .rs-timeline-align-left .rs-timeline-item-tail {
    left: 4px;
  }
  .rs-timeline-align-left .rs-timeline-item {
    padding-left: 22px;
  }
  .rs-timeline-align-left .rs-timeline-item {
    padding-left: 22px;
  }
  .rs-timeline-align-right .rs-timeline-item-dot {
    right: 0;
  }
  .rs-timeline-align-right .rs-timeline-item-tail {
    right: 4px;
  }
  .rs-timeline-align-right .rs-timeline-item {
    padding-right: 22px;
  }
  .rs-timeline-align-right .rs-timeline-item-content {
    text-align: right;
  }
  .rs-timeline-align-alternate .rs-timeline-item-dot {
    left: calc(50% - 5px);
  }
  .rs-timeline-align-alternate .rs-timeline-item-tail {
    left: calc(50% - 1px);
  }
  .rs-timeline-align-alternate .rs-timeline-item-content {
    width: 50%;
  }
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(even) .rs-timeline-item-content {
    text-align: right;
  }
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(odd) {
    text-align: right;
  }
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(odd) .rs-timeline-item-content {
    display: inline-block;
    text-align: left;
  }
  .rs-timeline-with-time .rs-timeline-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
  }
  .rs-timeline-with-time .rs-timeline-item-dot {
    left: calc(50% - 5px);
  }
  .rs-timeline-with-time .rs-timeline-item-tail {
    left: calc(50% - 1px);
  }
  .rs-timeline-with-time .rs-timeline-item-time,
  .rs-timeline-with-time .rs-timeline-item-content {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 50%;
            flex: 1 0 50%;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-timeline-with-time .rs-timeline-item-time,
    .rs-timeline-with-time .rs-timeline-item-content {
      max-width: 50%;
    }
  }
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(2n),
  .rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
  .rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item {
    padding-right: 0;
  }
  .rs-timeline-with-time.rs-timeline-align-left .rs-timeline-item-time {
    text-align: right;
  }
  .rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item-time {
    text-align: left;
  }
  .rs-timeline-with-time.rs-timeline-align-left .rs-timeline-item-time,
  .rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item-content,
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(2n + 1) .rs-timeline-item-time,
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(2n) .rs-timeline-item-content {
    padding-right: 17px;
  }
  .rs-timeline-with-time.rs-timeline-align-left .rs-timeline-item-content,
  .rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item-time,
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(2n + 1) .rs-timeline-item-content,
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(2n) .rs-timeline-item-time {
    padding-left: 17px;
  }
  .rs-toggle {
    position: relative;
  }
  .rs-toggle .rs-toggle-loader {
    width: 18px;
    height: 18px;
    left: 3px;
    top: 3px;
  }
  .rs-toggle .rs-toggle-loader .rs-loader-spin,
  .rs-toggle .rs-toggle-loader .rs-loader-spin::before,
  .rs-toggle .rs-toggle-loader .rs-loader-spin::after {
    width: 18px;
    height: 18px;
  }
  .rs-toggle .rs-toggle-presentation {
    height: 24px;
    min-width: 44px;
    border-radius: 12px;
  }
  .rs-toggle .rs-toggle-presentation::after {
    width: 18px;
    height: 18px;
    left: 3px;
    top: 3px;
    border-radius: 9px;
  }
  .rs-toggle .rs-toggle-presentation:active::after {
    width: 21.6px;
  }
  .rs-toggle .rs-toggle-inner {
    margin-left: 24px;
    margin-right: 8px;
    height: 24px;
    line-height: 2;
    font-size: 12px;
  }
  .rs-toggle .rs-toggle-inner .rs-icon {
    font-size: 12px;
  }
  .rs-toggle.rs-toggle-checked .rs-toggle-presentation::after {
    left: 100%;
    margin-left: -21px;
  }
  .rs-toggle.rs-toggle-checked .rs-toggle-presentation:active::after {
    margin-left: -24.6px;
  }
  .rs-toggle.rs-toggle-checked .rs-toggle-presentation .rs-toggle-inner {
    margin-right: 24px;
    margin-left: 8px;
  }
  .rs-toggle.rs-toggle-checked .rs-toggle-loader {
    left: 100%;
    margin-left: -21px;
  }
  .rs-toggle-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
  .rs-toggle-presentation {
    position: relative;
    display: inline-block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    vertical-align: middle;
    background-color: #d9d9d9;
    background-color: #d9d9d9;
    color: #fff;
    color: #fff;
    cursor: pointer;
    -webkit-transition: background-color 0.15s ease-out, width 0.15s ease-out;
    transition: background-color 0.15s ease-out, width 0.15s ease-out;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .rs-theme-high-contrast .rs-toggle-presentation {
    -webkit-transition: none;
    transition: none;
    -webkit-box-shadow: inset 0 0 0 1px #fff;
    -webkit-box-shadow: inset 0 0 0 1px #cbced4;
            box-shadow: inset 0 0 0 1px #fff;
            box-shadow: inset 0 0 0 1px #cbced4;
  }
  .rs-toggle-input:focus-visible + .rs-toggle-presentation {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
  }
  .rs-theme-high-contrast .rs-toggle-input:focus-visible + .rs-toggle-presentation {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-toggle-input:focus-visible + .rs-toggle-presentation {
    -webkit-box-shadow: inset 0 0 0 1px #fff, 0 0 0 3px rgba(52, 152, 255, 0.25);
    -webkit-box-shadow: inset 0 0 0 1px #cbced4, 0 0 0 3px #0f131a, 0 0 0 5px #fff;
            box-shadow: inset 0 0 0 1px #fff, 0 0 0 3px rgba(52, 152, 255, 0.25);
            box-shadow: inset 0 0 0 1px #cbced4, 0 0 0 3px #0f131a, 0 0 0 5px #fff;
  }
  .rs-toggle-presentation:hover {
    background-color: #c5c6c7;
    background-color: #c5c6c7;
  }
  .rs-toggle-presentation::after {
    content: '';
    cursor: pointer;
    position: absolute;
    background-color: currentColor;
    -webkit-transition: left 0.15s ease-out, margin-left 0.15s ease-out, width 0.15s ease-out;
    transition: left 0.15s ease-out, margin-left 0.15s ease-out, width 0.15s ease-out;
  }
  .rs-theme-high-contrast .rs-toggle-presentation::after {
    -webkit-transition: none;
    transition: none;
  }
  .rs-toggle-disabled .rs-toggle-presentation {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    color: #fff;
    color: #fff;
    -webkit-box-shadow: inset 0 0 0 1px #fff;
    -webkit-box-shadow: inset 0 0 0 1px #fff;
            box-shadow: inset 0 0 0 1px #fff;
            box-shadow: inset 0 0 0 1px #fff;
    cursor: not-allowed;
  }
  .rs-toggle-checked .rs-toggle-presentation {
    background-color: #3498ff;
    background-color: #3498ff;
    color: #fff;
    color: #fff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-toggle-checked .rs-toggle-presentation:hover {
    background-color: #2589f5;
    background-color: #2589f5;
  }
  .rs-toggle-disabled.rs-toggle-checked .rs-toggle-presentation {
    background-color: #cce9ff;
    background-color: #cce9ff;
    color: #fff;
    color: #fff;
  }
  .rs-toggle-loading .rs-toggle-presentation::after {
    display: none;
  }
  .rs-toggle-inner {
    display: block;
    -webkit-transition: margin 0.15s ease-out;
    transition: margin 0.15s ease-out;
  }
  .rs-theme-high-contrast .rs-toggle-inner {
    -webkit-transition: none;
    transition: none;
  }
  .rs-toggle-loader {
    position: absolute;
    -webkit-transition: left 0.15s ease-out, margin-left 0.15s ease-out, width 0.15s ease-out;
    transition: left 0.15s ease-out, margin-left 0.15s ease-out, width 0.15s ease-out;
  }
  .rs-toggle-loader .rs-loader-spin::before {
    border-color: rgba(247, 247, 250, 0.3);
    border-color: rgba(247, 247, 250, 0.3);
  }
  .rs-toggle-loader .rs-loader-spin::after {
    border-top-color: #fff;
    border-top-color: #fff;
  }
  .rs-toggle-sm .rs-toggle-loader {
    width: 10px;
    height: 10px;
    left: 2px;
    top: 2px;
  }
  .rs-toggle-sm .rs-toggle-loader .rs-loader-spin,
  .rs-toggle-sm .rs-toggle-loader .rs-loader-spin::before,
  .rs-toggle-sm .rs-toggle-loader .rs-loader-spin::after {
    width: 10px;
    height: 10px;
  }
  .rs-toggle-sm .rs-toggle-loader .rs-loader-spin::before,
  .rs-toggle-sm .rs-toggle-loader .rs-loader-spin::after {
    border-width: 2px;
  }
  .rs-toggle-sm .rs-toggle-presentation {
    height: 14px;
    min-width: 26px;
    border-radius: 7px;
  }
  .rs-toggle-sm .rs-toggle-presentation::after {
    width: 10px;
    height: 10px;
    left: 2px;
    top: 2px;
    border-radius: 5px;
  }
  .rs-toggle-sm .rs-toggle-presentation:active::after {
    width: 12px;
  }
  .rs-toggle-sm .rs-toggle-inner {
    margin-left: 14px;
    margin-right: 6px;
    height: 14px;
    line-height: 1.4;
    font-size: 10px;
  }
  .rs-toggle-sm .rs-toggle-inner .rs-icon {
    font-size: 10px;
  }
  .rs-toggle-sm.rs-toggle-checked .rs-toggle-presentation::after {
    left: 100%;
    margin-left: -12px;
  }
  .rs-toggle-sm.rs-toggle-checked .rs-toggle-presentation:active::after {
    margin-left: -14px;
  }
  .rs-toggle-sm.rs-toggle-checked .rs-toggle-presentation .rs-toggle-inner {
    margin-right: 14px;
    margin-left: 6px;
  }
  .rs-toggle-sm.rs-toggle-checked .rs-toggle-loader {
    left: 100%;
    margin-left: -12px;
  }
  .rs-toggle-md .rs-toggle-loader {
    width: 18px;
    height: 18px;
    left: 3px;
    top: 3px;
  }
  .rs-toggle-md .rs-toggle-loader .rs-loader-spin,
  .rs-toggle-md .rs-toggle-loader .rs-loader-spin::before,
  .rs-toggle-md .rs-toggle-loader .rs-loader-spin::after {
    width: 18px;
    height: 18px;
  }
  .rs-toggle-md .rs-toggle-presentation {
    height: 24px;
    min-width: 44px;
    border-radius: 12px;
  }
  .rs-toggle-md .rs-toggle-presentation::after {
    width: 18px;
    height: 18px;
    left: 3px;
    top: 3px;
    border-radius: 9px;
  }
  .rs-toggle-md .rs-toggle-presentation:active::after {
    width: 21.6px;
  }
  .rs-toggle-md .rs-toggle-inner {
    margin-left: 24px;
    margin-right: 8px;
    height: 24px;
    line-height: 2;
    font-size: 12px;
  }
  .rs-toggle-md .rs-toggle-inner .rs-icon {
    font-size: 12px;
  }
  .rs-toggle-md.rs-toggle-checked .rs-toggle-presentation::after {
    left: 100%;
    margin-left: -21px;
  }
  .rs-toggle-md.rs-toggle-checked .rs-toggle-presentation:active::after {
    margin-left: -24.6px;
  }
  .rs-toggle-md.rs-toggle-checked .rs-toggle-presentation .rs-toggle-inner {
    margin-right: 24px;
    margin-left: 8px;
  }
  .rs-toggle-md.rs-toggle-checked .rs-toggle-loader {
    left: 100%;
    margin-left: -21px;
  }
  .rs-toggle-lg .rs-toggle-loader {
    width: 22px;
    height: 22px;
    left: 4px;
    top: 4px;
  }
  .rs-toggle-lg .rs-toggle-loader .rs-loader-spin,
  .rs-toggle-lg .rs-toggle-loader .rs-loader-spin::before,
  .rs-toggle-lg .rs-toggle-loader .rs-loader-spin::after {
    width: 22px;
    height: 22px;
  }
  .rs-toggle-lg .rs-toggle-presentation {
    height: 30px;
    min-width: 55px;
    border-radius: 15px;
  }
  .rs-toggle-lg .rs-toggle-presentation::after {
    width: 22px;
    height: 22px;
    left: 4px;
    top: 4px;
    border-radius: 11px;
  }
  .rs-toggle-lg .rs-toggle-presentation:active::after {
    width: 26.4px;
  }
  .rs-toggle-lg .rs-toggle-inner {
    margin-left: 30px;
    margin-right: 10px;
    height: 30px;
    line-height: 2.14285714;
    font-size: 14px;
  }
  .rs-toggle-lg .rs-toggle-inner .rs-icon {
    font-size: 14px;
  }
  .rs-toggle-lg.rs-toggle-checked .rs-toggle-presentation::after {
    left: 100%;
    margin-left: -26px;
  }
  .rs-toggle-lg.rs-toggle-checked .rs-toggle-presentation:active::after {
    margin-left: -30.4px;
  }
  .rs-toggle-lg.rs-toggle-checked .rs-toggle-presentation .rs-toggle-inner {
    margin-right: 30px;
    margin-left: 10px;
  }
  .rs-toggle-lg.rs-toggle-checked .rs-toggle-loader {
    left: 100%;
    margin-left: -26px;
  }
  /* rtl:begin:ignore */
  /* rtl:end:ignore */
  .rs-tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-size: 12px;
    opacity: 0;
    line-height: 1.66666667;
    max-width: 250px;
    padding: 2px 10px;
    color: #fff;
    color: #fff;
    background-color: #272c36;
    background-color: #272c36;
    border-radius: 4px;
    overflow-wrap: break-word;
  }
  .rs-tooltip.rs-anim-fade {
    -webkit-transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
  }
  .rs-tooltip.rs-anim-in {
    opacity: 1;
    -webkit-transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in, -webkit-transform 0.15s ease-in;
  }
  .rs-tooltip-arrow::before,
  .rs-tooltip-arrow::after {
    content: ' ';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .rs-tooltip-arrow::before {
    display: none;
  }
  .rs-theme-high-contrast .rs-tooltip {
    border: 1px solid #e5e5ea;
    border: 1px solid #cbced4;
  }
  .rs-theme-high-contrast .rs-tooltip-arrow::before {
    display: block;
  }
  .rs-tooltip[class*='placement-top'] {
    margin-top: -8px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .rs-tooltip[class*='placement-top'].rs-anim-in {
    -webkit-transform: translate(0, -2px);
            transform: translate(0, -2px);
  }
  .rs-tooltip[class*='placement-top']::after {
    bottom: -6px;
    margin-left: -6px;
    border-width: 6px 6px 0;
    border-top-color: #272c36;
    border-top-color: #272c36;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-tooltip[class*='placement-top']::after {
      bottom: -7px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-tooltip[class*='placement-top']::after {
      bottom: -7px;
    }
  }
  .rs-theme-high-contrast .rs-tooltip[class*='placement-top']::before {
    bottom: -7px;
    margin-left: -7px;
    border-width: 7px 7px 0;
    border-top-color: undefined;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-theme-high-contrast .rs-tooltip[class*='placement-top']::before {
      bottom: -8px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-theme-high-contrast .rs-tooltip[class*='placement-top']::before {
      bottom: -8px;
    }
  }
  .rs-tooltip[class*='placement-bottom'] {
    margin-top: 8px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .rs-tooltip[class*='placement-bottom'].rs-anim-in {
    -webkit-transform: translate(0, 2px);
            transform: translate(0, 2px);
  }
  .rs-tooltip[class*='placement-bottom']::after {
    top: -6px;
    margin-left: -6px;
    border-width: 0 6px 6px;
    border-bottom-color: #272c36;
    border-bottom-color: #272c36;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-tooltip[class*='placement-bottom']::after {
      top: -7px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-tooltip[class*='placement-bottom']::after {
      top: -7px;
    }
  }
  .rs-theme-high-contrast .rs-tooltip[class*='placement-bottom']::before {
    top: -7px;
    margin-left: -7px;
    border-width: 0 7px 7px;
    border-bottom-color: undefined;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-theme-high-contrast .rs-tooltip[class*='placement-bottom']::before {
      top: -8px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-theme-high-contrast .rs-tooltip[class*='placement-bottom']::before {
      top: -8px;
    }
  }
  /* rtl:begin:ignore */
  .rs-tooltip[class*='placement-right'] {
    margin-left: 8px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .rs-tooltip[class*='placement-right'].rs-anim-in {
    -webkit-transform: translate(2px, 0);
            transform: translate(2px, 0);
  }
  .rs-tooltip[class*='placement-right']::after {
    left: -6px;
    margin-top: -6px;
    border-width: 6px 6px 6px 0;
    border-right-color: #272c36;
    border-right-color: #272c36;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-tooltip[class*='placement-right']::after {
      left: -7px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-tooltip[class*='placement-right']::after {
      left: -7px;
    }
  }
  .rs-theme-high-contrast .rs-tooltip[class*='placement-right']::before {
    left: -7px;
    margin-top: -7px;
    border-width: 7px 7px 7px 0;
    border-right-color: undefined;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-theme-high-contrast .rs-tooltip[class*='placement-right']::before {
      left: -8px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-theme-high-contrast .rs-tooltip[class*='placement-right']::before {
      left: -8px;
    }
  }
  .rs-tooltip[class*='placement-left'] {
    margin-left: -8px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .rs-tooltip[class*='placement-left'].rs-anim-in {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  .rs-tooltip[class*='placement-left']::after {
    right: -6px;
    margin-top: -6px;
    border-width: 6px 0 6px 6px;
    border-left-color: #272c36;
    border-left-color: #272c36;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-tooltip[class*='placement-left']::after {
      right: -7px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-tooltip[class*='placement-left']::after {
      right: -7px;
    }
  }
  .rs-theme-high-contrast .rs-tooltip[class*='placement-left']::before {
    right: -7px;
    margin-top: -7px;
    border-width: 7px 0 7px 7px;
    border-left-color: undefined;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-theme-high-contrast .rs-tooltip[class*='placement-left']::before {
      right: -8px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-theme-high-contrast .rs-tooltip[class*='placement-left']::before {
      right: -8px;
    }
  }
  /* rtl:end:ignore */
  .rs-tooltip.placement-bottom::before,
  .rs-tooltip.placement-top::before,
  .rs-tooltip.placement-bottom::after,
  .rs-tooltip.placement-top::after {
    left: 50%;
  }
  .rs-tooltip.placement-bottom-start::before,
  .rs-tooltip.placement-top-start::before,
  .rs-tooltip.placement-bottom-start::after,
  .rs-tooltip.placement-top-start::after {
    left: 10px;
  }
  .rs-tooltip.placement-bottom-end::before,
  .rs-tooltip.placement-top-end::before {
    right: 3px;
  }
  .rs-tooltip.placement-bottom-end::after,
  .rs-tooltip.placement-top-end::after {
    right: 4px;
  }
  .rs-tooltip.placement-right::before,
  .rs-tooltip.placement-left::before,
  .rs-tooltip.placement-right::after,
  .rs-tooltip.placement-left::after {
    top: 50%;
  }
  .rs-tooltip.placement-right-start::before,
  .rs-tooltip.placement-left-start::before,
  .rs-tooltip.placement-right-start::after,
  .rs-tooltip.placement-left-start::after {
    top: 10px;
  }
  .rs-tooltip.placement-right-end::before,
  .rs-tooltip.placement-left-end::before {
    bottom: 3px;
  }
  .rs-tooltip.placement-right-end::after,
  .rs-tooltip.placement-left-end::after {
    bottom: 4px;
  }
  .rs-tree {
    max-height: 360px;
    height: 100%;
    overflow-y: auto;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }
  .rs-tree.rs-tree-virtualized {
    overflow: hidden;
  }
  .rs-tree-drag-preview {
    position: absolute;
    top: 0;
    color: #575757;
    color: #575757;
    background-color: #fff;
    background-color: #fff;
    display: inline-block;
    margin: 0;
    padding: 8px 12px;
    border-radius: 6px;
    -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
            box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
    z-index: -1;
  }
  .rs-tree-node {
    position: relative;
    font-size: 0;
    text-align: left;
    margin: 0 0 4px 0;
  }
  .rs-tree-node-label {
    position: relative;
    margin: 0;
    padding-left: 16px;
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    line-height: 22px;
  }
  .rs-tree-node-label-content {
    padding: 6px 12px 6px 8px;
    display: inline-block;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
  }
  .rs-tree-node-label-content:hover {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-tree-node-label-content:focus {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-tree-node-label-content.rs-tree-node-label-content-focus {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
    color: #1675e0;
    color: #1675e0;
  }
  .rs-theme-high-contrast .rs-tree-node-label-content:hover {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-tree-node-label-content:focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-tree-node-label-content.rs-tree-node-label-content-focus {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid #fff;
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-tree-node-label-content:hover,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-tree-node-label-content:focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-tree-node-label-content.rs-tree-node-label-content-focus {
    outline-offset: 2px;
  }
  .rs-tree-node-active .rs-tree-node-label-content {
    font-weight: bold;
    color: #1675e0;
    color: #1675e0;
    background-color: #f2faff;
    background-color: #f2faff;
  }
  .rs-tree-node-drag-over {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: rgba(204, 233, 255, 0.5);
  }
  .rs-tree-node-dragging {
    outline: 1px dashed #3498ff;
    outline: 1px dashed #3498ff;
  }
  .rs-tree-node-drag-over-top.rs-tree-node-label-content {
    border-top: 2px solid #1675e0 !important;
    border-top: 2px solid #1675e0 !important;
  }
  .rs-tree-node-drag-over-top.rs-tree-node-label-content::after {
    content: '';
    position: absolute;
    top: -2px;
    left: 12px;
    width: 0;
    height: 0;
    border-left: 6px solid #1675e0;
    border-left: 6px solid #1675e0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
  }
  .rs-tree-node-drag-over-bottom.rs-tree-node-label-content {
    border-bottom: 2px solid #1675e0 !important;
    border-bottom: 2px solid #1675e0 !important;
  }
  .rs-tree-node-drag-over-bottom.rs-tree-node-label-content::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 12px;
    width: 0;
    height: 0;
    border-left: 6px solid #1675e0;
    border-left: 6px solid #1675e0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
  }
  .rs-tree-node-drag-disabled.rs-tree-node-label-content {
    cursor: not-allowed;
  }
  .rs-tree-node-disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    cursor: not-allowed;
  }
  .rs-tree-node-disabled > .rs-tree-node-label > .rs-tree-node-label-content {
    cursor: not-allowed;
    color: #c5c6c7;
    color: #c5c6c7;
  }
  .rs-tree-node-disabled > .rs-tree-node-label > .rs-tree-node-label-content,
  .rs-tree-node-disabled > .rs-tree-node-label > .rs-tree-node-label-content:hover {
    background-color: transparent;
  }
  .rs-tree-node-disabled.rs-tree-node-active > .rs-tree-node-label > .rs-tree-node-label-content {
    color: #a6d7ff;
    color: #a6d7ff;
  }
  .rs-tree-node-disabled.rs-tree-node-active > .rs-tree-node-label > .rs-tree-node-label-content:hover {
    color: #a6d7ff;
    color: #a6d7ff;
  }
  .rs-tree-node:not(.rs-tree-node-disabled):focus > .rs-tree-node-label > .rs-tree-node-label-content {
    background-color: #f2faff;
    background-color: #f2faff;
  }
  .rs-tree-node:not(.rs-tree-node-disabled) > .rs-tree-node-label:focus > .rs-tree-node-label-content {
    background-color: #f2faff;
    background-color: #f2faff;
  }
  .rs-tree-node-expand-icon-wrapper {
    display: inline-block;
    position: absolute;
    cursor: pointer;
    z-index: 1;
  }
  .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expand-icon {
    display: inline-block;
    padding: 8px 0;
    padding-right: 8px;
    height: 36px;
    font-size: 16px;
    line-height: 22px;
    -webkit-transform: rotate(-90deg) /* rtl: rotate(90deg) translateX(-13px) translateY(-15px) */;
            transform: rotate(-90deg) /* rtl: rotate(90deg) translateX(-13px) translateY(-15px) */;
    -webkit-transform-origin: 1.5px 14px;
            transform-origin: 1.5px 14px;
    margin-top: 6px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .rs-tree-open > .rs-tree-node .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expand-icon {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    margin-left: 0;
    margin-top: 0;
  }
  .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expanded {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    margin-left: 0;
    margin-top: 0;
  }
  .rs-tree-node-expand-icon-wrapper > .rs-tree-node-custom-icon,
  .rs-tree-node-expand-icon-wrapper > .rs-tree-node-loading-icon {
    width: 14px;
    height: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 14px;
  }
  .rs-tree-node-children > .rs-tree-children {
    position: relative;
    display: none;
  }
  .rs-tree-open.rs-tree-node-children > .rs-tree-children {
    display: block;
  }
  .rs-tree-indent-line {
    height: 100%;
    width: 1px;
    position: absolute;
    top: 0;
    margin-left: -13px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
  .rs-picker-menu.rs-tree-menu {
    padding-top: 12px;
  }
  .rs-picker-menu.rs-tree-menu .rs-picker-search-bar {
    padding-top: 0;
  }
  .rs-picker-menu .rs-tree {
    padding: 0 12px 12px 0;
  }
  .rs-picker-menu .rs-tree.rs-tree-virtualized {
    padding: 0;
  }
  .rs-picker-menu .rs-tree-node {
    margin: 0;
  }
  .rs-picker-menu .rs-tree-node > .rs-tree-node-label > .rs-tree-node-label-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: block;
    line-height: 20px;
  }
  /* rtl:begin:ignore */
  [dir='rtl'] .rs-tree-node-expand-icon-wrapper {
    right: 0;
    padding-right: inherit;
  }
  /* rtl:end:ignore */
  .rs-uploader-trigger input[type='file'] {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
  }
  .rs-uploader-file-item-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  .rs-uploader-picture .rs-uploader-file-item-disabled .rs-uploader-file-item-status {
    cursor: not-allowed;
  }
  .rs-uploader-file-item-disabled .rs-uploader-file-item-btn-remove {
    display: none;
  }
  .rs-uploader-file-item-icon-reupload {
    cursor: pointer;
    margin-left: 10px;
    color: #575757;
    color: #575757;
    font-size: 12px;
  }
  .rs-theme-high-contrast .rs-uploader-file-item:hover .rs-uploader-file-item-title {
    color: #ffff00;
    text-decoration: underline;
  }
  .rs-uploader-text .rs-uploader-file-items {
    margin-top: 10px;
  }
  .rs-uploader-text .rs-uploader-file-item {
    padding: 8px 30px 8px 36px;
    position: relative;
    -webkit-transition: 0.3s linear background-color;
    transition: 0.3s linear background-color;
  }
  .rs-uploader-text .rs-uploader-file-item:hover {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-uploader-text .rs-uploader-file-item-icon-wrapper {
    position: absolute;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    top: 8px;
    left: 12px;
    width: 20px;
    height: 20px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon {
    width: 18px;
    height: 18px;
  }
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
    width: 18px;
    height: 18px;
  }
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    border-radius: 50%;
  }
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before {
    border: 3px solid rgba(247, 247, 250, 0.8);
    border: 3px solid rgba(247, 247, 250, 0.8);
  }
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
    border-width: 3px;
    border-style: solid;
    border-color: #a6a6a6 transparent transparent;
    border-color: #a6a6a6 transparent transparent;
    -webkit-animation: loaderSpin 0.6s infinite linear;
            animation: loaderSpin 0.6s infinite linear;
  }
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon > svg {
    display: none;
  }
  .rs-uploader-text .rs-uploader-file-item-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    color: #575757;
    color: #575757;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }
  .rs-uploader-text .rs-uploader-file-item-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }
  .rs-uploader-text .rs-uploader-file-item-size {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    color: #8e8e93;
    color: #8e8e93;
    padding: 0 8px;
  }
  .rs-uploader-text .rs-uploader-file-item-status {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
  .rs-uploader-text .rs-uploader-file-item-status > a {
    text-decoration: none;
  }
  .rs-uploader-text .rs-uploader-file-item-status > a:hover,
  .rs-uploader-text .rs-uploader-file-item-status > a:focus,
  .rs-uploader-text .rs-uploader-file-item-status > a:active {
    text-decoration: none;
  }
  .rs-uploader-text .rs-uploader-file-item-btn-remove {
    position: absolute;
    font-size: 12px;
    top: 12px;
    right: 12px;
    color: #8e8e93;
    color: #8e8e93;
    cursor: pointer;
    padding: 0;
  }
  .rs-uploader-text .rs-uploader-file-item-btn-remove:hover,
  .rs-uploader-text .rs-uploader-file-item-btn-remove:focus {
    text-decoration: none;
  }
  .rs-uploader-text .rs-uploader-file-item-panel {
    position: relative;
  }
  .rs-uploader-text .rs-uploader-file-item-progress {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }
  .rs-uploader-text .rs-uploader-file-item-progress-bar {
    height: 2px;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
  }
  .rs-uploader-text .rs-uploader-file-item-has-error .rs-uploader-file-item-content {
    color: #f44336;
    color: #f44336;
  }
  .rs-uploader-text .rs-uploader-file-item-has-error .rs-uploader-file-item-title {
    color: #f44336;
    color: #f44336;
  }
  .rs-uploader-picture {
    display: inline-block;
  }
  .rs-uploader-picture::before,
  .rs-uploader-picture::after {
    content: ' ';
    display: table;
  }
  .rs-uploader-picture::after {
    clear: both;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
    transition: color 0.15s ease-out, background-color 0.15s ease-out;
    border: none;
    border: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-decoration: none;
    color: #575757;
    color: #575757;
    background-color: #f7f7fa;
    background-color: #f7f7fa;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px;
    overflow: hidden;
    position: relative;
    color: #8e8e93;
    color: #8e8e93;
    background-color: transparent;
    border: none;
    width: 66px;
    height: 66px;
    margin: 0;
    padding: 0;
    border: 2px dotted #e5e5ea;
    border: 2px dotted #e5e5ea;
    border-radius: 6px;
    margin-top: 10px;
  }
  .rs-theme-high-contrast .rs-uploader-picture .rs-uploader-trigger-btn {
    -webkit-transition: none;
    transition: none;
  }
  .rs-btn-ghost.rs-uploader-picture .rs-uploader-trigger-btn {
    padding: 7px 11px;
  }
  .rs-btn-icon.rs-uploader-picture .rs-uploader-trigger-btn {
    padding: 10px 10px;
    line-height: 16px;
  }
  .rs-btn-icon.rs-uploader-picture .rs-uploader-trigger-btn > .rs-icon {
    font-size: 16px;
  }
  .rs-btn-icon-with-text.rs-uploader-picture .rs-uploader-trigger-btn {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-uploader-picture .rs-uploader-trigger-btn > .rs-icon {
    padding: 10px 10px;
    width: 36px;
    height: 36px;
  }
  .rs-btn-icon-with-text.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;
  }
  .rs-btn-icon-with-text.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: none;
  }
  .rs-btn-icon-with-text.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;
  }
  .rs-btn-icon-with-text.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:focus-visible {
    outline: 3px solid rgba(52, 152, 255, 0.25);
    outline: 3px solid rgba(52, 152, 255, 0.25);
  }
  .rs-theme-high-contrast .rs-uploader-picture .rs-uploader-trigger-btn:focus-visible {
    outline-offset: 2px;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:hover {
    color: undefined;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    text-decoration: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:focus {
    color: undefined;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    text-decoration: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:active {
    color: #272c36;
    color: #272c36;
    background-color: #d9d9d9;
    background-color: #d9d9d9;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active {
    color: #272c36;
    color: #272c36;
    background-color: #d9d9d9;
    background-color: #d9d9d9;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:disabled {
    cursor: not-allowed;
    color: #c5c6c7;
    color: #c5c6c7;
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled {
    cursor: not-allowed;
    color: #c5c6c7;
    color: #c5c6c7;
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-theme-high-contrast .rs-uploader-picture .rs-uploader-trigger-btn:disabled,
  .rs-theme-high-contrast .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled {
    opacity: 0.5;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-uploader-picture .rs-uploader-trigger-btn {
      /* stylelint-disable */
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      /* stylelint-enable */
    }
  }
  .rs-picker-default .rs-uploader-picture .rs-uploader-trigger-btn {
    -webkit-transition: border-color ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s;
  }
  .rs-theme-high-contrast .rs-picker-default .rs-uploader-picture .rs-uploader-trigger-btn {
    -webkit-transition: none;
    transition: none;
  }
  .rs-picker-default:not(.rs-picker-disabled) .rs-uploader-picture .rs-uploader-trigger-btn:hover {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-picker-default:not(.rs-picker-disabled) .rs-uploader-picture .rs-uploader-trigger-btn:focus {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-picker-default:not(.rs-picker-disabled) .rs-uploader-picture .rs-uploader-trigger-btn-active {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn {
    color: #8e8e93;
    color: #8e8e93;
    background-color: transparent;
    border: none;
    -webkit-transition: none;
    transition: none;
  }
  .rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn:hover {
    color: #575757;
    color: #575757;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn:focus {
    color: #575757;
    color: #575757;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn:active {
    color: #272c36;
    color: #272c36;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active {
    color: #272c36;
    color: #272c36;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn:disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-theme-high-contrast .rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn:disabled,
  .rs-theme-high-contrast .rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled {
    opacity: 0.5;
  }
  .rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn-active {
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    color: #575757;
    color: #575757;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:hover {
    color: #575757;
    color: #575757;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:focus {
    color: #575757;
    color: #575757;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:active {
    color: #272c36;
    color: #272c36;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active {
    color: #272c36;
    color: #272c36;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled {
    color: #c5c6c7;
    color: #c5c6c7;
    background: none;
  }
  .rs-theme-high-contrast .rs-uploader-picture .rs-uploader-trigger-btn:disabled,
  .rs-theme-high-contrast .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled {
    opacity: 0.5;
  }
  .rs-uploader-picture .rs-uploader-trigger.rs-uploader-trigger-disabled .rs-uploader-trigger-btn {
    color: #8e8e93 !important;
    color: #8e8e93 !important;
    background-color: transparent !important;
  }
  .rs-uploader-picture .rs-uploader-trigger.rs-uploader-trigger-disabled .rs-uploader-trigger-btn::after {
    display: none;
  }
  .rs-uploader-picture .rs-uploader-trigger,
  .rs-uploader-picture .rs-uploader-file-item {
    float: left;
  }
  .rs-uploader-picture .rs-uploader-file-items {
    display: inline;
  }
  .rs-uploader-picture .rs-uploader-file-item {
    width: 66px;
    height: 66px;
    overflow: hidden;
    background-color: #d9d9d9;
    background-color: #d9d9d9;
    border-radius: 6px;
    position: relative;
    margin-top: 10px;
    margin-right: 10px;
  }
  .rs-uploader-picture .rs-uploader-file-item-preview {
    position: relative;
    height: 66px;
    vertical-align: middle;
    text-align: center;
    z-index: 1;
  }
  .rs-uploader-picture .rs-uploader-file-item-preview > img {
    width: 100%;
    height: auto;
  }
  @supports ((-o-object-fit: cover) or (object-fit: cover)) {
    .rs-uploader-picture .rs-uploader-file-item-preview > img {
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }
  }
  .rs-uploader-picture .rs-uploader-file-item-preview .rs-uploader-file-item-icon {
    font-size: 22px;
    margin: 22px;
  }
  .rs-uploader-picture .rs-uploader-file-item-status {
    position: absolute;
    top: 0;
    left: 0;
    width: 66px;
    height: 66px;
    text-align: center;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.8);
    background-color: rgba(255, 255, 255, 0.8);
    cursor: default;
    color: #f44336;
    color: #f44336;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .rs-uploader-picture .rs-uploader-file-item-status > span {
    white-space: nowrap;
  }
  .rs-uploader-picture .rs-uploader-file-item-status > a:hover {
    text-decoration: none;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-wrapper {
    display: none;
  }
  .rs-uploader-picture .rs-uploader-file-item-btn-remove {
    color: #8e8e93;
    color: #8e8e93;
    cursor: pointer;
    position: absolute;
    top: 3px;
    right: 3px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8);
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: 0.2s opacity;
    transition: 0.2s opacity;
    z-index: 4;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-top: 0;
  }
  .rs-uploader-picture .rs-uploader-file-item-btn-remove:hover,
  .rs-uploader-picture .rs-uploader-file-item-btn-remove:focus {
    text-decoration: none;
  }
  .rs-uploader-picture .rs-uploader-file-item:hover .rs-uploader-file-item-btn-remove {
    opacity: 1;
    visibility: visible;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-loading {
    display: block;
    width: 66px;
    height: 66px;
    line-height: 66px;
    background-color: rgba(255, 255, 255, 0.8);
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    text-align: center;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon {
    width: 18px;
    height: 18px;
    display: inline-block;
    position: relative;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
    width: 18px;
    height: 18px;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    border-radius: 50%;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before {
    border: 3px solid rgba(247, 247, 250, 0.8);
    border: 3px solid rgba(247, 247, 250, 0.8);
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
    border-width: 3px;
    border-style: solid;
    border-color: #a6a6a6 transparent transparent;
    border-color: #a6a6a6 transparent transparent;
    -webkit-animation: loaderSpin 0.6s infinite linear;
            animation: loaderSpin 0.6s infinite linear;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon > svg {
    display: none;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-reupload {
    margin-left: 0;
    display: block;
  }
  .rs-uploader-picture-text .rs-uploader-file-item {
    position: relative;
    height: 50px;
    padding-left: 50px;
    margin-top: 10px;
    padding-right: 30px;
    border: 1px solid #e5e5ea;
    border: 1px solid #e5e5ea;
    border-radius: 6px;
    overflow: hidden;
    -webkit-transition: 0.3s linear background-color;
    transition: 0.3s linear background-color;
  }
  .rs-uploader-picture-text .rs-uploader-file-item:hover {
    background-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-wrapper {
    display: none;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-preview {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    text-align: center;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    overflow: hidden;
    border-radius: 6px 0 0 6px;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-preview > img {
    width: 100%;
    height: auto;
  }
  @supports ((-o-object-fit: cover) or (object-fit: cover)) {
    .rs-uploader-picture-text .rs-uploader-file-item-preview > img {
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }
  }
  .rs-uploader-picture-text .rs-uploader-file-item-preview .rs-uploader-file-item-icon {
    font-size: 22px;
    margin: 12px;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-content {
    padding: 5px 12px;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-title,
  .rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-size {
    display: block;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-title {
    color: #575757;
    color: #575757;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-size {
    color: #8e8e93;
    color: #8e8e93;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-btn-remove {
    position: absolute;
    top: 0;
    right: 12px;
    color: #8e8e93;
    color: #8e8e93;
    cursor: pointer;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 0;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-btn-remove:hover,
  .rs-uploader-picture-text .rs-uploader-file-item-btn-remove:focus {
    text-decoration: none;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-progress {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    padding-left: 50px;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-progress-bar {
    height: 2px;
    background-color: #3498ff;
    background-color: #3498ff;
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 50px;
    height: 50px;
    z-index: 1;
    background-color: #e5e5ea;
    background-color: #e5e5ea;
    text-align: center;
    line-height: 50px;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon {
    width: 18px;
    height: 18px;
    display: inline-block;
    position: relative;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
    width: 18px;
    height: 18px;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    border-radius: 50%;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before {
    border: 3px solid rgba(247, 247, 250, 0.8);
    border: 3px solid rgba(247, 247, 250, 0.8);
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
    border-width: 3px;
    border-style: solid;
    border-color: #a6a6a6 transparent transparent;
    border-color: #a6a6a6 transparent transparent;
    -webkit-animation: loaderSpin 0.6s infinite linear;
            animation: loaderSpin 0.6s infinite linear;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon > svg {
    display: none;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-size {
    display: none;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-title {
    display: block;
    color: #f44336;
    color: #f44336;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-status {
    display: block;
    color: #f44336;
    color: #f44336;
  }
  .rs-uploader-draggable .rs-uploader-trigger-customize .rs-uploader-trigger-btn {
    display: block;
    background: #fff;
    background: #fff;
    border: 1px dashed #e5e5ea;
    border: 1px dashed #e5e5ea;
  }
  .rs-uploader-draggable .rs-uploader-trigger.rs-uploader-trigger-customize:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:hover {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-uploader-draggable .rs-uploader-trigger-drag-over .rs-uploader-trigger-btn.rs-uploader-trigger-customize {
    border-color: #3498ff;
    border-color: #3498ff;
  }
  .rs-uploader-draggable .rs-uploader-trigger-disabled {
    cursor: not-allowed;
  }
  .rs-uploader-draggable .rs-uploader-trigger-disabled .rs-uploader-trigger-btn {
    pointer-events: none;
  }
  .rs-rate {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    color: #ffb300;
    color: #ffb300;
    list-style: none;
    vertical-align: middle;
    margin: 0;
    padding: 0;
  }
  .rs-rate-character {
    position: relative;
    margin-right: 10px;
    padding: 3px 0;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .rs-rate-character-before {
    position: absolute;
    width: 50%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
  }
  .rs-rate-character-after {
    color: #8e8e93;
    color: #8e8e93;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
  }
  .rs-theme-high-contrast .rs-rate-character-after > .rs-icon {
    fill: none;
    stroke: currentColor;
  }
  .rs-rate-character-before,
  .rs-rate-character-after {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 1;
  }
  .rs-rate-character-before > .rs-icon,
  .rs-rate-character-after > .rs-icon {
    font-size: inherit;
  }
  .rs-rate-character-half .rs-rate-character-before {
    opacity: 1;
    -webkit-filter: none;
            filter: none;
  }
  .rs-rate-character-full .rs-rate-character-after {
    color: inherit;
    -webkit-filter: none;
            filter: none;
  }
  .rs-rate-character-full .rs-rate-character-after > .rs-icon {
    fill: currentColor;
  }
  .rs-rate-character-vertical {
    width: 100%;
    height: 50%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    bottom: 3px;
  }
  .rs-rate-character .rs-icon {
    position: absolute;
    height: 1em;
    width: 1em;
  }
  .rs-rate:not(.rs-rate-readonly).rs-rate:not(.rs-rate-disabled) .rs-rate-character:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .rs-rate:not(.rs-rate-readonly).rs-rate:not(.rs-rate-disabled) .rs-rate-character:active {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .rs-rate-disabled {
    opacity: 0.3;
    outline: none;
  }
  .rs-rate-disabled .rs-rate-character {
    cursor: not-allowed;
  }
  .rs-rate-readonly .rs-rate-character {
    cursor: default;
    pointer-events: none;
  }
  .rs-rate-lg {
    font-size: 36px;
  }
  .rs-rate-lg .rs-rate-character {
    width: 36px;
    height: 42px;
  }
  .rs-rate-md {
    font-size: 30px;
  }
  .rs-rate-md .rs-rate-character {
    width: 30px;
    height: 36px;
  }
  .rs-rate-sm {
    font-size: 24px;
  }
  .rs-rate-sm .rs-rate-character {
    width: 24px;
    height: 30px;
  }
  .rs-rate-xs {
    font-size: 18px;
  }
  .rs-rate-xs .rs-rate-character {
    width: 18px;
    height: 24px;
  }
  .rs-rate-red {
    color: #f44336;
    color: #f44336;
  }
  .rs-rate-orange {
    color: #fa8900;
    color: #fa8900;
  }
  .rs-rate-yellow {
    color: #ffb300;
    color: #ffb300;
  }
  .rs-rate-green {
    color: #4caf50;
    color: #4caf50;
  }
  .rs-rate-cyan {
    color: #00bcd4;
    color: #00bcd4;
  }
  .rs-rate-blue {
    color: #2196f3;
    color: #2196f3;
  }
  .rs-rate-violet {
    color: #673ab7;
    color: #673ab7;
  }
  .rs-toast {
    pointer-events: auto;
  }
  .rs-toast-container {
    position: fixed;
    z-index: 1080;
    pointer-events: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .rs-toast-container .rs-toast:not(.rs-notification) {
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  }
  .rs-toast-container .rs-toast-fade-entered,
  .rs-toast-container .rs-toast-fade-exiting {
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  .rs-toast-container .rs-toast-fade-entered {
    -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
    -webkit-animation-timing-function: cubic-bezier(0.99, 0.44, 0.44, 1.35);
            animation-timing-function: cubic-bezier(0.99, 0.44, 0.44, 1.35);
  }
  .rs-toast-container .rs-toast-fade-exited,
  .rs-toast-container .rs-toast-fade-entering {
    opacity: 0;
  }
  .rs-toast-container .rs-toast-fade-exited {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
  .rs-toast-container-top-center,
  .rs-toast-container-bottom-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
  }
  .rs-toast-container-top-center .rs-toast-fade-entered,
  .rs-toast-container-bottom-center .rs-toast-fade-entered {
    -webkit-animation-name: notificationMoveIn;
            animation-name: notificationMoveIn;
  }
  .rs-toast-container-bottom-start,
  .rs-toast-container-top-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    left: 24px;
  }
  .rs-toast-container-bottom-start .rs-toast-fade-entered,
  .rs-toast-container-top-start .rs-toast-fade-entered {
    -webkit-animation-name: notificationMoveInLeft;
            animation-name: notificationMoveInLeft;
  }
  .rs-toast-container-bottom-end,
  .rs-toast-container-top-end {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    right: 24px;
  }
  .rs-toast-container-bottom-end .rs-toast-fade-entered,
  .rs-toast-container-top-end .rs-toast-fade-entered {
    margin-left: auto;
    -webkit-animation-name: notificationMoveInRight;
            animation-name: notificationMoveInRight;
  }
  .rs-toast-container-top-center,
  .rs-toast-container-top-start,
  .rs-toast-container-top-end {
    top: 24px;
  }
  .rs-toast-container-bottom-center,
  .rs-toast-container-bottom-start,
  .rs-toast-container-bottom-end {
    bottom: 24px;
  }
  .rs-toast-container .rs-toast-fade-exiting {
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-timing-function: cubic-bezier(0.64, 0.65, 0.57, 1.13);
            animation-timing-function: cubic-bezier(0.64, 0.65, 0.57, 1.13);
    -webkit-animation-name: notificationMoveOut;
            animation-name: notificationMoveOut;
  }
  .rs-btn-close {
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px 8px 0;
    font-size: 12px;
    line-height: 1;
    border: none;
    outline: none !important;
    cursor: pointer;
  }
  .rs-btn-close .rs-icon {
    vertical-align: bottom;
  }
  .rs-stack {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .ad-date-picker{
    input, .rs-picker-toggle-placeholder{
      // text-transform: uppercase;
    }
    .rs-picker-toggle-active{
      box-shadow: none !important;
    }
  }