.rs-dropdown-item-focus {
    color: #000 !important;

    // active class for menu items icon
    .active-text-white {
        color: #fff !important;
    }

    // active class for menu items background
    &.active-bg-danger {
        background-color: #F32758 !important;
    }

    // active class for menu items label
    &.active-text-white {
        color: #fff !important;
    }
}